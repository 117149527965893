import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { supabaseClient } from "../component/supabaseClient";
import { useStateAuthValue } from "../context/AuthState";
//GUI
import { Button } from "react-bootstrap";
import { GoBackButton } from "../component/GoBackButton";
import LoadingSpinner from "../component/Spinner";
//Component related
import { paymentsGroups, operationTypes, operationStates } from "../constants";
import { CashBoxData, Movements, CashBoxSummary } from "../component/CashBox";
import { fireConfirmationModal } from "../component/modals/ConfirmationModal";
import { FaCashRegister } from "react-icons/fa";
import { toast } from "react-toastify";

const Cash = () => {
  const [{ userRole, userData }] = useStateAuthValue();
  const location = useLocation();

  const cajaId = location.pathname.split("/")[2] || null;

  let navigate = useNavigate();
  if (!userRole) {
    navigate("/login");
  }
  const [isLoading, setIsLoading] = useState(true);
  //lastCashBox is the lastest cash box available
  const [lastCashBox, setLastCashBox] = useState([]);
  //prevCashBox it is the box before the lastCashBox
  const [prevCashBox, setPrevCashBox] = useState([]);
  //Observations of the cashbox
  const [observations, setObservations] = useState("");
  //Flag to set if the cash is open or not
  const [isCashBoxOpen, setIsCashBoxOpen] = useState(false);

  //Since I can't find a name for "boxes" I decided to call the variable cashBoxes in the plural and cashBox in the singular. If someone knows how to name it better please modify the name in the project
  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      let query = supabaseClient
        .from("cajas")
        .select()
        .eq("depositoId", userData?.depositoId)
        .eq("cajaPrincipal", true);
      //If we dont have in the URL the cajaId, we will get the lastest cash box
      if (!cajaId) {
        //Order to get the lastest Id on the top of the results
        query.order("cajaId", { ascending: false });
        //Limit the results to 2 rows
        query.limit(2);
        //else we will get the cash box with the id in the URL
      } else {
        query.eq("cajaId", cajaId);
      }
      const { data: lastestCashBoxes, error } = await query;
      if (error) throw error;
      //The first row on the response will be the lastest
      setLastCashBox(lastestCashBoxes[0]);
      //The second row on the response will be the cash before the lastest
      setPrevCashBox(lastestCashBoxes[1]);
      //Check if the lastets cash box is open or not
      setIsCashBoxOpen(lastestCashBoxes[0]?.fechaCierre === null);
    } catch (error) {
      // Display the error message in a more user-friendly way
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [userData.depositoId, cajaId]);

  useEffect(() => {
    getData();
  }, [userData, getData]);

  //Function to reset the state of the component
  const resetState = () => {
    setLastCashBox([]);
    setPrevCashBox([]);
    setObservations("");
    setIsLoading(false);
    setIsCashBoxOpen(false);
  };

  //Self explanatory
  const insertOperacionCabecera = async (
    operationType,
    date,
    time,
    observations
  ) => {
    const { data, error } = await supabaseClient
      .from("operacionCabecera")
      .insert([
        {
          tipoOpId: operationType,
          usrId: userData.usrId,
          depositoId: userData.depositoId,
          estadoOpId: operationStates.finalizado,
          fechaAlta: date,
          horaAlta: time,
          fechaFinalizado: date,
          observaciones: observations,
        },
      ])
      .select();
    if (error) {
      console.error(
        `An error occurred while inserting operation header: ${error.message}`
      );
    }
    return data;
  };

  const insertOperacionPago = async (opCabId, total) => {
    const { data, error } = await supabaseClient
      .from("operacionPago")
      .insert([
        {
          opCabId: opCabId,
          formaPagoId: paymentsGroups.efectivo,
          total: total,
          pagado: total,
          formaPagoDescripcion: "Efectivo",
          recargo: 0,
        },
      ])
      .select();
    if (error) {
      console.error(
        `An error occurred while inserting operation payment: ${error.message}`
      );
    }
    return data;
  };

  const handleClick = () => {
    fireConfirmationModal({
      title: `¿Estás seguro de que deseas ${
        isCashBoxOpen ? "cerrar" : "abrir"
      } la caja?`,
      submit: submit,
    });
  };

  const submit = async () => {
    setIsLoading(true);
    // create new caja
    let nowDate = new Date();
    const date = `${nowDate.getFullYear()}/${
      nowDate.getMonth() + 1
    }/${nowDate.getDate()}`;
    const time = `${nowDate.getHours()}:${nowDate.getMinutes()}:${nowDate.getSeconds()}`;

    //Open the Cash Box
    if (!isCashBoxOpen) {
      let totalCash = 0;

      try {
        const { data: movements, error } = await supabaseClient
          .from("movimientos")
          .select(
            `
            *,
            formaDePago(*)
            `
          )
          .eq("cajaId", lastCashBox.cajaId)
          .in("formaPagoId", [paymentsGroups.efectivo, paymentsGroups.sena]);

        if (error) throw error;
        totalCash = movements.reduce((acc, mov) => acc + mov.monto, 0);
      } catch (error) {
        console.error(
          `An error occurred while fetching income and outcome movements: ${error.message}`
        );
      }

      const dataCabecera = await insertOperacionCabecera(
        operationTypes.caja_apertura,
        date,
        time,
        observations
      );

      if (dataCabecera) {
        const pago = await insertOperacionPago(
          dataCabecera[0].opCabId,
          totalCash
        );

        const { data: cajaB, errorCajaB } = await supabaseClient
          .from("cajas")
          .insert([
            {
              depositoId: userData.depositoId,
              descripcion: observations,
              saldo: totalCash,
              fechaApertura: date,
              cajaPrincipal: true,
              usrId: userData.usrId,
              horaApertura: time,
            },
          ])
          .select();
        if (errorCajaB) toast.error("Hubo un error en la carga de datos: " + errorCajaB);

        const { error: errorMovimientos } = await supabaseClient
          .from("movimientos")
          .insert([
            {
              opPagoId: pago[0].opPagoId,
              cajaId: cajaB[0].cajaId,
              formaPagoId: paymentsGroups.efectivo,
              monto: totalCash,
              fecha: date,
            },
          ]);
        errorMovimientos && toast.error("Hubo un error en la carga de datos: " + errorMovimientos);
      }
      resetState();

      // close caja
    } else {
      const { error: errorCajas } = await supabaseClient
        .from("cajas")
        .update([
          {
            fechaCierre: date,
            horaCierre: time,
            descripcion: observations,
          },
        ])
        .eq("cajaId", lastCashBox.cajaId);
      errorCajas && alert(errorCajas);

      const dataCabecera = await insertOperacionCabecera(
        operationTypes.caja_cierre,
        date,
        time,
        observations
      );

      if (dataCabecera) {
        await insertOperacionPago(dataCabecera[0].opCabId, lastCashBox.saldo);
      }
    }
    resetState();
    getData();
  };

  return isLoading ? (
    <div className="center-div">
      <LoadingSpinner />
    </div>
  ) : (
    <div className="caja-container">
      <div className="crud__title">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <GoBackButton />
          <div style={{ width: "100%" }}>
            <h3>
            {cajaId === null ? (
            <>
              {isCashBoxOpen && cajaId === null ? "Cierre" : "Apertura"} de caja
            </>
          ) : (
            <>Detalle de caja</>
          )}
          </h3>
          </div>
        </div>
        <hr />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          marginLeft: "10px"
        }}
      >
        <Button
          variant="outline-success my-2"
          onClick={() => {
            navigate("/listado-cajas");
          }}
        >
          <FaCashRegister /> Listado de cajas
        </Button>
      </div>
      <div className="operation-item caja-general-data">
        <CashBoxData
          isCashBoxOpen={isCashBoxOpen}
          prevCashBox={prevCashBox}
          lastCashBox={lastCashBox}
          setObservations={setObservations}
          observations={observations}
          userData={userData}
        />
      </div>
      <div className="operation-item caja-saldos">
        <CashBoxSummary
          isCashBoxOpen={isCashBoxOpen}
          lastCashBox={lastCashBox}
          prevCashBox={prevCashBox}
        />
      </div>
      <div className="operation-item caja-ingresos">
        <Movements lastCashBox={lastCashBox} hasPositiveMovements={true} />
      </div>
      <div className="operation-item caja-egresos">
        <Movements lastCashBox={lastCashBox} hasPositiveMovements={false} />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: " 0 10px 10px 0",
        }}
      >
        {cajaId === null && (
          <Button variant="success my-2" onClick={handleClick}>
            {isCashBoxOpen ? "Cerrar" : "Abrir"} Caja
          </Button>
        )}
      </div>
    </div>
  );
};

export default Cash;
