import React, { useEffect, useState } from "react";
import { supabaseClient } from "../supabaseClient";
import numeral from "numeral";

export const CashBoxSummary = ({ isCashBoxOpen, lastCashBox, prevCashBox }) => {
  const [paymentsGroups, setPaymentsGroups] = useState([]);
  const [movementsByGroup, setMovementsByGroup] = useState([]);

  //Get the payments groups from supabase
  useEffect(() => {
    const getData = async () => {
      try {
        const { data, error } = await supabaseClient
          .from("grupoFormaDePago")
          .select();
        if (error) throw error;
        setPaymentsGroups(data);
      } catch (error) {
        console.error(error);
      } finally {
      }
    };
    getData();
  }, []);

  //Get the movements for the cajaId
  useEffect(() => {
    const getMovements = async () => {
      if (lastCashBox?.cajaId) {
        try {
          const { data, error } = await supabaseClient
            .from("movimientos")
            .select(
              `
            *,
            formaDePago(*)
          `
            )
            .eq("cajaId", lastCashBox?.cajaId);
          if (error) throw error;
          setMovementsByGroup(sumAmountByGroup(data));
        } catch (error) {
          console.error(error);
        }
      }
    };
    getMovements();
  }, [lastCashBox?.cajaId]);

  // sumAmountByGroup, takes an array of movements as its argument and returns a new array that sums up the monto (amount) for each unique idGrupoFormaDePago (group ID of payment method).
  function sumAmountByGroup(movements) {
    //JavaScript function, sumAmountByGroup, takes an array of movements as its argument and returns a new array that sums up the monto (amount) for each unique idGrupoFormaDePago (group ID of payment method).
    const result = [];
    //Looping Through movements
    // Iterates through each element in the movements array using forEach.
    // Destructures each element to extract idGrupoFormaDePago and monto.
    movements.forEach(({ formaDePago: { idGrupoFormaDePago }, monto }) => {
      // Checking for Valid Values
      // Checks if both idGrupoFormaDePago and monto exist (i.e., they are not null, undefined, or 0).
      if (idGrupoFormaDePago && monto) {
        // Finding Existing Group
        // Searches for an existing object in the result array that has the same idFormaDePago as idGrupoFormaDePago.
        const group = result.find(
          (g) => g.idFormaDePago === idGrupoFormaDePago
        );
        // Updating or Adding Group
        // If an existing group is found, it updates the monto by adding the new monto to it.
        // If no existing group is found, it pushes a new object with idFormaDePago and monto to the result array.
        if (group) {
          group.monto += monto;
        } else {
          result.push({ idFormaDePago: idGrupoFormaDePago, monto });
        }
      }
    });
    // Returns the result array containing the summed-up amounts for each unique idGrupoFormaDePago.
    return result;
  }

  //Component to show the PaymentsSummary for each group
  const PaymentsSummary = ({ paymentsGroups, movementsByGroup }) => {
    return (
      <>
        {paymentsGroups.map((group) => {
          const filteredMovements = movementsByGroup.filter(
            (movement) => movement.idFormaDePago === group.idGrupoFormaDePago
          );
          return (
            <div key={group.idGrupoFormaDePago}>
              <div>
                <h6>{group.descripcion}:</h6>
              </div>
              <div>
                {filteredMovements.length > 0
                  ? numeral(
                      filteredMovements.reduce(
                        (acc, movement) => acc + movement.monto,
                        0
                      )
                    ).format("$0,0.00")
                  : numeral(0).format("$0,0.00")}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div>
        <h5 style={{ fontWeight: "bold" }}>
          {isCashBoxOpen
            ? "Saldos en la caja actual"
            : "Revisión del saldo de la caja anterior"}
        </h5>
        <div className="caja-saldo-container" style={{ borderBottom: "none" }}>
          <PaymentsSummary
            paymentsGroups={paymentsGroups}
            movementsByGroup={movementsByGroup}
          />
          <div>
            <div>
              <h6>Saldo total: </h6>
            </div>
            <div>{numeral(lastCashBox?.saldo).format("$0,0.00")}</div>
          </div>
          {/* <div className="caja-saldo-container__item">
            <div className="caja-saldo-container__item__labels">
              
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
