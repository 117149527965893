import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../styles/MuiDataTableStyle";
import { Box } from "@mui/material";
import {
  textLabels,
  sortFunction,
} from "../utils/MuiTableOptions";
import numeral from "numeral";
import { useState } from "react";

export const ReportsProductPricesTable = ({ data, priceList }) => {
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const formatNumber = (value) => {
    let price = Math.abs(value);
    price = numeral(price).format("$0,0.00");
    return price;
  };

  const columns = [
    {
      name: "prodId",
      label: "Id",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "descripcionConcatenada",
      label: "Descripción",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "stockTotal",
      label: "Stock total",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "price",
      label: "Precio",
      options: {
        filter: false,
        sort: true,
      },
    },
    ...priceList?.map((payment) => ({
      name: `payment_${payment?.formaPagoId}`,
      label: payment?.descripcion,
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: (props) => {
          return props.label.length < 18 ? props.label : props.label.substring(0, 18).concat('...')
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          const prod = data[tableMeta.currentTableData[tableMeta.rowIndex].index];
          return (
            <div style={{ textAlign: "center" }}>
              <div>
                {value}
              </div>
              {payment.cuotas > 1 && (
                <div style={{ fontWeight: "bold" }}>
                  {payment?.cuotas} cuotas de{" "}
                  {(
                    (prod?.precioVenta * (1 + payment.recargo)) /
                    payment.cuotas
                  ).toFixed(2)}
                </div>
              )}
            </div>
          );
        },
      },
    })),
  ];

  const options = {
    searchOpen: true,
    selectableRows: "none",
    textLabels,
    fixedHeader: true,
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows)
    },
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions:[100,250,500],
    fixedSelectColumn: true,
    tableBodyHeight: "600px",
    filterType: "dropdown",
    responsive: "standard",
    customSort: sortFunction,
    draggableColumns: { enabled: true },
    enableNestedDataAccess: ".",
    elevation: 0,
    filter: false,
  };
  const finalData = data?.map((producto) => ({
    ...producto,
    ...priceList.reduce((acc, pr) => {
      acc[`payment_${pr.formaPagoId}`] =
      formatNumber(
        producto?.precioVenta + producto?.precioVenta * pr.recargo
      )
      return acc;
    }, {}),
  }));
  
  return (
    <ThemeProvider theme={MuiTheme}>
      <Box
        sx={{
          "& .MuiToolbar-root":{
              position: 'sticky',
          },
        }}
      >
        <MUIDataTable
          title=""
          data={finalData}
          columns={columns}
          options={options}
        />
      </Box>
    </ThemeProvider>
  );
};
