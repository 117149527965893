import { updateSimpleData } from "./supabaseFunctions/updateData";
import { insertDataIntoTable } from "./supabaseFunctions/insertData";
import {
  getStockOfProduct,
  getSimpleDataOfTable,
} from "./supabaseFunctions/getData";
import { operationTypes, productTypes, service } from "../constants";
import { ErrorModal } from "../component/modals/ErrorModal";
import { supabaseClient } from "../component/supabaseClient";

export const updateOperationSenada = async (
  opCabId,
  header,
  estado,
  opId,
  productos,
  userData,
  navigate,
  prevOp,
  recargoAdmin
) => {
  let nowDate = new Date();
  let date =
    nowDate.getFullYear() +
    "/" +
    (nowDate.getMonth() + 1) +
    "/" +
    nowDate.getDate();
  const time = nowDate.toLocaleTimeString("it-IT");

    const dataCabecera = await updateSimpleData({
      tableName: "operacionCabecera",
      object: {
        tipoOpId: opId,
        personaId: header?.cliente.personaId,
        estadoOpId: estado,
        fechaAlta: date,
        observaciones: header?.observaciones,
        numeroFactura: header?.numFactura,
        tipoFactura: header?.tipoFactura,
        horaAlta: time,
        recargoAdmin:
          recargoAdmin &&
          Number(recargoAdmin.replace(/\./g, "").replace(/,/g, ".")),
      },
      extraQueryEq: [{ queryColumn: "opCabId", queryValue: opCabId }],
    });

    //remove prior data of products before updating it
    prevOp.operacionDetalle.map(async (op) => {
      const { error: errorStock } = await supabaseClient
        .from("movimientoStock")
        .delete()
        .eq("opDetalleId", op.opDetalleId)

      if (errorStock) {
        ErrorModal({
          title: "Error",
          text: errorStock.message,
        });
      } else {
        const { error: errorDetalle } = await supabaseClient
          .from("operacionDetalle")
          .delete()
          .eq("opDetalleId", op.opDetalleId);

        if (errorDetalle) {
          ErrorModal({
            title: "Error",
            text: errorDetalle.message,
          });
        }
      }
    });

    productos?.forEach(async (p) => {
      if (p.noStock === false || p.noStock === undefined) {
        const dataDetalle = await insertDataIntoTable({
          tableName: "operacionDetalle",
          object: {
            opCabId: opCabId,
            prodId: p.prodId,
            cantidad: p.cantidad,
            detalle:
              header?.observaciones !== ""
                ? header?.observaciones
                : p.descripcion,
            precioUnitario: Number(String(p.precioVenta).replace(/,/g, ".")),
          },
        });

        
        // hacer movimiento de deposito solo si no es presupuesto o servicio
        if (
          opId !== operationTypes.presupuesto &&
          opId !== operationTypes.sena &&
          p.tipo !== productTypes.servicio
        ) {
          await insertDataIntoTable({
            tableName: "movimientoStock",
            object: {
              tipoOpId: opId,
              prodId: p.prodId,
              opDetalleId: dataDetalle[0].opDetalleId,
              depositoId: userData.depositoId,
              fecha: date,
              cantidad: p.cantidad,
              usrId: userData.usrId,
            },
          });
          
          const prevStock = await getSimpleDataOfTable({
            tableName: "stockDeposito",
            extraQueryEq : [
              {queryColumn: "prodId", queryValue: p.prodId},
              {queryColumn: "depositoId", queryValue: userData.depositoId}
            ]});
          
          await updateSimpleData({
            tableName: "stockDeposito",
            object: {
              prodId: p.prodId,
              depositoId: userData.depositoId,
              stockActual: prevStock[0]?.stockActual - p.cantidad,
              fecha: date,
            },
            extraQueryEq: [
              { queryColumn: "prodId", queryValue: p.prodId },
              { queryColumn: "depositoId", queryValue: userData.depositoId },
            ],
          });
        }
      }
    });
    return dataCabecera;
};
