import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Toolbar, TextField } from "@material-ui/core";
import { InputLabel } from "@mui/material";
import { Button } from "react-bootstrap";

const CashBoxFilter = ({ filterData, setFilterData, lookForFilter }) => {
  const handleFilterChange = (column, value) => {
    setFilterData((pastValue) =>
      pastValue.map((v) =>
        v.label === column.label ? { ...v, filterBy: value } : v
      )
    );
  };

  return (
    <Toolbar>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "40px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "12px",
            }}
          >
            {filterData?.map((f) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <InputLabel>{f.label}</InputLabel>
                  <DesktopDatePicker
                    format="DD/MM/YYYY"
                    slotProps={{ textField: { variant: "standard", disabled: true } }}
                    value={f.filterBy}
                    onChange={(value) => {                      
                      handleFilterChange(f, value);
                    }}
                  />
                </div>
             
              ))}
          </div>
          <div>
            <Button variant="danger" onClick={lookForFilter}>
              Aplicar
            </Button>
          </div>
        </div>
      </LocalizationProvider>
    </Toolbar>
  );
};

export default CashBoxFilter;
