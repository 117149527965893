/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import LoadingSpinner from "../component/Spinner";
import { pdf } from "../utils/operationsPdf";
import LoadingModal from "../component/modals/LoadingModal";
import { useStateAuthValue } from "../context/AuthState";
import GeneralData from "../component/operationFlowSteps/generalData";
import Products from "../component/operationFlowSteps/products";
import { PdfTemplate } from "../component/operationFlowSteps/pdfTemplate";
import { GoBackButton } from "../component/GoBackButton";
import { CajaIsNotOpenModal } from "../component/CajaIsNotOpenModal";
import { fireConfirmationModal } from "../component/modals/ConfirmationModal";
import {
  saveBuyOfUsedGoodsOperation,
  deleteBuyOfUsedGoodsOperation,
} from "../utils/UsedGoodsOperationUtils";
import { getSimpleDataOfTable } from "../utils/supabaseFunctions/getData";
import { operationStates, paymentsGroups, warehousesIds } from "../constants";
import { ErrorModal } from "../component/modals/ErrorModal";

const CompraBienesUsados = ({
  isPartOfOperation = false,
  prevClient,
  usedGoodsProducts = [],
  setUsedGoodsProducts,
  usedGoodsComprobante = "",
  setUsedGoodsComprobante,
  setShowUsedGoodsModal,
}) => {
  const [{ userRole, userData }] = useStateAuthValue();
  let navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [stock, setStock] = useState();
  const [saldo, setSaldo] = useState();
  const [productos, setProductos] = useState(usedGoodsProducts);
  const [totalPrice, setTotalPrice] = useState();
  const [header, setHeader] = useState({
    comprobante: usedGoodsComprobante,
    observaciones: "",
    cliente: {},
  });
  const [client, setClient] = useState({});
  const [isEditionOfOp, setIsEditionOfOp] = useState(false);
  const [prevCajaExist, setPrevCajaExist] = useState(true);
  const [cajaB, setCajaB] = useState();
  const [prevOperation, setPrevOperation] = useState([]);
  const [deposito, setDeposito] = useState();
  const [depositos, setDepositos] = useState([]);
  const [models, setModels] = useState([]);
  const [medidas, setMedidas] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [newMeasurements, setNewMeasurements] = useState({
    marcaDes: "",
    modeloDes: "",
    medidaDes: "",
    tipoDes: "",
  });
  const [loadingPrevData, setLoadingPrevData] = useState(true);
  var nowDate = new Date();
  var date =
    nowDate.getFullYear() +
    "/" +
    (nowDate.getMonth() + 1) +
    "/" +
    nowDate.getDate();

  if (!userRole) {
    navigate("/");
  }

//Nueva manera de obtener los productos sin traer los servicios
  // useEffect(() => {
  //   const getProductsData = async () => {
  //     const { data, error } = await supabaseClient
  //     .from("stockDeposito")
  //     .select(
  //       "prodId, productos(descripcionConcatenada,tipo),stockActual,depositoId,deposito(descripcion)"
  //     )
  //     .order("prodId", { ascending: true });

  //     const resultado = data.reduce((agrupado, item) => {
  //       const { prodId, stockActual, depositoId, productos, deposito } = item;

  //       const existingEntry = agrupado.find((entry) => entry.prodId === prodId);
  //       if (existingEntry) {
  //         existingEntry.stock.push({
  //           depositoId,
  //           stockActual,
  //           productos,
  //           deposito,
  //         });
  //       } else {
  //         agrupado.push({
  //           prodId,
  //           stock: [{ depositoId, stockActual, productos, deposito }],
  //           cantidad: 1,
  //         });
  //       }
  //       return agrupado;
  //     }, []);


  //   }
  //   getProductsData();
  //   console.log("stock:",stock)
  // },[])

  useEffect(() => {
    const getData = async () => {
      setLoadingPrevData(true);

      // stockProducts().then((r) => {
      //   const productList = r.filter((item) => item.prodId !== service);
      //   const productos = productList?.map((p) => {
      //     return {
      //       ...p,
      //       cantidad: 1,
      //       precioVenta: 0,
      //       tipoDes: p?.tipoDes?.descripcion,
      //       marcaDes: p?.marcaDes?.descripcion,
      //       medidaDes: p?.medidaDes?.descripcion,
      //       modeloDes: p?.modeloDes?.descripcion,
      //       usado: p?.usado,
      //     };
      //   });
      //   setStock(productos);
      // });

      const cajaB = await getSimpleDataOfTable({
        tableName: "cajas",
        extraQueryEq: [
          { queryColumn: "depositoId", queryValue: userData.depositoId },
          { queryColumn: "cajaPrincipal", queryValue: true },
        ],
        extraQueryIs: [{ queryColumn: "fechaCierre", queryValue: null }],
      });
      setCajaB(cajaB[0]);

      const deposits = await getSimpleDataOfTable({
        tableName: "deposito",
      });
      deposits.map((dep) => {
        if (dep.depositoId === userData.depositoId) {
          setDeposito(dep);
        }
      });
      setDepositos(deposits);

      const modelos = await getSimpleDataOfTable({
        tableName: "modelo",
      });
      setModels(modelos);

      const medidas = await getSimpleDataOfTable({
        tableName: "medida",
      });
      setMedidas(medidas);

      const marcas = await getSimpleDataOfTable({
        tableName: "marca",
      });
      setMarcas(marcas);

      const tipos = await getSimpleDataOfTable({
        tableName: "tipoProducto",
      });
      setTipos(tipos);

      setLoadingPrevData(false);
    };

    getData();
  }, [userData]);

  useEffect(() => {
    const getSaldo = async () => {
      // get cash saldo
      const movementsEfective = await getSimpleDataOfTable({
        tableName: "movimientos",
        lookFor: "*,formaDePago(*)",
        extraQueryEq: [
          {
            queryColumn: "cajaId",
            queryValue: cajaB.cajaId,
          },
          {
            queryColumn: "formaPagoId",
            queryValue: paymentsGroups.efectivo,
          },
        ],
      });

      const amount = movementsEfective.reduce(
        (acc, movement) => acc + movement.monto,
        0
      );

      setSaldo(amount);
    };

    if (cajaB) {
      getSaldo();
    }
  }, [cajaB]);

  useEffect(() => {
    const getPrevData = async () => {
      const operation = await getSimpleDataOfTable({
        tableName: "operacionDetalle",
        lookFor:
          "*, cabecera: operacionCabecera(*, cliente: personas(*)), producto: productos(*, stockDeposito(*)), tipoDes : productos(tipoProducto(*)), marcaDes : productos(marca(*)), modeloDes : productos(modelo(*)), medidaDes : productos(medida(descripcion))",
        extraQueryEq: [
          { queryColumn: "opCabId", queryValue: state?.operation },
        ],
      });
      if (operation) {
        setIsEditionOfOp(true);
        setPrevOperation(operation);
        setHeader({
          comprobante: operation[0]?.cabecera?.numeroFactura,
          observaciones: operation[0]?.cabecera?.observaciones,
          cliente: operation[0]?.cabecera?.cliente,
        });
        let prevProd = [];
        operation?.map((op) => {
          prevProd.push({
            ...op?.producto,
            cantidad: op.cantidad,
            precioVenta: op?.precioUnitario,
            tipoDes: op?.tipoDes?.tipoProducto?.descripcion || "",
            marcaDes: op?.marcaDes?.marca?.descripcion || "",
            medidaDes: op?.medidaDes?.medida?.descripcion || "",
            modeloDes: op?.modeloDes?.modelo?.descripcion || "",
          });
        });
        setProductos(prevProd);
      }
    };
    if (state?.operation) {
      getPrevData();
    } else {
      setIsEditionOfOp(false);
    }
  }, [state?.operation]);

  useEffect(() => {
    if (cajaB?.cajaId) {
      setPrevCajaExist(true);
    } else {
      setPrevCajaExist(false);
    }
  }, [cajaB]);

  const submit = async ({ deleteOp }) => {
    setLoadingPrevData(true);
      if (!deleteOp) {
        const saveOp = await saveBuyOfUsedGoodsOperation({
          userData,
          header,
          productos,
          cajaB,
        });
        if (saveOp) {
          setLoadingPrevData(false);
          navigate("/");
        }
      } else {
        deleteBuyOfUsedGoodsOperation({
          userData,
          prevOperation,
          productos,
          cajaB,
        });
        setLoadingPrevData(false);
        navigate("/operaciones");
      }
  };

  return loadingPrevData ? (
    <div className="center-div">
      <LoadingSpinner />
    </div>
  ) : (
    <>
      <CajaIsNotOpenModal prevCajaExist={prevCajaExist} />
      <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>Compra de bienes usados</h3>
              </div>
            </div>
            <hr />
          </div>
      <div className="operation-container">
        <div className="operation-general-data operation-item">
          <GeneralData
            userData={userData}
            header={header}
            setHeader={setHeader}
            state={{ ...state, deposito: deposito }}
            disable={isEditionOfOp}
            isCompra={true}
            operation={prevOperation[0]?.cabecera}
            isPartOfOperation={isPartOfOperation}
            prevClient={prevClient}
            client={client}
            setClient={setClient}
          />
        </div>
        <div className="operation-product-list operation-item">
          <Products
            disable={isEditionOfOp}
            setProducts={setProductos}
            products={productos}
            total={totalPrice}
            setTotal={setTotalPrice}
            depositoId={userData.depositoId}
            stock={stock}
            setStock={setStock}
            depositos={depositos}
            isCompra={true}
            saldo={saldo}
          />
        </div>
      </div>
      <div className="operation-buttons-container">
        <Button
          disabled={header?.cliente?.razonSocial ? false : true}
          variant="success my-2"
          onClick={() => {
            if (header?.cliente?.razonSocial) {
              pdf({
                filename: header.cliente.razonSocial + " - " + date,
                id: "presupuesto",
              });
            }
          }}
        >
          Imprimir
        </Button>
        <Button
          variant="secondary my-2"
          disabled={
            userRole !== 1 ||
            prevOperation[0]?.cabecera?.estadoOpId === operationStates.anulada
          }
          onClick={() => {
            if (isPartOfOperation) {
              setShowUsedGoodsModal(false);
            } else {
              fireConfirmationModal({
                title: `¿Estás seguro de que deseas anular esta compra?`,
                submit: () => {
                  if (!isEditionOfOp) {
                    navigate("/operaciones");
                  } else {
                    submit({
                      deleteOp: true,
                    });
                  }
                },
              });
            }
          }}
        >
          Anular
        </Button>
        <Button
          variant="outline-success my-2"
          onClick={() => {
            let total = 0;
            total = productos.map(
              (p) =>
                total +
                (Number(String(p.precioVenta).replace(/\./g, "").replace(/,/g, "."))*p.cantidad)
            );
            const hasNoQuantityProduct = productos.filter((item) => item.cantidad === '' || item.cantidad === 0);
            if (isPartOfOperation) {
              setUsedGoodsProducts(productos);
              setUsedGoodsComprobante(header.comprobante);
              setShowUsedGoodsModal(false);
            } else if (!isEditionOfOp && saldo < total[0]) {
              ErrorModal({
                title: "Error",
                text: `El saldo es insuficiente para realizar esta operación, su saldo actual es: $${saldo}`,
              });
            } else if (!isPartOfOperation && !header.cliente.personaId) {
              ErrorModal({
                title: "Debe escoger un cliente",
              });
            } else if (!isEditionOfOp && !header.comprobante) {
              ErrorModal({
                title: "Debe indicar el número de comprobante",
              });
            } else if (productos.length === 0) {
              ErrorModal({
                title: "Debe escoger al menos un producto para la venta",
              });
            } else if (hasNoQuantityProduct.length !== 0) {
              ErrorModal({
                title: "No se escogió stock de un producto",
              });
            } else {
              if (isEditionOfOp) {
                navigate("/operaciones");
              } else {
                productos.map((item) => {
                  if (item.cantidad === 0) {
                    ErrorModal({
                      title: `Debe seleccionar al menos una unidad en todos los productos`,
                    });
                  }
                })
                fireConfirmationModal({
                  title: `¿Estás seguro de que deseas realizar esta compra?`,
                  submit: () => {
                    submit({
                      deleteOp: false,
                    });
                  },
                });
              }
            }
          }}
        >
          Guardar
        </Button>
      </div>
      <div
        style={{
          visibility: "hidden",
          zIndex: "-9999",
          position: "fixed",
        }}
      >
        {deposito && deposito.length !== 0 && header.cliente && (
          <PdfTemplate
            generalData={{
              today: date,
              deposito: deposito[0]?.domicilio,
              vendedor: userData?.nombre,
            }}
            header={header}
            productos={productos}
            renderPrices={false}
            isCompra={true}
          />
        )}
      </div>
      <LoadingModal text="Cargando" show={loading} setShow={setLoading} />
    </>
  );
};

export default CompraBienesUsados;
