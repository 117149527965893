/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { supabaseClient } from "../../component/supabaseClient";
import { Formik, Field, Form } from "formik";
import { useStateAuthValue } from "../../context/AuthState";
import { useNavigate, useLocation } from "react-router-dom";
import { GoBackButton } from "../../component/GoBackButton";
import { errAndMsg } from "../../utils/MsgAlert";

const AddMedida = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { medida } = state || {};
  const submedida = medida ? medida?.descripcion.split("/") : "";
  const [{ userRole }] = useStateAuthValue();
  const [customized, setCustomized] = useState(false);

  if (userRole !== 1) {
    navigate("/");
  }

  useEffect(() => {
    setCustomized(medida.personalizada);
  }, [medida]);

  const handleChangeMedida = () => {
    setCustomized(!customized);
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>{medida ? "Editar medida" : "Agregar nueva medida"}</h3>
              </div>
            </div>
            <hr />
          </div>
      <Formik
        initialValues={
          medida
            ? {
                ancho: submedida[0],
                alto: submedida[1],
                diametro: submedida[2],
                activa: medida.activa,
                medidaPersonalizada: medida.descripcion,
                personalizada: medida.personalizada,
              }
            : {
                ancho: "",
                alto: "",
                diametro: "",
                activa: true,
                personalizada: false,
                medidaPersonalizada: "",
              }
        }
        //revisar validación
        validate={(values) => {
          const errors = {};
          if (!values.personalizada) {
            if (!values.ancho || values.ancho == 0) {
              errors.ancho = "Campo requerido";
            }
            if (!values.alto || values.alto == 0) {
              errors.alto = "Campo requerido";
            }
            if (!values.diametro || values.diametro == 0) {
              errors.diametro = "Campo requerido";
            }
          } else if (!values.medidaPersonalizada) {
            errors.medidaPersonalizada = "Campo requerido";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const descripcion = values.personalizada
            ? values.medidaPersonalizada
            : values.ancho + "/" + values.alto + "/" + values.diametro;
          if (!medida) {
            try {
              // eslint-disable-next-line no-unused-vars
              const { data, error } = await supabaseClient
                .from("medida")
                .insert([
                  {
                    descripcion: descripcion,
                    activa: values.activa,
                    personalizada: values.personalizada,
                  },
                ])
                .select();
              if (error) errAndMsg("Error", error.message, error.code);
              setSubmitting(false);
              navigate("/configuracion/medida");
            } catch (error) {
              errAndMsg("Error", error.message, error.code);
            }
          } else {
            const { error } = await supabaseClient
              .from("medida")
              .update({
                descripcion: descripcion,
                activa: values.activa,
                personalizada: values.personalizada,
              })
              .eq("medidaId", medida.medidaId);
            if (error) errAndMsg("Error", error.message, error.code);
            setSubmitting(false);
            navigate("/configuracion/medida");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="form-container form-container-three-colums">
              <div className="form-group form-container__item">
                <div className="row">
                  <div className="col-3">
                    <input
                      type="radio"
                      id="medida"
                      name="medida"
                      checked={!customized}
                      onChange={() => {
                        handleChangeMedida();
                        setFieldValue("personalizada", false);
                      }}
                    />
                    <label
                      className={`form-container__label2 ${
                        customized ? "form-radio-disabled" : ""
                      }`}
                      htmlFor="medida"
                    >
                      Ancho
                    </label>
                    <Field
                      className="form-control text-center"
                      id="ancho"
                      name="ancho"
                      placeholder="Ej: 195"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ancho}
                      disabled={customized}
                    />
                    <div className="invalid-feedback">
                      {errors.ancho && touched.ancho && errors.ancho}
                    </div>
                  </div>
                  <div className="col-1">
                    <label
                      className="form-container__label"
                      htmlFor="span1"
                    ></label>
                    <span id="span1" className="form-control no-border">
                      /
                    </span>
                  </div>
                  <div className="col-3">
                    <label
                      className={`form-container__label ${
                        customized ? "form-radio-disabled" : ""
                      }`}
                      htmlFor="medida"
                    >
                      Alto
                    </label>
                    <Field
                      className="form-control text-center"
                      id="alto"
                      name="alto"
                      placeholder="Ej: 50"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.alto}
                      disabled={customized}
                    />
                    <div className="invalid-feedback">
                      {errors.alto && touched.alto && errors.alto}
                    </div>
                  </div>
                  <div className="col-1">
                    <label
                      className="form-container__label"
                      htmlFor="span2"
                    ></label>
                    <span id="span2" className="form-control no-border">
                      /R
                    </span>
                  </div>
                  <div className="col-3">
                    <label
                      className={`form-container__label  ${
                        customized ? "form-radio-disabled" : ""
                      }`}
                      htmlFor="medida"
                    >
                      Diámetro
                    </label>
                    <Field
                      className="form-control text-center"
                      id="diametro"
                      name="diametro"
                      placeholder="Ej: 16"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.diametro}
                      disabled={customized}
                    />
                    <div className="invalid-feedback">
                      {errors.diametro && touched.diametro && errors.diametro}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group form-container__item">
                <input
                  type="radio"
                  id="medidaPersonalizada"
                  name="medidaPersonalizada"
                  checked={customized}
                  onChange={(e) => {
                    handleChangeMedida();
                    setFieldValue("personalizada", true);
                  }}
                />
                <label
                  className={`form-container__label2 ${
                    customized ? "" : "form-radio-disabled"
                  }`}
                  htmlFor="medidaPersonalizada"
                >
                  Medida personalizada
                </label>
                <Field
                  className="form-control"
                  id="medidaPersonalizada"
                  name="medidaPersonalizada"
                  placeholder="195/60/R16 x 1234"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("medidaPersonalizada", e.target.value);
                  }}
                  value={values.medidaPersonalizada}
                  disabled={!customized}
                />
                <div className="invalid-feedback">
                  {errors.medidaPersonalizada &&
                    touched.medidaPersonalizada &&
                    errors.medidaPersonalizada}
                </div>
              </div>
              <div className="form-group form-container__item">
                <div className="form-group">
                  <label className="form-container__label" htmlFor="medidaDes">
                    Activa
                  </label>
                  <Field
                    as="select"
                    className="form-control form-select"
                    id="activa"
                    name="activa"
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue(
                        "activa",
                        e.target.options[e.target.selectedIndex].value
                      );
                    }}
                    onBlur={handleBlur}
                    value={values.activa}
                  >
                    <option value="">- Escoger</option>
                    <option id="1" value={true}>
                      Activa
                    </option>
                    <option id="0" value={false}>
                      No activa
                    </option>
                  </Field>
                </div>
                <div className="form-container__button">
                  <button
                    className="btn btn-dark"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {medida ? "Editar medida" : "Agregar medida"}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddMedida;
