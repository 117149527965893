import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import LoadingSpinner from "../component/Spinner";
import { ThemeProvider } from "@mui/material/styles";
import { BsPlusLg } from "react-icons/bs";
import { IoReload } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { MuiTheme } from "../styles/MuiDataTableStyle";

import {
  textLabels,
  downloadOptions,
  sortFunction,
} from "../utils/MuiTableOptions";
import { GoBackButton } from "../component/GoBackButton";
import { supabaseClient } from "../component/supabaseClient";
import { errAndMsg } from "../utils/MsgAlert";
import { getSimpleDataOfTable } from "../utils/supabaseFunctions/getData";

const Stock = () => {
  const navigate = useNavigate();
  const [stockProductos, setStockProductos] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [depositos, setDepositos] = useState([]);

  const getStockData = async () => {
    try {

      const productos = await getSimpleDataOfTable({
        tableName: "productos",
        lookFor:
          "*, tipoDes : tipoProducto(descripcion), stock: stockDeposito(*, deposito : deposito(*))",
        extraQueryOrder: [
          {
            queryColumn: "descripcionConcatenada",
            queryValue: { ascending: true },
          },
        ],
      });

      setStockProductos(productos);
    } catch (error) {
      console.error("Error al obtener el stock:", error);
    }
  };
  useEffect(() => {
    getStockData();
  }, []);

  useEffect(() => {
    const getDeposits = async () => {
      const { data, error } = await supabaseClient
        .from("deposito")
        .select()
        .eq("mostrar", true);
      if (data) setDepositos(data);
      if (error)
        errAndMsg("Error al obtener los depósitos", error.message, error.code);
    };
    getDeposits();
  }, []);

  const allDepositos = depositos.map((deposito) => deposito.descripcion);

  // // Obtenemos el stock de un producto en un depósito específico
  const getStockForProductoAndDeposito = (prodId, depositoId) => {
    const producto = stockProductos.find((stock) => stock.prodId === prodId);
    if (producto) {
      const stockData = producto.stock.find(
        (stockItem) => stockItem.depositoId === depositoId
      );
      return stockData ? stockData.stockActual : 0;
    }
    return 0; // predeterminado si no se encuentra el producto
  };

  const columns = [
    {
      name: "prodId",
      label: "ID",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "nombreProducto",
      label: "Producto",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "stockTotal",
      label: "Total Stock",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    ...depositos.map((dep) => ({
      name: `stock_${dep.depositoId}`,
      label: dep.descripcion,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const producto =
            data[tableMeta.currentTableData[tableMeta.rowIndex].index];
          return (
            <div style={{ textAlign: "center" }}>
              {getStockForProductoAndDeposito(producto.prodId, dep.depositoId)}
            </div>
          );
        },
      },
    })),
  ];

  const options = {
    searchOpen: true,
    textLabels,
    downloadOptions,
    filter: true,
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows)
    },
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions:[100,250,500],
    selectableRows: "none",
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: "600px",
    filterType: "dropdown",
    responsive: "standard",
    customSort: sortFunction,
    draggableColumns: { enabled: true },
  };

  const data = stockProductos?.map((producto) => {
    const prodId = producto.prodId;
    const stockTotal = producto.stock.reduce(
      (total, stockItem) => total + stockItem.stockActual,
      0
    );

    return {
      prodId: prodId,
      stockTotal: stockTotal,
      nombreProducto: producto.descripcionConcatenada,
      ...allDepositos.reduce((acc, dep) => {
        acc[
          `stock_${depositos.find((d) => d.descripcion === dep).depositoId}`
        ] = getStockForProductoAndDeposito(
          producto.prodId,
          depositos.find((d) => d.descripcion === dep).depositoId
        );
        return acc;
      }, {}),
    };
  });

  return (
    <>
      <ThemeProvider theme={MuiTheme}>
        <div>
          <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>Stock</h3>
              </div>
            </div>
            <hr />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginLeft: "10px",
            }}
          >
            <Button
              variant="outline-success my-2"
              onClick={() => {
                navigate("agregar-producto", { state: { product: false } });
              }}
            >
              <BsPlusLg /> Nuevo producto
            </Button>
            <Button
              variant="outline-success my-2"
              style={{ marginLeft: 20 }}
              onClick={() => {
                setStockProductos([]);
                getStockData();
              }}
            >
              <IoReload /> Recargar Stock
            </Button>
          </div>

          {stockProductos ? (
            <div
              style={{
                margin: "8px",
                overflow: "scroll",
              }}
            >
              <MUIDataTable data={data} columns={columns} options={options} />
            </div>
          ) : (
            <div className="center-div">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </ThemeProvider>
    </>
  );
};

export default Stock;
