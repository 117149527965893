import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
  userData: [],
  userRole : null
};

const AuthReducer = (state, action) => {
  switch (action.type) {
    case 'LOG_IN_ADMIN':
      return {
        ...state,
        userData : action.userData,
      };
    case 'LOG_IN_USER':
        return {
          ...state,
          userData : action.userData,
          userRole : action.userRole
        };

    case 'LOG_OUT':
      return {
        ...state,
        userRole : null,
        userData : null,
      };

    case 'SIGN_UP':
      return {
        ...state,
        userData : action.userData,
        userRole : action.userRole
      };
    default:
      return state;
  }
};

const AuthContext = createContext();

export const AuthState = ({ children }) => (
  <AuthContext.Provider value={useReducer(AuthReducer, initialState)}>{children}</AuthContext.Provider>
);

export const useStateAuthValue = () => useContext(AuthContext);
