import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { supabaseClient } from "../../component/supabaseClient";
import { useNavigate } from "react-router-dom";
import { MuiTheme } from "../../styles/MuiDataTableStyle";
import MUIDataTable from "mui-datatables";
import LoadingModal from "../../component/modals/LoadingModal";
import {MdEdit} from "react-icons/md";

import {
  textLabels,
  downloadOptions,
  sortFunction,
} from "../../utils/MuiTableOptions";
import { GoBackButton } from "../../component/GoBackButton";
import { errAndMsg } from "../../utils/MsgAlert";

function Modelo() {
  const navigate = useNavigate();

  const [modelo, setModelo] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getModelo = async () => {
      const { data, error } = await supabaseClient
        .from("modelo")
        .select()
        .eq("mostrar", true)
        .order("modeloId", { ascending: true });
      if (error) {
        errAndMsg("Error", error.message, error.code);
      } else {
        data && setModelo(data);
      }
    };
    getModelo();
  }, []);

  const editModelo = (event) => {
    // eslint-disable-next-line array-callback-return
    modelo?.map((m) => {
      if (m.modeloId === parseInt(event.currentTarget.id)) {
        navigate("editar-modelo", {
          state: {
            modelo: m,
          },
        });
      }
    });
  };

  const columns = [
    {
      name: "modeloId",
      label: "Id",
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta) => (
          <th style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
            {columnMeta.label}
          </th>
        ),
      },
    },
    {
      name: "descripcion",
      label: "Descripcion",
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta) => (
          <th style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
            {columnMeta.label}
          </th>
        ),
       },
    },
    {
      name: "acciones",
      label: "Acciones",
      options: {
        customHeadRender: (columnMeta) => (
          <th style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
            {columnMeta.label}
          </th>
        ),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div className="crud__table__icon-container">
              <MdEdit
                id={modelo[dataIndex].modeloId}
                style={{ cursor: "pointer" }}
                className="crud__table__icon"
                alt="Item menú"
                onClick={editModelo}
              />
            </div>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    searchOpen: true,
    textLabels,
    downloadOptions,
    filter: true,
    selectableRows: "none",
    fixedHeader: false,
    filterType: "dropdown",
    responsive: "standard",
    customSort: sortFunction,
    draggableColumns: { enabled: true },
  };

  return (
    <>
      <ThemeProvider theme={MuiTheme}>
        <div className="crud">
        <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>Modelos</h3>
              </div>
            </div>
            <hr />
          </div>
          <MUIDataTable
            title={"Listado de modelos"}
            data={modelo}
            columns={columns}
            options={options}
          />
          <div className="crud__rigth-buttons-container">
            <button
              className="btn btn-dark"
              onClick={() => {
                navigate("agregar-modelo", { state: { modelo: false } });
              }}
            >
              Agregar nuevo modelo
            </button>
          </div>
        </div>
        <LoadingModal
          text="Eliminando modelo..."
          show={loading}
          setShow={setLoading}
        />
      </ThemeProvider>
    </>
  );
}

export default Modelo;
