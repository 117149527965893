import React from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";

const LoadingSpinner = () => {
   return (
      <div className="loading-spinner">
         <ScaleLoader
            color={"red"}
            loading={true}
            size={50}
        />     
      </div>
   );
};

export default LoadingSpinner;
