import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import numeral from "numeral";
import { formatDate } from "../../utils/formatDate";

function CheckDetailsModal({ data, setShow, show }) {
  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Detalles del cheque</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="user-details-modal">
          <div className="user-details-modal-container">
            <div className="user-details-modal-item">
              <h4 className="user-details-modal-item__subtitle">
                Número de cheque:
              </h4>
              <p>{data?.nroCheque ? data?.nroCheque : "No especificado"}</p>
            </div>
            <hr style={{ margin: "0" }} />
            <div className="user-details-modal-item">
              <h4 className="user-details-modal-item__subtitle">
                Fecha de operación:
              </h4>
              <p>{formatDate(data?.fecha)}</p>
            </div>
            <hr style={{ margin: "0" }} />
          </div>
          <div className="user-details-modal-container">
            <div className="user-details-modal-item">
              <h4 className="user-details-modal-item__subtitle">Monto: </h4>
              <p>{numeral(data?.monto).format("$0,0.00")}</p>
            </div>
            <hr style={{ margin: "0" }} />
            <div className="user-details-modal-item">
              <h4 className="user-details-modal-item__subtitle">Banco:</h4>
              <p>{data?.banco ? data?.banco : "No especificado"}</p>
            </div>
            <hr style={{ margin: "0" }} />
            <div className="user-details-modal-item">
              <h4 className="user-details-modal-item__subtitle">Sucursal:</h4>
              <p>{data?.sucursal ? data?.sucursal : "No especificado"}</p>
            </div>
            <hr style={{ margin: "0" }} />
            <div className="user-details-modal-item">
              <h4 className="user-details-modal-item__subtitle">Titular:</h4>
              <p>{data?.titular ? data?.titular : "No especificado"}</p>
            </div>
            <hr style={{ margin: "0" }} />
            <div className="user-details-modal-item">
              <h4 className="user-details-modal-item__subtitle">
                Fecha de emisión de cheque:
              </h4>
              <p>{data?.fechaEmision ? formatDate(data?.fechaEmision) : "No especificado"}</p>
            </div>
            {data?.fechaCobro && (
              <>
                <hr style={{ margin: "0" }} />
                <div className="user-details-modal-item">
                  <h4 className="user-details-modal-item__subtitle">
                    Fecha de cobro de cheque:
                  </h4>
                  <p>{formatDate(data?.fechaCobro)}</p>
                </div>
              </>
            )}
            {data?.fechaVencimiento && (
              <>
                <hr style={{ margin: "0" }} />
                <div className="user-details-modal-item">
                  <h4 className="user-details-modal-item__subtitle">
                    Fecha de vencimiento de cheque:
                  </h4>
                  <p>{formatDate(data?.fechaVencimiento)}</p>
                </div>
              </>
            )}
            <hr style={{ margin: "0" }} />
            <div className="user-details-modal-item">
              <h4 className="user-details-modal-item__subtitle">Procesado:</h4>
              <p>{data?.procesado ? "SI" : "NO"}</p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} variant="secondary">
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CheckDetailsModal;
