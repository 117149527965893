import {
    insertDataIntoTable,
    insertProdDetails,
    checkBeforeInsertProduct
  } from "./supabaseFunctions/insertData";
  import {
    checkStockDepositoBeforeUpdate
  } from "./supabaseFunctions/updateData";
  import {
    operationStates,
    operationTypes,
  } from "../constants";
  
  export const addNewProduct = async ({
    userData,
    productos,
  }) => {
    let nowDate = new Date();
    let date =
      nowDate.getFullYear() +
      "/" +
      (nowDate.getMonth() + 1) +
      "/" +
      nowDate.getDate();
    const time = nowDate.toLocaleTimeString("it-IT");
  
    const dataCabecera = await insertDataIntoTable({
      tableName:"operacionCabecera",
      object:{
        usrId: userData.usrId,
        tipoOpId: operationTypes.stock_ingreso_manual,
        estadoOpId: operationStates.finalizado,
        fechaAlta: date,
        observaciones: "Creación de producto",
        horaAlta: time,
        depositoId: userData.depositoId,
    }});
  
    productos.map(async (p) => {
      const measurements = await insertProdDetails(p);
  
      // obtener producto nuevo
      const prod = await checkBeforeInsertProduct({ p, measurements })

      await checkStockDepositoBeforeUpdate({
        p: prod,
        depositoId : userData.depositoId,
        date: date,
        isSum: true
      }); 
  
      //TODO:actualizar tipoOpId en compra de bienes
      const detail = await insertDataIntoTable({
        tableName:"operacionDetalle",
        object: {
        opCabId: dataCabecera[0].opCabId,
        prodId: prod.prodId,
        cantidad: p.cantidad,
        detalle: p.descripcionConcatenada,
        precioUnitario: p.precioVenta,
      }});
  
        await insertDataIntoTable({
          tableName:"movimientoStock",
          object: {
            prodId: prod.prodId,
            tipoOpId: operationTypes.stock_ingreso_manual,
            opDetalleId : detail[0].opDetalleId,
            depositoId: userData.depositoId,
            cantidad: p.cantidad,
            fecha: date,
            usrId: userData.usrId,
            hora: time
          }
        });
    });
    return true; 
  };
