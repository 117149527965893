import Swal from 'sweetalert2';

export const ErrorModal = ({
    title,
    text,
    icon = "error"
}) => {
    Swal.fire({
        title : title,
        text: text,
        icon: icon,
        showCancelButton: false,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
      })

    return <></>;
}