import { Table } from "react-bootstrap";
import Logo from "../../assets/brand/logo24.png";

export const StockMovementPdfTemplate = ({generalData, header}) => {

    return(
    <div id="cash-movement" style={{width: "1180px"}}>
        <div style={{
            backgroundColor: "black",
            padding: "15px",
            display: "flex",
            justifyContent: "space-between"
        }}>
            <img src={Logo} alt="Gomeria Norte" />
        </div>
        <h3 style={{textAlign: "center"}}>Comprobante {generalData.isIngreso ? "ingreso" : generalData.isTransfer ? "traslado" : "egreso"} de mercadería</h3>
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px"
        }}>
            <div>
                <h5>Datos generales</h5>
                <p style={{margin: 0}}>Sucursal  {generalData.deposito}</p>
                <p style={{margin: 0}}>Vendedor : {generalData.vendedor}</p>  
            </div>
            <div>
            <h5>Usuario relacionado</h5>
            <p style={{margin: 0}}>{header.userRelacionado.nombre}</p>
            <p style={{margin: 0}}>{header.userRelacionado.deposito}</p>
            <p style={{margin: 0}}>{header.userRelacionado.email}</p>
            </div>
            <div>
                <p style={{margin: 0}}>Fecha : {generalData.today}</p>
            </div>
        </div>
        
        <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th style={{ "width": "90%"}}>Descripción</th>
            <th>Cantidad</th>
          </tr>
        </thead>
        <tbody>
        {generalData.productos.map((p, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {p?.tipoDes + " - " + p?.marcaDes + " - " + p?.modeloDes + " - " + p?.medidaDes + " - " + p?.descripcion}
                        
                      </div>
                    </td>
                    <td
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                    <input
                    style={{ width: "70px", margin: "0 10px" }}
                    className="form-control"
                    type="number"
                    defaultValue={p.cantidad}
                    />
                    </td>
                  </tr>
                );
              })}
        </tbody>
        </Table>
    </div>
)}