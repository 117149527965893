import React from "react";
import LoginForm from "../component/LoginForm";
import Logo from "../assets/brand/logo.png"

const Login = () => {
  return <div className="login-container">
    <div className="login-container__box center-div">
      <div className="login-title">
        <div>
          <img src={Logo} style={{
            width: "360px"
          }} alt="Gomeria Norte" />  
        </div>      
        <h5 style={{
              textTransform: "uppercase",
              color: "black"
        }}>Sistema de administración</h5>
      </div>
    </div>
    <div className="login-container__mobile-logo">
          <img src={Logo} alt="Gomeria Norte" />  
        </div> 
    <div className="center-div">
    <LoginForm />
    </div>
  </div>;
};

export default Login;
