import React from "react";
import { useLocation } from "react-router-dom";
import Operations from "./Reports/Operations";
import Stock from "./Reports/Stock";
import Users from "./Reports/Users";
import StockGlobal from "./Reports/StockGlobal";

const Reports = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/reportes/operaciones" && <Operations />}
      {location.pathname === "/reportes/stock" && <Stock />}
      {location.pathname === "/reportes/stock-global" && <StockGlobal />}
      {location.pathname === "/reportes/usuarios" && <Users />}
    </>
  );
};

export default Reports;
