import React from "react";
import { useLocation } from "react-router-dom";
import FormaDePago from "./Configurations/FormaDePago";
import Marca from "./Configurations/Marca";
import Medida from "./Configurations/Medida";
import Modelo from "./Configurations/Modelo";
import TipoProducto from "./Configurations/TipoProducto";
import Warehouses from "./Configurations/Warehouses";
import Users from "./Configurations/Users";

const Configuration = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/configuracion/depositos" && <Warehouses />}
      {location.pathname === "/configuracion/formas-pago" && <FormaDePago />}
      {location.pathname === "/configuracion/marca" && <Marca />}
      {location.pathname === "/configuracion/medida" && <Medida />}
      {location.pathname === "/configuracion/modelo" && <Modelo />}
      {location.pathname === "/configuracion/tipo-producto" && <TipoProducto />}
      {location.pathname === "/configuracion/usuarios" && <Users />}
    </>
  );
};

export default Configuration;
