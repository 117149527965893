//Constants for the payment groups
export const paymentsGroups = {
  tarjetas: 2,
  efectivo: 3,
  cheques: 4,
  transferencias: 5,
  pagare: 6,
  ordenesDeCompanias: 7,
  sena: 15
};
