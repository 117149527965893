/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useStateAuthValue } from "../context/AuthState";
import { useNavigate, useLocation } from "react-router-dom";
import { getSimpleDataOfTable } from "../utils/supabaseFunctions/getData";
import { insertDataIntoTable } from "../utils/supabaseFunctions/insertData";
import { updateCajaSumNewPayment } from "../utils/supabaseFunctions/updateData";
import GeneralDataCashMovements from "../component/CashMovementsSteps/generalData";
import PaymentCashMovements from "../component/CashMovementsSteps/payment";
import { Button } from "react-bootstrap";
import { pdf } from "../utils/operationsPdf";
import { CashMovementPdfTemplate } from "../component/CashMovementsSteps/cashMovementPdfTemplate";
import { CajaIsNotOpenModal } from "../component/CajaIsNotOpenModal";
import LoadingSpinner from "../component/Spinner";
import { GoBackButton } from "../component/GoBackButton";
import { ErrorModal } from "../component/modals/ErrorModal";
import { paymentsGroups } from "../constants";

const CashMovement = () => {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const isIngreso = state?.ingreso === true || pathname === "/ingreso-efectivo";
  const [{ userRole, userData }] = useStateAuthValue();
  const [relatedUser, setClientRelatedUser] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [prevOp, setPrevOp] = useState();
  const [cajaB, setCajaB] = useState();
  const [loading, setLoading] = useState(true);
  const [prevCajaExist, setPrevCajaExist] = useState(true);
  const [header, setHeader] = useState({
    caja: "B",
    observaciones: "",
    cliente: {},
  });
  const [deposito, setDeposito] = useState();
  const [amount, setAmount] = useState(0);
  let nowDate = new Date();
  let date =
    nowDate.getFullYear() +
    "/" +
    (nowDate.getMonth() + 1) +
    "/" +
    nowDate.getDate();
  const time = nowDate.toLocaleTimeString("it-IT");

  if (!userRole) {
    navigate("/");
  }

  useEffect(() => {
    const getPrevData = async () => {
      setDisabled(true);
      const opPago = await getSimpleDataOfTable({
        tableName: "operacionPago",
        lookFor:
          "*, movimientos(*, caja : cajas(cajaPrincipal)), operacionCabecera(*, usrRelacionado: usrIdRelacionado(*))",
        extraQueryEq: [
          { queryColumn: "opCabId", queryValue: state?.operation },
        ],
      });

      setPrevOp(opPago[0]?.operacionCabecera)
      setAmount(opPago[0].pagado);

      if (opPago[0].movimientos[0]?.caja.cajaPrincipal === true) {
        setHeader({ ...header, caja: "B" });
      } else {
        setHeader({ ...header, caja: "N" });
      }
      setHeader({
        ...header,
        cliente: opPago[0]?.operacionCabecera?.usrRelacionado,
      });
      setClientRelatedUser(opPago[0]?.operacionCabecera?.usrRelacionado);
    };

    if (state?.operation) {
      getPrevData();
    } else {
      setDisabled(false);
      setHeader({
        caja: "B",
        observaciones: "",
        cliente: {},
      });
      setClientRelatedUser({});
      setAmount(0);
    }
  }, [state?.operation]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      const deposito = await getSimpleDataOfTable({
        tableName: "deposito",
        extraQueryEq: [
          { queryColumn: "depositoId", queryValue: userData.depositoId },
        ],
      });
      setDeposito(deposito);

      const cajaB = await getSimpleDataOfTable({
        tableName: "cajas",
        extraQueryEq: [
          { queryColumn: "depositoId", queryValue: userData.depositoId },
          { queryColumn: "cajaPrincipal", queryValue: true },
        ],
        extraQueryIs: [{ queryColumn: "fechaCierre", queryValue: null }],
      });
      setCajaB(cajaB[0]);

      setLoading(false);
    };
    getData();
  }, [userData]);

  useEffect(() => {
    if (cajaB?.cajaId) {
      setPrevCajaExist(true);
    } else {
      setPrevCajaExist(false);
    }
  }, [cajaB]);

  const submit = async () => {
    const sanitizedNumber = amount
      ? amount.toString().replace(/[^0-9.,-]/g, "")
      : "0";
    const amountSanitized = Number(
      sanitizedNumber.replace(/\./g, "").replace(/,/g, ".")
    );

    if (!header.cliente.usrId) {
      ErrorModal({
        title: "Debe escoger un usuario relacionado para esta operación.",
      });
    } else if (amountSanitized === 0) {
      ErrorModal({
        title: "Debe ingresar un monto para guardar la operación.",
      });
    } else if (
      (header.caja === "B" && amountSanitized <= cajaB.saldo) ||
      isIngreso
    ) {
      setLoading(true);

      const dataCabecera = await insertDataIntoTable({
        tableName: "operacionCabecera",
        object: {
          usrId: userData.usrId,
          tipoOpId: isIngreso ? 8 : 9,
          usrIdRelacionado: header?.cliente?.usrId || userData.usrId,
          estadoOpId: 6,
          fechaAlta: date,
          observaciones: header?.observaciones,
          horaAlta: time,
          depositoId: userData.depositoId,
        },
      });

      await insertDataIntoTable({
        tableName: "operacionDetalle",
        object: {
          opCabId: dataCabecera[0].opCabId,
          precioUnitario: isIngreso ? amountSanitized : -amountSanitized,
          cantidad: 1,
        },
      });

      const opPago = await insertDataIntoTable({
        tableName: "operacionPago",
        object: {
          formaPagoDescripcion: "Efectivo",
          opCabId: dataCabecera[0].opCabId,
          formaPagoId: paymentsGroups.efectivo,
          total: isIngreso ? amountSanitized : -amountSanitized,
          pagado: isIngreso ? amountSanitized : -amountSanitized,
          recargo: 0,
        },
      });

      await insertDataIntoTable({
        tableName: "movimientos",
        object: {
          cajaId: cajaB.cajaId,
          formaPagoId: paymentsGroups.efectivo,
          monto: isIngreso ? amountSanitized : -amountSanitized,
          fecha: date,
          opPagoId: opPago[0].id,
          formaPagoDescripcion: "Efectivo",
        },
      });

      await updateCajaSumNewPayment({
        saldo: cajaB.saldo,
        pagado: isIngreso ? amountSanitized : -amountSanitized,
        cajaId: cajaB.cajaId,
      });

      setLoading(false);
      navigate("/operaciones");
    } else {
      ErrorModal({
        title: "El monto ingresado no concuerda con la caja escogida.",
      });
    }
  };

  return loading ? (
    <div className="center-div">
      <div>
        <LoadingSpinner />
      </div>
    </div>
  ) : (
    <div>
      <h3 className="caja-title">
        Caja: {isIngreso ? "Ingreso" : "Egreso"} de efectivo
      </h3>
      <div className="operation-container">
        <div className="operation-general-data operation-item">
          <GeneralDataCashMovements
            disabled={disabled}
            prevOp={prevOp}
            userData={userData}
            header={header}
            setHeader={setHeader}
            deposito={deposito}
            relatedUser={relatedUser}
            setClientRelatedUser={setClientRelatedUser}
          />
        </div>
        <div className="operation-product-list operation-item">
          <PaymentCashMovements
            disabled={disabled}
            amount={amount}
            setAmount={setAmount}
            saldoB={cajaB?.saldo}
            //saldoN={cajaN?.saldo}
            currentCaja={header.caja}
            isIngreso={isIngreso}
          />
        </div>
      </div>
      <div className="operation-buttons-container">
        <GoBackButton />
        <Button
          variant="success my-2"
          onClick={() => {
            pdf({
              filename: header?.cliente?.nombre + " - " + date,
              id: "cash-movement",
            });
          }}
        >
          Imprimir
        </Button>
        <Button
          variant="secondary my-2"
          onClick={() => {
            navigate("/operaciones");
          }}
          disabled={disabled}
        >
          Anular
        </Button>
        <Button
          variant="outline-success my-2"
          onClick={() => {
            if (disabled) {
              navigate("/operaciones");
            } else {
              submit();
            }
          }}
        >
          Guardar
        </Button>
      </div>
      <div
        style={{
          visibility: "hidden",
          zIndex: "-9999",
          position: "fixed",
        }}
      >
        {deposito && deposito.length !== 0 && header.cliente && (
          <CashMovementPdfTemplate
            generalData={{
              today: date,
              deposito: deposito[0]?.domicilio,
              vendedor: userData?.nombre,
              amount: amount,
              isIngreso: isIngreso,
            }}
            header={header}
          />
        )}
      </div>
      {!loading && <CajaIsNotOpenModal prevCajaExist={prevCajaExist} />}
    </div>
  );
};

export default CashMovement;
