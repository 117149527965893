import { useState } from "react";
import { PiMagnifyingGlassBold } from "react-icons/pi";
import { MdAttachMoney, MdEdit } from "react-icons/md";
import MUIDataTable from "mui-datatables";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../styles/MuiDataTableStyle";
import { textLabels, downloadOptions } from "../utils/MuiTableOptions";
import { operationTypes } from "../constants/operationTypes";
import numeral from "numeral";
import { formatDate } from "../utils/formatDate";
import { operationStates } from "../constants";

export const OperationsTable = ({ data }) => {
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const editOperation = (event, path) => {
    navigate(path, {
      state: {
        operation: event.currentTarget.id,
      },
    });
  };

  const columns = [
    {
      name: "opCabId",
      label: "Id",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "estadoOperacion.descripcion",
      label: "Estado",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "tipoOperacion.descripcion",
      label: "Tipo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fechaAlta",
      label: "Fecha alta",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => <span>{formatDate(value)}</span>,
      },
    },
    {
      name: "horaAlta",
      label: "Hora alta",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "fechaAnulada",
      label: "Fecha anulación",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => <span>{formatDate(value)}</span>,
      },
    },
    {
      name: "horaAnulada",
      label: "Hora anulación",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "personas.razonSocial",
      label: "Cliente",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "usuario.nombre",
      label: "Usuario",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "usuarioRelacionado.nombre",
      label: "Usuario Relacionado",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "monto",
      label: "Monto",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const prod =
            data[tableMeta.currentTableData[tableMeta.rowIndex].index];
          let total = value;
          // subtract any discount from the operation total
          for (let i = 0; i < prod.operacionPago.length; i++) {
            if (prod.operacionPago[i].formaPagoDescripcion === "Descuento") {
              total -= prod.operacionPago[i].pagado;
            }
          }
          return numeral(total).format("$0,0.00");
        },
      },
    },
    {
      name: "acciones",
      label: "Acciones",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          if (
            data[dataIndex]?.tipoOpId === operationTypes.caja_egreso ||
            data[dataIndex]?.tipoOpId === operationTypes.caja_ingreso
          ) {
            return (
              <div className="crud__table__icon-container">
                <PiMagnifyingGlassBold
                  data-tooltip-id="meaning_of_icon"
                  data-tooltip-content="Ver operación"
                  id={data[dataIndex].opCabId}
                  style={{ cursor: "pointer" }}
                  className="crud__table__icon"
                  alt="Item menú"
                  onClick={(e) =>
                    editOperation(
                      e,
                      data[dataIndex]?.tipoOpId === operationTypes.caja_egreso
                        ? "/egreso-efectivo"
                        : "/ingreso-efectivo"
                    )
                  }
                />
              </div>
            );
          } else if (
            data[dataIndex]?.tipoOpId === operationTypes.caja_apertura ||
            data[dataIndex]?.tipoOpId === operationTypes.caja_cierre
          ) {
            return <></>;
          } else if (
            data[dataIndex]?.tipoOpId ===
              operationTypes.stock_traslado_entre_sucursales_egreso ||
            data[dataIndex]?.tipoOpId ===
              operationTypes.stock_traslado_entre_sucursales_ingreso
          ) {
            return (
              <div className="crud__table__icon-container">
                <PiMagnifyingGlassBold
                  data-tooltip-id="meaning_of_icon"
                  data-tooltip-content="Ver operación"
                  id={data[dataIndex].opCabId}
                  style={{ cursor: "pointer" }}
                  className="crud__table__icon"
                  alt="Item menú"
                  onClick={(e) => editOperation(e, "/traspaso-mercaderia")}
                />
              </div>
            );
          } else if (
            data[dataIndex]?.tipoOpId === operationTypes.stock_egreso_manual ||
            data[dataIndex]?.tipoOpId === operationTypes.stock_ingreso_manual
          ) {
            return (
              <div className="crud__table__icon-container">
                <PiMagnifyingGlassBold
                  data-tooltip-id="meaning_of_icon"
                  data-tooltip-content="Ver operación"
                  id={data[dataIndex].opCabId}
                  style={{ cursor: "pointer" }}
                  className="crud__table__icon"
                  alt="Item menú"
                  onClick={(e) =>
                    editOperation(
                      e,
                      data[dataIndex]?.tipoOpId ===
                        operationTypes.stock_egreso_manual
                        ? "/egreso-mercaderia"
                        : "/ingreso-mercaderia"
                    )
                  }
                />
              </div>
            );
          } else if (
            data[dataIndex]?.tipoOpId ===
              operationTypes.stock_ingreso_por_remito_de_compra ||
            data[dataIndex]?.tipoOpId ===
              operationTypes.stock_ingreso_por_compra_a_particulares
          ) {
            return (
              <div className="crud__table__icon-container">
                <PiMagnifyingGlassBold
                  data-tooltip-id="meaning_of_icon"
                  data-tooltip-content="Ver operación"
                  id={data[dataIndex].opCabId}
                  style={{ cursor: "pointer" }}
                  className="crud__table__icon"
                  alt="Item menú"
                  onClick={(e) => editOperation(e, "/compra-bienes-usados")}
                />
              </div>
            );
          } else {
            return (data[dataIndex]?.tipoOpId === operationTypes.presupuesto ||
              data[dataIndex]?.tipoOpId === operationTypes.sena) &&
              data[dataIndex]?.estadoOpId !== operationStates.anulada ? (
              <div className="crud__table__icon-container">
                <MdAttachMoney
                  data-tooltip-id="meaning_of_icon"
                  data-tooltip-content="Realizar venta"
                  id={data[dataIndex].opCabId}
                  style={{ cursor: "pointer" }}
                  className="crud__table__icon"
                  alt="Item menú"
                  onClick={(e) => editOperation(e, "/operacion")}
                />
                <MdEdit
                  data-tooltip-id="meaning_of_icon"
                  data-tooltip-content="Editar"
                  id={data[dataIndex].opCabId}
                  style={{ cursor: "pointer" }}
                  className="crud__table__icon"
                  alt="Item menú"
                  onClick={(e) => {
                    navigate("/operacion", {
                      state: {
                        operation: e.currentTarget.id,
                        presupuesto: true,
                      },
                    });
                  }}
                />
              </div>
            ) : (
              <div className="crud__table__icon-container">
                <PiMagnifyingGlassBold
                  data-tooltip-id="meaning_of_icon"
                  data-tooltip-content="Ver operación"
                  id={data[dataIndex].opCabId}
                  style={{ cursor: "pointer" }}
                  className="crud__table__icon"
                  alt="Item menú"
                  onClick={(e) => editOperation(e, "/operacion")}
                />
              </div>
            );
          }
        },
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    searchOpen: true,
    selectableRows: "none",
    textLabels,
    downloadOptions,
    filterType: "dropdown",
    responsive: "standard",
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows)
    },
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions:[100,250,500],
    draggableColumns: { enabled: true },
    enableNestedDataAccess: ".",
    elevation: 0,
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: "600px",
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <MUIDataTable
        title={data[0]?.deposito.descripcion}
        data={data}
        columns={columns}
        options={options}
      />
      <Tooltip id="meaning_of_icon" />
    </ThemeProvider>
  );
};
