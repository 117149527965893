import React from "react";
import { formatCurrency } from "../../utils/formatCurrency";

const PricesPresupuestoPdf = ({ formaPagos, total, saldo = false }) => {
  const totalNumber = Number(String(total).replace(/\./g, '').replace(/,/g, '.'));
  const saldoNumber = Number(String(saldo).replace(/\./g, '').replace(/,/g, '.'));

  // Calculate the index to split the array
  const splitIndex = Math.ceil(formaPagos?.length / 2);

  // Use slice to divide the array into two halves
  const firstHalfArray = formaPagos?.slice(0, splitIndex);
  const secondHalfArray = formaPagos?.slice(splitIndex);

  // filter out items with formaPagoId equal to 5 and map over the remaining array
  // the formaPagoId equal to 5 is Comprobante de Compra de Bienes Usados No Registrables a Consumidores Finales  
  const filteredPagos = (array) => <div>
    <table>
      <thead>
        <tr>
        <th></th>
         <th><h6>Total</h6></th>
         <th><h6>Saldo</h6></th>
        </tr>
      </thead>
      <tbody>

    {array
    ?.filter((pago) => pago.formaPagoId !== 5)
    .map((pago, index) => (
      <tr key={index}>
        <td><div style={{ textAlign: "center" }}>
          <h6 style={{ margin: "0", fontSize: "13px", letterSpacing: "1px" }}>{pago.descripcion}:</h6>
        </div></td>
        <td><div style={{ display: "flex", alignItems: "end" }}>
          <p
            style={{
              margin: 0,
              fontSize: "13px",
              backgroundColor: pago.cuotas > 1 ? "#ddd" : "transparent", // set background color if cuotas is greater than 1
              padding: "6px"
            }}
          >
            {formatCurrency(totalNumber * (1 + pago.recargo))}
            {pago.cuotas > 1 &&
              ` - ${pago.cuotas} cuotas de ${formatCurrency(
                (totalNumber * (1 + pago.recargo)) / pago.cuotas
              )}`}
          </p>
        </div></td>
        <td><div style={{ display: "flex", alignItems: "end" }}>
          <p
            style={{
              margin: 0,
              fontSize: "13px",
              backgroundColor: pago.cuotas > 1 ? "#ddd" : "transparent", // set background color if cuotas is greater than 1
              padding: "6px"
            }}
          >
            {formatCurrency(saldoNumber * (1 + pago.recargo))}
          {pago.cuotas > 1 &&
            ` - ${pago.cuotas} cuotas de ${formatCurrency(
              (saldoNumber * (1 + pago.recargo)) / pago.cuotas
            )}`}
          </p>
        </div></td>
      </tr>
    ))}
    </tbody>
    </table>
    </div>;

  return (
    <div>
      <h6>Totales de la operación</h6>
      <div style={{
            display: 'flex',
            justifyContent: 'space-around'
      }}>
      <div>{filteredPagos(firstHalfArray)}</div>
      <div>{filteredPagos(secondHalfArray)}</div>
      </div>
    </div>
  );
};

export default PricesPresupuestoPdf;
