import { Modal } from "react-bootstrap";
import {Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

export const CajaIsNotOpenModal = ({prevCajaExist}) => {
    const navigate = useNavigate();
        return <Modal centered show={!prevCajaExist} onHide={() => {navigate(-1)}} >
        <Modal.Header closeButton>
          <Modal.Title>No hay una caja abierta para este depósito</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h6>Debe abrir una caja para realizar la siguiente operación.</h6>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => {navigate("/caja")}} variant="secondary">Abrir caja</Button>        
        </Modal.Footer>
    </Modal>
}