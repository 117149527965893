import { Table } from "react-bootstrap";
import Logo from "../../assets/brand/logo24.png";

export const CashMovementPdfTemplate = ({generalData, header}) => {

    return(
    <div id="cash-movement" style={{width: "102%"}}>
        <div style={{
            backgroundColor: "black",
            padding: "15px",
            display: "flex",
            justifyContent: "space-between"
        }}>
            <img src={Logo} alt="Gomeria Norte" />
            <h3 style={{ fontWeight: "800"}}>FACTURA</h3>
        </div>
        <h3 style={{textAlign: "center"}}>Factura {generalData.isIngreso ? "ingreso" : "egreso"} de efectivo</h3>
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px"
        }}>
            <div>
                <h5>Datos generales</h5>
                <p style={{margin: 0}}>Sucursal  {generalData.deposito}</p>
                <p style={{margin: 0}}>Vendedor : {generalData.vendedor}</p>  
            </div>
            <div>
            <h5>Facturar a</h5>
            <p style={{margin: 0}}>{header.cliente.nombre}</p>
            <p style={{margin: 0}}>{header.cliente.deposito}</p>
            <p style={{margin: 0}}>{header.cliente.email}</p>
            </div>
            <div>
                <p style={{margin: 0}}>Fecha : {generalData.today}</p>
                <p style={{margin: 0}}>Caja {header.caja === "A" ? "Principal" : "Secundaria"}</p>
            </div>
        </div>
        
        <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th style={{ "width": "80%"}}>Descripción</th>
            <th>Total efectivo</th>
          </tr>
        </thead>
        <tbody>
            <tr>
                <td style={{display: "flex", alignItems: "center"}}>
                    Efectivo
                </td>
              <td>
                <input className="form-control" type="number" value={generalData.amount} onChange={() =>{}}/>
              </td>
            </tr>
        </tbody>
        </Table>
    </div>
)}