import CurrencyInput from "react-currency-input-field";

export const sanitizeInput = (inputValue) => {
  // Remove non-numeric and non-comma characters
  const sanitizedValue = inputValue.replace(/[^\d,]/g, "");

  // Limit input length to two characters after the comma
  const commaIndex = sanitizedValue.indexOf(",");
  if (commaIndex !== -1 && sanitizedValue.length - commaIndex > 3) {
    return sanitizedValue.slice(0, commaIndex + 3);
  }
  return sanitizedValue;
};

export const formatNumber = (number) => {
  // Format with dots for thousands
  const parts = number.split(".");
  const integerPart = parts[0];
  const decimalPart = parts.length > 1 ? "." + parts[1] : "";

  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    "."
  );

  return formattedIntegerPart + decimalPart;
};

export const currencyOperations = (number1, number2, op) => {
  const sanitizedNumber1 = number1 ? number1.toString().replace(/[^0-9.,-]/g, "") : "0";
  const sanitizedNumber2 = number2 ? number2.toString().replace(/[^0-9.,-]/g, "") : "0";
  const num1 = Number(sanitizedNumber1.replace(/\./g, "").replace(/,/g, "."));
  const num2 = Number(sanitizedNumber2.replace(/\./g, "").replace(/,/g, "."));

  if (op === "GREATERTHAN") {
    return num1 >= num2;
  } else if (op === "LESSERTHAN") {
    return num1 <= num2;
  } else if (op === "EQUAL") {
    return num1 === num2;
  } else {
    const resultValue =
      op === "SUM"
        ? num1 + num2
        : op === "SUBTRAC"
        ? num1 - num2
        : op === "DIVIDE"
        ? num1 / num2
        : num1 * num2;

    const parts = resultValue.toFixed(2).toString().split(".");
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const decimalPart = parts[1] || "00";

    return `${integerPart},${decimalPart}`;
  }
};

export const NumberInputValidation = (props) => {
  return (
    <CurrencyInput
      className="form-control"
      name="number"
      decimalsLimit={2}
      allowNegativeValue={false}
      prefix={"$"}
      decimalSeparator=","
      groupSeparator="."
      onValueChange={(value, name) =>
        props.handlePriceChange({ value: value, id: props.id })
      }
      {...props}
    />
  );
};
