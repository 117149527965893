import { Table } from "react-bootstrap";
import Logo from "../../assets/brand/logo.png";
import PricesPresupuestoPdf from "./pricesPresupuestoPdf";
import { currencyOperations } from "../../utils/NumberInput";
import { getSimpleDataOfTable } from "../../utils/supabaseFunctions/getData";
import { useState } from "react";

export const PdfTemplate = ({
  generalData,
  header,
  productos,
  total,
  isSenado,
  recargo,
  saldo,
  renderPrices = true,
  isCompra,
  formasDePagoEscogidas,
  isPresupuesto,
  formaPagos,
  estado,
}) => {
  const totalOperation = renderPrices
    ? currencyOperations(total, recargo, "SUM")
    : total;
  const iva = renderPrices
    ? currencyOperations(totalOperation, "21", "DIVIDE")
    : 0;

  const [estadoDes, setEstadoDes] = useState("");
  
  const getEstado = async () => {
    if(estado){
      const estados = await getSimpleDataOfTable({
        tableName: "estadoOperacion",
                extraQueryEq: [
                  { queryColumn: "estadoOpId", queryValue: estado },
                ]
      });
      setEstadoDes(estados[0].descripcion);
    }
  }
  getEstado();

  return (
    <div id="presupuesto" style={{ width: "1180px" }}>
      <div
        style={{
          backgroundColor: "black",
          padding: "15px",
          display: "flex",
          justifyContent: "space-between",
          color: "#b8b8b8",
        }}
      >
        <img src={Logo} style={{ width: "300px"}} alt="Gomeria Norte" />
        <div>
          <h3 style={{ fontWeight: "800" }}>FACTURA</h3>
          <h3>Operación {estadoDes}</h3>
        </div>
      </div>
      {isCompra && (
        <h3 style={{ textAlign: "center" }}>FACTURA DE COMPRA DE BIENES</h3>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <div>
          <h5>Punto de venta</h5>
          <p style={{ margin: 0 }}>Sucursal {generalData.deposito}</p>
          <p style={{ margin: 0 }}>Vendedor : {generalData.vendedor}</p>
        </div>
        <div>
          <h5>Facturar {isCompra && "compra de bien"} a</h5>
          <p style={{ margin: 0 }}>{header.cliente.razonSocial}</p>
          <p style={{ margin: 0 }}>{header.cliente.domicilio}</p>
          <p style={{ margin: 0 }}>{header.cliente.email}</p>
        </div>
        <div>
          <p style={{ margin: 0 }}>Fecha : {generalData.today}</p>
          <p style={{ margin: 0 }}>
            {!renderPrices
              ? `Comprobante:  ${header.comprobante}`
              : `${header.tipoFactura} - ${
                  header.numFactura || header?.numeroFactura || ""
                }`}
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="operation-container" style={{ width: "100%" }}>
          <div className="operation-product-list operation-item">
            <h5>Producto o servicio prestado</h5>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th style={{width: "10%"}}>Cantidad</th>
                  <th style={{ width: "50%" }}>Descripción</th>
                  <th>Subtotal efectivo</th>
                  <th>Total efectivo</th>
                </tr>
              </thead>
              <tbody>
                {productos.map((p, index) => {
                  const result = currencyOperations(
                    p.precioVenta,
                    p.cantidad,
                    "MULTIPLY"
                  );

                  return (
                    <tr key={index}>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          style={{ width: "70px", margin: "0 10px" }}
                          className="form-control"
                          type="number"
                          value={p.cantidad}
                          disabled={true}
                        />
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            letterSpacing: "1px"
                          }}
                        >
                          {p.descripcionConcatenada}
                        </div>
                      </td>
                      <td>${p.precioVenta}</td>
                      <td>${result}</td>
                    </tr>
                  );
                })}
                {productos.length !== 0 && (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>Sub total</td>
                    <td>${total}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        {renderPrices && (!isPresupuesto || isSenado) ? (
          <div className="operation-container" style={{ width: "100%" }}>
            <h3 style={{ padding: "0 10px" }}>{(isPresupuesto && !isSenado) ? 'Presupuesto' : 'Venta'}</h3>
            <div className="operation-pay-forms operation-item" style={{ gridArea: "4 / 1 / 6 / 5"}}>
              <h5>Pagado</h5>
              <hr style={{ background: "black" }} />
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th style={{ width: "45%" }}>Descripción</th>
                    <th>Sub total</th>
                    <th>Recargo</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {formasDePagoEscogidas?.map((p, index) => {
                    const recargoIndividual = currencyOperations(
                      p.clientPaid,
                      p.recargo,
                      "MULTIPLY"
                    );
                    const totalIndividual = currencyOperations(
                      recargoIndividual,
                      p.clientPaid,
                      "SUM"
                    );
                    return (
                      <tr key={index}>
                        <td>
                          <div style={{letterSpacing: "1px"}}>{p.descripcion}</div>
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            ${p.clientPaid}
                          </div>
                        </td>
                        <td>${recargoIndividual}</td>
                        <td>${totalIndividual}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="operation-total operation-item" style={{gridArea: "6 / 1 / 6 / 5"}}>
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    {header?.tipoFactura === "A" && (
                      <>
                        <th>Importe Neto Gravado</th>
                        <th>IVA 21%</th>
                      </>
                    )}
                    <th>Total de la operación</th>
                    <th>Saldo a cancelar</th>
                    <th>Recargo financiación</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {header?.tipoFactura === "A" && (
                      <>
                        <td>
                          ${currencyOperations(totalOperation, iva, "SUBTRAC")}
                        </td>
                        <td>${iva}</td>
                      </>
                    )}
                    <td>${totalOperation}</td>
                    <td>${saldo}</td>
                    <td>${recargo}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <h3 style={{ padding: "0 10px" }}>Presupuesto</h3>
            <div className="operation-pay-list operation-item">
              <h5>Formas de pago disponibles</h5>
              <hr style={{ background: "black" }} />
              <PricesPresupuestoPdf formaPagos={formaPagos} total={total} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
