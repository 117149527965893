import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { Formik, Field, Form  } from 'formik';
import { useStateAuthValue } from "../context/AuthState";
import { supabaseClient } from "../component/supabaseClient";
import { errAndMsg } from "../utils/MsgAlert"

const LoginForm = () => {
    // eslint-disable-next-line no-empty-pattern
    const [{}, dispatch] = useStateAuthValue();
    const navigate =  useNavigate();
    const [messageError,setMessageError]  = useState("");
    
  return (
        <Formik
            initialValues={{ email: '',password:""}}
              validate={values => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'Campo requerido';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Mail inválido';
                } else if(!values.password) {
                  errors.password = 'Campo requerido';
                }
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                    const { data, error } = await supabaseClient
                    .from('usuarios')
                    .select("*, deposito : deposito(descripcion)")
                    .eq("email", values.email)
                    .eq("password", values.password)
                    .eq("activo", true);
                    if(data?.length === 0){
                        setSubmitting(false);
                        setMessageError("Credenciales incorrectas");
                    } else {
                      const expirationDate = new Date(); // Calcula la fecha de vencimiento
                      expirationDate.setMinutes(expirationDate.getMinutes() + 60); 
                      Cookies.set('authenticated', "true", { expires: expirationDate });
                      localStorage.setItem("user", data[0].email)
                      localStorage.setItem("warehouseUser", data[0].deposito.descripcion)
                       dispatch({
                          type: 'LOG_IN_USER',
                          userData : data[0],
                          userRole : data[0].privilegioId
                       });
                        navigate("/")
                    }
                    if(error) errAndMsg('Error', error.message, error.code);
                }}
            >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <div className="login-container__form">
                <h3>Login</h3>
                <hr style={{margin: 0}}/>
                <Form onSubmit={handleSubmit}>
                <div className="form-container form-container-one-colum">
                <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="email">Email</label>
                <Field 
                  className="form-control"
                  id="email" 
                  name="email" 
                  placeholder="Email" 
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <div className="invalid-feedback">{errors.email && touched.email && errors.email}</div>
                <label className="form-container__label" htmlFor="password">Contraseña</label>
                <Field 
                  className="form-control"
                  id="password" 
                  name="password" 
                  type="password"
                  placeholder="Contraseña" 
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <div className="invalid-feedback">{errors.password && touched.password && errors.password}</div>
                <div className="invalid-feedback" style={{
                  fontWeight: "800",
                  textAlign: "center",
                  fontSize: "15px"
                }}>{messageError}</div>
                  <div className="login-container__btn-container">
                    <button className="btn btn-dark" style={{margin:"10px"}} type="submit" disabled={isSubmitting}>
                      Ingresar
                    </button>
                  </div>
                </div>
                </div>
              </Form>
              </div>
            )}
        </Formik>)
};

export default LoginForm;
