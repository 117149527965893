/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../../styles/MuiDataTableStyle";
import { supabaseClient } from "../../component/supabaseClient";
import { LuTrash2 } from "react-icons/lu";
import {MdEdit} from "react-icons/md";
import { PiMagnifyingGlassBold } from "react-icons/pi";
import LoadingModal from "../../component/modals/LoadingModal";
import UserDetailsModal from "../../component/modals/SeeUserDetailsModal";
import {
  textLabels,
  downloadOptions,
  sortFunction,
} from "../../utils/MuiTableOptions";
import { GoBackButton } from "../../component/GoBackButton";
import { errAndMsg } from "../../utils/MsgAlert";

const Users = () => {
  const navigate = useNavigate();

  const [updated, setUpdated] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);
  const [users, setUsers] = useState();

  useEffect(() => {
    const getUsers = async () => {
      let query = supabaseClient
        .from("usuarios")
        .select(
          "*, privilegio : privilegios(descripcion), depo : deposito(descripcion)"
        )
        .order("usrId", { ascending: true });

      const { data: usuarios, error } = await query;

      if (error) {
        errAndMsg("Error", error.message, error.code);
      } else {
        const users = usuarios.map((u) => {
          return {
            ...u,
            activo_label: u.activo === true ? "SI" : "NO",
            privilegio: u?.privilegio?.descripcion,
            deposito: u?.depo?.descripcion,
          };
        });
        setUsers(users);
      }
    };
    getUsers();
  }, [updated]);

  const editUser = (event) => {
    users?.map((u) => {
      if (u.usrId === parseInt(event.currentTarget.id)) {
        navigate("editar-usuario", {
          state: {
            user: u,
          },
        });
      }
    });
  };

  const deleteUser = async (event) => {
    if (window.confirm(`¿Estás seguro de que deseas eliminar este usuario?`)) {
      setLoading(true);
      let userId;
      users?.map((u) => {
        if (u.usrId === parseInt(event.currentTarget.id)) {
          userId = u.usrId;
        }
      });
      if (userId) {
        const { error } = await supabaseClient
          .from("usuarios")
          .update({ activo: false })
          .eq("usrId", userId);
        if (error) {
          errAndMsg("Error", error.message, error.code);
        } else {
          setUpdated(true);
          setLoading(false);
        }
      }
    }
  };

  const seeDetailsUser = (event) => {
    users?.map((u) => {
      if (u.usrId === parseInt(event.currentTarget.id)) {
        setUserDetails(u);
        setShowUserDetailsModal(true);
      }
    });
  };

  const columns = [
    {
      name: "nombre",
      label: "Nombre",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "password",
      label: "Clave",
    },
    {
      name: "deposito",
      label: "Depósito",
    },
    {
      name: "telefono",
      label: "Teléfono",
    },
    {
      name: "privilegio",
      label: "Privilegios",
    },
    {
      name: "activo_label",
      label: "Activo",
    },
    {
      name: "acciones",
      label: "ACCIONES",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div>
              <PiMagnifyingGlassBold
                id={users[dataIndex].usrId}
                style={{ cursor: "pointer" }}
                className="crud__table__icon"
                alt="Item menú"
                onClick={seeDetailsUser}
              />
              <LuTrash2
                id={users[dataIndex].usrId}
                style={{ cursor: "pointer" }}
                className="crud__table__icon"
                alt="Item menú"
                onClick={deleteUser}
              />
              <MdEdit
                id={users[dataIndex].usrId}
                style={{ cursor: "pointer" }}
                className="crud__table__icon"
                alt="Item menú"
                onClick={editUser}
              />
            </div>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    searchOpen: true,
    textLabels,
    downloadOptions,
    filter: true,
    selectableRows: "none",
    fixedHeader: false,
    filterType: "dropdown",
    responsive: "standard",
    customSort: sortFunction,
    draggableColumns: { enabled: true },
  };

  return (
    <>
      <ThemeProvider theme={MuiTheme}>
        <div>
        <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>Usuarios</h3>
              </div>
            </div>
            <hr />
          </div>
          <MUIDataTable data={users} columns={columns} options={options} />
          <div className="crud__rigth-buttons-container">
            <button
              className="btn btn-dark"
              onClick={() => {
                navigate("agregar-usuario", { state: { user: false } });
              }}
            >
              Agregar usuario nuevo
            </button>
          </div>
        </div>
        <LoadingModal
          text="Eliminando usuario"
          show={loading}
          setShow={setLoading}
        />
        <UserDetailsModal
          user={userDetails}
          show={showUserDetailsModal}
          setShow={setShowUserDetailsModal}
        />
      </ThemeProvider>
    </>
  );
};

export default Users;
