import {
  insertDataIntoTable,
  insertProdDetails,
  checkBeforeInsertProduct
} from "./supabaseFunctions/insertData";
import {
  updateCajaReducePrevPayment,
  updateCajaSumNewPayment,
  checkStockDepositoBeforeUpdate,
  updateSimpleData
} from "./supabaseFunctions/updateData";
import { getStockOfProduct } from "./supabaseFunctions/getData";
import {
  operationStates,
  operationTypes,
  paymentsGroups,
} from "../constants";
import { currencyOperations } from "./NumberInput";

export const saveBuyOfUsedGoodsOperation = async ({
  userData,
  header,
  productos,
  cajaB,
}) => {
  let nowDate = new Date();
  let date =
    nowDate.getFullYear() +
    "/" +
    (nowDate.getMonth() + 1) +
    "/" +
    nowDate.getDate();
  const time = nowDate.toLocaleTimeString("it-IT");

  const dataCabecera = await insertDataIntoTable({
    tableName:"operacionCabecera",
    object:{
    usrId: userData.usrId,
    usrIdRelacionado:userData.usrId,
    tipoOpId: operationTypes.stock_ingreso_por_remito_de_compra,
    personaId: header?.cliente.personaId,
    estadoOpId: operationStates.finalizado,
    fechaAlta: date,
    observaciones: header?.observaciones,
    numeroFactura: header?.comprobante,
    horaAlta: time,
    depositoId: userData.depositoId,
  }});

  productos.map(async (p) => {
    const measurements = await insertProdDetails(p);

    // obtener producto nuevo
    const prod = await checkBeforeInsertProduct({ p, measurements })
    await checkStockDepositoBeforeUpdate({
      p: prod,
      depositoId : userData.depositoId,
      date: date,
      isSum: true
    });
    let total = typeof p.precioVenta === "number" ? p.precioVenta*p.cantidad : Number(p.precioVenta.replace(/\./g, '').replace(/,/g, '.'))*p.cantidad;
    //TODO:actualizar tipoOpId en compra de bienes
    const detail = await insertDataIntoTable({
      tableName:"operacionDetalle",
      object: {
      opCabId: dataCabecera[0].opCabId,
      prodId: prod.prodId,
      cantidad: p.cantidad,
      detalle: p.descripcion,
      precioUnitario: total,
    }});

    const pago = await insertDataIntoTable({
      tableName:"operacionPago",
      object: {opCabId: dataCabecera[0].opCabId,
      formaPagoId: paymentsGroups.efectivo,
      total: -total,
      pagado: -total,
      formaPagoDescripcion: "Compra de bienes usados",
      recargo: 0,
    }});
    if (pago) {
      await insertDataIntoTable({
        tableName:"movimientos",
        object: {opPagoId: pago[0].id,
        cajaId: cajaB.cajaId,
        formaPagoId: paymentsGroups.efectivo,
        monto: -total,
        fecha: date,
      }});

      await insertDataIntoTable({
        tableName:"movimientoStock",
        object: {
          prodId: prod.prodId,
          tipoOpId: operationTypes.stock_ingreso_por_remito_de_compra,
          opDetalleId : detail[0].opDetalleId,
          depositoId: userData.depositoId,
          cantidad: p.cantidad,
          fecha: date,
          usrId: userData.usrId,
          hora: time
        }
      });

      await updateCajaReducePrevPayment({
        saldo: cajaB.saldo,
        pagado: total,
        cajaId: cajaB.cajaId,
      });
    }
  });
  return true; 
};

export const deleteBuyOfUsedGoodsOperation = async ({
  userData,
  prevOperation,
  productos,
  cajaB,
}) => {
  let totalPagado = 0;
  for (let i = 0; i < prevOperation.length; i++) {
    totalPagado = currencyOperations(totalPagado, prevOperation[i].precioUnitario, "SUM");
    totalPagado = currencyOperations(totalPagado, prevOperation[i].cantidad);
  }
  let nowDate = new Date();
  let date =
    nowDate.getFullYear() +
    "/" +
    (nowDate.getMonth() + 1) +
    "/" +
    nowDate.getDate();
  const time = nowDate.toLocaleTimeString("it-IT");

  await updateSimpleData({
    tableName: "operacionCabecera",
    object: {
      fechaAnulada: date,
      horaAnulada: time,
      estadoOpId: operationStates.anulada,
    },
    extraQueryEq: [
      { queryColumn: "opCabId", queryValue: prevOperation[0]?.cabecera?.opCabId },
    ],
  });

  productos.map(async (p) => {
    const stockActual = getStockOfProduct({ depositoId: userData.depositoId, p: p });

    prevOperation.map(async (prevOp) => {
      await updateSimpleData({
        tableName: "operacionDetalle",
        object: {
          prodId: p.prodId,
          cantidad: p.cantidad,
          detalle: "Anulado",
          precioUnitario: p.precioVenta,
        },
        extraQueryEq: [
          { queryColumn: "opCabId", queryValue: prevOp.cabecera.opCabId },
          { queryColumn: "opDetalleId", queryValue: prevOp.opDetalleId },
        ],
      });

      const pago = await insertDataIntoTable({
        tableName:"operacionPago",
        object: {opCabId: prevOp.opCabId,
        formaPagoId: paymentsGroups.efectivo,
        total: prevOp.precioUnitario*p.cantidad,
        pagado: prevOp.precioUnitario*p.cantidad,
        formaPagoDescripcion: "Anulado",
        recargo: 0,
      }});

      await insertDataIntoTable({
        tableName:"movimientos",
        object: {opPagoId: pago[0].id,
        cajaId: cajaB.cajaId,
        formaPagoId: paymentsGroups.efectivo,
        monto: prevOp.precioUnitario*p.cantidad,
        fecha: date,
      }});
    });
    await updateSimpleData({
      tableName: "stockDeposito",
      object: {
        prodId: p?.prodId,
        depositoId: userData.depositoId,
        stockActual: stockActual - p.cantidad,
        fecha: date,
      },
      extraQueryEq: [
        { queryColumn: "prodId", queryValue: p.prodId },
        { queryColumn: "depositoId", queryValue: userData.depositoId }
      ],
    });
  });

  await updateCajaSumNewPayment({
    saldo: cajaB.saldo,
    pagado: Number(String(totalPagado).replace(/\./g, '').replace(/,/g, '.')),
    cajaId: cajaB.cajaId,
  });
};
