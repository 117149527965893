import { operationTypes } from "../../constants/operationTypes";
import { operationStates } from "../../constants/operationStates";
import {
  insertDataIntoTable,
} from "../supabaseFunctions/insertData";
import { checkStockDepositoBeforeUpdate } from "../supabaseFunctions/updateData";

export const ingresoEgreso = async (userData, header, products, operationType) => {
  // Obtener la fecha actual
  let nowDate = new Date();
  const date = `${nowDate.getFullYear()}/${
    nowDate.getMonth() + 1
  }/${nowDate.getDate()}`;
  const time = `${nowDate.getHours()}:${nowDate.getMinutes()}:${nowDate.getSeconds()}`;
  var cabecera;

  operationType.map(async (op) => {
    const userLogedForOperation =
      op === operationTypes.stock_traslado_entre_sucursales_ingreso
        ? header?.userRelacionado?.personaId
        : userData.usrId;
    const userRelatedForOperation =
      op === operationTypes.stock_traslado_entre_sucursales_ingreso
        ? userData.usrId
        : header?.userRelacionado?.personaId;
    const warehouseForOperation =
      op === operationTypes.stock_traslado_entre_sucursales_ingreso
        ? header?.userRelacionado?.depositoId
        : userData.depositoId;

    cabecera = await insertDataIntoTable({
      tableName: "operacionCabecera",
      object: {
        tipoOpId: op,
        usrId: userLogedForOperation,
        fechaAlta: date,
        estadoOpId: operationStates.finalizado,
        observaciones: header?.observaciones,
        numeroFactura: header?.numFactura,
        fechaFinalizado: date,
        horaAlta: time,
        usrIdRelacionado: userRelatedForOperation,
        depositoId: warehouseForOperation,
      },
    });
  });
  for (const p of products) {
    //FIXME: revisar
    // const measurements = await insertProdDetails(p);

    // // obtener producto nuevo
    // const prod = await checkBeforeInsertProduct({ p });

    await checkStockDepositoBeforeUpdate({
      p,
      depositoId: userData.depositoId,
      date: date,
      isSum: operationType[0] === operationTypes.stock_ingreso_manual,
    });
    const operacionDetalle = await insertDataIntoTable({
      tableName: "operacionDetalle",
      object: {
        opCabId: cabecera[0].opCabId,
        prodId: p.prodId,
        cantidad: p.cantidad,
        detalle: header?.observaciones,
        precioUnitario: 0,
      },
    });

    await insertDataIntoTable({
      tableName: "movimientoStock",
      object: {
        tipoOpId: cabecera[0].tipoOpId,
        prodId: p.prodId,
        opDetalleId: operacionDetalle[0].opDetalleId,
        depositoId: cabecera[0].depositoId,
        fecha: date,
        hora: time,
        cantidad: p.cantidad,
        usrId: cabecera[0].usrId,
      },
    });
    
  }
}

export const opCabeceraDetalleMovimiento = async (
  userData,
  header,
  products,
  operationType
) => {
  let nowDate = new Date();
  const date = `${nowDate.getFullYear()}/${
    nowDate.getMonth() + 1
  }/${nowDate.getDate()}`;
  const time = `${nowDate.getHours()}:${nowDate.getMinutes()}:${nowDate.getSeconds()}`;
  operationType.map(async (op) => {
    const userLogedForOperation =
      op === operationTypes.stock_traslado_entre_sucursales_ingreso
        ? header?.userRelacionado?.personaId
        : userData.usrId;
    const userRelatedForOperation =
      op === operationTypes.stock_traslado_entre_sucursales_ingreso
        ? userData.usrId
        : header?.userRelacionado?.personaId;
    const warehouseForOperation =
      op === operationTypes.stock_traslado_entre_sucursales_ingreso
        ? header?.userRelacionado?.depositoId
        : userData.depositoId;

    const dataCabecera = await insertDataIntoTable({
      tableName: "operacionCabecera",
      object: {
        tipoOpId: op,
        usrId: userLogedForOperation,
        fechaAlta: date,
        estadoOpId: operationStates.finalizado,
        observaciones: header?.observaciones,
        numeroFactura: header?.numFactura,
        fechaFinalizado: date,
        horaAlta: time,
        usrIdRelacionado: userRelatedForOperation,
        depositoId: warehouseForOperation,
      },
    });

    if (dataCabecera) {
      for (const p of products) {
        const operacionDetalle = await insertDataIntoTable({
          tableName: "operacionDetalle",
          object: {
            opCabId: dataCabecera[0].opCabId,
            prodId: p.prodId,
            cantidad: p.cantidad,
            detalle: header?.observaciones,
            precioUnitario: 0,
          },
        });

        await insertDataIntoTable({
          tableName: "movimientoStock",
          object: {
            tipoOpId: op,
            prodId: p.prodId,
            opDetalleId: operacionDetalle[0].opDetalleId,
            depositoId: warehouseForOperation,
            fecha: date,
            hora: time,
            cantidad: p.cantidad,
            usrId: userLogedForOperation,
          },
        });
      }
    }
  });
};

export const traslado = async (userData, header, products, operationType) => {
  let nowDate = new Date();
  const date = `${nowDate.getFullYear()}/${
    nowDate.getMonth() + 1
  }/${nowDate.getDate()}`;
  const time = `${nowDate.getHours()}:${nowDate.getMinutes()}:${nowDate.getSeconds()}`;
  // var cabecera;

  operationType.map(async (op) => {
    const warehouseForStockDeposito =
      op === operationTypes.stock_traslado_entre_sucursales_ingreso
        ? header?.userRelacionado?.depositoId
        : userData.depositoId;

        const userLogedForOperation =
        op === operationTypes.stock_traslado_entre_sucursales_ingreso
          ? header?.userRelacionado?.personaId
          : userData.usrId;
      const userRelatedForOperation =
        op === operationTypes.stock_traslado_entre_sucursales_ingreso
          ? userData.usrId
          : header?.userRelacionado?.personaId;
      const warehouseForOperation =
        op === operationTypes.stock_traslado_entre_sucursales_ingreso
          ? header?.userRelacionado?.depositoId
          : userData.depositoId;
  
      const dataCabecera = await insertDataIntoTable({
        tableName: "operacionCabecera",
        object: {
          tipoOpId: op,
          usrId: userLogedForOperation,
          fechaAlta: date,
          estadoOpId: operationStates.finalizado,
          observaciones: header?.observaciones,
          numeroFactura: header?.numFactura,
          fechaFinalizado: date,
          horaAlta: time,
          usrIdRelacionado: userRelatedForOperation,
          depositoId: warehouseForOperation,
        },
      });

    for (const p of products) {
      //FIXME: revisar
      /*       const measurements = await insertProdDetails(p);
      // obtener producto nuevo
      const prod = await checkBeforeInsertProduct({ p, measurements });
     */

      await checkStockDepositoBeforeUpdate({
        p,
        depositoId: warehouseForStockDeposito,
        date: date,
        isSum: op === operationTypes.stock_traslado_entre_sucursales_ingreso,
      });


      const operacionDetalle = await insertDataIntoTable({
        tableName: "operacionDetalle",
        object: {
          opCabId: dataCabecera[0].opCabId,
          prodId: p.prodId,
          cantidad: p.cantidad,
          detalle: header?.observaciones,
          precioUnitario: 0,
        },
      });

      await insertDataIntoTable({
        tableName: "movimientoStock",
        object: {
          tipoOpId: op,
          prodId: p.prodId,
          opDetalleId: operacionDetalle[0].opDetalleId,
          depositoId: warehouseForOperation,
          fecha: date,
          hora: time,
          cantidad: p.cantidad,
          usrId: userLogedForOperation,
        },
      });
    }
  });
};
