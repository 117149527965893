/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { useStateAuthValue } from "../../context/AuthState";
import LoadingSpinner from "../../component/Spinner";
import { ReportsTable } from "../../component/ReportsUsersTable";
import { getSimpleDataOfTable } from "../../utils/supabaseFunctions/getData";
import { operationStates, operationTypes } from "../../constants";

const Reports = () => {
  const [reports, setReports] = useState();
  const [loading, setLoading] = useState(true);
  const [{ userData }] = useStateAuthValue();

  const getReports = async () => {
    const reports = await getSimpleDataOfTable({
      tableName: "operacionCabecera",
      lookFor: `
      *,
      deposito : deposito(*),
      usuario: usrId(*),
      usrIdRelacionado(*),
      operacionDetalle(*),
      operacionPago(*,formaDePago(*))
      `,
      extraQueryEq: [
        { queryColumn: "tipoOpId", queryValue: operationTypes.venta_b },
        { queryColumn: "estadoOpId", queryValue: operationStates.venta_b },
      ],
      extraQueryOrder: [
        { queryColumn: "opCabId", queryValue: { ascending: false } },
      ],
    });

    const newData = reports.map((item) => {
      let formasPago = [];
      item?.operacionPago.map((f) => {
        formasPago.push(f.formaDePago);
      });
      const monto = item.operacionDetalle.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.cantidad * currentValue.precioUnitario,
        0
      );
      return { ...item, monto, formaPago: formasPago };
    });

    setReports(newData);
    setLoading(false);
  };

  useEffect(() => {
    getReports();
  }, [userData]);

  return (
    <>
      {loading ? (
        <div className="center-div">
          <div>
            <LoadingSpinner />
          </div>
        </div>
      ) : (
        <div>
          <div className="crud__title">
            <h3>Reportes de usuarios</h3>
            <hr />
          </div>

          {reports?.length > 0 ? (
            <ReportsTable data={reports} />
          ) : (
            <div className="center-div">
              <h3>No se realizaron operaciones</h3>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Reports;
