import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import {
  BsPlusCircleFill,
  BsFillPlusCircleFill,
  BsFillTrashFill,
} from "react-icons/bs";
import { AiFillMinusCircle } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import { useStateAuthValue } from "../../context/AuthState";
import { Table } from "react-bootstrap";
import {
  textLabels,
  downloadOptions,
  sortFunction,
} from "../../utils/MuiTableOptions";
import MUIDataTable from "mui-datatables";
import { supabaseClient } from "../supabaseClient";
import { errAndMsg } from "../../utils/MsgAlert";

const ProductsMovements = ({
  setProducts,
  products,
  stock,
  depositoId,
  isCompra,
  isReading,
  isIngreso,
}) => {
  const [show, setShow] = useState(false);
  const [{ userData }] = useStateAuthValue();
  const [depositos, setDepositos] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const iconsPlusStyle = {
    height: "21px",
    width: "21px",
    color: "#1f7d1f",
    cursor: "pointer",
  };
  const iconsMinusStyle = {
    height: "24px",
    width: "24px",
    color: "#b90202",
    cursor: "pointer",
  };

  //obtenemos los depositos
  useEffect(() => {
    const getDeposits = async () => {
      const { data, error } = await supabaseClient
        .from("deposito")
        .select()
        .eq("mostrar", true);
      if (data) setDepositos(data);
      if (error)
        errAndMsg("Error al obtener los depósitos", error.message, error.code);
    };
    getDeposits();
  }, []);
  const allDepositos = depositos.map((deposito) => deposito.descripcion);
  // // Obtenemos el stock de un producto en un depósito específico
  const getStockForProductoAndDeposito = (prodId, depositoId) => {
    const producto = stock.find((stock) => stock.prodId === prodId);
    if (producto) {
      const stockData = producto.stock.find(
        (stockItem) => stockItem.depositoId === depositoId
      );
      return stockData ? stockData.stockActual : 0;
    }
    return 0; // predeterminado si no se encuentra el producto
  };
  const columns = [
    {
      name: "prodId",
      label: "ID",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "nombreProducto",
      label: "Producto",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "stockTotal",
      label: "Total Stock",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    ...depositos.map((dep) => ({
      name: `stock_${dep.depositoId}`,
      label: dep.descripcion,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const producto =
            data[tableMeta.currentTableData[tableMeta.rowIndex].index];
          return (
            <div style={{ textAlign: "center" }}>
              {getStockForProductoAndDeposito(producto.prodId, dep.depositoId)}
            </div>
          );
        },
      },
    })),
  ];

  const options = {
    searchOpen: true,
    textLabels,
    downloadOptions,
    selectableRowsOnClick: true,
    filter: true,
    customToolbarSelect: () => {},
    selectableRows: "multiple",
    rowsSelected: selectedRows,
    selectableRowsHeader: false,
    selectToolbarPlacement: "none",
    onRowSelectionChange: (currentSelect) => {
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = [...prevSelectedRows];
        // Add or remove indexes based on current selection
        currentSelect.forEach(({ dataIndex }) => {
          const index = newSelectedRows.indexOf(dataIndex);
          if (index === -1) {
            newSelectedRows.push(dataIndex);
          } else {
            newSelectedRows.splice(index, 1);
          }
        });

        return newSelectedRows;
      });

      let newProducts = [...products];
      const selectedProduct = stock[currentSelect[0].dataIndex];
      if (!newProducts.some((e) => e.prodId === selectedProduct.prodId)) {
        newProducts.push(selectedProduct);
        setProducts(newProducts);
      } else {
        let notDeletedProducts = products.filter(
          (item) => item.prodId !== selectedProduct.prodId
        );
        setProducts(notDeletedProducts);
      }
    },
    isRowSelectable: (dataIndex) => {
      if (
        isIngreso ||
        getStockForProductoAndDeposito(
          stock[dataIndex].prodId,
          userData.depositoId
        ) > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: "550px",
    filterType: "dropdown",
    responsive: "standard",
    customSort: sortFunction,
    draggableColumns: { enabled: true },
    sort: true,
    sortColumnIndex: 2,
    sortOrder: { asc: true },
  };
  const data = stock?.map((producto) => {
    let totalStock = producto.stock.reduce(
      (sum, dep) => sum + dep.stockActual,
      0
    );
    return {
      prodId: producto.prodId,
      nombreProducto: producto.descripcionConcatenada,
      stockTotal: totalStock,
      ...allDepositos.reduce((acc, dep) => {
        acc[
          `stock_${depositos.find((d) => d.descripcion === dep).depositoId}`
        ] = getStockForProductoAndDeposito(
          producto.prodId,
          depositos.find((d) => d.descripcion === dep).depositoId
        );
        return acc;
      }, {}),
    };
  });
  const increment = (e) => {
    if (!isReading) {
      let prod = products.slice();
      const stock = getStockForProductoAndDeposito(
        prod[e.currentTarget?.id].prodId,
        userData.depositoId
      );
      if (
        prod[e.currentTarget?.id].cantidad + 1 > stock && !isIngreso
      ) {
        prod[e.currentTarget?.id].error =
          "Llegó al máximo de stock disponible: " +
          prod[e.currentTarget.id].cantidad;
      } else {
        ++prod[e.currentTarget?.id].cantidad;
        prod[e.currentTarget?.id].error = "";
      }
      setProducts(prod);
    }
  };

  const decrement = (e) => {
    if (!isReading) {
      let prod = products.slice();
      const stock = getStockForProductoAndDeposito(
        prod[e.currentTarget.id].prodId,
        userData.depositoId
      );
      if (prod[e.currentTarget.id].cantidad > 0) {
        --prod[e.currentTarget.id].cantidad;

        if (prod[e.currentTarget.id].cantidad > stock && !isIngreso) {
          prod[e.currentTarget.id].error =
            "Llegó al máximo de stock disponible: " + stock;
        } else {
          prod[e.currentTarget.id].error = "";
        }
        setProducts(prod);
      }
    }
  };

  const deleteProduct = (e) => {
    if (!isReading) {
      let prod = products.slice();
      const newProd = prod.filter((item) => {
        if (!isCompra) {
          return item.prodId !== Number(prod[e.currentTarget?.id].prodId);
        } else {
          return (
            item.descripcionConcatenada !==
            prod[e.currentTarget?.id].descripcionConcatenada
          );
        }
      });
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = [...prevSelectedRows];
        newSelectedRows.splice(e.currentTarget?.id, 1);
        return newSelectedRows;
      });
      setProducts(newProd);
    }
  };
  const mapProducts = products.map((p, index) => {
    return (
      <tr key={index}>
        <td
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AiFillMinusCircle
            style={iconsMinusStyle}
            onClick={(e) => {
              if (!p.wasSeñado) {
                decrement(e);
              }
            }}
            id={index}
          />
          <input
            disabled={isReading}
            style={{ width: "70px", margin: "0 10px" }}
            className="form-control"
            type="number"
            value={p.cantidad}
            onChange={(e) => {
              const number =
                e.target.value === "" ? e.target.value : Number(e.target.value);
              let prod = products.slice();
              const stockActual = prod[index]?.stock?.filter(
                (item) => item.depositoId === depositoId
              );
              if (number >= 0) {
                prod[index].cantidad = number;
                if (stockActual[0].stockActual < number && !isIngreso) {
                  prod[index].error =
                    "Llegó al máximo de stock disponible: " +
                    stockActual[0].stockActual;
                } else {
                  prod[index].error = "";
                }
                setProducts(prod);
              }
            }}
          />
          <BsFillPlusCircleFill
            style={iconsPlusStyle}
            onClick={(e) => {
              if (!p.wasSeñado) {
                increment(e);
              }
            }}
            id={index}
          />
        </td>
        <td>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {p?.prodId} -{" "}
            { p?.descripcionConcatenada}
            <BsFillTrashFill
              style={{ color: "grey", cursor: "pointer" }}
              onClick={(e) => {
                if (!p.wasSeñado) {
                  deleteProduct(e);
                }
              }}
              id={index}
            />
          </div>
          <strong>
            {p.error || (p.noStock ? "Ya no hay stock de este producto" : "")}
          </strong>
        </td>
      </tr>
    );
  });

  return (
    <>
      <div>
        <div>
          <h5 style={{ fontWeight: "bold" }}>Productos</h5>
        </div>
        <div
          style={{
            height: "250px",
            minHeight: "200px",
            overflow: "scroll",
          }}
        >
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Cantidad</th>
                <th style={{ width: "60%" }}>Descripción</th>
              </tr>
            </thead>
            <tbody>{mapProducts}</tbody>
          </Table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={isReading ? true : false}
            variant="outline-success my-2"
            onClick={() => {
              setShow(true);
            }}
          >
            <BsPlusCircleFill style={{ marginRight: "10px" }} />
            Agregar producto
          </Button>
        </div>
      </div>
      <Modal
        size="xl"
        show={show}
        onHide={() => {
          setShow(!show);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Escoja productos</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              height: "700px",
            }}
          >
            <MUIDataTable data={data} columns={columns} options={options} />
          </div>
          <div
            style={{
              maxHeight: "149px",
            }}
          >
            {products.map((prod, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#ede9e9",
                    padding: "10px",
                  }}
                >
                  { prod?.descripcionConcatenada}
                  <BsFillTrashFill
                    style={{ color: "grey", cursor: "pointer" }}
                    onClick={(e) => {
                      if (!prod.wasSeñado) {
                        deleteProduct(e);
                      }
                    }}
                    id={index}
                  />
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShow(false);
            }}
            variant="primary"
          >
            Escoger
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProductsMovements;
