import React from "react";
import { supabaseClient } from "../../component/supabaseClient";
import { Formik, Field, Form } from "formik";
import { useStateAuthValue } from "../../context/AuthState";
import { useNavigate, useLocation } from "react-router-dom";
import { GoBackButton } from "../../component/GoBackButton";
import { errAndMsg } from "../../utils/MsgAlert";

const AddMarca = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { marca } = state || {};

  const [{ userRole }] = useStateAuthValue();

  if (userRole !== 1) {
    navigate("/");
  }

  return (
    <div style={{ width: "100%" }}>
      <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>{marca ? "Editar marca" : "Agregar nueva marca"}</h3>
              </div>
            </div>
            <hr />
          </div>
      <Formik
        initialValues={
          marca
            ? {
                descripcion: marca.descripcion,
                activa: marca.activa
              }
            : {
                descripcion: "",
                activa: true,
              }
        }

        validate={(values) => {
          const errors = {};
          if (!values.descripcion) {
            errors.descripcion = "Campo requerido";
          } 
          return errors;
        }}

        onSubmit={async (values, { setSubmitting }) => {
          if (!marca) {
            try {
              // eslint-disable-next-line no-unused-vars
              const { data, error } = await supabaseClient
                .from("marca")
                .insert([
                  {
                    descripcion: values.descripcion,
                    activa: values.activa
                  },
                ])
                .select();
              if (error) errAndMsg('Error', error.message, error.code)
              setSubmitting(false);
              navigate("/configuracion/marca");
            } catch (error) {
              errAndMsg('Error', error.message, error.code)
            }
          } else {
            const { error } = await supabaseClient
              .from("marca")
              .update({
                descripcion: values.descripcion,
                activa: values.activa
              })
              .eq("marcaId", marca.marcaId);
            if (error) errAndMsg('Error', error.message, error.code)
            setSubmitting(false);
            navigate("/configuracion/marca");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="form-container form-container-three-colums">
              <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="descripcion">
                  Descripcion
                </label>
                <Field
                  className="form-control"
                  id="descripcion"
                  name="descripcion"
                  placeholder="Descripcion"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.descripcion}
                />
                <div className="invalid-feedback">
                  {errors.descripcion && touched.descripcion && errors.descripcion}
                </div>
              </div>
              <div className="form-group form-container__select">
                <label className="form-container__label" htmlFor="medidaDes">
                  Activa
                </label>
                <Field
                  as="select"
                  className="form-control form-select"
                  id="activa"
                  name="activa"
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue(
                      "activa",
                      e.target.options[e.target.selectedIndex].value
                    );
                  }}
                  onBlur={handleBlur}
                  value={values.activa}
                >
                  <option value="">- Escoger</option>
                  <option id={true} value={true}>
                    Activa
                  </option>
                  <option id={false} value={false}>
                    No activa
                  </option>
                </Field>
              </div>
            </div>
            <div className="form-container__button">
              <button
                className="btn btn-dark"
                type="submit"
                disabled={isSubmitting}
              >
                {marca ? "Editar marca" : "Agregar marca"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddMarca;
