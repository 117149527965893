import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return <div className="center-div" style={{ flexDirection: "column"}}>
    <div>
      <h6>La página que busca está en desarrolloro o no existe, vuelva a la página principal o revisa la url.</h6>
    </div>
    <div>
      <Button variant="outline-success my-2" onClick={() => {navigate("/")}}>
        Volver al menú
      </Button>
    </div>
  </div>;
};

export default NotFound;
