import React,{useState, useEffect} from "react";
import { supabaseClient } from "../component/supabaseClient";
import { ProductTable } from "../component/ProductTable";
import { toast } from "react-toastify";


const Products = () => {
  const [stock, setStock] = useState();

  useEffect(() => {
    const getStock = async () => {
      const { data, errorProductos } = await supabaseClient
      .from('productos')
      .select("*, tipoDes : tipoProducto(descripcion), marcaDes : marca(descripcion), modeloDes : modelo(descripcion), medidaDes : medida(descripcion), stockDeposito(*)");
      if(errorProductos) {toast.error("Hubo un error en la carga de datos: " + errorProductos)
      } else {
        const getProductos = async () => {
          return await Promise.all(data.map(async (p) => {
            let stockActual = p.stockDeposito.reduce(
              (sum, dep) =>
                sum + dep.stockActual,
              0
            );
          return {
            ...p,
            totalStock : stockActual,
          };
        }))}

        getProductos().then((r) => {
          setStock(r)
        })
      }
    }
    getStock();
  },[]);

  return <div>
    {stock && (
        <ProductTable data={stock} shouldSelect={false} depositos={[]}/>
      )}
  </div>;
};

export default Products;
