import MUIDataTable from "mui-datatables";
import { textLabels, downloadOptions } from "../../utils/MuiTableOptions";
import { formatDate } from "../../utils/formatDate";
import { supabaseClient } from "../supabaseClient";
import { useCallback, useEffect, useState } from "react";
import numeral from "numeral";

export const Movements = ({ lastCashBox, hasPositiveMovements }) => {
  const [movements, setMovements] = useState([]);

  const getMovements = useCallback(async () => {
    //Function to filter movements from the results, this is made for performance purposes
    const filterMovementsByValue = (movements, isPositive) => {
      return movements.filter((movement) =>
        isPositive ? movement.monto >= 0 : movement.monto < 0
      );
    };

    //Look for cash movements and set them if the lastestCashBoxes has at least one register
    if (lastCashBox?.cajaId) {
      const { cajaId } = lastCashBox;
      try {
        const { data: movements, error } = await supabaseClient
          .from("movimientos")
          .select(
            `
            *,
            formaDePago: formaDePago(
              *
            ),
            operacionPago: operacionPago(
              *,
              operacionCabecera(
                *,
                tipoOperacion (*)
              )
            )
            `
          )
          .eq("cajaId", cajaId)
          .order("fecha", { ascending: false })
          .order("movId", { ascending: false });
        if (error) throw error;

        setMovements(
          hasPositiveMovements
            ? filterMovementsByValue(movements, true)
            : filterMovementsByValue(movements, false)
        );
      } catch (error) {
        console.error(
          `An error occurred while fetching income and outcome movements: ${error.message}`
        );
      }
    }
  }, [hasPositiveMovements, lastCashBox]);

  useEffect(() => {
    getMovements();
  }, [lastCashBox, hasPositiveMovements, getMovements]);

  const columns = [
    {
      name: "movId",
      label: "Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fecha",
      label: "Fecha",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => <span>{formatDate(value)}</span>,
      },
    },

    {
      name: "operacionPago.operacionCabecera.tipoOperacion.descripcion",
      label: "Tipo de operación",
      options: {
        filter: true,
        sort: true,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "operacionPago.operacionCabecera.opCabId",
      label: "operacionPago.operacionCabecera.opCabId",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },

    {
      name: "operacionPago.opCabId",
      label: "Operación",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            {tableMeta.rowData[2]} - {tableMeta.rowData[3]}
          </>
        ),
      },
    },
    {
      name: "formaDePago.descripcion",
      label: "Forma de Pago",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "monto",
      label: "Monto",
      options: {
        customBodyRender: (value) => numeral(value).format("$0,0.00"),
        customSort: (value1, value2) => {
          const num1 = numeral(value1)._value;
          const num2 = numeral(value2)._value;
          if (num1 < num2) {
            return -1;
          }
          if (num1 > num2) {
            return 1;
          }
          return 0;
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    textLabels,
    downloadOptions,
    filterType: "dropdown",
    responsive: "standard",
    draggableColumns: { enabled: true },
    enableNestedDataAccess: ".",
    elevation: 0,
  };

  return (
    <MUIDataTable
      title={hasPositiveMovements ? "Ingresos" : "Egresos"}
      data={movements}
      columns={columns}
      options={options}
    />
  );
};
