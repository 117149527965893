import React from "react";
import Modal from 'react-bootstrap/Modal';
import ScaleLoader from "react-spinners/ScaleLoader";

function LoadingModal({text, setShow, show}) {
    return (
    <Modal centered show={show}>
        <Modal.Header closeButton>
          <Modal.Title>{text}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
            }}>
                <h5>Espere un momento...</h5>
                <ScaleLoader
                    color={"red"}
                    loading={true}
                    size={50}
                />
            </div>
        </Modal.Body>
    </Modal>
  );
}

export default LoadingModal;