import { toast } from "react-toastify";
import { supabaseClient } from "../../component/supabaseClient";
import { warehousesIds } from "../../constants";

export const stockProducts = async () => {
    try {
        const { data: productos, error } = await supabaseClient
            .from("productos")
            .select(
                "*, tipoDes : tipoProducto(descripcion), marcaDes : marca(descripcion), modeloDes : modelo(descripcion), medidaDes : medida(descripcion), medidaPer : medida(personalizada), stock: stockDeposito(*)"
            )
            .order("descripcionConcatenada", { ascending: true });

        if (error) {
            throw new Error(error.message);
        }

        const productIds = productos.map((p) => p.prodId);
        const { data: stockDeposito, error: stockError } = await supabaseClient
            .from("stockDeposito")
            .select("prodId, stockActual, deposito:deposito(*)")
            .in("prodId", productIds);

        if (stockError) {
            throw new Error(stockError.message);
        }

        const stock = productos.map((p) => {
            const stockData = stockDeposito.filter((s) => s.prodId === p.prodId);
            let stockOfVenRp = 0;
            let stockDepoRp = 0;
            let stockOfVenSm = 0;
            let stockDepoSm = 0;
            let stockRp = 0;
            let stockSm = 0;
            let stockTotal = 0;
            stockData.map((sd) => {
                stockTotal = stockTotal + sd.stockActual;
            if (sd.deposito?.depositoId === warehousesIds.oficina_venta_rp) {
                stockOfVenRp = sd.stockActual;
            }
            if (sd.deposito?.depositoId === warehousesIds.deposito_rp) {
                stockDepoRp = sd.stockActual;
            }
            if (sd.deposito?.depositoId === warehousesIds.oficina_venta_sm) {
                stockOfVenSm = sd.stockActual;
            }
            if (sd.deposito?.depositoId === warehousesIds.deposito_sm) {
                stockDepoSm = sd.stockActual;
            }
            if (sd.deposito?.depositoId === warehousesIds.san_martin) {
                stockSm = sd.stockActual;
            }
            if (sd.deposito?.depositoId === warehousesIds.rodriguez_penia) {
                stockRp = sd.stockActual;
            }
            return true;
            });

            return {
                ...p,
                stockOfVenRp,
                stockDepoRp,
                stockOfVenSm,
                stockDepoSm,
                stockRp,
                stockSm,
                stockTotal,
                cantidad: 1,
                tipoDes: p?.tipoDes?.descripcion || "",
                marcaDes: p?.marcaDes?.descripcion || "",
                medidaDes: p?.medidaDes?.descripcion || "",
                modeloDes: p?.modeloDes?.descripcion || "",
                usado: p.usado,
            };
        });

        return stock;
    } catch (error) {
        toast.error("Hubo un error en la carga de datos: " + error.message);
    }
};