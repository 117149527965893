import {
  updateCajaReducePrevPayment,
  updateSimpleData,
} from "./supabaseFunctions/updateData";
import { getStockOfProduct } from "./supabaseFunctions/getData";
import { insertDataIntoTable } from "./supabaseFunctions/insertData";
import { operationStates, operationTypes, service } from "../constants";

export const deleteOperation = async (
  userData,
  operation,
  productos,
  prevPayment,
  cajaB,
  notMoveStock,
) => {  
  let nowDate = new Date();
  let date =
    nowDate.getFullYear() +
    "/" +
    (nowDate.getMonth() + 1) +
    "/" +
    nowDate.getDate();
  const time = nowDate.toLocaleTimeString("it-IT");

  let totalPagado = 0;
  let formaPagoId = [];
  for (let i = 0; i < prevPayment.length; i++) {
    totalPagado = totalPagado + Number(prevPayment[i].pagado);
    formaPagoId.push(prevPayment[i].formaPagoId);
  }
  await updateSimpleData({
    tableName: "operacionCabecera",
    object: {
      fechaAnulada: date,
      horaAnulada: time,
      estadoOpId: operationStates.anulada,
    },
    extraQueryEq: [
      { queryColumn: "opCabId", queryValue: operation.opCabId },
    ],
  });

  operation.operacionDetalle.map(async (det) => {
    await updateSimpleData({
      tableName: "operacionDetalle",
      object: {
        detalle: "Anulado"
      },
      extraQueryEq: [
        { queryColumn: "opCabId", queryValue: operation.opCabId },
        { queryColumn: "opDetalleId", queryValue: det.opDetalleId },
      ],
    });
  });

  productos.map(async (p) => {
    const stockActual = getStockOfProduct({
      depositoId: userData.depositoId,
      p: p,
    });

    // actualizar deposito si no es un servicio
    if (p.prodId !== service && !notMoveStock) {
      await updateSimpleData({
        tableName: "stockDeposito",
        object: {
          prodId: p?.prodId,
          depositoId: userData.depositoId,
          stockActual: stockActual + Number(p.cantidad),
          fecha: date,
        },
        extraQueryEq: [
          { queryColumn: "prodId", queryValue: p.prodId },
          { queryColumn: "depositoId", queryValue: userData.depositoId }
        ],
      });
    }
  });

  // actualizar pago con valor negativo
  prevPayment.map(async (p) => {
    const pago = await insertDataIntoTable({
      tableName: "operacionPago",
      object: {
        opCabId: operation.opCabId,
        formaPagoId: p.formaPagoId,
        total: -p.total,
        pagado: -p.pagado,
        formaPagoDescripcion: "Anulado",
        recargo: 0,
      },
    });

    if (
      operation.tipoOpId === operationTypes.venta_b ||
      operation.tipoOpId === operationTypes.sena
    ) {
      await insertDataIntoTable({
        tableName: "movimientos",
        object: {
          opPagoId: pago[0].id,
          cajaId: cajaB.cajaId,
          formaPagoId: p.formaPagoId,
          monto: -p.pagado,
          fecha: date,
        },
      });

      await updateCajaReducePrevPayment({
        saldo: cajaB.saldo,
        pagado: totalPagado,
        cajaId: cajaB.cajaId,
      });
    }
  });
};
