import Swal from "sweetalert2";
// Muestra Alert con estetica y mensajes personalizados
export function errAndMsg(titleMsg, textMsg, typeMsg ) {
    Swal.fire({
        icon: 'error', // definir
        title: titleMsg,
        // text: textMsg,
        footer: 'Cod: ' + typeMsg
    })
}

export function sucAndMsg(titleMsg, textMsg, typeMsg ) {
    Swal.fire({
        icon: 'success', // definir
        title: titleMsg ? titleMsg : '',
        // text: textMsg,
        footer: typeMsg ? 'Cod: ' + typeMsg : ''
    })
}

/* EJEMPLOS DE FUNCIONES DE ALERTAS  
// Mensaje de confirma eliminar con api de eliminacion y control de error
export function msgDeleteConfirm() {
    new Swal({
        title: 'Cuidado.',
        text: 'Esta a punto de borrar definitivamente un registro. Esta información no podrá volver a visualizarse.',
        icon: 'warning',
        buttons: ['Cancelar', 'Confirmar']
        }).then((willDelete) => {
            let resultado = willDelete ? true : false;
            return resultado;
    });
}


// Mensaje de confirma eliminar con api de eliminacion
export function advMsgReload(url, selected, jwt) {
    new Swal({
        title: 'Cuidado.',
        text: 'Esta a punto de borrar definitivamente un registro. Esta información no podrá volver a visualizarse.',
        icon: 'warning',
        buttons: ['Cancelar', 'Confirmar']
    }).then((willDelete) => {
        const urlAPI = url + selected;
        const setDeleteUser = async () => {
        // ejemplo
        // const resultadoUser = await axios.delete(urlAPI, { headers: { Authorization: jwt } });
    };
        // ejemplo

       // const resultadoUser = axios.delete(urlAPI, { headers: { Authorization: jwt } });
        if (willDelete) {
            setDeleteUser();
            new Swal({
                title: 'Borrado exitoso.',
                text: 'Se acaba de borrar un registro correctamente.',
                icon: 'success'
            }).then((confirm) => {
                if (confirm) {
                    window.setTimeout(window.location.replace(''), 10);
                }
            });
        }
    });
}

// Muestra Alert con estetica y mensajes personalizados, Luego recarga la pagina
export function errAndMsgReload({ titleMsg, textMsg, typeMsg, buttonMsg }) {
    new Swal({
        title: titleMsg,
        text: textMsg,
        icon: typeMsg,
        button: buttonMsg
    }).then((res) => {
        window.setTimeout(window.location.replace(''), 10);
    });
}
*/