import Swal from 'sweetalert2';
export const fireConfirmationModal = ({
    title,
    submit,
    props
}) => {
    Swal.fire({
        title : title,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar acción',
        cancelButtonText: 'Cancelar acción'
      }).then((result) => {
        if (result.isConfirmed) {
            submit(props);
        }
      })
}