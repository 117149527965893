import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../styles/MuiDataTableStyle";
import CustomReportsToolbar from "./CustomReportsOperation";
import { Box } from "@mui/material";
import { formatDate } from "../utils/formatDate";
import { textLabels, downloadOptions } from "../utils/MuiTableOptions";
import numeral from "numeral";
import { discountId } from "../constants";
import { getSimpleDataOfTable } from "../utils/supabaseFunctions/getData";

export const ReportsTable = ({ data }) => {
  const [finalData, setFinalData] = useState(data);
  const [users, setUsers] = useState([]);
  const [filterData, setFilterData] = useState([
    {
      label: "Mínima fecha de alta",
      filterBy: "",
      column: "fechaAlta",
      objective: "min",
    },
    {
      label: "Máxima fecha de alta",
      filterBy: "",
      column: "fechaAlta",
      objective: "max",
    },
    {
      label: "Usuario principal",
      filterBy: "",
      column: "usrId",
      objective: "eq",
      options: users,
    },
    {
      label: "Usuario relacionado",
      filterBy: "",
      column: "usrIdRelacionado",
      objective: "eq",
      options: users,
    },
  ]);

  useEffect(() => {
    const getUsers = async () => {
      let data = await getSimpleDataOfTable({
        tableName: "usuarios",
      });
      let users = data?.map((user) => {
        return {
          label: user.nombre,
          value: user.usrId
        }
      });
      setUsers(users)
    }

    getUsers();
  },[]);
  
  useEffect(() => {
    if(users.length){
      setFilterData([
        {
          label: "Mínima fecha de alta",
          filterBy: "",
          column: "fechaAlta",
          objective: "min",
        },
        {
          label: "Máxima fecha de alta",
          filterBy: "",
          column: "fechaAlta",
          objective: "max",
        },
        {
          label: "Usuario principal",
          filterBy: "",
          column: "usrId",
          objective: "eq",
          options: users,
        },
        {
          label: "Usuario relacionado",
          filterBy: "",
          column: "usrIdRelacionado",
          objective: "eq",
          options: users,
        },
      ])
    }
  },[users]);


  const lookForFilter = () => {
    const filters = filterData.filter(
      (filter) => filter.filterBy !== ""
    );

    if (filters.length === 0) {
      setFinalData(data);
      return;
    }

    const filteredArray = data.filter((item) => {
      let currentDate = new Date(item.fechaAlta.replace(/-/g, '/'));
      currentDate.setHours(0, 0, 0, 0);

      let dateMin =
        filterData[0]?.filterBy ? new Date(filterData[0].filterBy)
          : false;
          dateMin = dateMin ? dateMin.setHours(0, 0, 0, 0) : false;

      let dateMax =
        filterData[1]?.filterBy ?  new Date(filterData[1].filterBy)
          : false;
          dateMax = dateMax ? dateMax.setHours(0, 0, 0, 0) : false;

      const principalUser =
        typeof filterData[2]?.filterBy === "number" && filterData[2]?.filterBy;
        
      const secondaryUser =
        typeof filterData[3]?.filterBy === "number" && filterData[3]?.filterBy;


      let checkDates = true;
      let checkPincipalUser = true;
      let checkSecondaryUser = true;

      if (dateMax || dateMin) {
        checkDates =
          dateMax && dateMin
            ? currentDate.getTime() <= dateMax &&
              currentDate.getTime() >= dateMin
            : dateMax
            ? currentDate.getTime() <= dateMax
            : dateMin
            ? currentDate.getTime() >= dateMin
            : false;
      }

      if (principalUser) {
        checkPincipalUser = item?.usrId === principalUser;
      }

      if (secondaryUser) {
        checkSecondaryUser = item?.usrIdRelacionado.usrId === secondaryUser;
      }

      return (
        checkPincipalUser && checkDates && checkSecondaryUser
      );
    });
    setFinalData(filteredArray);
  };

  const columns = [
    {
      name: "opCabId",
      label: "Id",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "usuario.nombre",
      label: "Usuario principal",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "usrIdRelacionado.nombre",
      label: "Usuario relacionado",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fechaAlta",
      label: "Fecha de alta",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => <>{formatDate(value)}</>,
      },
    },
    {
      name: "operacionDetalle",
      label: "Producto",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          let response = value[0]?.detalle;
          for(let i=1; i<value.length; i++){
            response = response + ", " + value[i]?.detalle
          }
          return response
        },
      },
    },
    {
      name: "operacionDetalle",
      label: "cantidad",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          let response = value[0]?.cantidad;
          for(let i=1; i<value.length; i++){
            response = response + value[i]?.cantidad
          }
          return response
        },
      },
    },
    {
      name: "monto",
      label: "Monto",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          let discount = 0;
          let hasDiscount = false;
          // subtract any discount from the operation total
          for (let i = 0; i < finalData[dataIndex]?.operacionPago.length; i++) {
            if(finalData[dataIndex].operacionPago[i].formaPagoId === discountId){
              discount = Math.abs(Number(String(finalData[dataIndex].operacionPago[i].pagado).replace(/,/g, '.')))
              hasDiscount = true;
            }
          }
          let finalValue = hasDiscount ? finalData[dataIndex].monto - discount : finalData[dataIndex].monto;
          return numeral(finalValue).format("$0,0.00");
        },
        customSort: (value1, value2) => {
          const num1 = numeral(value1)._value;
          const num2 = numeral(value2)._value;
          if (num1 < num2) {
            return -1;
          }
          if (num1 > num2) {
            return 1;
          }
          return 0;
        },
      },
    },
    
  ];

  const options = {
    selectableRows: "none",
    textLabels,
    downloadOptions,
    filterType: "dropdown",
    responsive: "standard",
    draggableColumns: { enabled: true },
    enableNestedDataAccess: ".",
    elevation: 0,
    customToolbar: ({ displayData }) => {
      return (
        <CustomReportsToolbar
          filterData={filterData}
          setFilterData={setFilterData}
          lookForFilter={lookForFilter}
          setFinalData={setFinalData}
          data={data}
        />
      );
    },
    filter: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: "500px",
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <Box
        sx={{
          "& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head": {
            span: {
              justifyContent: "center",
            },
          },
        }}
      >
        <MUIDataTable
          title=""
          data={finalData}
          columns={columns}
          options={options}
        />
      </Box>
    </ThemeProvider>
  );
};
