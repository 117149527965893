import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/styles.scss";
import ProtectedAdmiRoute from "./utils/ProtectedAdmiRoute";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthState } from "./context/AuthState";
import Cash from "./pages/Cash";
import CompraBienesUsados from "./pages/CompraBienesUsados";
import NotFound from "./pages/NotFound";
import Operation from "./pages/Operation";
import Operations from "./pages/Operations";
import Product from "./pages/Product";
import Home from "./pages/Home";
import Stock from "./pages/Stock";
import StockNuevo from "./pages/StockNuevo";
import Products from "./pages/Products";
import Reports from "./pages/Reports";
import AddNewUser from "./pages/Configurations/AddNewUser";
import Login from "./pages/Login";
import Clients from "./pages/Clients";
import AddNewClient from "./pages/AddNewClient";
import AddNewWarehouse from "./pages/Configurations/AddNewWarehouse";
import AddNewProduct from "./pages/AddNewProduct";
import CashMovement from "./pages/CahsMovement";
import CashList from "./pages/CashList";
import StockMovement from "./pages/StockMovement";
import Configuration from "./pages/Configuration";
import AddFormaDePago from "./pages/Configurations/AddFormaDePago";
import AddMarca from "./pages/Configurations/AddMarca";
import AddMedida from "./pages/Configurations/AddMedida";
import AddModelo from "./pages/Configurations/AddModelo";
import AddTipoProducto from "./pages/Configurations/AddTipoProducto";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Checks from "./pages/Checks";
import ReportsProductPrices from "./pages/ReportsProductPrices";
import EditPrices from "./pages/Configurations/Precios";

const App = () => {
  return (
    <AuthState>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        limit={5}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="colored"
      />
      <Router>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <ProtectedAdmiRoute>
                <Home />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            path="/reportes"
            element={
              <ProtectedAdmiRoute>
                <Reports />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            path="/reportes/precios"
            element={
              <ProtectedAdmiRoute>
                <ReportsProductPrices />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            path="/reportes/operaciones"
            element={
              <ProtectedAdmiRoute>
                <Reports />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            path="/reportes/stock"
            element={
              <ProtectedAdmiRoute>
                <Reports />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            path="/reportes/stock-global"
            element={
              <ProtectedAdmiRoute>
                <Reports />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            path="/reportes/usuarios"
            element={
              <ProtectedAdmiRoute>
                <Reports />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/caja"
            element={
              <ProtectedAdmiRoute>
                <Cash />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/caja/:id"
            element={
              <ProtectedAdmiRoute>
                <Cash />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/listado-cajas"
            element={
              <ProtectedAdmiRoute>
                <CashList />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/stock"
            element={
              <ProtectedAdmiRoute>
                <Stock />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/stocknuevo"
            element={
              <ProtectedAdmiRoute>
                <StockNuevo />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/productos"
            element={
              <ProtectedAdmiRoute>
                <Products />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/productos/agregar-producto"
            element={
              <ProtectedAdmiRoute>
                <AddNewProduct />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/productos/editar-producto"
            element={
              <ProtectedAdmiRoute>
                <AddNewProduct />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/ingreso-mercaderia"
            element={
              <ProtectedAdmiRoute>
                <StockMovement />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/egreso-mercaderia"
            element={
              <ProtectedAdmiRoute>
                <StockMovement />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/traspaso-mercaderia"
            element={
              <ProtectedAdmiRoute>
                <StockMovement />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/clientes"
            element={
              <ProtectedAdmiRoute>
                <Clients />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/ingreso-efectivo"
            element={
              <ProtectedAdmiRoute>
                <CashMovement />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/egreso-efectivo"
            element={
              <ProtectedAdmiRoute>
                <CashMovement />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/clientes/agregar-cliente"
            element={
              <ProtectedAdmiRoute>
                <AddNewClient />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/clientes/editar-cliente"
            element={
              <ProtectedAdmiRoute>
                <AddNewClient />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/compra-bienes-usados"
            element={
              <ProtectedAdmiRoute>
                <CompraBienesUsados />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/cheques"
            element={
              <ProtectedAdmiRoute>
                <Checks />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/operacion"
            element={
              <ProtectedAdmiRoute>
                <Operation />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/operaciones"
            element={
              <ProtectedAdmiRoute>
                <Operations />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/producto"
            element={
              <ProtectedAdmiRoute>
                <Product />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/stock/agregar-producto"
            element={
              <ProtectedAdmiRoute>
                <AddNewProduct />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/stock/editar-producto"
            element={
              <ProtectedAdmiRoute>
                <AddNewProduct />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion"
            element={
              <ProtectedAdmiRoute>
                <Configuration />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/depositos"
            element={
              <ProtectedAdmiRoute>
                <Configuration />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/precios"
            element={
              <ProtectedAdmiRoute>
                <EditPrices />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/depositos/agregar-deposito"
            element={
              <ProtectedAdmiRoute>
                <AddNewWarehouse />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/depositos/editar-deposito"
            element={
              <ProtectedAdmiRoute>
                <AddNewWarehouse />
              </ProtectedAdmiRoute>
            }
          />

          <Route
            exact
            path="/configuracion/formas-pago"
            element={
              <ProtectedAdmiRoute>
                <Configuration />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/formas-pago/agregar-formas-pago"
            element={
              <ProtectedAdmiRoute>
                <AddFormaDePago />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/formas-pago/editar-formas-pago"
            element={
              <ProtectedAdmiRoute>
                <AddFormaDePago />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/marca"
            element={
              <ProtectedAdmiRoute>
                <Configuration />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/marca/agregar-marca"
            element={
              <ProtectedAdmiRoute>
                <AddMarca />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/marca/editar-marca"
            element={
              <ProtectedAdmiRoute>
                <AddMarca />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/medida"
            element={
              <ProtectedAdmiRoute>
                <Configuration />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/medida/agregar-medida"
            element={
              <ProtectedAdmiRoute>
                <AddMedida />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/medida/editar-medida"
            element={
              <ProtectedAdmiRoute>
                <AddMedida />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/modelo"
            element={
              <ProtectedAdmiRoute>
                <Configuration />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/modelo/agregar-modelo"
            element={
              <ProtectedAdmiRoute>
                <AddModelo />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/modelo/editar-modelo"
            element={
              <ProtectedAdmiRoute>
                <AddModelo />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/tipo-producto"
            element={
              <ProtectedAdmiRoute>
                <Configuration />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/tipo-producto/agregar-tipo-producto"
            element={
              <ProtectedAdmiRoute>
                <AddTipoProducto />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/tipo-producto/editar-tipo-producto"
            element={
              <ProtectedAdmiRoute>
                <AddTipoProducto />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/usuarios"
            element={
              <ProtectedAdmiRoute>
                <Configuration />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/usuarios/agregar-usuario"
            element={
              <ProtectedAdmiRoute>
                <AddNewUser />
              </ProtectedAdmiRoute>
            }
          />
          <Route
            exact
            path="/configuracion/usuarios/editar-usuario"
            element={
              <ProtectedAdmiRoute>
                <AddNewUser />
              </ProtectedAdmiRoute>
            }
          />

          <Route exact path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </AuthState>
  );
};

export default App;
