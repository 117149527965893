import React, { useEffect, useState } from "react";
import { formatDate } from "../../utils/formatDate";
import { supabaseClient } from "../supabaseClient";

export const CashBoxData = ({
  isCashBoxOpen,
  lastCashBox,
  prevCashBox,
  observations,
  setObservations,
  userData,
}) => {
  const lastCloseDate =
    lastCashBox?.fechaCierre ?? prevCashBox?.fechaCierre ?? null;

  const warehouseId = userData?.depositoId ?? null;
  const [warehouse, setWarehouse] = useState({});

  const fetchWarehouse = async (id, setWarehouse) => {
    try {
      const { data: warehouse } = await supabaseClient
        .from("deposito")
        .select()
        .eq("depositoId", id);
      setWarehouse(warehouse[0]);
    } catch (error) {
      console(error.message);
    }
  };

  useEffect(() => {
    if (warehouseId) {
      fetchWarehouse(warehouseId, setWarehouse);
    }
  }, [warehouseId]);

  return (
    <div>
      <div className="header-container">
        <div>
          <h5 style={{ fontWeight: "bold", paddingLeft: "10px" }}>
            Datos generales caja {isCashBoxOpen ? "actual" : "anterior"}
          </h5>

          <div className="vendedor-container">
            <p>Caja: {lastCashBox?.cajaId}</p>
            <p>Apertura: {formatDate(lastCashBox?.fechaApertura)}</p>
            <p>
              Cierre anterior:{" "}
              {lastCloseDate
                ? formatDate(lastCloseDate)
                : "No hay caja anterior"}
            </p>
            <p>Sucursal: {warehouse?.domicilio}</p>
            <p>Vendedor: {userData?.nombre}</p>
          </div>
        </div>
        <div
          className="observaciones"
          style={{
            display: "flex",
            alignItems: "baseline",
            marginTop: "10px",
          }}
        >
          <h5 style={{ fontWeight: "bold", margin: "10px 150px 0px 13px" }}>
            Observaciones:
          </h5>
          <input
            style={{ width: "30%" }}
            className="form-control"
            id="observaciones"
            name="observaciones"
            placeholder="Observaciones"
            onChange={(e) => {
              setObservations(e.target.value);
            }}
            value={observations}
          />
        </div>
      </div>
    </div>
  );
};
