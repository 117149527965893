import React, { useState, useEffect } from "react";
import { useStateAuthValue } from "../../context/AuthState";
import LoadingSpinner from "../../component/Spinner";
import { ReportsStockTable } from "../../component/ReportsStockTable";
import { supabaseClient } from "../../component/supabaseClient";

const StockGlobal = () => {
  const [reportsLength, setReportsLength] = useState(0);
  const [reports, setReports] = useState();
  const [loading, setLoading] = useState(true);
  const [{ userData }] = useStateAuthValue();

  const getReports = async (depositoId, setLoading) => {
    try {
      const { data: reportes } = await supabaseClient
        .from("movimientoStock")
        .select(
          `
        *,
        tipoOperacion : tipoOperacion(descripcion),
        usuario: usrId(*),
        producto: prodId(*),
        deposito: depositoId(descripcion),
        operacionDetalle: opDetalleId(*))`
        )
        .in("tipoOpId", [1, 2, 4, 5, 11, 12]);
      setReports(reportes);
      setReportsLength(reportes.length);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userData.depositoId) {
      getReports(userData.depositoId, setLoading);
    }
  }, [userData]);

  return (
    <>
      {loading ? (
        <div className="center-div">
          <div>
            <LoadingSpinner />
          </div>
        </div>
      ) : (
        <div>
          <div className="crud__title">
            <h3>Reportes general de stock</h3>
            <hr />
          </div>
          {reportsLength > 0 ? (
            <ReportsStockTable data={reports} isGlobal={true} />
          ) : (
            <div className="center-div">
              <h3>Stock no tiene reportes</h3>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default StockGlobal;
