import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Formik, Field, Form } from "formik";
import MUIDataTable from "mui-datatables";
import { BsPlusCircleFill } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { supabaseClient } from "../supabaseClient";
import {
  textLabels,
  downloadOptions,
  sortFunction,
} from "../../utils/MuiTableOptions";
import { errAndMsg } from "../../utils/MsgAlert";
import { ErrorModal } from "../modals/ErrorModal";
import { formatDate } from "../../utils/formatDate";

const GeneralData = ({
  state,
  userData,
  header,
  señado,
  setHeader,
  isEditionOfOp,
  operation,
  isCompra = false,
  isPartOfOperation = false,
  disable = false,
  setRelatedUser,
  relatedUser,
  client, 
  setClient
}) => {
  const { deposito } = state || {};
  const date = new Date();
  const today = operation?.fechaAlta ? formatDate(operation?.fechaAlta) : date.toLocaleDateString("es-AR");
  const [openClientForm, setOpenClientForm] = useState(false);
  const [showClientes, setShowClientes] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [users, setUsers] = useState([]);
  const [userIndex, setUserIndex] = useState([]);
  const [clientIndex, setClientIndex] = useState([]);

  const getClients = async () => {
    const { data: personas, error } = await supabaseClient
    .from("personas")
    .select("*");
  const { data: users, errorUsers } = await supabaseClient
    .from("usuarios")
    .select("*, deposito: deposito(*)");

  if (error || errorUsers) {
    errAndMsg('Error', error.message, error.code); // alert(error || errorUsers);
  } else {
    setClientes(personas);
    const usuarios = users?.map((u) => {
      return{
        personaId: u.usrId,
        razonSocial: u.nombre,
        domicilio: u?.deposito?.descripcion + " - " + u?.deposito?.domicilio,
        telefono1: u.telefono,
        email: u.email,
        cuit: "-"
      }
    });
    setUsers(usuarios);
  }
  }

  useEffect(() => {
    const getUsers = async () => {
      if (isEditionOfOp || operation?.personaId ) {
        let query = supabaseClient
          .from("personas")
          .select()
          .eq("personaId",  operation?.personas?.personaId || header?.cliente?.personaId);

          const { data: cliente, error } = await query;

        if (error) {
          errAndMsg('Error', error.message, error.code);
        } else {
          setClient(cliente[0]);
        }
        if(relatedUser){
          const index = users.findIndex((obj) => obj.usrId === relatedUser);
          setRelatedUser(users[index])
          setUserIndex([index]);
        }
        setHeader({
          ...operation,
          cliente: cliente ? cliente[0] : [],
        });
      } else if (header?.cliente?.personaId){
        setClient(header.cliente)
      }
    };
    getUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditionOfOp, operation]);

  const handleChange = ({ target }) => {
    setHeader({ ...header, [target.name]: target.value });
  };

  const handleClose = () => {
    if (client.personaId) {
      setOpenClientForm(false);
      setShowClientes(!showClientes);
    } else {
      ErrorModal({
        title: `Debe escoger un cliente`,
      });
    }
  };

  const columns = [
    {
      name: "personaId",
      label: "Id",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "razonSocial",
      label: "Razón Social",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "domicilio",
      label: "Domicilio",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "telefono1",
      label: "Telefono principal",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "cuit",
      label: "Cuit",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const options = {
    searchOpen: true,
    textLabels,
    downloadOptions,
    filter: true,
    selectableRowsOnClick: true,
    selectableRows: "single",
    rowsSelected: showUsers ? userIndex : clientIndex,
    customToolbarSelect: () => {},
    fixedHeader: false,
    filterType: "dropdown",
    responsive: "standard",
    onRowSelectionChange: (currentSelect, allSelected) => {
      if(showClientes){
        setHeader({ ...header, cliente: clientes[currentSelect[0].dataIndex] });
        setClient(clientes[currentSelect[0].dataIndex]);
        setClientIndex([currentSelect[0].dataIndex]);
      } else {
        setRelatedUser(users[currentSelect[0].dataIndex]);
        setUserIndex([currentSelect[0].dataIndex]);
      }
      setShowUsers(false);
      setOpenClientForm(false);
      setShowClientes(false);
    },
    onRowsDelete: (rowsDeleted) => {
      setHeader({ ...header, cliente: {} });
      setClient({});
    },
    customSort: sortFunction,
    draggableColumns: { enabled: true },
  };

  return (
    <>
      <div>
        <div className="header-container">
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 10px",
              }}
            >
              <h5 style={{ fontWeight: "bold" }}>Datos generales</h5>
              {señado && (
                <span className="señado-span">
                  <h5>Señado</h5>
                </span>
              )}
            </div>
            {deposito && (
              <>
                <div className="vendedor-container">
                  {state?.operation  && <p>Operación N° {state?.operation} </p>}
                  <p>Fecha: {today}</p>
                  <p>Sucursal: {deposito?.domicilio}</p>
                  <p>Vendedor: {userData?.nombre}</p>
                  {isCompra ? (
                    <div className="factura">
                      <p>Comprobante AFIP</p>
                      <input
                        disabled={disable}
                        style={{ width: "100px" }}
                        className="form-control"
                        id="comprobante"
                        name="comprobante"
                        placeholder=""
                        type="number"
                        onChange={handleChange}
                        value={header?.comprobante}
                      />
                    </div>
                  ) : (
                    <div className="factura">
                      <p>Tipo Factura:</p>
                      <select
                        disabled={disable}
                        style={{ width: "30%" }}
                        className="form-select"
                        id="myList"
                        name="tipoFactura"
                        onChange={handleChange}
                        value={disable || isEditionOfOp ? operation?.tipoFactura : header?.tipoFactura}
                      >
                        <option value="A"> Factura A </option>
                        <option value="B"> Factura B </option>
                        <option value="Consumidor Final"> Consumidor Final </option>
                      </select>
                      <input
                        disabled={disable}
                        className="form-control"
                        style={{ width: "35%"}}
                        id="numFactura"
                        name="numFactura"
                        type="number"
                        placeholder=""
                        onChange={handleChange}
                        value={ header?.numFactura}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div></div>
        </div>
        <div className="cliente-container">
          <div className="cliente">
            <h5 style={{ fontWeight: "bold" }}>Cliente</h5>
            <div>
              <p>Nombre: {client?.razonSocial}</p>
              <p>Email: {client?.email}</p>
            </div>
            <div>
              <p>Domicilio: {client?.domicilio}</p>
              <p>CUIT: {client?.cuit}</p>
            </div>
          </div>
          {(relatedUser?.personaId || relatedUser?.usrId) && (
            <div className="usuario-relacionado">
            <h5 style={{ fontWeight: "bold" }}>Usuario Relacionado</h5>
            <div>
              <p>Nombre: {relatedUser?.razonSocial || relatedUser?.nombre}</p>
              <p>Email: {relatedUser?.email}</p>
            </div>
            <div>
              <p>Depóstio: {relatedUser?.domicilio || relatedUser?.deposito.domicilio}</p>
              <p>Telefono: {relatedUser?.telefono1 || relatedUser?.telefono}</p>
            </div>
          </div>
          )}
          <div className="observaciones">
            <h5 style={{ fontWeight: "bold" }}>Observaciones:</h5>
            <input
              disabled={disable}
              className="form-control"
              id="observaciones"
              name="observaciones"
              placeholder="Descripción general de la operación"
              onChange={handleChange}
              value={disable || isEditionOfOp ? operation?.observaciones : header.observaciones}
            />
          </div>
          <div className="button">
            <Button
              disabled={disable}
              style={isPartOfOperation ? { cursor: "default" } : {}}
              variant={isPartOfOperation ? "secondary" : "outline-success my-2"}
              onClick={() => {
                if (!isPartOfOperation) {
                  getClients();
                  setShowClientes(true);
                }
              }}
            >
              <BsPlusCircleFill style={{ marginRight: "10px" }} />
              Buscar Cliente
            </Button>
            {!isCompra && (
              <Button
                disabled={disable}
                style={isPartOfOperation ? { cursor: "default" } : {}}
                variant={
                  isPartOfOperation ? "secondary" : "outline-success my-2"
                }
                onClick={() => {
                  setShowUsers(true);
                }}
              >
                <BsPlusCircleFill style={{ marginRight: "10px" }} />
                Escoger usuario relacionado
              </Button>
            )}
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        show={showClientes}
        onHide={() => {
          setShowClientes(!showClientes);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Clientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MUIDataTable
            title={"Clientes"}
            data={clientes}
            columns={columns}
            options={options}
          />

          {openClientForm && (
            <div>
              <Formik
                initialValues={{
                  email: "",
                  razonSocial: "",
                  phone_number1: "",
                  phone_number2: "",
                  domicilio: "",
                  cuit: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = "Campo requerido";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Mail inválido";
                  } else if (!values.razonSocial) {
                    errors.razonSocial = "Campo requerido";
                  } else if (!values.phone_number1) {
                    errors.phone_number1 = "Campo requerido";
                  } else if (/\D/.test(values.phone_number1)) {
                    errors.phone_number1 =
                      "Solo se aceptarn números en este campo.";
                  } else if (!values.domicilio) {
                    errors.domicilio = "Campo requerido";
                  } else if (!values.cuit) {
                    errors.cuit = "Campo requerido";
                  }
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  let year = date.toLocaleString("default", { year: "numeric" });
                  let month = date.toLocaleString("default", { month: "2-digit" });
                  let day = date.toLocaleString("default", { day: "2-digit" });

                  // Generate yyyy-mm-dd date string
                  let formattedDate = year + "-" + month + "-" + day;
                  const { data: client, error } = await supabaseClient
                    .from("personas")
                    .insert([
                      {
                        email: values.email,
                        razonSocial: values.razonSocial,
                        domicilio: values.domicilio,
                        telefono1: values.phone_number1,
                        telefono2: values.phone_number2,
                        cuit: values.cuit,
                        fechaAlta: formattedDate,

                      },
                    ])
                    .select("*");
                  if (error) errAndMsg('Error', error.message, error.code);
                  setHeader({ ...header, cliente: client[0] });
                  setClient(client[0]);
                  setOpenClientForm(false);
                  setShowClientes(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="form-container form-container-two-colums">
                      <div className="form-group form-container__item">
                        <label
                          className="form-container__label"
                          htmlFor="razonSocial"
                        >
                          Razón social
                        </label>
                        <Field
                          style={{ width: "20rem" }}
                          className="form-control"
                          id="razonSocial"
                          name="razonSocial"
                          placeholder="Razón social"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.razonSocial}
                        />
                        <div className="invalid-feedback">
                          {errors.razonSocial &&
                            touched.razonSocial &&
                            errors.razonSocial}
                        </div>
                      </div>
                      <div className="form-group form-container__item">
                        <label
                          className="form-container__label"
                          htmlFor="domicilio"
                        >
                          Domicilio
                        </label>
                        <Field
                          style={{ width: "20rem" }}
                          className="form-control"
                          id="domicilio"
                          name="domicilio"
                          placeholder="Domicilio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.domicilio}
                        />
                        <div className="invalid-feedback">
                          {errors.domicilio &&
                            touched.domicilio &&
                            errors.domicilio}
                        </div>
                      </div>
                      <div className="form-group form-container__item">
                        <label
                          className="form-container__label"
                          htmlFor="email"
                        >
                          Email
                        </label>
                        <Field
                          style={{ width: "20rem" }}
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        <div className="invalid-feedback">
                          {errors.email && touched.email && errors.email}
                        </div>
                      </div>
                      <div className="form-group form-container__item">
                        <label
                          className="form-container__label"
                          htmlFor="phone_number1"
                        >
                          Número de teléfono principal
                        </label>
                        <Field
                          style={{ width: "20rem" }}
                          className="form-control"
                          id="phone_number1"
                          name="phone_number1"
                          placeholder="Número de teléfono"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone_number1}
                        />
                        <div className="invalid-feedback">
                          {errors.phone_number1 &&
                            touched.phone_number1 &&
                            errors.phone_number1}
                        </div>
                      </div>
                      <div className="form-group form-container__item">
                        <label
                          className="form-container__label"
                          htmlFor="phone_number2"
                        >
                          Número de teléfono secundario
                        </label>
                        <Field
                          style={{ width: "20rem" }}
                          className="form-control"
                          id="phone_number2"
                          name="phone_number2"
                          placeholder="Opcional"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone_number2}
                        />
                      </div>
                      <div className="form-group form-container__item">
                        <label className="form-container__label" htmlFor="cuit">
                          Cuit
                        </label>
                        <Field
                          style={{ width: "20rem" }}
                          className="form-control"
                          id="cuit"
                          name="cuit"
                          placeholder="Cuit"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.cuit}
                        />
                        <div className="invalid-feedback">
                          {errors.cuit && touched.cuit && errors.cuit}
                        </div>
                      </div>
                    </div>
                    <div className="form-container__button">
                      <button
                        className="btn btn-dark"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Agregar cliente
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          {!openClientForm && (
            <Button
              onClick={() => {
                setOpenClientForm(true);
              }}
              variant="secondary"
            >
              Crear cliente
            </Button>
          )}
          <Button onClick={handleClose} variant="primary">
            Escoger
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        show={showUsers}
        onHide={() => {
          setShowUsers(!showUsers);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Usuarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MUIDataTable
            title={"Usuarios"}
            data={users}
            columns={columns}
            options={options}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => {
            setShowUsers(!showUsers);
          }} variant="primary">
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GeneralData;
