import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import MUIDataTable from 'mui-datatables';
import { BsPlusCircleFill } from 'react-icons/bs';
import Modal from 'react-bootstrap/Modal';
import { supabaseClient } from '../supabaseClient';
import {
  textLabels,
  downloadOptions,
  sortFunction,
} from '../../utils/MuiTableOptions';
import { formatDate } from '../../utils/formatDate';
import { ErrorModal } from '../modals/ErrorModal';

const GeneralDataCashMovements = ({
  userData,
  header,
  deposito,
  setHeader,
  relatedUser,
  setClientRelatedUser,
  disabled,
  prevOp
}) => {
  const date = new Date();
  const today = prevOp?.fechaAlta ? formatDate(prevOp?.fechaAlta) : date.toLocaleDateString("es-AR");
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      let query = supabaseClient
        .from('usuarios')
        .select('*, deposito : depositoId(descripcion)');

      const { data: personas, error } = await query;

      if (error) {
        ErrorModal({
          title: error.message,
        });
      } else {
        const users = personas.map((p) => {
          return { ...p, deposito: p?.deposito?.descripcion };
        });
        setUsers(users);
      }
    };
    getUsers();
  }, []);

  const handleChange = ({ target }) => {
    setHeader({ ...header, [target.name]: target.value });
  };

  const handleClose = () => {
    if (relatedUser.usrId) {
      setShow(!show);
    } else {
      ErrorModal({
        title: "Debe escoger un usuario relacionado para esta operación.",
        text:"Complete el saldo si desea realizar esta venta"
      });
    }
  };

  const columns = [
    {
      name: 'usrId',
      label: 'Id',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'deposito',
      label: 'Deposito',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'nombre',
      label: 'Nombre',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'telefono',
      label: 'Telefono',
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const options = {
    searchOpen: true,
    textLabels,
    downloadOptions,
    filter: true,
    selectableRows: 'single',
    customToolbarSelect: () => {},
    selectableRowsOnClick: true,
    fixedHeader: false,
    filterType: 'dropdown',
    responsive: 'standard',
    onRowSelectionChange: (currentSelect, allSelected) => {
      setHeader({ ...header, cliente: users[currentSelect[0].dataIndex] });
      setClientRelatedUser(users[currentSelect[0].dataIndex]);
      setShow(false);
    },
    onRowsDelete: (rowsDeleted) => {
      setHeader({ ...header, cliente: {} });
      setClientRelatedUser({});
    },
    customSort: sortFunction,
    draggableColumns: { enabled: true },
  };

  return (
    <>
      <div>
        <div className="header-container">
          <div style={{ width: '85%' }}>
            <h5 style={{ fontWeight: 'bold', paddingLeft: '10px' }}>
              Datos generales
            </h5>
            {deposito && (
              <>
                <div className="vendedor-container">
                  {prevOp?.opCabId && <p>Operación N° {prevOp?.opCabId}</p>}
                  <p>Fecha: {today}</p>
                  <p>Sucursal: {deposito[0]?.domicilio}</p>
                  <p>Vendedor: {userData?.nombre}</p>
                  <div className="factura">
                    <p>Caja:</p>
                    <select
                      className="form-select"
                      name="caja"
                      onChange={handleChange}
                      value={header.caja}
                      disabled={true}
                    >
                      <option value="B"> Principal </option>
                      <option value="N"> Secundaria </option>
                    </select>
                  </div>
                </div>
              </>
            )}
          </div>
          <div></div>
        </div>
        <div className="cliente-container">
          <div className="cliente">
            <h5 style={{ fontWeight: 'bold' }}>Usuario relacionado</h5>
            <div>
              <p>Nombre: {relatedUser?.nombre}</p>
              <p>Email: {relatedUser?.email}</p>
            </div>
            <div>
              <p>Deposito: {relatedUser?.deposito}</p>
              <p>Telefono: {relatedUser?.telefono}</p>
            </div>
          </div>
          <div className="observaciones">
            <h5 style={{ fontWeight: 'bold' }}>Observaciones:</h5>
            <input
              disabled={disabled}
              className="form-control"
              id="observaciones"
              name="observaciones"
              placeholder="Descripción general de la operación"
              onChange={handleChange}
              value={header.observaciones}
            />
          </div>
          <div className="button">
            <Button
              disabled={disabled}
              variant="outline-success my-2"
              onClick={() => {
                setShow(true);
              }}
            >
              <BsPlusCircleFill style={{ marginRight: '10px' }} />
              Buscar Usuario
            </Button>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          setShow(!show);
        }}
        
      >
        <Modal.Header closeButton>
          <Modal.Title>Usuarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MUIDataTable
            title={'Usuarios'}
            data={users}
            columns={columns}
            options={options}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={handleClose} variant="primary">
            Escoger
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GeneralDataCashMovements;
