import React from "react";
import { supabaseClient } from "../component/supabaseClient";
import { Formik, Field, Form } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { GoBackButton } from "../component/GoBackButton";
import { errAndMsg } from "../utils/MsgAlert";

const AddNewClient = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user } = state || {};
  const date = new Date();
  const today = date.toLocaleDateString("en-US");
  const errorMsgs = {
    required: "Campo requerido",
    emailInvalid: "Mail inválido",
    onlyNumbers: "Solo se aceptan números en este campo.",
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="crud__title">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <GoBackButton />
          <div style={{ width: "100%" }}>
            <h3>{user ? "Editar cliente" : "Agregar nuevo cliente"}</h3>
          </div>
        </div>
        <hr />
      </div>

      <Formik
        initialValues={
          user
            ? {
                email: user.email,
                razonSocial: user.razonSocial,
                phone_number1: user.telefono1,
                phone_number2: user.telefono2,
                domicilio: user.domicilio,
                cuit: user.cuit,
              }
            : {
                email: "",
                razonSocial: "",
                phone_number1: "",
                phone_number2: "",
                domicilio: "",
                cuit: "",
              }
        }
        validate={(values) => {
          const errors = {};

          // if (!values.email) {
          //   errors.email = errorMsgs.emailRequired;
          // } else if (
          //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          // ) {
          //   errors.email = "Mail inválido";
          // } else if (!values.razonSocial) {
          //   errors.razonSocial = "Campo requerido";
          // } else if (!values.phone_number1) {
          //   errors.phone_number1 = "Campo requerido";
          // } else if (/\D/.test(values.phone_number1)) {
          //   errors.phone_number1 = "Solo se aceptarn números en este campo.";
          // } else if (!values.domicilio) {
          //   errors.domicilio = "Campo requerido";
          // } else if (!values.cuit) {
          //   errors.cuit = "Campo requerido";
          // }
          if (!values.email) {
            errors.email = errorMsgs.required;
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = errorMsgs.emailInvalid;
          }
          if (!values.razonSocial) {
            errors.razonSocial = errorMsgs.required;
          }

          if (!values.phone_number1) {
            errors.phone_number1 = errorMsgs.required;
          }
          // else if (/\D/.test(values.phone_number1)) {
          //   errors.phone_number1 = errorMsgs.onlyNumbers;
          // }

          if (!values.domicilio) {
            errors.domicilio = errorMsgs.required;
          }

          if (!values.cuit) {
            errors.cuit = errorMsgs.required;
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (!user) {
            try {
              const { error } = await supabaseClient.from("personas").insert([
                {
                  email: values.email,
                  razonSocial: values.razonSocial,
                  domicilio: values.domicilio,
                  telefono1: values.phone_number1,
                  telefono2: values.phone_number2,
                  cuit: values.cuit,
                  fechaAlta: today,
                },
              ]);
              if (error) errAndMsg("Error", error.message, error.code);
              setSubmitting(false);
              navigate("/clientes");
            } catch (error) {
              errAndMsg("Error", error.message, error.code);
            }
          } else {
            const { error } = await supabaseClient
              .from("personas")
              .update({
                email: values.email,
                razonSocial: values.razonSocial,
                domicilio: values.domicilio,
                telefono1: values.phone_number1,
                telefono2: values.phone_number2,
                cuit: values.cuit,
              })
              .eq("personaId", user.personaId);
            if (error) errAndMsg("Error", error.message, error.code);
            setSubmitting(false);
            navigate("/clientes");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="form-container form-container-three-colums">
              <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="razonSocial">
                  Razón social
                </label>
                <Field
                  className="form-control"
                  id="razonSocial"
                  name="razonSocial"
                  placeholder="Razón social"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.razonSocial}
                />
                <div className="invalid-feedback">
                  {errors.razonSocial &&
                    touched.razonSocial &&
                    errors.razonSocial}
                </div>
              </div>
              <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="domicilio">
                  Domicilio
                </label>
                <Field
                  className="form-control"
                  id="domicilio"
                  name="domicilio"
                  placeholder="Domicilio"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.domicilio}
                />
                <div className="invalid-feedback">
                  {errors.domicilio && touched.domicilio && errors.domicilio}
                </div>
              </div>
              <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="email">
                  Email
                </label>
                <Field
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <div className="invalid-feedback">
                  {errors.email && touched.email && errors.email}
                </div>
              </div>
              <div className="form-group form-container__item">
                <label
                  className="form-container__label"
                  htmlFor="phone_number1"
                >
                  Número de teléfono principal
                </label>
                <Field
                  className="form-control"
                  id="phone_number1"
                  name="phone_number1"
                  placeholder="Número de teléfono"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone_number1}
                />
                <div className="invalid-feedback">
                  {errors.phone_number1 &&
                    touched.phone_number1 &&
                    errors.phone_number1}
                </div>
              </div>
              <div className="form-group form-container__item">
                <label
                  className="form-container__label"
                  htmlFor="phone_number2"
                >
                  Número de teléfono secundario
                </label>
                <Field
                  className="form-control"
                  id="phone_number2"
                  name="phone_number2"
                  placeholder="Opcional"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone_number2}
                />
              </div>
              <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="cuit">
                  Cuit
                </label>
                <Field
                  className="form-control"
                  id="cuit"
                  name="cuit"
                  placeholder="Cuit"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cuit}
                />
                <div className="invalid-feedback">
                  {errors.cuit && touched.cuit && errors.cuit}
                </div>
              </div>
            </div>
            <div className="form-container__button">
              <button
                className="btn btn-dark"
                type="submit"
                disabled={isSubmitting}
              >
                {user ? "Editar cliente" : "Agregar cliente"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddNewClient;
