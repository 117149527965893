import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function UserDetailsModal({ user, setShow, show }) {
  const handleClose = () => setShow(false);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Detalles de usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="user-details-modal">
          <div className="user-details-modal-container">
            <div className="user-details-modal-item">
              <h4 className="user-details-modal-item__subtitle">Nombre: </h4>
              <p>{user?.nombre}</p>
            </div>
            <hr style={{ margin: "0" }} />
            <div className="user-details-modal-item">
              <h4 className="user-details-modal-item__subtitle">Email: </h4>
              <p>{user?.email}</p>
            </div>
            <hr style={{ margin: "0" }} />
            <div className="user-details-modal-item">
              <h4 className="user-details-modal-item__subtitle">Usuario: </h4>
              <p>{user?.user}</p>
            </div>
            <hr style={{ margin: "0" }} />
          </div>
          <div className="user-details-modal-container">
            <div className="user-details-modal-item">
              <h4 className="user-details-modal-item__subtitle">Telefono: </h4>
              <p>{user?.telefono}</p>
            </div>
            <hr style={{ margin: "0" }} />
            <div className="user-details-modal-item">
              <h4 className="user-details-modal-item__subtitle">
                Contraseña:{" "}
              </h4>
              <p>{user?.password}</p>
            </div>
            <hr style={{ margin: "0" }} />
            <div className="user-details-modal-item">
              <h4 className="user-details-modal-item__subtitle">
                Privilegio:{" "}
              </h4>
              <p>{user?.privilegio}</p>
            </div>
            <hr style={{ margin: "0" }} />
            <div className="user-details-modal-item">
              <h4 className="user-details-modal-item__subtitle">Activo:</h4>
              <p>{user?.activo ? "SI" : "NO"}</p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} variant="secondary">
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UserDetailsModal;
