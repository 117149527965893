/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { useStateAuthValue } from "../../context/AuthState";
import LoadingSpinner from "../../component/Spinner";
import { ReportsTable } from "../../component/ReportsOperationTable";
import { getSimpleDataOfTable } from "../../utils/supabaseFunctions/getData";

const Reports = () => {
  const [reports, setReports] = useState();
  const [loading, setLoading] = useState(true);
  const [{ userData }] = useStateAuthValue();

  const getReports = async (depositoId, setLoading, setReports) => {
    try {
      const reports = await getSimpleDataOfTable({
        tableName: "operacionCabecera",
        lookFor: `
      *,
      tipoOperacion : tipoOperacion(descripcion),
      deposito : deposito(*),
      usuario: usrId(*),
      usuarioRelacionado: usrIdRelacionado(*),
      personas (*),
      operacionDetalle(*),
      operacionPago(*,formaDePago(*))
      `,
        extraQueryEq: [{ queryColumn: "depositoId", queryValue: depositoId }],
        extraQueryOrder: [
          { queryColumn: "opCabId", queryValue: { ascending: false } },
        ],
      });

      const newData = reports.map((item) => {
        let formasPago = [];
        item?.operacionPago.map((f) => {
          formasPago.push(f.formaDePago);
        });
        const monto = item.operacionDetalle.reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue.cantidad * currentValue.precioUnitario,
          0
        );
        return { ...item, monto, formaPago: formasPago };
      });

      setReports(newData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userData.depositoId) {
      getReports(userData.depositoId, setLoading, setReports);
    }
  }, [userData]);

  return (
    <>
      {loading ? (
        <div className="center-div">
          <div>
            <LoadingSpinner />
          </div>
        </div>
      ) : (
        <div>
          <div className="crud__title">
            <h3>Reportes de caja</h3>
            <hr />
          </div>

          {reports.length > 0 ? (
            <ReportsTable data={reports} />
          ) : (
            <div className="center-div">
              <h3>La caja no tiene reportes</h3>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Reports;
