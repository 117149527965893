import React, { useState, useEffect } from "react";
import { supabaseClient } from "../../component/supabaseClient";
import { Formik, Field, Form } from "formik";
import { useStateAuthValue } from "../../context/AuthState";
import { useNavigate, useLocation } from "react-router-dom";
import { GoBackButton } from "../../component/GoBackButton";
import { FormControlLabel, Switch } from "@mui/material";
import { errAndMsg } from "../../utils/MsgAlert";
import { insertDataIntoTable } from "../../utils/supabaseFunctions/insertData";
import { updateSimpleData } from "../../utils/supabaseFunctions/updateData";

const AddNewUser = () => {
  const [privilegios, setPrivilegios] = useState();
  const [depositos, setDepositos] = useState();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user } = state || {};

  const [{ userRole }] = useStateAuthValue();

  if (userRole !== 1) {
    navigate("/");
  }

  useEffect(() => {
    const getPrivilegs = async () => {
      const { data, error } = await supabaseClient.from("privilegios").select();
      if (data) setPrivilegios(data);
      if (error)
        errAndMsg(
          "Error al obtener los privilegios",
          error.message,
          error.code
        );
    };
    getPrivilegs();
  }, []);

  useEffect(() => {
    const getDeposits = async () => {
      const { data, error } = await supabaseClient.from("deposito").select();
      if (data) setDepositos(data);
      if (error)
        errAndMsg("Error al obtener los depósitos", error.message, error.code);
    };
    getDeposits();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>{user ? "Editar usuario" : "Agregar nuevo usuario"}</h3>
              </div>
            </div>
            <hr />
          </div>
      <Formik
        initialValues={
          user
            ? {
                email: user.email,
                name: user.nombre,
                phone_number: user.telefono,
                password: user.password,
                deposit_id: user.depositoId,
                privilegioId: user.privilegioId,
                user: user.user,
                activo: user.activo,
              }
            : {
                email: "",
                name: "",
                phone_number: "",
                password: "",
                deposit_id: "",
                privilegioId: "",
                user: "",
                activo: true,
              }
        }
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Campo requerido";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Mail inválido";
          } else if (!values.name) {
            errors.name = "Campo requerido";
          } else if (!values.phone_number) {
            errors.phone_number = "Campo requerido";
          } else if (/\D/.test(values.phone_number)) {
            errors.phone_number = "Solo se aceptarn números en este campo.";
          } else if (!values.password) {
            errors.password = "Campo requerido";
          } else if (!values.user) {
            errors.user = "Campo requerido";
          } else if (!values.privilegioId) {
            errors.privilegioId = "Campo requerido";
          } else if (!values.deposit_id) {
            errors.deposit_id = "Campo requerido";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (!user) {
            await insertDataIntoTable({
              tableName: "usuarios",
              object: {
                email: values.email,
                telefono: values.phone_number,
                nombre: values.name,
                password: values.password,
                user: values.user,
                privilegioId: values.privilegioId,
                activo: true,
                depositoId: values.deposit_id,
              },
            });
            setSubmitting(false);
            navigate("/configuracion/usuarios");
          } else {
            await updateSimpleData({
              tableName: "usuarios",
              object: {
                email: values.email,
                telefono: values.phone_number,
                nombre: values.name,
                password: values.password,
                user: values.user,
                depositoId: values.deposit_id,
                privilegioId: values.privilegioId,
                activo: values.activo,
              },
              extraQueryEq: [{ queryColumn: "usrId", queryValue: user.usrId }],
            });
            setSubmitting(false);
            navigate("/configuracion/usuarios");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="form-container form-container-three-colums">
              <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="name">
                  Nombre
                </label>
                <Field
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Nombre"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                <div className="invalid-feedback">
                  {errors.name && touched.name && errors.name}
                </div>
              </div>
              <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="user">
                  Usuario
                </label>
                <Field
                  className="form-control"
                  id="user"
                  name="user"
                  placeholder="Usuario"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.user}
                />
                <div className="invalid-feedback">
                  {errors.user && touched.user && errors.user}
                </div>
              </div>
              <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="email">
                  Email
                </label>
                <Field
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <div className="invalid-feedback">
                  {errors.email && touched.email && errors.email}
                </div>
              </div>
              <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="phone_number">
                  Número de teléfono
                </label>
                <Field
                  className="form-control"
                  id="phone_number"
                  name="phone_number"
                  placeholder="Número de teléfono"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone_number}
                />
                <div className="invalid-feedback">
                  {errors.phone_number &&
                    touched.phone_number &&
                    errors.phone_number}
                </div>
              </div>
              <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="password">
                  Contraseña
                </label>
                <Field
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="Contraseña"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <div className="invalid-feedback">
                  {errors.password && touched.password && errors.password}
                </div>
              </div>
              <div className="form-group form-container__select">
                <label className="form-container__label" htmlFor="password">
                  Privilegio
                </label>
                <Field
                  className="form-control form-select"
                  as="select"
                  name="privilegioId"
                >
                  <option value=""></option>
                  {privilegios?.map((p) => {
                    return (
                      <option value={p.privilegioId}>{p.descripcion}</option>
                    );
                  })}
                </Field>
                <div className="invalid-feedback">
                  {errors.privilegioId &&
                    touched.privilegioId &&
                    errors.privilegioId}
                </div>
              </div>
              <div className="form-group form-container__select">
                <label className="form-container__label" htmlFor="password">
                  Deposito
                </label>
                <Field
                  className="form-control form-select"
                  as="select"
                  name="deposit_id"
                >
                  <option value=""></option>
                  {depositos?.map((p) => {
                    return (
                      <option value={p.depositoId}>
                        {p.descripcion + " - " + p.domicilio}
                      </option>
                    );
                  })}
                </Field>
                <div className="invalid-feedback">
                  {errors.deposit_id && touched.deposit_id && errors.deposit_id}
                </div>
              </div>
              <div className="form-group form-container__item">
                <FormControlLabel
                  control={
                    <Switch
                      name="mostrar_ocultar"
                      color="error"
                      checked={values.activo === true}
                      onChange={(event, checked) => {
                        setFieldValue("activo", checked ? true : false);
                      }}
                    />
                  }
                  label="Está activo?"
                />
              </div>
            </div>
            <div className="form-container__button">
              <button
                className="btn btn-dark"
                type="submit"
                disabled={isSubmitting}
              >
                {user ? "Editar usuario" : "Agregar usuario"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddNewUser;
