import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/scss/bootstrap.scss";

// TODO: Look for the best place to set the locale on numeral
import numeral from "numeral";
numeral.register("locale", "es", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  currency: {
    symbol: "$",
  },
});

// switch between locales
//numeral.locale("es");
window.alert = function(e){ console.warn( "Alerted: " + e ); }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
