/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import LoadingSpinner from "../component/Spinner";
import { pdf } from "../utils/operationsPdf";
import LoadingModal from "../component/modals/LoadingModal";
import { useStateAuthValue } from "../context/AuthState";
import { deleteOperation } from "../utils/OperationDeleteSale";
import { updateOperationSenada } from "../utils/OperationSaveSena";
import {
  saveSaleOperation,
  updateCajas,
  savePayment,
} from "../utils/OperationSaveSale";
import {
  getCompleteOperationByOpCabId,
  getSimpleDataOfTable,
} from "../utils/supabaseFunctions/getData";

import GeneralData from "../component/operationFlowSteps/generalData";
import Products from "../component/operationFlowSteps/products";
import Prices from "../component/operationFlowSteps/prices";
import PaymentOperation from "../component/operationFlowSteps/paymentOperation";
import OperationTotal from "../component/operationFlowSteps/operationTotal";
import { PdfTemplate } from "../component/operationFlowSteps/pdfTemplate";
import { CajaIsNotOpenModal } from "../component/CajaIsNotOpenModal";
import { GoBackButton } from "../component/GoBackButton";
import { fireConfirmationModal } from "../component/modals/ConfirmationModal.js";
import {
  operationTypes,
  operationStates,
  warehousesIds,
  service,
  efectiveSena,
  discountId,
  paymentsGroups,
  finalConsumerId,
  productTypes,
} from "../constants";
import { ErrorModal } from "../component/modals/ErrorModal";
import { toast } from "react-toastify";
import { formatDate } from "../utils/formatDate";
import { supabaseClient } from "../component/supabaseClient.jsx";
import { WarningModal } from "../component/modals/WarningModal.js";
import { currencyOperations } from "../utils/NumberInput.js";
import moment from "moment";

const Operation = () => {
  const [{ userRole, userData }] = useStateAuthValue();
  let navigate = useNavigate();
  const { state } = useLocation();
  const [operation, setOperation] = useState({});
  const [prevCajaExist, setPrevCajaExist] = useState(true);
  const [cajaB, setCajaB] = useState();
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditionOfOp, setIsEditionOfOp] = useState(false);
  const [submitUsedGoodsForm, setSubmitUsedGoodsForm] = useState(false);
  const [prevPayment, setPrevPayment] = useState([]);
  const [formasDePagoEscogidas, setFormasDePagoEscogidas] = useState([]);
  const [stock, setStock] = useState();
  const [productos, setProductos] = useState([]);
  const [prevDetailSenado, setPrevDetailSenado] = useState({});
  const [prevSenadosProductos, setPrevSenadosProductos] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [recargoAdmin, setRecargoAdmin] = useState(0);
  const [totalPagado, setTotalPagado] = useState();
  const [estado, setEstado] = useState();
  const [header, setHeader] = useState({
    numFactura: "",
    tipoFactura: "A",
    observaciones: "",
    cliente: {},
  });
  const [client, setClient] = useState({});
  const [detallesPagos, setDetallesPagos] = useState([]);
  const [pagoDetalle, setPagoDetalle] = useState([]);
  const [formPagoDetalle, setFormPagoDetalle] = useState([
    {
      formaPagoId: 5,
      name: "Comprobante de Compra de Bienes Usados No Registrables a Consumidores Finales",
      openModalUsedGoods: true,
      detail: [{ input: "", value: "value" }],
    },
    {
      formaPagoId: 4,
      name: "Pagaré",
      detail: [{ input: "fecha de vencimiento", value: "" }],
    },
    {
      formaPagoId: 6,
      name: "Cheque",
      detail: [
        { input: "numero de cheque", value: "" },
        { input: "banco", value: "" },
        { input: "sucursal", value: "" },
        { input: "fecha de emision", value: "" },
        { input: "fecha de cobro", value: "" },
        { input: "titular", value: "" },
      ],
    },
    {
      formaPagoId: 16,
      name: "Orden de compañia",
      detail: [
        { input: "numero de orden", value: "" },
        { input: "nombre de compañia", value: "" },
        { input: "fecha", value: "" },
      ],
    },
  ]);
  const [formaPagosModal, setFormaPagosModal] = useState();
  const [formaPagosList, setFormaPagosList] = useState();
  const [deposito, setDeposito] = useState();
  const [depositos, setDepositos] = useState([]);
  const [recargo, setRecargo] = useState();
  const [saldo, setSaldo] = useState();
  const [senado, setSenado] = useState(false);
  const [isPresupuesto, setIsPresupuesto] = useState(false);
  const [loadingPrevData, setLoadingPrevData] = useState(false);
  const [relatedUser, setRelatedUser] = useState({});

  var nowDate = new Date();
  const date = operation?.fechaAlta
    ? formatDate(operation?.fechaAlta)
    : nowDate.toLocaleDateString("es-AR");

  if (!userRole) {
    navigate("/");
  }

  useEffect(() => {
    const getPrevOperation = async () => {
      setLoadingPrevData(true);

      const operation = await getCompleteOperationByOpCabId(state?.operation);
      setOperation(operation);
      setEstado(operation.estadoOpId);
      setRecargoAdmin(operation?.recargoAdmin);
    };

    if (state?.operation) {
      getPrevOperation();
    }
  }, [state?.operation]);

  useEffect(() => {
    const setPrevOperationData = async () => {
      //TODO: Aca siempre va a entrar porque no existe operationStates.presupuesto
      if (operation.estadoOpId !== operationStates.presupuesto) {
        setIsEditionOfOp(true);
      } else {
        setIsEditionOfOp(false);
      }
      if (operation.estadoOpId === operationStates.sena) {
        setSenado(true);
      }
      setHeader({
        ...operation,
        numFactura: operation.numeroFactura,
      });
      if (operation?.operacionDetalle) {
        setPrevDetailSenado(operation.operacionDetalle);
        const prod = [];
        operation.operacionDetalle?.map(async (op) => {
          let prevProd = [];
          // product is service
          if (op.prodId === service) {
            prevProd = await getSimpleDataOfTable({
              tableName: "productos",
              lookFor:
                "*, tipoDes : tipoProducto(*), marcaDes : marca(*), modeloDes : modelo(*), medidaDes : medida(descripcion)",
              extraQueryEq: [{ queryColumn: "prodId", queryValue: op.prodId }],
            });
          } else {
            prevProd = await getSimpleDataOfTable({
              tableName: "stockDeposito",
              lookFor:
                "*, producto : productos(*), tipoDes : productos(tipoProducto(*)), marcaDes : productos(marca(*)), modeloDes : productos(modelo(*)), medidaDes : productos(medida(descripcion)), deposito : deposito(*)",
              extraQueryEq: [{ queryColumn: "prodId", queryValue: op.prodId }],
            });
          }
          //TODO: Que resuelve p o para que sirve la variable no es auto explicativa
          let p = {
            ...(prevProd[0]?.producto || prevProd[0]),
            wasSeñado: false,
            cantidad: op.cantidad,
            precioVenta: op.precioUnitario,
            noStock: false,
            stock: prevProd,
            stockOfVenRp:
              prevProd[0]?.deposito?.depositoId ===
              warehousesIds.oficina_venta_rp
                ? prevProd[0]?.stockActual
                : 0,
            stockDepoSm:
              prevProd[0]?.deposito?.depositoId === warehousesIds.deposito_sm
                ? prevProd[0]?.stockActual
                : 0,
            stockOfVenSm:
              prevProd[0]?.deposito?.depositoId ===
              warehousesIds.oficina_venta_sm
                ? prevProd[0]?.stockActual
                : 0,
            stockDepoRp:
              prevProd[0]?.deposito?.depositoId === warehousesIds.deposito_rp
                ? prevProd[0]?.stockActual
                : 0,
            stockRp:
              prevProd[0]?.deposito?.depositoId ===
              warehousesIds.rodriguez_penia
                ? prevProd[0]?.stockActual
                : 0,
            stockSm:
              prevProd[0]?.deposito?.depositoId === warehousesIds.san_martin
                ? prevProd[0]?.stockActual
                : 0,
            tipoDes: prevProd[0]?.tipoDes?.tipoProducto?.descripcion || "",
            marcaDes: prevProd[0]?.marcaDes?.marca?.descripcion || "",
            medidaDes: prevProd[0]?.medidaDes?.medida?.descripcion || "",
            modeloDes: prevProd[0]?.modeloDes?.modelo?.descripcion || "",
          };
          // check if the product still has stock if the operation was reserved
          const depositoId = prevProd[0]?.deposito?.depositoId;
          const stockActual = prevProd[0]?.stockActual;
          const noStock =
            state?.operation?.tipoOpId === operationTypes.presupuesto &&
            (depositoId !== userData.depositoId || stockActual === 0);
          p = {
            ...(prevProd[0].producto || prevProd[0]),
            ...p,
            cantidad: op.cantidad,
            precioVenta: op.precioUnitario,
            noStock,
            tipoDes: prevProd[0]?.tipoDes?.tipoProducto?.descripcion || "",
            marcaDes: prevProd[0]?.marcaDes?.marca?.descripcion || "",
            medidaDes: prevProd[0]?.medidaDes?.medida?.descripcion || "",
            modeloDes: prevProd[0]?.modeloDes?.modelo?.descripcion || "",
          };
          if (operation.estadoOpId === operationStates.pendiente) {
            p = {
              ...(prevProd[0].producto || prevProd[0]),
              ...p,
              wasSeñado: false,
            };
          } else {
            // check stock for current warehouse
            p = {
              ...(prevProd[0].producto || prevProd[0]),
              ...p,
              wasSeñado: true,
            };
          }
          prod.push(p);
        });

        setProductos(prod);
        if (operation.usrIdRelacionado) {
          setRelatedUser(operation.usrIdRelacionado);
        }

        const payment = await getSimpleDataOfTable({
          tableName: "operacionPago",
          extraQueryEq: [
            { queryColumn: "opCabId", queryValue: operation.opCabId },
          ],
          extraQueryGreatherThan: [{ queryColumn: "pagado", queryValue: 0 }],
        });
        setPrevPayment(payment);

        // pass the format of payments to the custom format of our form

        payment.map(async (p) => {
          const detailsPayment = await getSimpleDataOfTable({
            tableName: "detalleFormaPago",
            extraQueryEq: [{ queryColumn: "opPagoId", queryValue: p.id }],
          });

          let prevForm = formPagoDetalle;

          if (detailsPayment.length) {
            Object.entries(detailsPayment[0]).map(([key, value]) => {
              if (p.formaPagoId === 4 && key === "fechaVencimiento") {
                const i = prevForm.findIndex(
                  (_element) => _element.formaPagoId === p.formaPagoId
                );
                if (i > -1)
                  prevForm[i] = {
                    formaPagoId: 4,
                    name: "Pagaré",
                    detail: [
                      {
                        input: "fecha de vencimiento",
                        value: value
                          ? new Date(moment(formatDate(value), "DD/MM/YYYY"))
                          : "",
                      },
                    ],
                  };
              }
            });

            Object.entries(detailsPayment[0]).map(([key, value]) => {
              if (
                p.formaPagoId === 5 &&
                key ===
                  "Comprobante de Compra de Bienes Usados No Registrables a Consumidores Finales"
              ) {
                const i = prevForm.findIndex(
                  (_element) => _element.formaPagoId === p.formaPagoId
                );
                if (i > -1)
                  prevForm[i] = {
                    formaPagoId: 5,
                    name: "Comprobante de Compra de Bienes Usados No Registrables a Consumidores Finales",
                    openModalUsedGoods: true,
                  };
              }
            });

            if (p.formaPagoId === 6) {
              const i = prevForm.findIndex(
                (_element) => _element.formaPagoId === p.formaPagoId
              );
              let det = [];
              Object.entries(detailsPayment[0]).map(([key, value]) => {
                if (key === "nroCheque") {
                  det.push({ input: "numero de cheque", value: value });
                }
                if (key === "banco") {
                  det.push({ input: "banco", value: value });
                }
                if (key === "sucursal") {
                  det.push({ input: "sucursal", value: value });
                }
                if (key === "fechaEmision") {
                  det.push({
                    input: "fecha de emision",
                    value: value
                      ? new Date(moment(formatDate(value), "DD/MM/YYYY"))
                      : "",
                  });
                }
                if (key === "fechaCobro") {
                  det.push({
                    input: "fecha de cobro",
                    value: value
                      ? new Date(moment(formatDate(value), "DD/MM/YYYY"))
                      : "",
                  });
                }
                if (key === "titular") {
                  det.push({ input: "titular", value: value });
                }
              });
              if (i > -1)
                prevForm[i] = { formaPagoId: 6, name: "Cheque", detail: det };
            }

            if (p.formaPagoId === 16) {
              const i = prevForm.findIndex(
                (_element) => _element.formaPagoId === p.formaPagoId
              );
              let det = [];
              Object.entries(detailsPayment[0]).map(([key, value]) => {
                if (key === "nroOrden") {
                  det.push({ input: "numero de orden", value: value });
                }
                if (key === "compania") {
                  det.push({ input: "nombre de compañia", value: value });
                }
                if (key === "fecha") {
                  det.push({
                    input: "fecha",
                    value: value
                      ? new Date(moment(formatDate(value), "DD/MM/YYYY"))
                      : "",
                  });
                }
              });
              if (i > -1)
                prevForm[i] = {
                  formaPagoId: 16,
                  name: "Orden de compañia",
                  detail: det,
                };
            }
            setFormPagoDetalle(prevForm);
          }
        });
      }
      setLoadingPrevData(false);
    };

    if (operation?.opCabId) {
      setPrevOperationData();
      if (
        (operation?.tipoOpId === operationTypes.presupuesto &&
          operation?.estadoOpId === operationStates.presupuesto) ||
        (operation?.tipoOpId === operationTypes.sena &&
          operation?.estadoOpId === operationStates.sena) ||
        operation?.estadoOpId === operationStates.pendiente
      ) {
        setDisable(false);
      } else if (
        operation?.tipoOpId === operationTypes.venta_b ||
        operation.estadoOpId === operationStates.anulada
      ) {
        setDisable(true);
      }
    } else {
      setDisable(false);
      setIsEditionOfOp(false);
      setHeader({
        numFactura: "",
        tipoFactura: "A",
        observaciones: "",
        cliente: {},
      });
      setProductos([]);
      setFormasDePagoEscogidas([]);
      setPrevPayment([]);
      setPagoDetalle([]);
      setSenado(false);
    }
  }, [operation, operation?.opCabId, userData, operation?.numeroFactura]);

  useEffect(() => {
    const setOperation = async () => {
      setLoadingPrevData(true);

      const client = await getSimpleDataOfTable({
        tableName: "personas",
        extraQueryEq: [
          { queryColumn: "personaId", queryValue: finalConsumerId },
        ],
      });
      setHeader({
        ...header,
        cliente: client[0],
        tipoFactura: "Consumidor Final",
      });
      setClient(client[0]);

      const product = await getSimpleDataOfTable({
        tableName: "productos",
        lookFor:
          "*, tipoDes : tipoProducto(descripcion), marcaDes : marca(descripcion), modeloDes : modelo(descripcion), medidaDes : medida(descripcion), stock: stockDeposito(*, deposito : deposito(*))",

        extraQueryEq: [{ queryColumn: "prodId", queryValue: service }],
      });

      setProductos([
        {
          ...product[0],
          cantidad: 1,
        },
      ]);

      const payment = await getSimpleDataOfTable({
        tableName: "formaDePago",
        extraQueryEq: [
          { queryColumn: "formaPagoId", queryValue: paymentsGroups.efectivo },
        ],
      });

      setFormasDePagoEscogidas(payment);
    };
    if (state?.fastSale) {
      setOperation();
    }
  }, [state?.fastSale]);

  useEffect(() => {
    if (state?.presupuesto) {
      setIsPresupuesto(true);
    }
  }, [state?.presupuesto]);

  useEffect(() => {
    const getData = async () => {
      setLoadingPrevData(true);
      const pagosModal = await getSimpleDataOfTable({
        tableName: "formaDePago",
        lookFor: "*",
        extraQueryEq: [{ queryColumn: "activa", queryValue: true }],
      });

      const pagosList = await getSimpleDataOfTable({
        tableName: "formaDePago",
        lookFor: "*",
        extraQueryEq: [{ queryColumn: "mostrar", queryValue: true }],
        extraQueryNotEq:
          userRole === 1
            ? []
            : [{ queryColumn: "formaPagoId", queryValue: discountId }],
      });

      const paidPagos = pagosModal.map((p) => {
        return { ...p, clientPaid: 0 };
      });
      setFormaPagosModal(paidPagos);
      setFormaPagosList(pagosList);

      getProductos().then((r) => {
        setStock(r);
      });

      const deposits = await getSimpleDataOfTable({
        tableName: "deposito",
      });
      deposits.map((dep) => {
        if (dep.depositoId === userData.depositoId) {
          setDeposito(dep);
        }
      });

      setDepositos(deposits);

      const cajaB = await getSimpleDataOfTable({
        tableName: "cajas",
        extraQueryEq: [
          { queryColumn: "depositoId", queryValue: userData.depositoId },
          { queryColumn: "cajaPrincipal", queryValue: true },
        ],
        extraQueryIs: [{ queryColumn: "fechaCierre", queryValue: null }],
      });
      if (cajaB) {
        setCajaB(cajaB[0]);
      }

      setLoadingPrevData(false);
    };

    getData();
  }, [userData]);

  useEffect(() => {
    const prod = async () => {
      try {
        const promises = prevDetailSenado.map(async (op) => {
          let prevProd = await getSimpleDataOfTable({
            tableName: "stockDeposito",
            lookFor: "*, producto : productos(*), deposito : deposito(*)",
            extraQueryEq: [
              { queryColumn: "prodId", queryValue: op.prodId },
              { queryColumn: "depositoId", queryValue: userData.depositoId },
            ],
          });

          if (op.prodId === service) {
            prevProd = await getSimpleDataOfTable({
              tableName: "productos",
              extraQueryEq: [{ queryColumn: "prodId", queryValue: op.prodId }],
            });
          }

          const depositoId = prevProd[0]?.deposito?.depositoId;
          const stockActual = prevProd[0]?.stockActual;

          const noStock =
            state?.operation?.tipoOpId === operationTypes.presupuesto &&
            (depositoId !== userData.depositoId || stockActual === 0);

          return {
            ...(op.prodId === service ? prevProd[0] : prevProd[0].producto),
            cantidad: op.cantidad,
            precioVenta: op.precioUnitario,
            noStock: noStock,
            wasSeñado: true,
            stockOfVenRp:
              depositoId === warehousesIds.oficina_venta_rp ? stockActual : 0,
            stockDepoSm:
              depositoId === warehousesIds.deposito_sm ? stockActual : 0,
            stockOfVenSm:
              depositoId === warehousesIds.oficina_venta_sm ? stockActual : 0,
            stockDepoRp:
              depositoId === warehousesIds.deposito_rp ? stockActual : 0,
            stockRp:
              depositoId === warehousesIds.rodriguez_penia ? stockActual : 0,
            stockSm: depositoId === warehousesIds.san_martin ? stockActual : 0,
          };
        });

        const result = await Promise.all(promises);
        return result;
      } catch (error) {
        console.error(error);
        return [];
      }
    };
    if (prevDetailSenado[0]?.opDetalleId) {
      prod().then((r) => setPrevSenadosProductos(r));
    }
  }, [prevDetailSenado]);

  useEffect(() => {
    if (cajaB?.cajaId) {
      setPrevCajaExist(true);
    } else {
      setPrevCajaExist(false);
    }
  }, [cajaB]);

  const getProductos = async () => {
    let allDeposits = depositos.map((deposito) => deposito.descripcion);
    const productos = await getSimpleDataOfTable({
      tableName: "productos",
      lookFor:
        "*, tipoDes : tipoProducto(descripcion), marcaDes : marca(descripcion), modeloDes : modelo(descripcion), medidaDes : medida(descripcion), stock: stockDeposito(*, deposito : deposito(*))",
      extraQueryOrder: [
        {
          queryColumn: "descripcionConcatenada",
          queryValue: { ascending: true },
        },
      ],
    });
    return await Promise.all(
      productos.map(async (p) => {
        let stockActual = p.stock.filter(
          (sto) => sto.depositoId === userData.depositoId
        );
        let totalStock = p.stock.reduce((sum, dep) => sum + dep.stockActual, 0);
        return {
          ...p,
          cantidad: 1,
          stocksDepositosIds: p.stock.map((sto) => {
            return sto.depositoId;
          }),
          stockActual: stockActual[0]?.stockActual,
          totalStock: totalStock,
          ...allDeposits.reduce((acc, dep) => {
            acc[
              `stock_${depositos.find((d) => d.descripcion === dep).depositoId}`
            ] = getStockForProductoAndDeposito(
              p.prodId,
              depositos.find((d) => d.descripcion === dep).depositoId,
              p.stock
            );
            return acc;
          }, {}),
          tipoDes: p?.tipoDes?.descripcion || "",
          marcaDes: p?.marcaDes?.descripcion || "",
          medidaDes: p?.medidaDes?.descripcion || "",
          modeloDes: p?.modeloDes?.descripcion || "",
          usado: p?.usado,
        };
      })
    );
  };
  const submit = async ({ typoOp, checkPayment }) => {
    const saldoNumber = Number(saldo.replace(/\./g, "").replace(/,/g, "."));
    if (saldoNumber < 0) {
      ErrorModal({
        title: "Error",
        text: "El saldo no puede ser negativo",
      });
    } else {
      // "delete" operation
      if (typoOp === 5) {
        let hasSaldo = true;
        setLoading(true);
        //revisar que haya saldo de efectivo
        prevPayment.map(async (p) => {
          if (
            p.formaPagoId === paymentsGroups.efectivo ||
            p.formaPagoId === paymentsGroups.sena
          ) {
            const movementEfectivo = await getSimpleDataOfTable({
              tableName: "movimientos",
              extraQueryEq: [
                { queryColumn: "formaPagoId", queryValue: p.formaPagoId },
                { queryColumn: "cajaId", queryValue: cajaB.cajaId },
              ],
            });

            let saldoEfectivo = movementEfectivo.length
              ? movementEfectivo.reduce(
                  (acc, movement) => acc + movement.monto,
                  0
                )
              : 0;
            if (saldoEfectivo < p.pagado) {
              hasSaldo = false;
              setLoading(false);
              setPagoDetalle([]);
              navigate("/operaciones");
              ErrorModal({
                title: "Error",
                text: "No hay suficiente saldo de efectivo para realizar este contramovimiento",
              });
            }
          }
        });

        setTimeout(async () => {
          if (hasSaldo) {
            await deleteOperation(
              userData,
              operation,
              productos,
              prevPayment,
              cajaB,
              senado || operation.estadoOpId === operationStates.pendiente,
              navigate
            );
            setLoading(false);
            setPagoDetalle([]);
            navigate("/operaciones");
          }
        }, 1000);
      } else if (saldoNumber === 0 || !checkPayment) {
        setLoading(true);
        setSubmitUsedGoodsForm(true);

        let hasSena = formasDePagoEscogidas.some(
          (obj) => obj.formaPagoId === efectiveSena
        );
        let opId = operationTypes.venta_b;
        let totalPagadoNumber = totalPagado
          ? Number(totalPagado.replace(/\./g, "").replace(/,/g, "."))
          : 0;

        if (typoOp === operationTypes.presupuesto) {
          if (totalPagadoNumber === 0) {
            opId = operationTypes.presupuesto;
          } else if (saldoNumber > 0 && hasSena) {
            opId = operationTypes.sena;
          } else {
            opId = operationTypes.presupuesto;
          }
        } else {
          opId = operationTypes.venta_b;
        }

        let estado = operationStates.pendiente;
        if (typoOp === operationTypes.presupuesto) {
          if (saldoNumber > 0 && formasDePagoEscogidas.length && hasSena) {
            estado = operationStates.sena;
          } else {
            estado = operationStates.pendiente;
          }
        } else if (saldoNumber === 0 && (!hasSena || senado)) {
          estado = operationStates.venta_b;
        }

        if (senado || operation.estadoOpId === operationStates.pendiente) {
          // descontar nuevas formas de pago de las que fueron señadas
          const newPaymentForms = formasDePagoEscogidas.filter(
            (item) =>
              !prevPayment.some((obj) => obj.formaPagoId === item.formaPagoId)
          );

          const dataCabecera = await updateOperationSenada(
            state?.operation,
            header,
            estado,
            opId,
            productos,
            userData,
            navigate,
            operation,
            recargoAdmin
          );
          if (dataCabecera) {
            if (
              estado !== operationStates.pendiente &&
              opId !== operationTypes.presupuesto
            ) {
              await updateCajas(
                cajaB,
                prevPayment,
                formasDePagoEscogidas,
                opId,
                estado
              );
            }

            newPaymentForms.map(async (p) => {
              savePayment(
                dataCabecera,
                p,
                totalPrice,
                header,
                detallesPagos,
                cajaB
              );
            });
          }

          setLoading(false);
          setPagoDetalle([]);
          navigate("/operaciones");
        } else {
          const dataCabecera = await saveSaleOperation({
            userData,
            header,
            opId,
            productos,
            estado,
            relatedUser,
            navigate,
            recargoAdmin,
          });

          if (dataCabecera) {
            await updateCajas(
              cajaB,
              prevPayment,
              formasDePagoEscogidas,
              opId,
              estado
            );

            formasDePagoEscogidas.map(async (p) => {
              try {
                const paymentSaved = await savePayment(
                  dataCabecera,
                  p,
                  totalPrice,
                  header,
                  detallesPagos,
                  cajaB
                );
                if (!paymentSaved) {
                  toast.error(
                    "Error, got : " + paymentSaved + " instead of true"
                  );
                }
              } catch (error) {
                toast.error(
                  "Hubo un error en la carga de datos: " + error.message
                );
              }
            });
          }
          setLoading(false);
          setPagoDetalle([]);
          navigate("/operaciones");
        }
      } else {
        ErrorModal({
          title: "El saldo no fue completado.",
          text: "Complete el saldo si desea realizar esta venta",
        });
      }
    }
  };

  const getStockForProductoAndDeposito = (prodId, depositoId, deposits) => {
    const stockData = deposits.find(
      (stock) => stock.prodId === prodId && stock.depositoId === depositoId
    );
    return stockData ? stockData?.stockActual : 0;
  };

  return loadingPrevData ? (
    <div className="center-div">
      <LoadingSpinner />
    </div>
  ) : (
    <div>
      <div className="crud__title">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <GoBackButton />
          <div style={{ width: "100%" }}>
            {isPresupuesto ? <h3>Presupuesto</h3> : <h3>Venta</h3>}
          </div>
        </div>
        <hr />
      </div>
      <div className="operation-container">
        <div className="operation-general-data operation-item">
          <GeneralData
            userData={userData}
            header={header}
            señado={senado}
            setHeader={setHeader}
            isEditionOfOp={isEditionOfOp}
            operation={operation}
            state={{ ...state, deposito: deposito }}
            disable={disable}
            relatedUser={relatedUser}
            setRelatedUser={setRelatedUser}
            client={client}
            setClient={setClient}
          />
        </div>
        <div className="operation-product-list operation-item">
          <Products
            disable={disable}
            senado={senado}
            setProducts={setProductos}
            products={productos}
            total={totalPrice}
            setTotal={setTotalPrice}
            stock={stock}
            depositoId={userData.depositoId}
            getProductos={getProductos}
            setStock={setStock}
            depositos={depositos}
            isCompra={false}
            isPresupuesto={isPresupuesto}
          />
        </div>
        <div className="operation-pay-list operation-item">
          <Prices
            formaPagos={formaPagosList}
            total={totalPrice}
            saldo={saldo}
          />
        </div>
        <div className="operation-pay-forms operation-item">
          <PaymentOperation
            disable={disable}
            formaPagos={formaPagosModal}
            total={totalPrice}
            setFormasDePagoEscogidas={setFormasDePagoEscogidas}
            formasDePagoEscogidas={formasDePagoEscogidas}
            recargo={recargo}
            señado={senado}
            setRecargo={setRecargo}
            saldo={saldo}
            setSaldo={setSaldo}
            setDetallesPagos={setDetallesPagos}
            detallesPagos={detallesPagos}
            totalPagado={totalPagado}
            setTotalPagado={setTotalPagado}
            pagoDetalle={pagoDetalle}
            setPagoDetalle={setPagoDetalle}
            prevPayment={prevPayment}
            isEditionOfOp={isEditionOfOp}
            isPresupuesto={isPresupuesto}
            formPagoDetalle={formPagoDetalle}
            header={header}
            date={date}
            submitUsedGoodsForm={submitUsedGoodsForm}
            recargoAdmin={recargoAdmin}
            setRecargoAdmin={setRecargoAdmin}
          />
        </div>
        <div className="operation-total operation-item">
          <OperationTotal
            recargo={recargo}
            total={totalPagado}
            formasDePagoEscogidas={formasDePagoEscogidas}
            header={header}
          />
        </div>
      </div>
      <div className="operation-buttons-container">
        <div>
          <Button
            disabled={header?.cliente?.razonSocial ? false : true}
            variant="success my-2"
            onClick={() => {
              if (header?.cliente?.razonSocial) {
                let filename = operation?.opCabId
                  ? `${operation?.estadoOperacion?.descripcion}[${operation.opCabId}]`
                  : header.cliente.razonSocial + " - " + date;
                pdf({
                  filename: filename,
                  id: "presupuesto",
                });
              }
            }}
          >
            Imprimir
          </Button>
          {!isPresupuesto && (
            <Button
              variant="success my-2"
              disabled={disable}
              style={{
                backgroundColor: "#52a2fe",
                borderColor: "#52a2fe",
              }}
              onClick={async () => {
                const saldoNumber = Number(
                  saldo.replace(/\./g, "").replace(/,/g, ".")
                );
                let hasMoreThanSena = formasDePagoEscogidas.some(
                  (obj) => obj.formaPagoId !== efectiveSena
                );
                let hasSena = formasDePagoEscogidas.some(
                  (obj) => obj.formaPagoId === efectiveSena
                );
                const hasNoQuantityProduct = productos.filter(
                  (item) => item.cantidad === "" || item.cantidad === 0
                );
                //check if details payment were submited.
                let detailsPaymentNotFilled = formasDePagoEscogidas?.some((obj) => 
                  formPagoDetalle?.some((pagoDet) => (pagoDet.formaPagoId === obj.formaPagoId) && pagoDet?.detail.some((detail) => detail.value === ""))
                )

                if (!header.cliente.personaId) {
                  ErrorModal({
                    title: "Debe escoger un cliente",
                  });
                } else if (formasDePagoEscogidas?.length === 0) {
                  ErrorModal({
                    title: "Debe escoger al menos una forma de pago",
                  });
                } else if (saldoNumber !== 0) {
                  ErrorModal({
                    title: "El saldo no fue completado",
                  });
                } else if (productos?.length === 0) {
                  ErrorModal({
                    title: "Debe escoger al menos un producto para la venta",
                  });
                } else if (hasNoQuantityProduct.length !== 0) {
                  ErrorModal({
                    title: "No se escogió stock de un producto",
                  });
                } else if (
                  formasDePagoEscogidas.length &&
                  !hasMoreThanSena &&
                  hasSena &&
                  saldoNumber <= 0
                ) {
                  ErrorModal({
                    title:
                      "No puede realizar una seña que complete todo el pago",
                    text: "El monto de seña debe ser menor al total de la operación para el señado",
                  });
                } else if(detailsPaymentNotFilled){
                  ErrorModal({
                    title:
                      "Faltan datos de una forma de pago",
                    text: "Debe rellenar los detalles de una o más formas de pago. Para hacerlo, aprete el botón de DETALLES en el listado de formas de pago.",
                  });
                } else {
                  setLoading(true);
                  // check stock before confirmation
                  const promises = productos.map(async (item) => {
                    const { data, error } = await supabaseClient
                      .from("stockDeposito")
                      .select("*")
                      .eq("prodId", item.prodId)
                      .eq("depositoId", userData.depositoId);

                    if (
                      (!data[0].stockActual ||
                        data[0].stockActual < item.cantidad) &&
                      item.tipo !== productTypes.servicio
                    ) {
                      WarningModal({
                        title: `Oops!`,
                        text: "Ha excedido el stock existente en uno o más productos, por favor revise los mismos para continuar. Es probable que el stock haya sido cambiado por otra operación realizada al mismo momento que esta.",
                      });
                    setLoading(false);
                    } else {
                      return true; // Indicate that the check passed
                    }
                  });

                  const results = await Promise.all(promises);

                  if (results.every((result) => result)) {
                    fireConfirmationModal({
                      title: `¿Estás seguro de que deseas realizar esta venta?`,
                      submit: submit,
                      props: {
                        typoOp: operationStates.finalizado,
                      },
                    });
                    setLoading(false);
                  }
                }
              }}
            >
              Generar venta
            </Button>
          )}
          <Button
            variant="secondary my-2"
            disabled={
              operation.estadoOpId === operationStates.anulada || userRole !== 1
            }
            onClick={() => {
              if (!header.cliente.personaId) {
                ErrorModal({
                  title: "Debe escoger un cliente",
                });
              } else if (productos?.length === 0) {
                ErrorModal({
                  title: "Debe escoger al menos un producto para la venta",
                });
              } else {
                if (isEditionOfOp) {
                  fireConfirmationModal({
                    title: isPresupuesto
                      ? "¿Estás seguro de que deseas anular este presupuesto?"
                      : "¿Estás seguro de que deseas anular esta venta?",
                    submit: submit,
                    props: {
                      typoOp: operationStates.anulada,
                      checkPayment: false,
                    },
                  });
                } else {
                  fireConfirmationModal({
                    title: isPresupuesto
                      ? "¿Estás seguro de que deseas anular este presupuesto?"
                      : "¿Estás seguro de que deseas anular esta venta?",
                    submit: () => {
                      navigate("/operaciones");
                    },
                  });
                }
              }
            }}
          >
            Anular
          </Button>
        </div>
        <Button
          variant="outline-success my-2"
          onClick={() => {
            fireConfirmationModal({
              title: "¿Estás seguro de que deseas cerrar esta operación?",
              submit: () => {
                navigate(-1);
              },
            });
          }}
        >
          Cerrar
        </Button>
        {isPresupuesto ? (
          <Button
            disabled={senado}
            variant="outline-success my-2"
            onClick={() => {
              const hasNoQuantityProduct = productos.filter(
                (item) => item.cantidad === "" || item.cantidad === 0
              );
              if (disable) {
                navigate("/operaciones");
              } else if (!header.cliente.personaId) {
                ErrorModal({
                  title: "Debe escoger un cliente",
                });
              } else if (hasNoQuantityProduct?.length !== 0) {
                ErrorModal({
                  title:
                    "Debe escoger al menos un producto para guardar esta venta",
                });
              } else if (formasDePagoEscogidas.length) {
                ErrorModal({
                  title:
                    "Para guardar un presupuesto no debe ingresar formas de pago",
                  text: "Si tiene alguna forma de pago seleccionada se recomienda eliminarla",
                });
              } else {
                fireConfirmationModal({
                  title:
                    "¿Estás seguro de que deseas guardar este presupuesto?",
                  submit: submit,
                  props: {
                    typoOp: operationTypes.presupuesto,
                    checkPayment: false,
                  },
                });
              }
            }}
          >
            Generar presupuesto
          </Button>
        ) : (
          <Button
            disabled={disable}
            variant="outline-success my-2"
            onClick={() => {
              const hasNoQuantityProduct = productos.filter(
                (item) => item.cantidad === "" || item.cantidad === 0
              );
              let hasMoreThanSena = formasDePagoEscogidas.some(
                (obj) => obj.formaPagoId !== efectiveSena
              );
              let hasSena = formasDePagoEscogidas.some(
                (obj) => obj.formaPagoId === efectiveSena
              );
              if (disable) {
                navigate("/operaciones");
              } else if (!header.cliente.personaId) {
                ErrorModal({
                  title: "Debe escoger un cliente",
                });
              } else if (hasNoQuantityProduct?.length !== 0) {
                ErrorModal({
                  title:
                    "Debe escoger al menos un producto para guardar esta venta",
                });
              } else if (formasDePagoEscogidas.length === 0) {
                ErrorModal({
                  title: "Debe agregar una seña para guardar esta venta",
                });
              } else if (formasDePagoEscogidas.length && hasMoreThanSena) {
                ErrorModal({
                  title:
                    "Para guardar una seña debe ingresar únicamente la forma de pago: Seña efectivo",
                  text: "Si tiene alguna otra forma de pago seleccionada, se recomienda eliminarla",
                });
              } else if (
                formasDePagoEscogidas.length &&
                !hasMoreThanSena &&
                hasSena &&
                Number(saldo.replace(/\./g, "").replace(/\,/g, ".")) <= 0
              ) {
                ErrorModal({
                  title: "No puede realizar una seña que complete todo el pago",
                  text: "El monto de seña debe ser menor al total de la operación para el señado",
                });
              } else {
                fireConfirmationModal({
                  title: "¿Estás seguro de que deseas señar esta venta?",
                  submit: submit,
                  props: {
                    typoOp: operationTypes.presupuesto,
                    checkPayment: false,
                  },
                });
              }
            }}
          >
            Generar seña
          </Button>
        )}
      </div>
      <div
        style={{
          visibility: "hidden",
          zIndex: "-9999",
          position: "fixed",
        }}
      >
        {deposito && deposito.length !== 0 && header.cliente && (
          <PdfTemplate
            generalData={{
              today: date,
              deposito: deposito[0]?.domicilio,
              vendedor: userData?.nombre,
            }}
            header={header}
            productos={productos}
            recargo={recargo}
            total={totalPrice}
            saldo={saldo}
            formasDePagoEscogidas={formasDePagoEscogidas}
            isPresupuesto={
              productos?.length && Number(saldo) !== 0
                ? true
                : state?.operation?.tipoOpId === 3
                ? true
                : isPresupuesto
                ? true
                : false
            }
            isSenado={senado}
            formaPagos={formaPagosList}
            estado={estado}
          />
        )}
      </div>
      <LoadingModal text="Cargando" show={loading} setShow={setLoading} />
      {prevCajaExist || isEditionOfOp || isPresupuesto ? (
        ""
      ) : (
        <CajaIsNotOpenModal prevCajaExist={prevCajaExist} />
      )}
    </div>
  );
};

export default Operation;
