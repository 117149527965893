/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { GoBackButton } from "../component/GoBackButton";
import Select from "react-select";

import { useStateAuthValue } from "../context/AuthState";
import { addNewProduct } from "../utils/AddNewProduct";
import { getSimpleDataOfTable } from "../utils/supabaseFunctions/getData";
import LoadingSpinner from "../component/Spinner";
import { updateSimpleData } from "../utils/supabaseFunctions/updateData";
import { NumberInputValidation } from "../utils/NumberInput";
import { productTypes } from "../constants/productTypes";
import { insertProdDetails } from "../utils/supabaseFunctions/insertData";

const AddNewProduct = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { product } = state || {};
  const [{ userData }] = useStateAuthValue();

  const [models, setModels] = useState([]);
  const [medidas, setMedidas] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [newMeasurements, setNewMeasurements] = useState({
    marcaDes: "",
    modeloDes: "",
    medidaDes: "",
    tipoDes: "",
  });
  const [loading, setLoading] = useState(false);
  const [customized, setCustomized] = useState(false);
  const [standarized, setStandarized] = useState(false);

  const estados = [
    {
      value: false,
      label: "Nuevo",
    },
    {
      value: true,
      label: "Usado",
    },
  ];
  useEffect(() => {
    const getData = async () => {
      const modelos = await getSimpleDataOfTable({
        tableName: "modelo",
        lookFor: "*",
        extraQueryEq: [
          { queryColumn: "activa", queryValue: true },
          { queryColumn: "mostrar", queryValue: true },
        ],
        extraQueryOrder: [
          { queryColumn: "descripcion", queryValue: { ascending: true } },
        ],
      });
      let arrayTypeTModels = modelos.map((item) => {
        return { value: item.modeloId, label: item.descripcion };
      });
      setModels(arrayTypeTModels);

      const medidas = await getSimpleDataOfTable({
        tableName: "medida",
        lookFor: "*",
        extraQueryEq: [
          { queryColumn: "activa", queryValue: true },
          { queryColumn: "mostrar", queryValue: true },
        ],
        extraQueryOrder: [
          { queryColumn: "descripcion", queryValue: { ascending: true } },
        ],
      });
      let arrayTypeTMedidas = medidas.map((item) => {
        return { value: item.medidaId, label: item.descripcion };
      });
      setMedidas(arrayTypeTMedidas);

      const marcas = await getSimpleDataOfTable({
        tableName: "marca",
        lookFor: "*",
        extraQueryEq: [
          { queryColumn: "activa", queryValue: true },
          { queryColumn: "mostrar", queryValue: true },
        ],
        extraQueryOrder: [
          { queryColumn: "descripcion", queryValue: { ascending: true } },
        ],
      });
      let arrayTypeMarcas = marcas.map((item) => {
        return { value: item.marcaId, label: item.descripcion };
      });
      setMarcas(arrayTypeMarcas);

      const tipos = await getSimpleDataOfTable({
        tableName: "tipoProducto",
        lookFor: "*",
        extraQueryOrder: [
          { queryColumn: "descripcion", queryValue: { ascending: true } },
        ],
      });
      let arrayTypeTipos = tipos.map((item) => {
        return { value: item.tipoProdId, label: item.descripcion };
      });
      setTipos(arrayTypeTipos);
    };

    getData();
  }, [userData]);

  return loading ? (
    <div className="center-div">
      <LoadingSpinner />
    </div>
  ) : (
    <div style={{ width: "100%" }}>
      <div className="crud__title">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <GoBackButton />
          <div style={{ width: "100%" }}>
            <h3>{product ? "Editar producto" : "Agregar nuevo producto"}</h3>
          </div>
        </div>
        <hr />
      </div>
      <Formik
        initialValues={
          product
            ? {
                marca: product?.marca,
                marcaDes: product?.marcaDes,
                modelo: product?.modelo,
                modeloDes: product?.modeloDes,
                tipo: product?.tipo,
                tipoDes: product?.tipoDes,
                medida: product?.medida,
                medidaDes: product?.medidaDes,
                descripcion: product?.descripcion,
                tipoDibujo: product?.tipoDibujo,
                estado: product?.usado,
                precioVenta: product?.precioVenta,
                ancho: "",
                alto: "",
                diametro: "",
                agujeros: "",
                medidaPersonalizada: "",
                estadoDes: product.usado ? "Usado" : "Nuevo",
              }
            : {
                marca: "",
                marcaDes: "",
                modelo: "",
                modeloDes: "",
                tipo: "",
                tipoDes: "",
                medida: "",
                medidaDes: "",
                descripcion: "",
                tipoDibujo: "",
                estado: false,
                precioVenta: 0,
                ancho: "",
                alto: "",
                diametro: "",
                agujeros: "",
                medidaPersonalizada: "",
                estadoDes: "",
              }
        }
        validate={(values) => {
          const errors = {};
          if (!values.tipoDes) {
            errors.tipoDes = "Campo requerido";
          } else if (values.tipoDes !== "Servicio" && values.tipoDes !== "SERVICIOS") {
            if (!values.marcaDes) {
              errors.marcaDes = "Campo requerido";
            } else if (!values.medidaDes && !values.medidaPersonalizada) {
              errors.medidaDes = "Campo requerido";
            } else if (!values.modeloDes) {
              errors.modeloDes = "Campo requerido";
            } else if (!values.descripcion) {
              errors.descripcion = "Campo requerido";
            }
          } else if (!values.descripcion) {
            errors.descripcion = "Campo requerido";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setLoading(true);
          console.log(values)
          const medidaDescripcion = values.alto
            ? `${values.ancho}/${values.alto}/${values.diametro}`
            : values.agujeros
            ? `${values.diametro}/${values.agujeros}/${values.ancho}`
            : customized
            ? values.medidaPersonalizada
            : values.medidaDes;

          const descripcionCompleta =
            values.tipo == productTypes.servicio
              ? values.tipoDes + " " + values.descripcion
              : values.tipoDes +
                " " +
                values.marcaDes +
                " " +
                values.modeloDes +
                " " +
                medidaDescripcion +
                " " +
                values.descripcion;

          const measurement = {
            newMeasurements: {
              ...newMeasurements,
              medidaDes: medidaDescripcion,
            },
          };

          //como puedo editar la medida standarizada y la personalizada, siempre chequeo si es necesario crear una nueva
          //el valor devuelto por measurementNew va a ser una que ya existia o la recien creada
          const measurementNew = values.tipo === productTypes.servicio ? [] :await insertProdDetails(measurement);
          console.log(measurementNew)
          var medida = measurementNew[2]?.medidaId;

          const producto = {
            tipo: values.tipo,
            marca: values.tipo == productTypes.servicio ? null : values.marca,
            modelo: values.tipo == productTypes.servicio ? null : values.modelo,
            medida: values.tipo == productTypes.servicio ? null : medida,
            descripcion: values.descripcion,
            descripcionConcatenada: descripcionCompleta,
            tipoDibujo: values.dibujo,
            precioCosto: Number(
              String(values.precioVenta).replace(/\./g, "").replace(/,/g, ".")
            ),
            precioVenta: Number(
              String(values.precioVenta).replace(/\./g, "").replace(/,/g, ".")
            ),
            usado: values.tipo == productTypes.servicio ? true : values.estado,
          };

          if (product) {
            await updateSimpleData({
              tableName: "productos",
              object: producto,
              extraQueryEq: [
                { queryColumn: "prodId", queryValue: product.prodId },
              ],
            });
            setSubmitting(false);
          navigate("/productos");
          setLoading(false);
          } else {
            await addNewProduct({
              userData,
              productos: [
                {
                  ...producto,
                  cantidad: 0,
                  newMeasurements:
                    values.tipo == productTypes.servicio
                      ? ""
                      : {
                          ...newMeasurements,
                          medidaDes: medida ? "" : medidaDescripcion,
                        },
                },
              ],
            });
            setSubmitting(false);
          navigate("/productos");
          setLoading(false);
          }
          
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          return (
          <Form onSubmit={handleSubmit}>
            <div className="form-container form-container-two-colums">
              <div className="form-group form-container__select">
                <label className="form-container__label" htmlFor="tipoDes">
                  Tipo de producto
                </label>
                <Select
                  className="form-control"
                  id="tipo"
                  name="tipo"
                  isSearchable
                  noOptionsMessage={() => "No hay opciones"}
                  onChange={(e) => {
                    setFieldValue("tipoDes", e.label);
                    setFieldValue("tipo", e.value);
                    setNewMeasurements({
                      ...newMeasurements,
                      tipoDes: "",
                    });
                  }}
                  onBlur={handleBlur}
                  value={{ value: values.tipo, label: values.tipoDes }}
                  options={tipos}
                />
              </div>
              {values.tipo == productTypes.servicio ? (
                <>
                  <div className="form-group form-container__item">
                    <label
                      className="form-container__label"
                      htmlFor="descripcion"
                    >
                      Descripcion
                    </label>
                    <Field
                      style={{ width: "20rem" }}
                      className="form-control"
                      id="descripcion"
                      name="descripcion"
                      placeholder="descripcion"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.descripcion}
                    />
                    <div className="invalid-feedback">
                      {errors.descripcion &&
                        touched.descripcion &&
                        errors.descripcion}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="form-group form-container__item">
                    <label
                      className="form-container__label"
                      htmlFor="tipo"
                    ></label>
                    <Field
                      id="tipo"
                      name="tipo"
                      style={{ width: "20rem" }}
                      className="form-control"
                      placeholder="Crear nuevo tipo de producto"
                      onChange={(e) => {
                        setFieldValue("tipoDes", e.target.value);
                        setNewMeasurements({
                          ...newMeasurements,
                          tipoDes: e.target.value,
                        });
                      }}
                      onBlur={handleBlur}
                      value={newMeasurements.tipoDes}
                    />
                    <div className="invalid-feedback">
                      {errors.tipoDes && touched.tipoDes && errors.tipoDes}
                    </div>
                  </div>
                  <div className="form-group form-container__select">
                    <label className="form-container__label" htmlFor="marcaDes">
                      Marca
                    </label>
                    <Select
                      className="form-control"
                      id="marca"
                      name="marca"
                      isSearchable
                      noOptionsMessage={() => "No hay opciones"}
                      onChange={(e) => {
                        setFieldValue("marcaDes", e.label);
                        setFieldValue("marca", e.value);
                        setNewMeasurements({
                          ...newMeasurements,
                          marcaDes: "",
                        });
                      }}
                      onBlur={handleBlur}
                      value={{ value: values.marca, label: values.marcaDes }}
                      options={marcas}
                    />
                  </div>
                  <div className="form-group form-container__item">
                    <label
                      className="form-container__label"
                      htmlFor="marca"
                    ></label>
                    <Field
                      id="marca"
                      name="marca"
                      style={{ width: "20rem" }}
                      className="form-control"
                      placeholder="Crear nueva marca"
                      onChange={(e) => {
                        setFieldValue("marcaDes", e.target.value);
                        setNewMeasurements({
                          ...newMeasurements,
                          marcaDes: e.target.value,
                        });
                      }}
                      onBlur={handleBlur}
                      value={newMeasurements.marcaDes}
                    />
                    <div className="invalid-feedback">
                      {errors.marcaDes && touched.marcaDes && errors.marcaDes}
                    </div>
                  </div>
                  <div className="form-group form-container__select">
                    <label
                      className="form-container__label"
                      htmlFor="modeloDes"
                    >
                      Modelo
                    </label>
                    <Select
                      className="form-control"
                      id="modelo"
                      name="modelo"
                      isSearchable
                      noOptionsMessage={() => "No hay opciones"}
                      onChange={(e) => {
                        setFieldValue("modeloDes", e.label);
                        setFieldValue("modelo", e.value);
                        setNewMeasurements({
                          ...newMeasurements,
                          modeloDes: "",
                        });
                      }}
                      onBlur={handleBlur}
                      value={{ value: values.modelo, label: values.modeloDes }}
                      options={models}
                    />
                  </div>
                  <div className="form-group form-container__item">
                    <label
                      className="form-container__label"
                      htmlFor="modelo"
                    ></label>
                    <Field
                      id="modelo"
                      name="modelo"
                      style={{ width: "20rem" }}
                      className="form-control"
                      placeholder="Crear nuevo modelo"
                      onChange={(e) => {
                        setFieldValue("modeloDes", e.target.value);
                        setNewMeasurements({
                          ...newMeasurements,
                          modeloDes: e.target.value,
                        });
                      }}
                      onBlur={handleBlur}
                      value={newMeasurements.modeloDes}
                    />
                    <div className="invalid-feedback">
                      {errors.modeloDes &&
                        touched.modeloDes &&
                        errors.modeloDes}
                    </div>
                  </div>
                  <div className="form-group form-container__select">
                    <label
                      className="form-container__label"
                      htmlFor="medidaDes"
                    >
                      Medida
                    </label>
                    <Select
                      className="form-control"
                      id="medida"
                      name="medida"
                      isSearchable
                      noOptionsMessage={() => "No hay opciones"}
                      onChange={(e) => {
                        setFieldValue("medidaDes", e.label);
                        setFieldValue("medida", e.value);
                        setFieldValue("medidaPersonalizada", "");

                        setCustomized(false);
                        setStandarized(false);
                        setFieldValue("alto", "");
                        setFieldValue("ancho", "");
                        setFieldValue("diametro", "");
                        setFieldValue("agujeros", "");
                        setNewMeasurements({
                          ...newMeasurements,
                          medidaDes: "",
                        });
                      }}
                      onBlur={handleBlur}
                      value={{ value: values.medida, label: values.medidaDes }}
                      options={medidas}
                    />
                    <div className="invalid-feedback">
                      {errors.medidaDes &&
                        touched.medidaDes &&
                        errors.medidaDes}
                    </div>
                  </div>

                  <div className="form-group form-container__item">
                    <input
                      type="radio"
                      id="medidaPersonalizada"
                      name="medidaPersonalizada"
                      checked={customized}
                      onChange={(e) => {
                        setCustomized(true);
                        setStandarized(false);
                        setFieldValue("personalizada", true);
                        setFieldValue("alto", "");
                        setFieldValue("ancho", "");
                        setFieldValue("diametro", "");
                        setFieldValue("agujeros", "");
                      }}
                    />
                    <label
                      className={`form-container__label2 ${
                        customized ? "" : "form-radio-disabled"
                      }`}
                      htmlFor="medidaPersonalizada"
                    >
                      Medida personalizada
                    </label>
                    <Field
                      className="form-control"
                      id="medidaPersonalizada"
                      name="medidaPersonalizada"
                      placeholder="195/60/R16 x 1234"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("medidaPersonalizada", e.target.value);
                        setFieldValue("medidaDes", e.target.value);
                      }}
                      value={values.medidaPersonalizada}
                      disabled={!customized}
                    />
                    <div className="invalid-feedback">
                      {errors.medidaPersonalizada &&
                        touched.medidaPersonalizada &&
                        errors.medidaPersonalizada}
                    </div>

                    {values.tipo != productTypes.llanta && (
                      <div className="row">
                        <div className="col-3">
                          <input
                            type="radio"
                            id="medida"
                            name="medida"
                            checked={standarized}
                            onChange={() => {
                              setCustomized(false);
                              setStandarized(true);
                              setFieldValue("personalizada", false);
                              setFieldValue("medidaPersonalizada", "");
                            }}
                          />
                          <label
                            className={`form-container__label2 ${
                              !standarized ? "form-radio-disabled" : ""
                            }`}
                            htmlFor="medida"
                          >
                            Ancho
                          </label>
                          <Field
                            className="form-control text-center"
                            id="ancho"
                            name="ancho"
                            placeholder="195"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ancho}
                            disabled={!standarized}
                          />
                          <div className="invalid-feedback">
                            {errors.ancho && touched.ancho && errors.ancho}
                          </div>
                        </div>
                        <div className="col-1">
                          <label
                            className="form-container__label"
                            htmlFor="span1"
                          ></label>
                          <span id="span1" className="form-control no-border">
                            /
                          </span>
                        </div>
                        <div className="col-3">
                          <label
                            className={`form-container__label2 ${
                              !standarized ? "form-radio-disabled" : ""
                            }`}
                            htmlFor="alto"
                          >
                            Perfil
                          </label>
                          <Field
                            className="form-control text-center"
                            id="alto"
                            name="alto"
                            placeholder="60"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.alto}
                            disabled={!standarized}
                          />
                          <div className="invalid-feedback">
                            {errors.alto && touched.alto && errors.alto}
                          </div>
                        </div>
                        <div className="col-1">
                          <label
                            className={`form-container__label2 ${
                              !standarized ? "form-radio-disabled" : ""
                            }`}
                            htmlFor="span2"
                          ></label>
                          <span id="span2" className="form-control no-border">
                            /R
                          </span>
                        </div>
                        <div className="col-3">
                          <label
                            className={`form-container__label2 ${
                              !standarized ? "form-radio-disabled" : ""
                            }`}
                            htmlFor="diametro"
                          >
                            Diámetro
                          </label>
                          <Field
                            className="form-control text-center"
                            id="diametro"
                            name="diametro"
                            placeholder="16"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.diametro}
                            disabled={!standarized}
                          />
                          <div className="invalid-feedback">
                            {errors.diametro &&
                              touched.diametro &&
                              errors.diametro}
                          </div>
                          {!values.medidaDes &&
                            values.ancho &&
                            values.alto &&
                            values.diametro &&
                            setFieldValue(
                              "medidaDes",
                              values.ancho +
                                "/" +
                                values.alto +
                                "/" +
                                values.diametro
                            )}
                        </div>
                      </div>
                    )}
                    {values.tipo == productTypes.llanta && (
                      <div className="row">
                        <div className="col-4">
                          <input
                            type="radio"
                            id="medida"
                            name="medida"
                            checked={standarized}
                            onChange={() => {
                              setCustomized(false);
                              setStandarized(true);
                              setFieldValue("personalizada", false);
                              setFieldValue("medidaPersonalizada", "");
                            }}
                          />
                          <label
                            className={`form-container__label2 ${
                              !standarized ? "form-radio-disabled" : ""
                            }`}
                            htmlFor="medida"
                          >
                            Diámetro
                          </label>
                          <Field
                            className="form-control text-center"
                            id="diametro"
                            name="diametro"
                            placeholder="17"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.diametro}
                            disabled={!standarized}
                          />
                          <div className="invalid-feedback">
                            {errors.diametro &&
                              touched.diametro &&
                              errors.diametro}
                          </div>
                        </div>
                        <div className="col-1">
                          <label
                            className="form-container__label"
                            htmlFor="span1"
                          ></label>
                          <span id="span1" className="form-control no-border">
                            /
                          </span>
                        </div>
                        <div className="col-3">
                          <label
                            className={`form-container__label ${
                              !standarized ? "form-radio-disabled" : ""
                            }`}
                            htmlFor="distribucion"
                          >
                            Agujeros
                          </label>
                          <Field
                            className="form-control text-center"
                            id="agujeros"
                            name="agujeros"
                            placeholder="4x100"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.agujeros}
                            disabled={!standarized}
                          />
                          <div className="invalid-feedback">
                            {errors.agujeros &&
                              touched.agujeros &&
                              errors.agujeros}
                          </div>
                        </div>
                        <div className="col-1">
                          <label
                            className="form-container__label"
                            htmlFor="span2"
                          ></label>
                          <span id="span2" className="form-control no-border">
                            /
                          </span>
                        </div>
                        <div className="col-3">
                          <label
                            className={`form-container__label ${
                              !standarized ? "form-radio-disabled" : ""
                            }`}
                            htmlFor="ancho"
                          >
                            Ancho
                          </label>
                          <Field
                            className="form-control text-center"
                            id="ancho"
                            name="ancho"
                            placeholder="7"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ancho}
                            disabled={!standarized}
                          />
                          <div className="invalid-feedback">
                            {errors.ancho && touched.ancho && errors.ancho}
                          </div>
                          {!values.medidaDes &&
                            values.ancho &&
                            values.agujeros &&
                            values.diametro &&
                            setFieldValue(
                              "medidaDes",
                              values.diametro +
                                "/" +
                                values.agujeros +
                                "/" +
                                values.ancho
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="form-group form-container__item">
                    <label
                      className="form-container__label"
                      htmlFor="descripcion"
                    >
                      Descripcion
                    </label>
                    <Field
                      style={{ width: "20rem" }}
                      className="form-control"
                      id="descripcion"
                      name="descripcion"
                      placeholder="descripcion"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.descripcion}
                    />
                    <div className="invalid-feedback">
                      {errors.descripcion &&
                        touched.descripcion &&
                        errors.descripcion}
                    </div>
                  </div>
                  <div className="form-group form-container__item">
                    <label
                      className="form-container__label"
                      htmlFor="tipoDibujo"
                    >
                      Tipo de dibujo
                    </label>
                    <Field
                      style={{ width: "20rem" }}
                      className="form-control"
                      id="tipoDibujo"
                      name="tipoDibujo"
                      placeholder="Opcional"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tipoDibujo}
                    />
                  </div>
                  <div className="form-group form-container__select">
                    <label className="form-container__label" htmlFor="estado">
                      Estado
                    </label>

                    <Field
                      className="form-control form-select"
                      as="select"
                      id="estado"
                      name="estado"
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue(
                          "estado",
                          e.target.options[e.target.selectedIndex].value
                        );
                      }}
                      onBlur={handleBlur}
                      value={values.estado}
                    >
                      {estados.map((e, index) => {
                        return (
                          <option key={index} value={e.value}>
                            {e.label}
                          </option>
                        );
                      })}
                    </Field>
                    <div className="invalid-feedback">
                      {errors.estado && touched.estado && errors.estado}
                    </div>
                  </div>
                </>
              )}
              <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="tipoDes">
                  Precio
                </label>
                <NumberInputValidation
                  style={{ width: "20rem" }}
                  className="form-control"
                  placeholder=""
                  handlePriceChange={({ value }) => {
                    setFieldValue("precioVenta", value);
                  }}
                  onBlur={handleBlur}
                  value={values.precioVenta}
                />
              </div>
            </div>
            <div className="form-container__button">
              <button
                className="btn btn-dark"
                type="submit"
                disabled={isSubmitting}
              >
                {product ? "Guardar cambios" : "Crear producto"}
              </button>
            </div>
          </Form>
        )}}
      </Formik>
    </div>
  );
};

export default AddNewProduct;
