import React, { useState, useEffect } from "react";
import { supabaseClient } from "../component/supabaseClient";
import { useStateAuthValue } from "../context/AuthState";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../component/Spinner";
import { GoBackButton } from "../component/GoBackButton";
import { CashBoxTable } from "../component/CashBox/CashBoxTable";
import { Button } from "react-bootstrap";
import { FaCashRegister } from "react-icons/fa";
import { errAndMsg } from "../utils/MsgAlert";

const CashList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [cashboxList, setCashboxList] = useState({});
  const [{ userData }] = useStateAuthValue();
  /* const [openingStartDate, setOpeningStartDate] = useState();
  const [openingEndDate, setOpeningEndDate] = useState(); */

  useEffect(() => {
    //Function to get all rows from table cajas
    const getCashboxList = async () => {
      setIsLoading(true);
      //query variable
      let query = supabaseClient
        .from("cajas")
        .select(
          `
      *,
      usuario:usuarios(*)
      `
        )
        .eq("depositoId", userData.depositoId);
      //conditional query
      /* if (openingStartDate) {
        query.gte("fechaApertura", openingStartDate);
      }
      if (openingEndDate) {
        query.lte("fechaApertura", openingEndDate);
      } */
      query.order("cajaId", { ascending: false });

      try {
        setIsLoading(true);
        const { data, error } = await query;
        if (error) throw error;
        setCashboxList(data);
      } catch (error) {
        errAndMsg(
          "Error al obtener las formas de pago",
          error.message,
          error.code
        );
      } finally {
        setIsLoading(false);
      }
    };
    getCashboxList();
  }, [userData]);

  return (
    <div>
      {isLoading ? (
        <div className="center-div">
          <div>
            <LoadingSpinner />
          </div>
        </div>
      ) : (
        <div>
          <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>Reporte de cajas</h3>
              </div>
            </div>
            <hr />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginLeft: "10px",
            }}
          >
            <Button
              variant="outline-success my-2"
              onClick={() => {
                navigate("/caja");
              }}
            >
              <FaCashRegister /> Caja actual
            </Button>
          </div>
          {cashboxList.length > 0 ? (
            <CashBoxTable data={cashboxList} />
          ) : (
            <div className="center-div">
              <h3>No hay cajas para mostrar</h3>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CashList;
