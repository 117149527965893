import { toast } from "react-toastify";
import { supabaseClient } from "../../component/supabaseClient";
import { getSimpleDataOfTable } from "./getData";
import { insertDataIntoTable } from "./insertData";
import { productTypes } from "../../constants";

  export const updateSimpleData = async ({
    tableName,
    extraQueryEq,
    object,
  }) => {
    const query = supabaseClient.from(tableName).update([object]).select();

    if (extraQueryEq) {
      extraQueryEq.forEach((queryParam) => {
        query.eq(queryParam.queryColumn, queryParam.queryValue);
      });
    }

    try {
      const { data, error } = await query;

      if (error) {
        console.error(`updateSimpleData error for table ${tableName}:`, error.message);
        return null;
      }

      return data;
    } catch (exception) {
      console.error(`updateSimpleData exception for table ${tableName}:`, exception.message);
      return null;
    }
  };

export const updateCajaReducePrevPayment = async ({
  saldo,
  pagado,
  cajaId,
}) => {
  const { data: cajaBln, errorCajaB } = await supabaseClient
    .from("cajas")
    .update([
      {
        saldo: Number(saldo) - Number(pagado),
      },
    ])
    .eq("cajaId", cajaId)
    .select();

  if (errorCajaB) {
    toast.error("updateCajaReducePrevPayment error " + errorCajaB);
  } else {
    return cajaBln[0];
  }
};

export const updateCajaSumNewPayment = async ({ saldo, pagado, cajaId }) => {
  const { data: cajaBln, errorCajaB } = await supabaseClient
    .from("cajas")
    .update([
      {
        saldo: Number(saldo) + Number(pagado),
      },
    ])
    .eq("cajaId", cajaId)
    .select();

  if (errorCajaB) {
    toast.error("updateCajaSumNewPayment error " + errorCajaB);
  } else {
    return cajaBln[0];
  }
};

export const checkStockDepositoBeforeUpdate = async ({
  p,
  depositoId,
  date,
  isSum,
}) => {
  const dataDepo = await getSimpleDataOfTable({
    tableName: "stockDeposito",
    extraQueryEq: [
      { queryColumn: "prodId", queryValue: p.prodId },
      { queryColumn: "depositoId", queryValue: depositoId },
    ],
  });
  
  if (dataDepo.length) {
    await updateSimpleData({
      tableName: "stockDeposito",
      object: {
        stockActual: isSum
          ? dataDepo[0].stockActual + p.cantidad
          : dataDepo[0].stockActual - p.cantidad, 
        fecha: date,
      },
      extraQueryEq: [
        { queryColumn: "prodId", queryValue: p.prodId },
        { queryColumn: "depositoId", queryValue: depositoId }
      ],
    });

  } else {
    if(p.tipo === productTypes.servicio){
      const idDepositos = await getSimpleDataOfTable({
        tableName: "deposito",
      });
      for (let i=0;i<idDepositos?.length ; i++) {
        await insertDataIntoTable({
          tableName: "stockDeposito",
          object: {
            fecha: date,
            prodId: p.prodId,
            depositoId: idDepositos[i].depositoId,
            stockActual: 1 ,
          },
        });
      }
    } else {
      await insertDataIntoTable({
        tableName: "stockDeposito",
        object: {
          fecha: date,
          prodId: p.prodId,
          depositoId: depositoId,
          stockActual: p.cantidad? p.cantidad : p.stockMinimo ,
        },
      });
    }
  }
};
