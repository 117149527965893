import { traslado} from './saveMovements';
import { operationTypes } from "../../constants/operationTypes";
import { getSimpleDataOfTable } from '../supabaseFunctions/getData';
import { ErrorModal } from '../../component/modals/ErrorModal';
import { productTypes } from '../../constants';

export const transferStock = async (
  userData,
  header,
  products,
  navigate
) => {
  const operationTypeOut = operationTypes.stock_traslado_entre_sucursales_egreso;
  const operationTypeIn = operationTypes.stock_traslado_entre_sucursales_ingreso;
  const operationType = [operationTypeOut, operationTypeIn];

  for (const p of products) {
    const prevStock = await getSimpleDataOfTable({
      tableName: "stockDeposito",
      extraQueryEq : [
        {queryColumn: "prodId", queryValue: p.prodId},
        {queryColumn: "depositoId", queryValue: userData.depositoId}
      ]});

      if((!prevStock.length || prevStock[0]?.stockActual <= 0) && p.tipo !== productTypes.servicio){
      navigate("/operaciones");
      ErrorModal({
        title: "Error",
        text:"El producto " + p?.descripcion + " no tiene stock en tu depósito, por lo que la operación no puede completarse.",
      });
    } 
  }

  await traslado (   
    userData,
    header,
    products,
    operationType
  )
}