import { useState } from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../styles/MuiDataTableStyle";
import CustomReportsToolbar from "./CustomReportsOperation";
import { paymentsGroups } from "../constants/paymentGroup";
import { Box } from "@mui/material";
import { formatDate } from "../utils/formatDate";
import { textLabels, downloadOptions } from "../utils/MuiTableOptions";
import numeral from "numeral";
import { currencyOperations } from "../utils/NumberInput";
import { discountId } from "../constants";

export const ReportsTable = ({ data }) => {
  const [finalData, setFinalData] = useState(data);
  const [filterData, setFilterData] = useState([
    {
      label: "Mínima fecha de alta",
      filterBy: "",
      column: "fechaAlta",
      objective: "min",
    },
    {
      label: "Máxima fecha de alta",
      filterBy: "",
      column: "fechaAlta",
      objective: "max",
    },
    {
      label: "Ingreso / egreso de dinero",
      filterBy: "",
      column: "montoSelect",
      objective: "eq",
      options: [
        { label: "Ingreso", value: 1 },
        { label: "Egreso", value: -1 },
      ],
    },
    {
      label: "Monto mínimo",
      filterBy: "",
      column: "monto",
      objective: "min",
    },
    {
      label: "Monto máximo",
      filterBy: "",
      column: "monto",
      objective: "max",
    },
    {
      label: "Tipo de forma de pago",
      filterBy: "",
      column: "formaPagoId",
      objective: "eq",
      options: [
        { label: "Tarjeta", value: paymentsGroups.tarjetas },
        { label: "Efectivo", value: paymentsGroups.efectivo },
        { label: "Cheque", value: paymentsGroups.cheques },
        { label: "Transferencia", value: paymentsGroups.transferencias },
        { label: "Pagaré", value: paymentsGroups.pagare },
        {
          label: "Ordenes de compañia",
          value: paymentsGroups.ordenesDeCompanias,
        },
      ],
    },
  ]);

  const lookForFilter = () => {
    const filters = filterData.filter(
      (filter) => filter.filterBy !== ""
    );

    if (filters.length === 0) {
      setFinalData(data);
      return;
    }

    const filteredArray = data.filter((item) => {
      let currentDate = new Date(item.fechaAlta.replace(/-/g, '/'));
      currentDate.setHours(0, 0, 0, 0);

      let dateMax =
        filterData[1]?.filterBy ? new Date(filterData[1].filterBy)
          : false;
      dateMax = dateMax ? dateMax.setHours(0, 0, 0, 0) : false;

      let dateMin =
        filterData[0]?.filterBy ?  new Date(filterData[0].filterBy)
          : false;
      dateMin = dateMin ? dateMin.setHours(0, 0, 0, 0) : false;

      const amountSelected =
        typeof filterData[2]?.filterBy === "number" && filterData[2]?.filterBy;
      const amountMax =
        filterData[4]?.filterBy !== "" ? filterData[4]?.filterBy : false;
      const amountMin =
        filterData[3]?.filterBy !== "" ? filterData[3]?.filterBy : false;
      const paymentFormSelected =
        typeof filterData[5]?.filterBy === "number" && filterData[5]?.filterBy;


      let checkAmountMaxMin = true;
      let checkDates = true;
      let checkAmountNumer = true;
      let checkPaymentType = true;

      // check one by one if the user inputted a value for those columns
      // if true then implement filter, otherwise don't check that column
      if (amountMax || amountMin) {
        checkAmountMaxMin =
          amountMax && amountMin
            ? currencyOperations(amountMax, item?.monto, "GREATERTHAN") && currencyOperations(amountMin, item?.monto, "LESSERTHAN")
            : amountMax
            ? currencyOperations(amountMax, item?.monto, "GREATERTHAN")
            : amountMin
            ? currencyOperations(amountMin, item?.monto, "LESSERTHAN")
            : false;
      }
      if (dateMax || dateMin) {
        checkDates =
          dateMax && dateMin
            ? currentDate.getTime() <= dateMax &&
              currentDate.getTime() >= dateMin
            : dateMax
            ? currentDate.getTime() <= dateMax
            : dateMin
            ? currentDate.getTime() >= dateMin
            : false;
      }

      if (amountSelected) {
        if(amountSelected > 0) checkAmountNumer = item.monto >= 0;
        if(amountSelected < 0) checkAmountNumer = item.monto <= 0;
      }
      if (paymentFormSelected) {
        checkPaymentType = item?.formaPago?.some((obj) => {
          return obj.idGrupoFormaDePago === paymentFormSelected;
        });
      }

      return (
        checkAmountMaxMin && checkDates && checkAmountNumer && checkPaymentType
      );
    });
    setFinalData(filteredArray);
  };

  const columns = [
    {
      name: "opCabId",
      label: "Id",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "tipoOperacion.descripcion",
      label: "Tipo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fechaAlta",
      label: "Fecha de alta",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => <>{formatDate(value)}</>,
      },
    },
    {
      name: "personas.razonSocial",
      label: "Cliente",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "formaPago",
      label: "Forma de pago",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let response = value[0]?.descripcion;
          for(let i=1; i<value.length; i++){
            response = response + ", " + value[i]?.descripcion
          }
          return response
        },
      },
    },
    {
      name: "monto",
      label: "Monto",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          let discount = 0;
          let hasDiscount = false;
          // subtract any discount from the operation total
          for (let i = 0; i < finalData[dataIndex].operacionPago.length; i++) {
            if(finalData[dataIndex].operacionPago[i].formaPagoId === discountId){
              discount = Math.abs(Number(String(finalData[dataIndex].operacionPago[i].pagado).replace(/,/g, '.')))
              hasDiscount = true;
            }
          }
          let finalValue = hasDiscount ? finalData[dataIndex].monto - discount : finalData[dataIndex].monto;
          return numeral(finalValue).format("$0,0.00");
        },
        customSort: (value1, value2) => {
          const num1 = numeral(value1)._value;
          const num2 = numeral(value2)._value;
          if (num1 < num2) {
            return -1;
          }
          if (num1 > num2) {
            return 1;
          }
          return 0;
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    textLabels,
    downloadOptions,
    filterType: "dropdown",
    responsive: "standard",
    draggableColumns: { enabled: true },
    enableNestedDataAccess: ".",
    elevation: 0,
    customToolbar: ({ displayData }) => {
      return (
        <CustomReportsToolbar
          filterData={filterData}
          setFilterData={setFilterData}
          lookForFilter={lookForFilter}
          setFinalData={setFinalData}
          data={data}
        />
      );
    },
    filter: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: "500px",
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <Box
        sx={{
          "& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head": {
            span: {
              justifyContent: "center",
            },
          },
        }}
      >
        <MUIDataTable
          title=""
          data={finalData}
          columns={columns}
          options={options}
        />
      </Box>
    </ThemeProvider>
  );
};
