/* eslint-disable eqeqeq */
import { toast } from "react-toastify";
import { supabaseClient } from "../../component/supabaseClient";
import { getSimpleDataOfTable } from "./getData";

export const insertDataIntoTable = async ({ tableName, object }) => {
  try {
    
    let query = supabaseClient.from(tableName).insert([object]).select();
    const { data: dataTable,errorData} = await query;
    if (errorData) {
      console.log("Error en insertDataIntoTable (dataTable): ", errorData);
    } else {
      return dataTable;
    }
  } catch (error) {
    console.error("Error en insertDataIntoTable:", error);
    throw error;
  }
};

export const insertProdDetails = async (p) => {
  const measurements = [];

  const insertProductDetailIfNeeded = async (detailName, detailValue) => {
    if (detailName && detailValue !== "" && detailValue !== undefined) {
      const prevDetailExist = await getSimpleDataOfTable({
        tableName: detailName,
        extraQueryEqInsensitive: [
          {
            queryColumn: "descripcion",
            queryValue: detailValue,
          },
        ],
      });
      if (!prevDetailExist || !prevDetailExist.length) {
        const { data: newDet, errorDet } = await supabaseClient
          .from(detailName)
          .insert([
            {
              descripcion: detailValue,
            },
          ])
          .select();
        if (errorDet) {
          toast.error("insertProdDetails error", errorDet);
        } else {
          return newDet[0];
        }
      }
      return prevDetailExist[0];
    }
  };

  measurements.push(
    await insertProductDetailIfNeeded("marca", p?.newMeasurements?.marcaDes)
  );
  measurements.push(
    await insertProductDetailIfNeeded("modelo", p?.newMeasurements?.modeloDes)
  );
  measurements.push(
    await insertProductDetailIfNeeded("medida", p?.newMeasurements?.medidaDes)
  );
  measurements.push(
    await insertProductDetailIfNeeded(
      "tipoProducto",
      p?.newMeasurements?.tipoDes
    )
  );

  return measurements;
};

export const checkBeforeInsertProduct = async ({ p, measurements }) => {
  // check if product characteristics exist alredy
  // if it exist don't create a new instance of product and return the previous product
  // is it doesn't, create new instance and return it
  let extraQueryEq = [];
  let extraQueryEqInsensitive = [];

  extraQueryEq.push({
    queryColumn: "tipo",
    queryValue:
      p?.newMeasurements && p?.newMeasurements?.tipoDes !== ""
        ? measurements[3]?.tipoProdId
        : p.tipo,
  })

  if((p?.newMeasurements || p?.newMeasurements?.marcaDes) && p.marca){
    extraQueryEq.push({
      queryColumn: "marca",
      queryValue:
        p?.newMeasurements && p?.newMeasurements?.marcaDes !== ""
          ? measurements[0]?.marcaId
          : p.marca,
    })
  }

  if((p?.newMeasurements || p?.newMeasurements?.modeloDes) && p.modelo){
    extraQueryEq.push({
      queryColumn: "modelo",
      queryValue:
        p?.newMeasurements && p?.newMeasurements?.modeloDes !== ""
          ? measurements[1]?.modeloId
          : p.modelo,
    })
  }

  if((p?.newMeasurements || p?.newMeasurements?.medidaDes) && p.medida){
    extraQueryEq.push({
      queryColumn: "medida",
      queryValue:
        p?.newMeasurements && p?.newMeasurements?.medidaDes !== ""
          ? measurements[2]?.medidaId
          : p.medida,
    })
  }

  extraQueryEqInsensitive.push({
    queryColumn: "descripcion",queryValue: p.descripcion,
  })

  extraQueryEq.push({
    queryColumn: "usado",queryValue: p.usado,
  })

  const prevProdExist = await getSimpleDataOfTable({
    tableName: "productos",
    extraQueryEq: extraQueryEq,
    extraQueryEqInsensitive: extraQueryEqInsensitive
  });

  if (!prevProdExist.length) {
    const prod = await insertDataIntoTable({
      tableName: "productos",
      object: {
        tipo:
          p?.newMeasurements && p?.newMeasurements?.tipoDes !== ""
            ? measurements[3]?.tipoProdId
            : p.tipo,
        marca:
          p?.newMeasurements && p?.newMeasurements?.marcaDes !== ""
            ? measurements[0]?.marcaId
            : p.marca,
        modelo:
          p?.newMeasurements && p?.newMeasurements?.modeloDes !== ""
            ? measurements[1]?.modeloId
            : p.modelo,
        medida:
          p?.newMeasurements && p?.newMeasurements?.medidaDes !== ""
            ? measurements[2]?.medidaId
            : p.medida,
        tipoDibujo: p.tipoDibujo,
        descripcion: p.descripcion,
        descripcionConcatenada: p.descripcionConcatenada,
        stockMinimo: p.cantidad,
        precioCosto: p.precioVenta,
        precioVenta: p.precioVenta,
        usado: p.usado,
      },
    });
    return prod[0];
  }
  return {...p, ...prevProdExist[0]}; 
};
