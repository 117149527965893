import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import { useStateAuthValue } from "../../context/AuthState";
import { useNavigate } from "react-router-dom";
import { GoBackButton } from "../../component/GoBackButton";
import { getSimpleDataOfTable } from "../../utils/supabaseFunctions/getData";
import { ErrorModal } from "../../component/modals/ErrorModal";
import { updateSimpleData } from "../../utils/supabaseFunctions/updateData";
import LoadingModal from "../../component/modals/LoadingModal";
import { supabaseClient } from "../../component/supabaseClient";
import { fireConfirmationModal } from "../../component/modals/ConfirmationModal";

const EditPrices = () => {
  const navigate = useNavigate();
  const [{ userRole }] = useStateAuthValue();

  if (userRole !== 1) {
    navigate("/");
  }

  const [loading, setLoading] = useState(false);
  const [modelos, setModelos] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const estados = [
    {
      est: "USADO",
      desc: "Usado",
      sel: false,
    },
    {
      est: "NUEVO",
      desc: "Nuevo",
      sel: false,
    },
  ];

  useEffect(() => {
    const getData = async () => {
      const marcas = await getSimpleDataOfTable({
        tableName: "marca",
        lookFor: "*",
        extraQueryEq: [
          { queryColumn: "activa", queryValue: true },
          { queryColumn: "mostrar", queryValue: true },
        ],
      });
      setMarcas(marcas);
      const modelos = await getSimpleDataOfTable({
        tableName: "modelo",
        lookFor: "*",
        extraQueryEq: [
          { queryColumn: "activa", queryValue: true },
          { queryColumn: "mostrar", queryValue: true },
        ],
      });
      setModelos(modelos);
    };
    getData();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <div className="crud__title">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <GoBackButton />
          <div style={{ width: "100%" }}>
            <h3 className="page-title">Actualización masiva de precios</h3>
            <h5 style={{ padding: 0, fontWeight: 400, textAlign: "center" }}>
              Escoja las características del producto que desea actualizar
            </h5>
          </div>
        </div>
        <hr />
      </div>
      <Formik
        initialValues={{
          marca: "",
          modelo: "",
          estado: "",
          price: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.price) {
            errors.price = "Campo requerido";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          let query = supabaseClient.from("productos").select();

          if (values.marca !== "" && values.marca !== "ALL") {
            query = query.eq("marca", values.marca);
          }
          if (values.modelo !== "" && values.modelo !== "ALL") {
            query = query.eq("modelo", values.modelo);
          }
          if (values.estado !== "" && values.estado !== "ALL") {
            query = query.eq("usado", values.estado === "USADO" ? true : false);
          }
          const { data: products } = await query;

          if (!products || products.length === 0) {
            ErrorModal({
              title: `No se encontró productos con las características indicadas`,
              text: "Asegurese de estar escogiendo las características correctas",
              icon: "info",
            });
          } else {
            fireConfirmationModal({
              title: `¿Estás seguro de que deseas realizar la siguiente operación? \n Aumentar productos por un porcentaje de %${values.price}`,
              submit: () => {
                setLoading(true);
                products.map(async (prod) => {
                  let newPrice = prod.precioVenta;
                  newPrice = newPrice * (1 + values.price / 100);
                  newPrice =
                    Math.round((newPrice + Number.EPSILON) * 100) / 100;
                  await updateSimpleData({
                    tableName: "productos",
                    object: {
                      precioVenta: newPrice,
                      precioCosto: newPrice,
                    },
                    extraQueryEq: [
                      { queryColumn: "prodId", queryValue: prod.prodId },
                    ],
                  });
                });
                ErrorModal({
                  title: `Precios actualizados`,
                  icon: "success",
                });
                setLoading(false);
                navigate("/");
              },
            });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="form-container form-container-three-colums">
              <div className="form-group form-container__select">
                <label className="form-container__label" htmlFor="tipoDes">
                  Marca
                </label>
                <Field
                  as="select"
                  className="form-control form-select"
                  id="marca"
                  name="marca"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.marca}
                >
                  <option value="">- Escoger</option>
                  <option value="ALL">Todos</option>
                  {marcas.map((t, index) => {
                    return (
                      <option key={index} value={t.marcaId}>
                        {t.descripcion}
                      </option>
                    );
                  })}
                </Field>
              </div>

              <div className="form-group form-container__select">
                <label className="form-container__label" htmlFor="tipoDes">
                  Modelo
                </label>
                <Field
                  as="select"
                  className="form-control form-select"
                  id="modelo"
                  name="modelo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.modelo}
                >
                  <option value="">- Escoger</option>
                  <option value="ALL">Todos</option>
                  {modelos.map((t, index) => {
                    return <option key={index} value={t.modeloId}>{t.descripcion}</option>;
                  })}
                </Field>
              </div>
              <div className="form-group form-container__select">
                <label className="form-container__label" htmlFor="tipoDes">
                  Estado
                </label>
                <Field
                  className="form-control form-select"
                  as="select"
                  id="estado"
                  name="estado"
                >
                  <option value="">- Escoger</option>
                  <option value="ALL">Todos</option>
                  {estados.map((e, index) => {
                    return <option key={index} value={e.est}>{e.desc}</option>;
                  })}
                </Field>
              </div>
              <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="phone_number">
                  Porcentaje de aumento
                </label>
                <div className="center-div" style={{ minHeight: "initial" }}>
                  <Field
                    className="form-control"
                    id="price"
                    name="price"
                    placeholder="Ej.: 10"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.price}
                    type="number"
                  />
                  <span
                    style={{
                      padding: " 0 5px",
                      fontSize: "25px",
                      fontWeight: 600,
                      color: "#ce0b0b",
                    }}
                  >
                    %
                  </span>
                </div>
                <div className="invalid-feedback">
                  {errors.price && touched.price && errors.price}
                </div>
              </div>
            </div>
            <div className="form-container__button">
              <button
                className="btn btn-dark"
                type="submit"
                disabled={isSubmitting}
              >
                Editar precio
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <LoadingModal
        text="Productos encontrados. Actualizando..."
        show={loading}
        setShow={setLoading}
      />
    </div>
  );
};

export default EditPrices;
