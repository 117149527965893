import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Toolbar } from "@material-ui/core";
import { InputLabel } from "@mui/material";
import { Button } from "react-bootstrap";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const CustomReportsStockToolbar = ({ 
  filterData, 
  setFilterData, 
  lookForFilter,
  setFinalData,
  data
 }) => {
  const handleFilterChange = (column, value) => {
    setFilterData((pastValue) =>
      pastValue.map((v) =>
        v.label === column.label ? { ...v, filterBy: value } : v
      )
    );
  };

  return (
    <Toolbar>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "40px",
          }}
        >
          <div 
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "12px",
            }}
          >
            {filterData?.map((f) => {
              return f.column === "fecha" ? (
                <div
                  key={f.label}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    flexBasis: "calc(29%)"
                  }}
                >
                  <InputLabel>{f.label}</InputLabel>
                  <DesktopDatePicker
                    value={f.filterBy}
                    format="DD/MM/YYYY"
                    slotProps={{textField: {variant: "standard", disabled: true}}}
                    onChange={(value) => {                      
                      handleFilterChange(f, value);
                    }}
                  />
                </div>
              ) : (
                <div
                  key={f.label}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "200px",
                    flexBasis: "calc(29%)"
                  }}
                >
                  <InputLabel>{f.label}</InputLabel>
                  <FormControl variant="standard" sx={{ width: "100%" }}>
                    <Select
                      labelId="select-label"
                      value={f.filterBy}
                      onChange={(e) => {
                        handleFilterChange(f, e.target.value);
                      }}
                      sx={{ textAlign: "left" }}
                    >
                      <MenuItem key={0} value="">----</MenuItem>
                      {f?.options.map((op) => (
                        <MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              );
            })}
          </div>
          <div>
            <Button style={{ margin: "10px"}} variant="danger" onClick={lookForFilter}>
              Aplicar
            </Button>
            <Button variant="dark" onClick={() => {
              setFilterData((pastValue) =>{
                return pastValue.map((v) =>({...v, filterBy: "" }))}
              );
              setFinalData(data)
              }}>
              Borrar filtros
            </Button>
          </div>
        </div>
      </LocalizationProvider>
    </Toolbar>
  );
};

export default CustomReportsStockToolbar;
