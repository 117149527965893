import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

import {
  FaCartPlus,
  FaBoxes,
  FaCashRegister,
  FaStore,
  FaMoneyBillAlt,
  FaShoppingBasket,
  FaMoneyBill,
  FaRunning,
  FaSearchDollar,
} from "react-icons/fa";
import {
  RiInboxArchiveFill,
  RiInboxUnarchiveFill,
  RiNewspaperLine 
} from "react-icons/ri";

const Home = () => {
  const navigate = useNavigate();

  const buttons = [
    {
      title: "Ver todas las operaciones",
      link: "/operaciones",
      icon: <FaStore size={40} />,
    },
    {
      title: "Nueva venta",
      link: "/operacion",
      icon: <FaCartPlus size={40} />,
    },
    {
      title: "Nuevo presupuesto",
      link: "/operacion",
      state: { presupuesto: true },
      icon: <RiNewspaperLine size={40} />,
    },
    {
      title: "Operación rápida",
      link: "/operacion",
      state: { fastSale: true },
      icon: <FaRunning size={40} />,
    },
    {
      title: "Ingreso de mercadería",
      link: "/ingreso-mercaderia",
      icon: <RiInboxArchiveFill size={40} />,
    },
    {
      title: "Egreso de mercadería",
      link: "/egreso-mercaderia",
      icon: <RiInboxUnarchiveFill size={40} />,
    },
    {
      title: "Traspaso de mercadería",
      link: "/traspaso-mercaderia",
      icon: <FaBoxes size={40} />,
    },
    {
      title: "Compra de Bienes Usados",
      link: "/compra-bienes-usados",
      icon: <FaShoppingBasket size={40} />,
    },
    {
      title: "Ingreso efectivo",
      link: "/ingreso-efectivo",
      state: { ingreso: true },
      icon: <FaMoneyBillAlt size={40} />,
    },
    {
      title: "Egreso efectivo",
      link: "/egreso-efectivo",
      state: { ingreso: false },
      icon: <FaMoneyBill size={40} />,
    },
    {
      title: "Apertura / Cierre de caja",
      link: "/caja",
      icon: <FaCashRegister size={40} />,
    },
    {
      title: "Listado de cajas",
      link: "/listado-cajas",
      icon: <FaCashRegister size={40} />,
    },
    {
      title: "Lista de precios",
      link: "/reportes/precios",
      icon: <FaSearchDollar size={40} />,
    },
  ];

  return (
    <div style={{ minHeight: "93%" }}>
      <div className="crud__title">
        <h3>Menú</h3>
        <hr />
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "3rem",
        }}
      >
        {buttons.map((button, index) => (
          <Button
            key={index}
            color="secondary"
            style={{
              width: 150,
              height: 150,
              fontSize: 20,
            }}
            variant="outline-success my-2"
            onClick={() => navigate(button.link, { state: button.state })}
          >
            {button.icon}
            <div>{button.title}</div>
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Home;
