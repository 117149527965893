import React from 'react';
import { Table } from "react-bootstrap";
import { NumberInputValidation } from "../../utils/NumberInput";
import { ErrorModal } from '../modals/ErrorModal';

const PaymentCashMovements = ({
    amount,
    setAmount,
    saldoB,
    saldoN,
    currentCaja,
    isIngreso,
    disabled
}) => {

  const handlePriceChange = ({value}) => { 
    if (!isIngreso && ((currentCaja === "B" && value > saldoB) || (currentCaja === "N" && value > saldoN))){
      ErrorModal({
        title: `El monto deseado no está disponible`,
        text: `El monto disponible para la caja seleccionada es $${currentCaja === "B" ? saldoB : saldoN}`
      });
    } else {
      setAmount(value);
    }
  };

  return (
    <>
      <div>
          <div style={{ width: '85%' }}>
            <h5 style={{ fontWeight: 'bold', paddingLeft: '10px' }}>
              Formas de pago
            </h5>
        </div>
        <div>
        <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th style={{ "width": "80%"}}>Descripción</th>
            <th>Total efectivo</th>
          </tr>
        </thead>
        <tbody>
            <tr>
                <td style={{display: "flex", alignItems: "center"}}>
                    Efectivo
                </td>
              <td>
                <NumberInputValidation disabled={disabled} value={amount} handlePriceChange={handlePriceChange}/>
              </td>
            </tr>
        </tbody>
        </Table>
        </div>
      </div>
      
    </>
  );
};

export default PaymentCashMovements;
