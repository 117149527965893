import { ingresoEgreso} from './saveMovements';
import { operationTypes } from "../../constants/operationTypes";
import { getSimpleDataOfTable } from '../supabaseFunctions/getData';
import { ErrorModal } from '../../component/modals/ErrorModal';
import { productTypes } from '../../constants';

export const outputStock = async (
  userData,
  header,
  products,
  navigate
) => {
  const operationType = [operationTypes.stock_egreso_manual];

  for (const p of products) {
    const prevStock = await getSimpleDataOfTable({
      tableName: "stockDeposito",
      extraQueryEq : [
        {queryColumn: "prodId", queryValue: p.prodId},
        {queryColumn: "depositoId", queryValue: userData.depositoId}
      ]});
    if(prevStock[0]?.stockActual <= 0 && p.tipo !== productTypes.servicio){
      navigate("/operaciones");
      ErrorModal({
        title: "Error",
        text:"El producto " + p?.descripcion + " no tiene stock en tu depósito, por lo que la operación no puede completarse.",
      });
    } 
  }

  await ingresoEgreso (   
    userData,
    header,
    products,
    operationType
  )
}