import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import LoadingSpinner from "../component/Spinner";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BsPlusLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import {MdEdit} from "react-icons/md";
import {
  textLabels,
  downloadOptions,
  sortFunction,
} from "../utils/MuiTableOptions";
import { GoBackButton } from "../component/GoBackButton";
import { stockProducts } from "../utils/Stock/getStockProducts";

const Products = () => {
  const navigate = useNavigate();
  const [productos, setProductos] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const getMuiTheme = createTheme({});

  //obtenemos los productos
  useEffect(() => {
    stockProducts().then((r) => {
      setProductos(r);
    });
  }, [productos]);

  const columns = [
    {
      name: "prodId",
      label: "ID",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "nombreProducto",
      label: "Producto",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "acciones",
      label: "Acciones",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div className="crud__table__icon-container">
              <MdEdit
                id={productos[dataIndex].prodId}
                style={{ cursor: "pointer" }}
                className="crud__table__icon"
                alt="Item menú"
                onClick={editProduct}
              />
            </div>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    searchOpen: true,
    textLabels,
    downloadOptions,
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows)
    },
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions:[100,250,500],
    filter: true,
    selectableRows: "none",
    fixedHeader: false,
    filterType: "dropdown",
    responsive: "standard",
    customSort: sortFunction,
    draggableColumns: { enabled: true },
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: "600px",

  };

  const data = productos?.map((producto) => ({
    prodId: producto.prodId,
    nombreProducto: producto.descripcionConcatenada,
  }));

  const editProduct = (event) => {
    // eslint-disable-next-line array-callback-return
    productos?.map((p) => {
      if (p.prodId === parseInt(event.currentTarget.id)) {
        navigate("editar-producto", {
          state: {
            product: p,
          },
        });
      }
    });
  };

  return (
    <>
      <ThemeProvider theme={getMuiTheme}>
        <div>
        <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>Productos</h3>
              </div>
            </div>
            <hr />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginLeft:"10px"
            }}
          >
            <Button
              variant="outline-success my-2"
              onClick={() => {
                navigate("agregar-producto", { state: { product: false } });
              }}
            >
              <BsPlusLg /> Nuevo producto
            </Button>
          </div>

          {productos ? (
            <div
              style={{
                margin: "8px",
                overflow: "scroll",
              }}
            >
              <MUIDataTable data={data} columns={columns} options={options} />
            </div>
          ) : (
            <div className="center-div">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </ThemeProvider>
    </>
  );
};

export default Products;
