import React, { useState, useEffect } from "react";
import { supabaseClient } from "../component/supabaseClient";
import { useStateAuthValue } from "../context/AuthState";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import LoadingSpinner from "../component/Spinner";
import { BsPlusLg } from "react-icons/bs";
import { IoReload } from "react-icons/io5";
import { GoBackButton } from "../component/GoBackButton";
import { OperationsTable } from "../component/OperationsTable";
import { errAndMsg } from "../utils/MsgAlert";
import { operationStates, operationTypes } from "../constants";

const getOperations = async (depositoId, setLoading, setOperations) => {
  setOperations([]);
  try {
    const { data, error } = await supabaseClient

      // Chaining `.from()` method to select table from which we want to fetch data.
      .from("operacionCabecera")

      // Chaining `.select()` method to specify columns from the table we want to retrieve.
      // The query uses aliases in order to format the output into human-readable notation
      .select(
        `
        *,
        tipoOperacion : tipoOperacion(descripcion),
        estadoOperacion (*),
        deposito : deposito(*),
        usuario: usrId(*),
        usuarioRelacionado: usrIdRelacionado(*),
        personas (*),
        operacionDetalle(*),
        operacionPago:operacionPago(*)
        `
      )
      .eq("depositoId", depositoId)
      .order("opCabId", { ascending: false });
    if (error) throw error;
    const newData = data.map((item) => {
      const monto = item?.operacionPago.reduce((accumulator, currentValue) => {        
        if((currentValue?.pagado < 0 && item.estadoOpId === operationStates.anulada) || (currentValue?.pagado < 0 && item.tipoOdId === operationTypes.stock_ingreso_por_remito_de_compra)){
          return accumulator;
        } else {
          return accumulator + Math.abs(currentValue?.pagado)
        }},
      0);
      return { ...item, monto };
    });

    setOperations(newData);
  } catch (error) {
    errAndMsg('Error al obtener las formas de pago', error.message, error.code)
  } finally {
    setLoading(false);
  }

  return () => {
    setLoading(true);
  }
};
const Operations = () => {
  const navigate = useNavigate();
  const [operations, setOperations] = useState();
  const [loading, setLoading] = useState(true);
  const [{ userData }] = useStateAuthValue();

  useEffect(() => {
    if (userData.depositoId) {
      getOperations(userData.depositoId, setLoading, setOperations);
    }
  }, [userData]);

  return (
    <>
      {loading ? (
        <div className="center-div">
          <div>
            <LoadingSpinner />
          </div>
        </div>
      ) : (
        <div>
          <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>Operaciones</h3>
              </div>
            </div>
            <hr />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginLeft:"10px"
            }}
          >
            <Button
              variant="outline-success my-2"
              onClick={() => {
                navigate("/operacion");
              }}
            >
              <BsPlusLg /> Nueva venta
            </Button>
            <Button
              style={{ marginLeft: 20 }}
              variant="outline-success my-2"
              onClick={() => {
                getOperations(userData.depositoId, setLoading, setOperations);
              }}
            >
              <IoReload /> Recargar Operaciones
            </Button>
          </div>
          <OperationsTable data={operations} />
        </div>
      )}
    </>
  );
};

export default Operations;
