import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { supabaseClient } from "../../component/supabaseClient";
import { MdEdit } from "react-icons/md";
import {
  textLabels,
  downloadOptions,
  sortFunction,
} from "../../utils/MuiTableOptions";
import { GoBackButton } from "../../component/GoBackButton";
import { errAndMsg } from "../../utils/MsgAlert";
import { MuiTheme } from "../../styles/MuiDataTableStyle";

const Warehouses = () => {
  const navigate = useNavigate();
  const [warehouses, setWarehouses] = useState();

  useEffect(() => {
    const getWarehouses = async (showAll) => {
      const { data: depositos, error } = await supabaseClient
        .from("deposito")
        .select("*")
        .order("depositoId", { ascending: true });

      if (error) {
        errAndMsg("Error", error.message, error.code);
      } else {
        depositos.forEach((deposito) => {
          deposito.vigente = deposito.mostrar ? "Vigente" : "No vigente";
          deposito.telefono1 ??= "-";
          deposito.telefono2 ??= "-";
        });
        setWarehouses(depositos);
      }
    };
    getWarehouses();
  }, []);

  const editWarehouse = ({ currentTarget: { id } }) => {
    const warehouse = warehouses.find(
      (warehouse) => warehouse.depositoId === parseInt(id)
    );

    if (warehouse) {
      navigate("editar-deposito", { state: { warehouse } });
    }
  };

  const columns = [
    {
      name: "depositoId",
      label: "Id",
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta) => (
          <th style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
            {columnMeta.label}
          </th>
        ),
      },
    },
    {
      name: "descripcion",
      label: "Descripción",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta) => (
          <th style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
            {columnMeta.label}
          </th>
        ),
      },
    },
    {
      name: "domicilio",
      label: "Domicilio",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta) => (
          <th style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
            {columnMeta.label}
          </th>
        ),
      },
    },
    {
      name: "telefono1",
      label: "Teléfono principal",
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta) => (
          <th style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
            {columnMeta.label}
          </th>
        ),
      },
    },
    {
      name: "telefono2",
      label: "Teléfono secundario",
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta) => (
          <th style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
            {columnMeta.label}
          </th>
        ),
      },
    },
    {
      name: "vigente",
      label: "Vigente",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta) => (
          <th style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
            {columnMeta.label}
          </th>
        ),
      },
    },
    {
      name: "acciones",
      label: "Acciones",
      options: {
        customHeadRender: (columnMeta) => (
          <th style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
            {columnMeta.label}
          </th>
        ),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div className="crud__table__icon-container">
              <MdEdit
                id={warehouses[dataIndex].depositoId}
                style={{ cursor: "pointer" }}
                className="crud__table__icon"
                alt="Item menú"
                onClick={editWarehouse}
              />
            </div>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    searchOpen: true,
    textLabels,
    downloadOptions,
    filter: true,
    selectableRows: "none",
    fixedHeader: false,
    filterType: "dropdown",
    responsive: "standard",
    customSort: sortFunction,
    draggableColumns: { enabled: true },
  };

  return (
    <>
      <ThemeProvider theme={MuiTheme}>
        <div className="crud">
        <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>Depósitos</h3>
              </div>
            </div>
            <hr />
          </div>
          <MUIDataTable data={warehouses} columns={columns} options={options} />
          {/* <div className="crud__rigth-buttons-container">
            <button
              className="btn btn-dark"
              onClick={() => {
                navigate("agregar-deposito", { state: { warehouse: false } });
              }}
            >
              Nuevo depósito
            </button>
          </div> */}
        </div>
      </ThemeProvider>
    </>
  );
};

export default Warehouses;
