/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useStateAuthValue } from "../context/AuthState";
import { useNavigate, useLocation } from "react-router-dom";
import { getSimpleDataOfTable } from "../utils/supabaseFunctions/getData";
import GeneralDataStockMovements from "../component/StockMovementsSteps/generalData";
import { Button } from "react-bootstrap";
import { fireConfirmationModal } from "../component/modals/ConfirmationModal";
import { pdf } from "../utils/operationsPdf";
import { StockMovementPdfTemplate } from "../component/StockMovementsSteps/stockMovementPdfTemplate";
import LoadingSpinner from "../component/Spinner";
import { GoBackButton } from "../component/GoBackButton";
import ProductsMovements from "../component/StockMovementsSteps/products";
import { deleteMovements } from "../utils/Stock/deleteMovements";
import { inputStock } from "../utils/Stock/inputStock";
import { outputStock } from "../utils/Stock/outputStock";
import { transferStock } from "../utils/Stock/transferStock";
import { supabaseClient } from "../component/supabaseClient";
import { getCompleteOperationByOpCabId } from "../utils/supabaseFunctions/getData";
import {
  operationStates,
  productTypes,
  service,
  warehousesIds,
} from "../constants";
import { formatDate } from "../utils/formatDate";
import { WarningModal } from "../component/modals/WarningModal";

const StockMovement = () => {
  const navigate = useNavigate();

  const { state, pathname } = useLocation();
  const isIngreso =
    state?.ingreso === true || pathname === "/ingreso-mercaderia";
  const isTransfer = pathname === "/traspaso-mercaderia";
  const isReading = state?.operation ? true : false;
  const [{ userRole, userData }] = useStateAuthValue();
  const [operation, setOperation] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [stock, setStock] = useState();
  const [productos, setProductos] = useState([]);
  const [header, setHeader] = useState({
    numFactura: "",
    observaciones: "",
    userRelacionado: {},
    depositoRelacionado: "",
    existeUsuarioRelacionado: false,
  });
  const [deposito, setDeposito] = useState();
  const [isSaving, setIsSaving] = useState(false);

  const [imprimir, setImprimir] = useState(false);
  let nowDate = new Date();
  const date = operation?.fechaAlta
    ? formatDate(operation?.fechaAlta)
    : nowDate.toLocaleDateString("es-AR");

  if (!userRole) {
    navigate("/");
  }

  useEffect(() => {
    const enablePrint = () => {
      if (productos.length > 0) {
        setImprimir(true);
      } else {
        setImprimir(false);
      }
    };
    enablePrint();
  }, [productos.length]);

  //Obtengo los valores de la operacion para cuando ingreso a ver la misma desde el listado de operaciones
  useEffect(() => {
    const getPrevData = async () => {
      setDisabled(true);
      const operacion = await getCompleteOperationByOpCabId(state?.operation);
      setOperation(operacion);
    };
    if (state?.operation) {
      getPrevData();
    } else {
      setDisabled(false);
    }
  }, [state]);

  //Obtengo el resto de los valores relacionados a la operacion de movimiento de stock
  useEffect(() => {
    const getPrevData = async () => {
      const { data: user, errorUser } = await supabaseClient
        .from("usuarios")
        .select("*,depositoRelacionado: deposito(descripcion)")
        .eq("usrId", operation?.usrIdRelacionado);
      if (user) {
        setHeader({
          ...header,
          numFactura: operation.numeroFactura,
          observaciones: operation.observaciones,
          userRelacionado: user[0],
          depositoRelacionado: user[0].depositoRelacionado.descripcion,
          existeUsuarioRelacionado: true,
        });
      }
    };
    if (operation?.usrIdRelacionado) {
      getPrevData();
    }
  }, [operation]);

  useEffect(() => {
    if (operation?.operacionDetalle) {
      operation.operacionDetalle?.map(async (op) => {
        let prevProd = await supabaseClient
          .from("productos")
          .select(
            "prodId, descripcion, descripcionConcatenada, tipoDes : tipo(*), marcaDes : marca(*), modeloDes : modelo(*), medidaDes : medida(*)"
          )
          .eq("prodId", op.prodId);

        let prevProdDepo = await getSimpleDataOfTable({
          tableName: "stockDeposito",
          lookFor: "*",
          extraQueryEq: [{ queryColumn: "prodId", queryValue: op.prodId }],
        });

        var stockOfVenRp = 0;
        var stockDepoRp = 0;
        var stockOfVenSm = 0;
        var stockDepoSm = 0;
        var stockSm = 0;
        var stockRp = 0;

        prevProdDepo.map((sd) => {
          if (sd.depositoId === warehousesIds.oficina_venta_rp) {
            stockOfVenRp = sd.stockActual;
          }
          if (sd?.depositoId === warehousesIds.deposito_rp) {
            stockDepoRp = sd.stockActual;
          }
          if (sd?.depositoId === warehousesIds.oficina_venta_sm) {
            stockOfVenSm = sd.stockActual;
          }
          if (sd?.depositoId === warehousesIds.deposito_sm) {
            stockDepoSm = sd.stockActual;
          }
          if (sd?.depositoId === warehousesIds.rodriguez_penia) {
            stockRp = sd.stockActual;
          }
          if (sd.depositoId === warehousesIds.san_martin) {
            stockSm = sd.stockActual;
          }
          return true;
        });
        if (prevProd) {
          let p = {
            ...prevProd.data[0],
            id: prevProd.data[0].prodId,
            prodId: prevProd.data[0].prodId,
            cantidad: op.cantidad,
            descripcion: prevProd.data[0].descripcion,
            descripcionConcatenada: prevProd.data[0].descripcionConcatenada,
            tipoDes: prevProd.data[0].tipoDes?.descripcion,
            marcaDes: prevProd.data[0].marcaDes?.descripcion,
            medidaDes: prevProd.data[0].medidaDes?.descripcion,
            modeloDes: prevProd.data[0].modeloDes?.descripcion,
            stockOfVenRp,
            stockDepoRp,
            stockOfVenSm,
            stockDepoSm,
            stockRp,
            stockSm,
          };
          setProductos((oldArray) => {
            const equal = oldArray.filter((item) => item.id === p.prodId);
            return !equal.length ? [...oldArray, p] : oldArray;
          });
        }
      });
    }
  }, [operation]);

  const getData = async () => {
    setLoading(true);
    try {
      const productos = await getSimpleDataOfTable({
          tableName: "productos",
          lookFor:
            "*, tipoDes : tipoProducto(descripcion), marcaDes : marca(descripcion), modeloDes : modelo(descripcion), medidaDes : medida(descripcion), stock: stockDeposito(*, deposito : deposito(*))",
          extraQueryOrder: [
            {
              queryColumn: "descripcionConcatenada",
              queryValue: { ascending: true },
            },
          ],
      });
      const resultado = productos.map((prod) => {return {...prod, cantidad: 1}})

      // Filtrar los productos cuyo tipo sea distinto de 3 (servicio)
      const productosFiltrados = resultado.filter(
        (item) => item.tipo !== productTypes.servicio
      );

      // Guardar los productos filtrados en el estado
      setStock(productosFiltrados);
    } catch (error) {
      console.error("Error al obtener el stock:", error);
    }

    const deposito = await getSimpleDataOfTable({
      tableName: "deposito",
      extraQueryEq: [
        { queryColumn: "depositoId", queryValue: userData.depositoId },
      ],
    });
    setDeposito(deposito);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [userData]);

  const submit = async (typoOp) => {
    setLoading(true);
    setIsSaving(true);
    if (typoOp.typoOp === 5) {
      await deleteMovements(operation, productos);
      setLoading(false);
    } else {
      isIngreso
        ? await inputStock(userData, header, productos)
        : isTransfer
        ? await transferStock(userData, header, productos, navigate)
        : await outputStock(userData, header, productos, navigate);

      setLoading(false);
    }
    setLoading(false);
    navigate("/operaciones");
  };
console.log(productos)
  return loading ? (
    <div className="center-div">
      <div>
        <LoadingSpinner />
      </div>
    </div>
  ) : (
    <div>
      <div className="crud__title">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <GoBackButton />
          <div style={{ width: "100%" }}>
            <h3>
              Stock:{" "}
              {isIngreso ? "Ingreso" : isTransfer ? "Traspaso" : "Egreso"} de
              mercadería
            </h3>
          </div>
        </div>
        <hr />
      </div>
      <div className="operation-container">
        <div className="operation-general-data operation-item">
          <GeneralDataStockMovements
            disabled={disabled}
            userData={userData}
            isReading={isReading}
            header={header}
            setHeader={setHeader}
            deposito={deposito}
            isIngreso={isIngreso}
            isTransfer={isTransfer}
            operation={operation}
          />
        </div>
        <div className="operation-product-list operation-item">
          <ProductsMovements
            setProducts={setProductos}
            products={productos}
            stock={stock}
            depositoId={userData.depositoId}
            isCompra={!isReading}
            isReading={isReading}
            isIngreso={isIngreso}
          />
        </div>
      </div>
      <h6
        style={{
          paddingLeft: "10px",
          color: "red",
          textTransform: "uppercase",
        }}
      ></h6>
      <div className="operation-buttons-container">
        <Button
          disabled={!imprimir}
          variant="success my-2"
          onClick={() => {
            let filename = operation?.opCabId
              ? `${operation?.tipoOperacion?.descripcion}[Operación nro ${operation.opCabId}]`
              : isIngreso
              ? "Ingreso"
              : isTransfer
              ? "Traspaso"
              : "Egreso" + "de mercadería - " + date;
            pdf({
              filename: filename,
              id: "cash-movement",
            });
          }}
        >
          Imprimir
        </Button>
        <Button
          variant="secondary my-2"
          onClick={() => {
            fireConfirmationModal({
              title:
                "¿Estás seguro de que deseas anular este movimiento de stock?",
              submit: submit,
              props: {
                typoOp: operationStates.anulada,
              },
            });
          }}
          disabled={!isReading || userRole !== 1}
        >
          Anular
        </Button>
        <Button
          variant="outline-success my-2"
          // disabled={isSaving}
          onClick={async () => {
            const hasNoQuantityProduct = productos.filter(
              (item) => item.cantidad === "" || item.cantidad === 0
            );

            if (disabled) {
              navigate("/operaciones");
            } else if (isTransfer && !header.existeUsuarioRelacionado) {
              WarningModal({
                title: `Oops!`,
                text: `Olvidó seleccionar un usuario para enviarle mercadería`,
              });
            } else if (productos.length === 0) {
              WarningModal({
                title: `Oops!`,
                text: `Olvidó seleccionar al menos un producto`,
              });
            } else if (hasNoQuantityProduct.length !== 0) {
              WarningModal({
                title: `Oops!`,
                text: "No se escogió stock de al menos un producto",
              });
            } else if (!isIngreso && !isReading) {
              setLoading(true);
              const promises = productos.map(async (item) => {
                const { data, error } = await supabaseClient
                  .from("stockDeposito")
                  .select("*")
                  .eq("prodId", item.prodId)
                  .eq("depositoId", userData.depositoId);

                if (
                  data[0].stockActual < item.cantidad &&
                  item.tipo !== productTypes.servicio
                ) {
                  WarningModal({
                    title: `Oops!`,
                    text: "Ha excedido el stock existente en uno o más productos, por favor revise los mismos para continuar. Es probable que el stock haya sido cambiado por otra operación realizada al mismo momento que esta.",
                  });
                  setLoading(false);
                } else {
                  return true; // Indicate that the check passed
                }
              });

              const results = await Promise.all(promises);

              if (results.every((result) => result)) {
                fireConfirmationModal({
                  title: `¿Estás seguro de que deseas realizar este movimiento de stock?`,
                  submit: submit,
                  props: {
                    typoOp: operationStates.finalizado,
                  },
                });
                setLoading(false);
              }
            } else {
              fireConfirmationModal({
                title: `¿Estás seguro de que deseas realizar este movimento de stock?`,
                submit: submit,
                props: {
                  typoOp: operationStates.finalizado,
                },
              });
            }
          }}
        >
          {isReading ? "Cerrar" : "Guardar"}
        </Button>
      </div>
      <div
        style={{
          visibility: "hidden",
          zIndex: "-9999",
          position: "fixed",
        }}
      >
        {deposito && deposito.length !== 0 && header.userRelacionado && (
          <StockMovementPdfTemplate
            generalData={{
              today: date,
              deposito: deposito[0]?.domicilio,
              vendedor: userData?.nombre,
              relatedUser: header.userRelacionado,
              isIngreso: isIngreso,
              isTransfer: isTransfer,
              productos: productos,
            }}
            header={header}
          />
        )}
      </div>
    </div>
  );
};

export default StockMovement;
