import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { FaSearch } from "react-icons/fa";
import { textLabels, downloadOptions } from "../../utils/MuiTableOptions";

import { MuiTheme } from "../../styles/MuiDataTableStyle";
import { formatDate } from "../../utils/formatDate";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";
import CashBoxFilter from "./CashBoxFilter";
import { useState } from "react";

export const CashBoxTable = ({ data }) => {
  const navigate = useNavigate();
  const [finalData, setFinalData] = useState(data);
  const [filterData, setFilterData] = useState([
    {
      label: "Fecha de apertura mínima",
      filterBy: "",
      column: "open",
      objective: "min",
    },
    {
      label: "Fecha de apertura máxima",
      filterBy: "",
      column: "open",
      objective: "max",
    },
    {
      label: "Fecha de cierre mínima",
      filterBy: "",
      column: "close",
      objective: "min",
    },
    {
      label: "Fecha de cierre máxima",
      filterBy: "",
      column: "close",
      objective: "max",
    },
  ]);

  const columns = [
    {
      name: "cajaId",
      label: "Id",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "fechaApertura",
      label: "Apertura",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => <>{formatDate(value)}</>,
      },
    },
    {
      name: "horaApertura",
      label: "Hora Apertura",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "fechaCierre",
      label: "Cierre",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => <>{formatDate(value)}</>,
      },
    },
    {
      name: "horaCierre",
      label: "Hora Cierre",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "usuario.nombre",
      label: "Usuario",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "descripcion",
      label: "Descripción",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "saldo",
      label: "Saldo",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => <>{numeral(value).format("$0,0.00")}</>,
      },
    },
    {
      name: "detalles",
      label: "DETALLES",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <FaSearch
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/caja/" + tableMeta.rowData[0] + "");
            }}
          />
        ),

        filter: false,
        sort: false,
        print: false,
        download: false,
      },
    },
  ];

  const lookForFilter = () => {
    const filters = filterData.filter((filter) => filter.filterBy !== "");

    if (filters.length === 0) {
      setFinalData(data);
      return;
    }

    const filteredArray = data.filter((item) => {
      let currentDateOpen = new Date(item.fechaApertura?.replace(/-/g, "/"));
      currentDateOpen.setHours(0, 0, 0, 0);
      let currentDateClose =
        item?.fechaCierre && new Date(item.fechaCierre?.replace(/-/g, "/"));
      currentDateClose =
        currentDateClose && currentDateClose.setHours(0, 0, 0, 0);

      let dateMaxOpen = filterData[1]?.filterBy
        ? new Date(filterData[1].filterBy)
        : false;
      dateMaxOpen = dateMaxOpen ? dateMaxOpen.setHours(0, 0, 0, 0) : false;

      let dateMinOpen = filterData[0]?.filterBy
        ? new Date(filterData[0].filterBy)
        : false;
      dateMinOpen = dateMinOpen ? dateMinOpen.setHours(0, 0, 0, 0) : false;

      let dateMaxClose = filterData[3]?.filterBy
        ? new Date(filterData[3].filterBy)
        : false;
      dateMaxClose = dateMaxClose ? dateMaxClose.setHours(0, 0, 0, 0) : false;

      let dateMinClose = filterData[2]?.filterBy
        ? new Date(filterData[2].filterBy)
        : false;
      dateMinClose = dateMinClose ? dateMinClose.setHours(0, 0, 0, 0) : false;

      let checkDatesOpen = true;
      let checkDatesClose = true;

      if (dateMaxOpen || dateMinOpen) {
        checkDatesOpen =
          dateMaxOpen && dateMinOpen
            ? currentDateOpen.getTime() <= dateMaxOpen &&
              currentDateOpen.getTime() >= dateMinOpen
            : dateMaxOpen
            ? currentDateOpen.getTime() <= dateMaxOpen
            : dateMinOpen
            ? currentDateOpen.getTime() >= dateMinOpen
            : false;
      }

      if (dateMaxClose || dateMinClose) {
        checkDatesClose = currentDateClose
          ? dateMaxClose && dateMinClose
            ? currentDateClose <= dateMaxClose &&
              currentDateClose >= dateMinClose
            : dateMaxClose
            ? currentDateClose <= dateMaxClose
            : dateMinClose
            ? currentDateClose >= dateMinClose
            : false
          : false;
      }

      return checkDatesOpen && checkDatesClose;
    });
    setFinalData(filteredArray);
  };

  const options = {
    selectableRows: "none",
    textLabels,
    downloadOptions,
    filterType: "dropdown",
    responsive: "standard",
    draggableColumns: { enabled: true },
    enableNestedDataAccess: ".",
    customToolbar: () => {
      return (
        <CashBoxFilter
          filterData={filterData}
          setFilterData={setFilterData}
          lookForFilter={lookForFilter}
        />
      );
    },
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <MUIDataTable data={finalData} columns={columns} options={options} />
    </ThemeProvider>
  );
};
