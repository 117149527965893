import { toast } from "react-toastify";
import { supabaseClient } from "../../component/supabaseClient";
import { warehousesIds } from "../../constants/warehousesIds";

export const getCompleteOperationByOpCabId = async (opCabId) => {
  const { data: operationCab, errorDetalle } = await supabaseClient
  .from("operacionCabecera")
  .select(
    `
    *,
    tipoOperacion : tipoOperacion(descripcion),
    estadoOperacion (*),
    usuario: usrId(*),
    usuarioRelacionado: usrIdRelacionado(*),
    personas(*),
    operacionDetalle(*)
    operacionPago : operacionDetalle(operacionpago(*))
    `
  )
  .eq("opCabId", opCabId);
  if(errorDetalle){
    toast.error("getCompleteOperationByOpCabId ", errorDetalle);
  } else {
    return operationCab[0];
  }
}


export const getStockOfProduct = ({ depositoId, p }) => {
  let stock = 0;

  switch (depositoId) {
    case warehousesIds.rodriguez_penia:
      stock = p.stockRp || p.stockActual || 0;
      break;
    case warehousesIds.san_martin:
      stock = p.stockSm || p.stockActual || 0;
      break;
    case warehousesIds.deposito_rp:
      stock = p.stockDepoRp || p.stockActual || 0;
      break;
    case warehousesIds.deposito_sm:
      stock = p.stockDepoSm || p.stockActual || 0;
      break;
    case warehousesIds.oficina_venta_rp:
      stock = p.stockOfVenRp || p.stockActual || 0;
      break;
    case warehousesIds.oficina_venta_sm:
      stock = p.stockOfVenSm || p.stockActual || 0;
      break;
    default:
      stock = 0;
      break;
  }
  return stock;
};


export const getSimpleDataOfTable = async ({
  tableName, 
  lookFor="*", 
  extraQueryEq, 
  extraQueryIs, 
  extraQueryNotEq, 
  extraQueryEqInsensitive,
  extraQueryOrder,
  extraQueryGreatherThan
}) => {
  let query = supabaseClient
  .from(tableName)
  .select(lookFor);

  if(extraQueryEq) {
    for(let i = 0; i < extraQueryEq.length; i++){
      query = query.eq(extraQueryEq[i].queryColumn, extraQueryEq[i].queryValue);
    }
  }

  if(extraQueryEqInsensitive) {
    for(let i = 0; i < extraQueryEqInsensitive.length; i++){
      query = query.ilike(extraQueryEqInsensitive[i].queryColumn, `%${extraQueryEqInsensitive[i].queryValue}%`);
    }
  }

  if(extraQueryIs) {
    for(let i = 0; i < extraQueryIs.length; i++){
      query = query.is(extraQueryIs[i].queryColumn, extraQueryIs[i].queryValue);
    }
  }

  if(extraQueryGreatherThan) {
    for(let i = 0; i < extraQueryGreatherThan.length; i++){
      query = query.gte(extraQueryGreatherThan[i].queryColumn, extraQueryGreatherThan[i].queryValue);
    }
  }

  if(extraQueryNotEq) {
    for(let i = 0; i < extraQueryNotEq.length; i++){
      query = query.neq(extraQueryNotEq[i].queryColumn, extraQueryNotEq[i].queryValue);
    }
  }

  if(extraQueryOrder) {
    for(let i = 0; i < extraQueryOrder.length; i++){
      query = query.order(extraQueryOrder[i].queryColumn, extraQueryOrder[i].queryValue);
    }
  }
  
  const { data: dataTable, errorData } = await query;
      if (errorData) {
        toast.error("Hubo un error en la carga de datos: " + errorData);
      } else {
        return dataTable;
      }
}