import React, { useState, useEffect } from "react";
import { supabaseClient } from "../../component/supabaseClient";
import { Formik, Field, Form } from "formik";
import { useStateAuthValue } from "../../context/AuthState";
import { useNavigate, useLocation } from "react-router-dom";
import { GoBackButton } from "../../component/GoBackButton";
import { FormControlLabel, Switch } from "@mui/material";
import { errAndMsg } from "../../utils/MsgAlert";

const AddFormaDePago = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { formaDePago } = state || {};
  const [{ userRole }] = useStateAuthValue();
  const [grupo, setGrupo] = useState();
  
  if (userRole !== 1) navigate("/");  

  useEffect(() => {
    const getGroups = async () => {
      const { data, error } = await supabaseClient.from("grupoFormaDePago").select();
      if (data) setGrupo(data);
      if (error) errAndMsg('Error al cargar forma de pago', error.message, error.code);
    };
    getGroups();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>{formaDePago ? "Editar forma de pago" : "Agregar nueva forma de pago"}</h3>
              </div>
            </div>
            <hr />
          </div>
      <Formik
        initialValues={
          formaDePago
            ? {
                descripcion: formaDePago.descripcion,
                recargo: formaDePago.recargo,
                cuotas: formaDePago.cuotas,
                idGrupoFormaDePago: formaDePago.idGrupoFormaDePago,
                activa:  formaDePago.activa,
                mostrar:  formaDePago.mostrar,
              }
            : {
                descripcion: "",
                recargo: "",
                cuotas:  "",
                idGrupoFormaDePago:  "",
                activa:  "",
                mostrar:  "",
              }
        }

        validate={(values) => {
          const errors = {};
          if (!values.descripcion) {
            errors.descripcion = "Campo requerido";
          } else if (values.recargo < 0 || values.recargo === null) {
            errors.recargo = "Campo requerido";
          } else if (!values.cuotas) {
            errors.cuotas = "Campo requerido";
          } else if (!values.idGrupoFormaDePago) {
            errors.password = "Campo requerido";
          } 
          return errors;
        }}

        onSubmit={async (values, { setSubmitting }) => {
          if (!formaDePago) {
            try {
              // eslint-disable-next-line no-unused-vars
              const { data, error } = await supabaseClient
                .from("formaDePago")
                .insert([
                  {
                    descripcion: values.descripcion,
                    recargo: parseFloat(values.recargo),
                    cuotas: parseInt(values.cuotas),
                    idGrupoFormaDePago: parseInt(values.idGrupoFormaDePago),
                    activa: values.activa,
                    mostrar: values.mostrar,
                  },
                ])
                .select();
              if (error) errAndMsg('Error', error.message, error.code);
              setSubmitting(false);
              navigate("/configuracion/formas-pago");
            } catch (error) {
              errAndMsg('Error', error.message, error.code);
            }
          } else {
            const { error } = await supabaseClient
              .from("formaDePago")
              .update({
                descripcion: values.descripcion,
                recargo: values.recargo,
                cuotas: values.cuotas,
                idGrupoFormaDePago: values.idGrupoFormaDePago,
                activa: values.activa,
                mostrar: values.mostrar,
              })
              .eq("formaPagoId", formaDePago.formaPagoId);
            if (error) errAndMsg('Error', error.message, error.code);
            setSubmitting(false);
            navigate("/configuracion/formas-pago");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="form-container form-container-three-colums">
              <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="descripcion">
                  Descripcion
                </label>
                <Field
                  key="descripcion"
                  className="form-control"
                  id="descripcion"
                  name="descripcion"
                  placeholder="Descripcion"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.descripcion}
                />
                <div className="invalid-feedback">
                  {errors.descripcion && touched.descripcion && errors.descripcion}
                </div>
              </div>
              <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="recargo">
                  Recargo
                </label>
                <Field
                  key="recargo"
                  className="form-control"
                  id="recargo"
                  name="recargo"
                  placeholder="Recargo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.recargo}
                />
                <div className="invalid-feedback">
                  {errors.recargo && touched.recargo && errors.recargo}
                </div>
              </div>
              <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="cuotas">
                  Cuotas
                </label>
                <Field
                  key="cuotas"
                  className="form-control"
                  id="cuotas"
                  name="cuotas"
                  placeholder="Cuotas"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cuotas}
                />
                <div className="invalid-feedback">
                  {errors.cuotas && touched.cuotas && errors.cuotas}
                </div>
              </div>
              <div className="form-group form-container__select">
                <label className="form-container__label" htmlFor="grupo">
                  Grupo de pago
                </label>
                <Field
                  key="grupo"
                  className="form-control form-select"
                  as="select"
                  name="idGrupoFormaDePago"
                >
                  <option value=""></option>
                  {grupo?.map((g) => {
                    return (
                      <option value={g.idGrupoFormaDePago} id={g.idGrupoFormaDePago} key={g.idGrupoFormaDePago}>{g.descripcion}</option>
                    );
                  })}
                </Field>
                <div className="invalid-feedback">
                  {errors.idGrupoFormaDePago &&
                    touched.idGrupoFormaDePago &&
                    errors.idGrupoFormaDePago}
                </div>
              </div>
                
              <div className="form-group form-container__item">
                <FormControlLabel
                  control={
                    <Switch
                      name="mostrar_ocultar"
                      color="error"
                      checked={values.activa === true}
                      onChange={(event, checked) => {
                        setFieldValue("activa", checked ? true : false);
                      }}
                    />
                  }
                  label="Está activo?"
                />
              </div>
              <div className="form-group form-container__item">
                <FormControlLabel
                  control={
                    <Switch
                      name="mostrar_ocultar"
                      color="error"
                      checked={values.mostrar === true}
                      onChange={(event, checked) => {
                        setFieldValue("mostrar", checked ? true : false);
                      }}
                    />
                  }
                  label="Mostrar?"
                />
              </div>

            </div>
            <div className="form-container__button">
              <button
                className="btn btn-dark"
                type="submit"
                disabled={isSubmitting}
              >
                {formaDePago ? "Editar forma de pago" : "Agregar forma de pago"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddFormaDePago;
