import { useEffect, useState } from "react";
import { useStateAuthValue } from "../context/AuthState";
import { supabaseClient } from "../component/supabaseClient";
import Login from "../pages/Login";
import IdleTimer from "../pages/IdleTimer";
import SidebarAFH from "../component/dashboard/SidebarAFH";
import NavbarAFH from "../component/dashboard/NavbarAFH";
import pkg from "../../package.json";

const ProtectedAdmiRoute = (props) => {
  const user = localStorage.getItem("user");
  const [{ userRole }, dispatch] = useStateAuthValue();
  const [isUserLoggedIn, setUserIsLoggedIn] = useState(false);
  const [hideSidebar, setHideSidebar] = useState(false);
  const [sidebarWasHidden, setSidebarWasHidden] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const checkPrivileges = async () => {
    // eslint-disable-next-line no-unused-vars
    const { data, error } = await supabaseClient
      .from("usuarios")
      .select()
      .eq("email", user);
    if (data?.length) {
      dispatch({
        type: "LOG_IN_USER",
        userData: data[0],
        userRole: data[0].privilegioId,
      });
      setUserIsLoggedIn(true);
    }
  };

  useEffect(() => {
    checkPrivileges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn, userRole]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 750) {
        setHideSidebar(true);
        setSidebarWasHidden(true);
      } else {
        setHideSidebar(false);
        setSidebarWasHidden(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      document.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isUserLoggedIn || user) {
    return (
      <>
        <IdleTimer />
        <div className="flexAFH">
          <SidebarAFH
            sidebarWasHidden={sidebarWasHidden}
            hideSidebar={hideSidebar}
            setHideSidebar={setHideSidebar}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
          <div
            className="page-container"
            style={{ minWidth: "83%", width: "100%" }}
          >
            {props.children}
            {pkg?.version && (
              <div className="project-version">
                <p>
                  Versión: {pkg?.version} {process.env.REACT_APP_ENVIRONMENT}
                </p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  } else if (!user && !isUserLoggedIn) {
    return <Login />;
  }
};
export default ProtectedAdmiRoute;
