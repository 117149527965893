import React from "react";
import { supabaseClient } from "../../component/supabaseClient";
import { Formik, Field, Form } from "formik";
import { useStateAuthValue } from "../../context/AuthState";
import { useNavigate, useLocation } from "react-router-dom";
import { GoBackButton } from "../../component/GoBackButton";
import { errAndMsg } from "../../utils/MsgAlert";

const AddTipoProducto = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { tipoProducto } = state || {};

  const [{ userRole }] = useStateAuthValue();

  if (userRole !== 1) {
    navigate("/");
  }

  return (
    <div style={{ width: "100%" }}>
      <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>{tipoProducto ? "Editar tipo de producto" : "Agregar nuevo tipo de producto"}</h3>
              </div>
            </div>
            <hr />
          </div>
      <Formik
        initialValues={
          tipoProducto
            ? {
                descripcion: tipoProducto.descripcion,
              }
            : {
                descripcion: "",
              }
        }

        validate={(values) => {
          const errors = {};
          if (!values.descripcion) {
            errors.descripcion = "Campo requerido";
          } 
          return errors;
        }}

        onSubmit={async (values, { setSubmitting }) => {
          if (!tipoProducto) {
            try {
              // eslint-disable-next-line no-unused-vars
              const { data, error } = await supabaseClient
                .from("tipoProducto")
                .insert([
                  {
                    descripcion: values.descripcion,
                  },
                ])
                .select();
              if (error) errAndMsg('Error', error.message, error.code);
              setSubmitting(false);
              navigate("/configuracion/tipo-producto");
            } catch (error) {
              errAndMsg('Error', error.message, error.code);
            }
          } else {
            const { error } = await supabaseClient
              .from("tipoProducto")
              .update({
                descripcion: values.descripcion,
              })
              .eq("tipoProdId", tipoProducto.tipoProdId);
            if (error) errAndMsg('Error', error.message, error.code);
            setSubmitting(false);
            navigate("/configuracion/tipo-producto");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="form-container form-container-three-colums">
              <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="descripcion">
                  Descripcion
                </label>
                <Field
                  className="form-control"
                  id="descripcion"
                  name="descripcion"
                  placeholder="Descripcion"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.descripcion}
                />
                <div className="invalid-feedback">
                  {errors.descripcion && touched.descripcion && errors.descripcion}
                </div>
              </div>
              
            </div>
            <div className="form-container__button">
              <button
                className="btn btn-dark"
                type="submit"
                disabled={isSubmitting}
              >
                {tipoProducto ? "Editar tipo de producto" : "Agregar tipo de producto"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddTipoProducto;
