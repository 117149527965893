import { useNavigate } from "react-router-dom"
import { Button } from "react-bootstrap";

export const GoBackButton = () => {
    const navigate = useNavigate();
    return(
        <div style={{padding:"10px"}}>
            <Button variant="danger" onClick={() => navigate(-1)}>
            Volver
            </Button>  
        </div>
        
    )
}