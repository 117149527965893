import MUIDataTable from 'mui-datatables';
import { textLabels, downloadOptions, sortFunction } from '../utils/MuiTableOptions';

export const OperationTypeTable = ({ data, shouldSelect, setDataSelected, setShowFormaPago }) => {
  const columns = [
    {
      name: 'descripcion',
      label: 'Forma de pago',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'cuotas',
      label: 'Cuotas',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    searchOpen: true,
    textLabels,
    selectableRowsOnClick: true,
    downloadOptions,
    filter: true,
    selectableRows: shouldSelect && 'multiple',
    sortOrder: {name:"descripcion", direction: "desc"},
    rowsPerPageOptions: [100],
    customToolbarSelect: () => {},
    selectableRowsHeader: false,
    onRowSelectionChange: (currentSelect, allSelected) => {
      setDataSelected((prevState) => {
        let newState = prevState;
        if (!newState.some(e => e.formaPagoId === data[currentSelect[0].dataIndex].formaPagoId)) {
          newState.push(data[currentSelect[0].dataIndex]);
        }return [...newState];
      });
      setShowFormaPago(false);
    },
    fixedHeader: false,
    filterType: 'dropdown',
    responsive: 'standard',
    customSort: sortFunction,
    draggableColumns: { enabled: true },
  };

  return (
    <MUIDataTable
      title={'Formas de pago'}
      data={data}
      columns={columns}
      options={options}
    />
  );
};
