import React,{useState} from "react";
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/material/styles';
import { MuiTheme } from '../styles/MuiDataTableStyle';
import { textLabels, downloadOptions, sortFunction } from '../utils/MuiTableOptions';

export const ProductTable = ({
  isCompra,
  isPresupuesto,
  data,
  shouldSelect,
  depositoId,
  setDataSelected,
  setShow,
  hideOnSelect,
  depositos,
  products,
  selectedRows, 
  setSelectedRows
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(100);

  // Obtenemos el stock de un producto en un depósito específico
  const getStockForProductoAndDeposito = (prodId, depositoId) => {
    const producto = data.find((stock) => stock.prodId === prodId);
    if (producto) {
      const stockData = producto.stock.find(
        (stockItem) => stockItem.depositoId === depositoId
      );
      return stockData ? stockData.stockActual : 0;
    }
    return 0; // predeterminado si no se encuentra el producto
  };

  const columnsNotCompra = [
    {
      name: "prodId",
      label: "ID",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "nombreProducto",
      label: "Producto",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
        name: "totalStock",
        label: "Total Stock",
        options: {
          sort: true,
        },
      },
      ...depositos.map((dep) => ({
        name: `stock_${dep.depositoId}`,
        label: dep.descripcion,
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const producto =
              data[tableMeta.currentTableData[tableMeta.rowIndex].index];
            return (
              <div style={{ textAlign: "center" }}>
                {getStockForProductoAndDeposito(producto.prodId, dep.depositoId)}
              </div>
            );
          },
        },
      })),
  ];

    const columnsCompra = [
    {
      name: "prodId",
      label: "ID",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "nombreProducto",
      label: "Producto",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const allDepositos = depositos.map((deposito) => deposito.descripcion);

  const newData = data?.map((producto) => {
    let totalStock = producto.stock.reduce(
      (sum, dep) =>
        sum + dep.stockActual,
      0
    );
    return {
      prodId: producto.prodId,
      nombreProducto: producto?.stock[0]?.productos?.descripcionConcatenada || producto?.descripcionConcatenada,
      totalStock: totalStock,
      ...allDepositos.reduce((acc, dep) => {
        acc[`stock_${depositos.find((d) => d.descripcion === dep).depositoId}`] =
          getStockForProductoAndDeposito(
            producto.prodId,
            depositos.find((d) => d.descripcion === dep).depositoId
          );
        return acc;
      }, {}),
    }
  });

  const options = {
    filter: true,
    sort: true,
    search: true,
    searchOpen: true,
    textLabels,
    selectableRowsOnClick: true,
    downloadOptions,
    selectableRows: shouldSelect && 'multiple',
    customToolbarSelect: () => {},
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows)
    },
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions:[100,250,500],
    rowsSelected: selectedRows,
    selectableRowsHeader: false,
    selectToolbarPlacement: "none",
    onRowSelectionChange: (currentSelect, allSelected) => {
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = [...prevSelectedRows];
        // Add or remove indexes based on current selection
        currentSelect.forEach(({ dataIndex }) => {
          const index = newSelectedRows.indexOf(dataIndex);
          if (index === -1) {
            newSelectedRows.push(dataIndex);
          } else {
            newSelectedRows.splice(index, 1);
          }
        });

        return newSelectedRows;
      });
      let newProducts = [...products];
      const selectedProduct = data[currentSelect[0].dataIndex];
      if (!newProducts.some((e) => e.prodId === selectedProduct.prodId)) {
        newProducts.push(selectedProduct);
        setDataSelected(newProducts);
      } else {
        let notDeletedProducts = products.filter(
          (item) => item.prodId !== selectedProduct.prodId
        );
        setDataSelected(notDeletedProducts);
      }
      hideOnSelect && setShow(false);
    },
    isRowSelectable: (dataIndex, selectedRows) => {
      const hasStock = data[dataIndex].stock.filter((item) => item.depositoId === depositoId && item.stockActual > 0)
        if(isCompra || isPresupuesto || hasStock.length !== 0) {
          return true;
        } else {
          return false;
        }
    },
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: "350px",
    filterType: 'dropdown',
    responsive: 'standard',
    customSort: sortFunction,
    draggableColumns: { enabled: true },
    sortOrder: {
      name: isCompra && !isPresupuesto ? columnsCompra.nombreProducto : columnsNotCompra.nombreProducto, // Nombre de la columna por la que deseas ordenar
      direction: 'asc', // Orden ascendente
    },
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <MUIDataTable
        title={'Productos'}
        data={newData}
        columns={isCompra && !isPresupuesto ? columnsCompra : columnsNotCompra}
        options={options}
      />
    </ThemeProvider>
  );
};
