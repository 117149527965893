import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import LoadingSpinner from "../component/Spinner";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BsPlusLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import {
  textLabels,
  downloadOptions,
  sortFunction,
} from "../utils/MuiTableOptions";
import { MuiTheme } from "../styles/MuiDataTableStyle";

import { GoBackButton } from "../component/GoBackButton";
import { supabaseClient } from "../component/supabaseClient";

const StockNuevo = () => {
  const navigate = useNavigate();
  const [stock, setStock] = useState([]);
  const getMuiTheme = createTheme({});

  const getStockssss = async () => {
    const { data, error } = await supabaseClient
      .from("stockDeposito")
      .select(
        "prodId,productos(descripcionConcatenada),stockActual,depositoId,deposito(descripcion)"
      )

      .order("prodId", { ascending: true });

    const groupedData = data.reduce((acc, item) => {
      const {
        prodId,
        depositoId,
        stockActual,
        productos: { descripcionConcatenada },
      } = item;

      if (!acc[prodId]) {
        acc[prodId] = {
          prodId,
          descripcionConcatenada,
          SM: 0,
          DRP: 0,
          ORP: 0,
          RP: 0,
          DSM: 0,
          OSM: 0,
          StockTotal: 0,
        };
      }

      // Actualizamos el stock correspondiente según el depositoId
      switch (depositoId) {
        case 16:
          acc[prodId]["SM"] += stockActual;
          break;
        case 17:
          acc[prodId]["DRP"] += stockActual;
          break;
        case 3:
          acc[prodId]["ORP"] += stockActual;
          break;
        case 15:
          acc[prodId]["RP"] += stockActual;
          break;
        case 18:
          acc[prodId]["DSM"] += stockActual;
          break;
        case 19:
          acc[prodId]["OSM"] += stockActual;
          break;
        default:
          break;
      }

      // Actualizamos el StockTotal
      acc[prodId].StockTotal += stockActual;

      return acc;
    }, {});

    // Convertimos el objeto agrupado de nuevo a un array
    const result = Object.values(groupedData);
    setStock(result);
  };
  useEffect(() => {
    getStockssss();
  }, []);

  const columns = [
    {
      name: "prodId",
      label: "ID",
    },
    {
      name: "descripcionConcatenada",
      label: "Producto",
    },
    {
      name: "StockTotal",
      label: "Total Stock",
      options: { align: "center" },
    },
    {
      name: "SM",
      label: "San Martín",
      options: { align: "center" },
    },
    {
      name: "DRP",
      label: "Depósito RP",
    },
    {
      name: "ORP",
      label: "Oficina RP",
    },
    {
      name: "RP",
      label: "Rodriguez Peña",
    },
    {
      name: "DSM",
      label: "Depósito SM",
    },
    {
      name: "OSM",
      label: "Oficina SM",
    },
  ];

  const options = {
    searchOpen: true,
    textLabels,
    downloadOptions,
    filter: true,
    selectableRows: "none",
    fixedHeader: false,
    filterType: "dropdown",
    responsive: "standard",
    customSort: sortFunction,
    draggableColumns: { enabled: true },
  };
  return (
    <>
      <ThemeProvider theme={MuiTheme}>
        <div>
          <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>Stock Nuevo</h3>
              </div>
            </div>
            <hr />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginLeft: "10px",
            }}
          >
            <Button
              variant="outline-success my-2"
              onClick={() => {
                navigate("agregar-producto", { state: { product: false } });
              }}
            >
              <BsPlusLg /> Nuevo producto
            </Button>
          </div>

          {stock ? (
            <div
              style={{
                margin: "8px",
                overflow: "scroll",
              }}
            >
              <MUIDataTable data={stock} columns={columns} options={options} />
            </div>
          ) : (
            <div className="center-div">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </ThemeProvider>
    </>
  );
};

export default StockNuevo;
