import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { useStateAuthValue } from "../../context/AuthState";
import Cookies from "js-cookie";
import { Dropdown } from "react-bootstrap";
import {
  FaChartLine,
  FaCartPlus,
  FaUsers,
  FaList,
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
  FaUsersCog,
  FaWarehouse,
  FaBoxes,
  FaCashRegister,
  FaStore,
  FaRegSun,
  FaAngleDown,
  FaAngleUp,
  FaCreditCard,
  FaTags,
  FaRulerHorizontal,
  FaCubes,
  FaBox,
  FaNewspaper,
  FaBoxOpen,
  FaUserCircle,
  FaProductHunt,
} from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { GiPriceTag } from "react-icons/gi";
import { TbReportAnalytics, TbReportMoney } from "react-icons/tb";
import { PiUserSquareBold } from "react-icons/pi";
import Logo from "../../assets/brand/logo.png";
import IsoLogo from "../../assets/brand/isologo.png";

const SidebarAFH = ({
  hideSidebar,
  setHideSidebar,
  sidebarWasHidden,
  isOpen,
  setIsOpen,
}) => {
  const [{ userData, userRole }, dispatch] = useStateAuthValue();
  const location = useLocation();
  const navigate = useNavigate();
  const componentRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState({
    id: "",
    active: false,
  });
  const [lastClick, setLastClick] = useState(0);
  const isCashier = userRole === 2;
  const isAdmin = userRole === 1;
  const isSeller = userRole === 3;
  const isOperator = userRole === 4;
  const toggle = () => {
    if (sidebarWasHidden) {
      setHideSidebar(true);
      setIsOpen(false);
    } else {
      setIsOpen(!isOpen);
    }
    setDropdownOpen({
      id: "",
      active: false,
    });
  };

  const logOut = async () => {
    Cookies.remove("authenticated");
    localStorage.clear();
    dispatch({
      type: "LOG_OUT",
    });
    navigate("/login");
  };

  const toggleDropdown = (index, subNav) => {
    if (subNav !== undefined) {
      setLastClick(index);
      setDropdownOpen({
        id: index,
        active:
          index !== dropdownOpen.id && dropdownOpen.active === false
            ? true
            : index !== dropdownOpen.id
            ? dropdownOpen.active
            : !dropdownOpen.active,
      });
      setIsOpen(true);
    } else if (lastClick === index) {
      setDropdownOpen({
        id: index,
        active:
          index !== dropdownOpen.id && dropdownOpen.active === false
            ? true
            : index !== dropdownOpen.id
            ? dropdownOpen.active
            : !dropdownOpen.active,
      });
    }
    if (subNav === undefined) {
      setIsOpen(false);
      if (sidebarWasHidden) {
        setHideSidebar(true);
      }
      setDropdownOpen({
        id: "",
        active: false,
      });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      document.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (hideSidebar) {
      setIsOpen(false);
    }
  }, [hideSidebar]);

  const handleResize = () => {
    if (window.innerWidth < 1000) {
      setIsOpen(false);
    }
  };

  switch (location.pathname) {
    case "/login":
      return null;
    case "/crear-cuenta-admin":
      return null;
    default:
      break;
  }

  let menuItem;

  if (isCashier || isSeller) {
    menuItem = [
      {
        id: 1,
        path: "/",
        name: "Menú",
        icon: <FaList />,
      },
      {
        id: 2,
        path: "/stock",
        name: "Stock",
        icon: <FaBoxes />,
      },
      {
        id: 3,
        path: "/caja",
        name: "Caja",
        icon: <FaCashRegister />,
      },
      {
        id: 4,
        path: "/operaciones",
        name: "Operaciones",
        icon: <FaStore />,
      },
      {
        id: 5,
        path: "/operacion",
        name: "Operación",
        icon: <FaCartPlus />,
      },
      {
        id: 6,
        path: "/productos",
        name: "Productos",
        icon: <FaProductHunt />,
      },
      {
        id: 7,
        path: "/clientes",
        name: "Clientes",
        icon: <FaUsers />,
      },
      {
        id: 8,
        path: "/cheques",
        name: "Cheques",
        icon: <FaNewspaper />,
      },
      {
        id: 11,
        path: "user",
        name: userData?.nombre.toLowerCase(),
        icon: <FaUserCircle />,
      },
      {
        id: 12,
        path: "/login",
        name: "Salir",
        icon: <FiLogOut />,
      },
    ];
  } else if (isOperator) {
    menuItem = [
      {
        id: 1,
        path: "/stock",
        name: "Stock",
        icon: <FaBoxes />,
      },
      {
        id: 11,
        path: "user",
        name: userData?.nombre.toLowerCase(),
        icon: <FaUserCircle />,
      },
      {
        id: 12,
        path: "/login",
        name: "Salir",
        icon: <FiLogOut />,
      },
    ];
  } else if (isAdmin) {
    menuItem = [
      {
        id: 1,
        path: "/",
        name: "Menú",
        icon: <FaList />,
      },
      {
        id: 2,
        path: "/stock",
        name: "Stock",
        icon: <FaBoxes />,
      },
      {
        id: 3,
        path: "/caja",
        name: "Caja",
        icon: <FaCashRegister />,
      },
      {
        id: 4,
        path: "/operaciones",
        name: "Operaciones",
        icon: <FaStore />,
      },
      {
        id: 5,
        path: "/operacion",
        name: "Operación",
        icon: <FaCartPlus />,
      },
      {
        id: 6,
        path: "/productos",
        name: "Productos",
        icon: <FaProductHunt />,
      },
      {
        id: 7,
        path: "/clientes",
        name: "Clientes",
        icon: <FaUsers />,
      },
      {
        id: 8,
        path: "/cheques",
        name: "Cheques",
        icon: <FaNewspaper />,
      },
      {
        id: 9,
        path: "#",
        name: "Reportes",
        icon: <FaChartLine />,
        iconClosed: <FaAngleDown />,
        iconOpened: <FaAngleUp />,
        subNav: [
          {
            id: 91,
            title: "Operaciones",
            path: "/reportes/operaciones",
            icon: <TbReportAnalytics />,
          },
          {
            id: 92,
            title: "Stock por depósito",
            path: "/reportes/stock",
            icon: <FaBoxOpen />,
          },
          {
            id: 93,
            title: "Stock global",
            path: "/reportes/stock-global",
            icon: <FaBoxes />,
          },
          {
            id: 94,
            title: "Usuarios",
            path: "/reportes/usuarios",
            icon: <PiUserSquareBold />,
          },
          {
            id: 95,
            title: "Precios",
            path: "/reportes/precios",
            icon: <TbReportMoney />,
          },
        ],
      },
      {
        id: 10,
        path: "#",
        name: "Configuracion",
        icon: <FaRegSun />,
        iconClosed: <FaAngleDown />,
        iconOpened: <FaAngleUp />,
        subNav: [
          {
            id: 108,
            title: "Actualización de precios",
            path: "/configuracion/precios",
            icon: <GiPriceTag />,
          },
          {
            id: 101,
            title: "Depósitos",
            path: "/configuracion/depositos",
            icon: <FaWarehouse />,
          },
          {
            id: 102,
            title: "Formas de pago",
            path: "/configuracion/formas-pago",
            icon: <FaCreditCard />,
          },
          {
            id: 103,
            title: "Marca",
            path: "/configuracion/marca",
            icon: <FaTags />,
          },
          {
            id: 104,
            title: "Medida",
            path: "/configuracion/medida",
            icon: <FaRulerHorizontal />,
          },
          {
            id: 105,
            title: "Modelo",
            path: "/configuracion/modelo",
            icon: <FaCubes />,
          },
          {
            id: 106,
            title: "Tipo de producto",
            path: "/configuracion/tipo-producto",
            icon: <FaBox />,
          },
          {
            id: 107,
            title: "Usuarios",
            path: "/configuracion/usuarios",
            icon: <FaUsersCog />,
          },
        ],
      },
      {
        id: 11,
        path: "user",
        name: userData?.nombre.toLowerCase(),
        icon: <FaUserCircle />,
      },
      {
        id: 12,
        path: "/login",
        name: "Salir",
        icon: <FiLogOut />,
      },
    ];
  }

  return (
    <div
      id="containerPpal"
      ref={componentRef}
      className="containerAFH"
      style={{ display: "flex" }}
    >
      <div
        className="sidebarAFH"
        style={{
          width: isOpen ? "230px" : "50px",
          borderRadius: isOpen ? "0 0 15px 0" : "inherit",
          position: "relative",
          transition: "width 0.4s, left 0.4s, border-radius 0.4s", // Agrega la transición para left y borderRadius
        }}
      >
        <img
          src={isOpen ? Logo : IsoLogo}
          style={
            isOpen
              ? {
                  width: "200px",
                  height: "40px",
                  margin: "5px",
                  marginTop: "10px",
                }
              : {
                  width: "35px",
                  margin: "5px",
                  marginTop: "10px",
                }
          }
          alt="Gomeria Norte"
        />
        <div className="topSection">
          <div
            style={{ marginLeft: isOpen ? "85%" : "0px" }}
            className="isOpen"
          >
            {isOpen ? (
              <FaRegArrowAltCircleLeft
                style={{ cursor: "pointer" }}
                onClick={toggle}
              />
            ) : (
              <FaRegArrowAltCircleRight
                style={{ cursor: "pointer" }}
                onClick={toggle}
              />
            )}
          </div>
        </div>
        {menuItem?.map((item, index) => (
          <div key={index} style={{ overflow: "hidden" }}>
            <NavLink
              className={({ isActive, isPending }) =>
                isActive &&
                (location.pathname.includes(item.path) ||
                  (location.pathname === "#" && index === lastClick))
                  ? "linkSidebar active-link"
                  : "linkSidebar"
              }
              to={item.path === "user" ? "#" : item.path}
              key={item.id}
              onClick={() => {
                if (item.path === "/login") {
                  logOut();
                }
                toggleDropdown(index, item.subNav);
              }}
            >
              <div className="linkIcon">{item.icon}</div>
              <div
                className="linkName"
                style={{
                  display: isOpen ? "block" : "none",
                  textTransform: "capitalize",
                }}
              >
                {item.name}
                {item.path === "user" && (
                  <p
                    style={{
                      margin: 0,
                      fontSize: "14px",
                    }}
                  >
                    Suc.{" "}
                    {localStorage
                      .getItem("warehouseUser")
                      .charAt(0)
                      .toUpperCase() +
                      localStorage
                        .getItem("warehouseUser")
                        .slice(1)
                        .toLowerCase()}
                  </p>
                )}
              </div>
              {menuItem[index]?.subNav &&
              dropdownOpen.id === index &&
              dropdownOpen.active === true ? (
                <div key={`1${item.id}`} className="linkIcon">
                  {item.iconOpened}
                </div>
              ) : menuItem[index]?.subNav ? (
                <div key={`2${item.id}`} className="linkIcon">
                  {item.iconClosed}
                </div>
              ) : null}
            </NavLink>
            {/* items menu */}
            {dropdownOpen.active === true &&
              lastClick === index &&
              menuItem[index]?.subNav?.map((itemsub, indexsub) => {
                return (
                  <NavLink
                    to={itemsub.path}
                    key={itemsub.id}
                    onClick={() => {
                      setIsOpen(false);
                      if (sidebarWasHidden) {
                        setHideSidebar(true);
                      }
                      setDropdownOpen(!dropdownOpen);
                    }}
                    className={({ isActive, isPending }) =>
                      isActive && location.pathname.includes(itemsub.path)
                        ? "submenu active-link"
                        : "submenu"
                    }
                  >
                    <div className="submenuItem">
                      <div className="linkIconSubmenu ">{itemsub.icon}</div>
                      <div
                        className="linkNameSubmenu"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        {itemsub.title}
                      </div>
                    </div>
                  </NavLink>
                );
              })}
          </div>
        ))}
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            bottom: "10px",
            left: isOpen ? "10px" : "0px",
          }}
        ></div>
      </div>
    </div>
  );
};

export default SidebarAFH;
