/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { supabaseClient } from "../../component/supabaseClient";
import { useNavigate } from "react-router-dom";
import { MuiTheme } from "../../styles/MuiDataTableStyle";
import MUIDataTable from "mui-datatables";
import LoadingModal from "../../component/modals/LoadingModal";
import {MdEdit} from "react-icons/md";
import {
  textLabels,
  downloadOptions,
  sortFunction,
} from "../../utils/MuiTableOptions";
import { GoBackButton } from "../../component/GoBackButton";
import { errAndMsg } from "../../utils/MsgAlert";

function FormaDePago() {
  const navigate = useNavigate();

  const [formaDePago, setFormaDePago] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getFormaDePago = async () => {
      let query = supabaseClient
        .from("formaDePago")
        .select(`*, grupoFormaDePago:idGrupoFormaDePago(descripcion)`)
        .order("formaPagoId", { ascending: true });

      const { data: formaDePago, error } = await query;

      if (error) {
        errAndMsg(
          "Error al obtener las formas de pago",
          error.message,
          error.code
        );
      } else {
        const formasDePago = formaDePago.map((fp) => {
          return {
            ...fp,
            grupoFormaDePago: fp?.grupoFormaDePago?.descripcion,
            estadoFormaDePago: fp?.activa === true ? "Activa" : "Inactiva",
          };
        });
        setFormaDePago(formasDePago);
      }
    };
    getFormaDePago();
  }, []);

  const editFormaPago = (event) => {
    formaDePago?.map((fp) => {
      if (fp.formaPagoId === parseInt(event.currentTarget.id)) {
        navigate("editar-formas-pago", {
          state: {
            formaDePago: fp,
          },
        });
      }
    });
  };

  const columns = [
    {
      name: "formaPagoId",
      label: "Id",
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta) => (
          <th style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
            {columnMeta.label}
          </th>
        ),
      },
    },
    {
      name: "descripcion",
      label: "Descripcion",
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta) => (
          <th style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
            {columnMeta.label}
          </th>
        ),
      },
    },
    {
      name: "recargo",
      label: "Recargo",
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta) => (
          <th style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
            {columnMeta.label}
          </th>
        ),
      },
    },
    {
      name: "cuotas",
      label: "Cuotas",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta) => (
          <th style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
            {columnMeta.label}
          </th>
        ),
      },
    },
    {
      name: "grupoFormaDePago",
      label: "Grupo",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta) => (
          <th style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
            {columnMeta.label}
          </th>
        ),
      },
    },
    {
      name: "estadoFormaDePago",
      label: "Estado",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta) => (
          <th style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
            {columnMeta.label}
          </th>
        ),
      },
    },
    {
      name: "acciones",
      label: "Acciones",
      options: {
        customHeadRender: (columnMeta) => (
          <th style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
            {columnMeta.label}
          </th>
        ),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div className="crud__table__icon-container">
              <MdEdit
                id={formaDePago[dataIndex].formaPagoId}
                style={{ cursor: "pointer" }}
                className="crud__table__icon"
                alt="Item menú"
                onClick={editFormaPago}
              />
            </div>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    searchOpen: true,
    textLabels,
    downloadOptions,
    filter: true,
    selectableRows: "none",
    fixedHeader: false,
    filterType: "dropdown",
    responsive: "standard",
    customSort: sortFunction,
    draggableColumns: { enabled: true },
  };

  return (
    <>
      <ThemeProvider theme={MuiTheme}>
        <div className="crud">
        <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>Formas De Pago</h3>
              </div>
            </div>
            <hr />
          </div>
          <MUIDataTable
            title={"Listado de formas de pago"}
            data={formaDePago}
            columns={columns}
            options={options}
          />
          <div className="crud__rigth-buttons-container">
            <button
              className="btn btn-dark"
              onClick={() => {
                navigate("agregar-formas-pago", {
                  state: { formaDePago: false },
                });
              }}
            >
              Agregar nueva foma de pago
            </button>
          </div>
        </div>
        <LoadingModal
          text="Eliminando forma de pago..."
          show={loading}
          setShow={setLoading}
        />
        {/*  <UserDetailsModal
       //  user={userDetails}
        show={showPaysDetailsModal}
        setShow={setShowPaysDetailsModal}
      /> */}
      </ThemeProvider>
    </>
  );
}

export default FormaDePago;
