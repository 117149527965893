/* eslint-disable array-callback-return */
import { operationTypes } from "../constants/operationTypes";
import { operationStates } from "../constants/operationStates";
import {
  insertDataIntoTable,
} from "./supabaseFunctions/insertData";

import {
  getSimpleDataOfTable,
} from "./supabaseFunctions/getData";

import { 
    updateCajaReducePrevPayment,
    updateCajaSumNewPayment,
    updateSimpleData
} from "./supabaseFunctions/updateData";
import { ErrorModal } from "../component/modals/ErrorModal";
import { discountId, productTypes, service } from "../constants";
import { formatDate } from "./formatDate";

export const saveSaleOperation = async ({
  userData,
  header,
  opId,
  productos,
  estado,
  relatedUser,
  navigate,
  recargoAdmin
}) => {
  let nowDate = new Date();
  let date =
    nowDate.getFullYear() +
    "/" +
    (nowDate.getMonth() + 1) +
    "/" +
    nowDate.getDate();
  const time = nowDate.toLocaleTimeString("it-IT");

  let hasStock = false;
  let stock = 0;
  if(opId !== operationTypes.presupuesto && opId !== operationTypes.sena){ // si no es un servicio controlo stock en deposito
    for (const p of productos) {
      const prevStock = await getSimpleDataOfTable({
        tableName: "stockDeposito",
        extraQueryEq : [
          {queryColumn: "prodId", queryValue: p.prodId},
          {queryColumn: "depositoId", queryValue: userData.depositoId}
        ]});

      if((prevStock[0]?.stockActual <= 0 || prevStock[0]?.stockActual - p.cantidad < 0) && p.tipo !== productTypes.servicio){
        hasStock = false;
        navigate("/operaciones");
        ErrorModal({
          title: "Error",
          text: prevStock[0]?.stockActual <= 0 ? "El producto " + p?.descripcion + " ya no tiene stock en tu depósito, por lo que la operación no puede completarse." : "El producto " + p?.descripcion + " no tiene suficiente stock, por lo que la operación no puede completarse.",
        });
      } else {
        hasStock = true;
        stock = prevStock[0]?.stockActual;
      }
    } 
  } else {
  hasStock = true;
}

  // upload cabecera
  const dataCabecera = await insertDataIntoTable({
    tableName:"operacionCabecera",
    object:{
    usrId: userData.usrId,
    tipoOpId: opId,
    personaId: header?.cliente.personaId,
    estadoOpId: estado,
    fechaAlta: date,
    observaciones: header?.observaciones,
    numeroFactura: header?.numFactura,
    tipoFactura: header?.tipoFactura,
    horaAlta: time,
    depositoId: userData.depositoId,
    usrIdRelacionado: relatedUser?.personaId || relatedUser?.personaId || relatedUser?.usrId || userData.usrId,
    recargoAdmin: recargoAdmin && Number(recargoAdmin.replace(/\./g, "").replace(/,/g, "."))
  }});
  
  // upload details of each selled product
  if (dataCabecera && hasStock) {
    for (const p of productos) {
        const dataDetalle = await insertDataIntoTable({
          tableName:"operacionDetalle",
          object:{
          opCabId: dataCabecera[0]?.opCabId,
          prodId: p.prodId,
          cantidad: p.cantidad,
          detalle: header?.observaciones || p.descripcion,
          precioUnitario: Number(String(p.precioVenta).replace(/,/g, '.')),
        }});
        if (
          opId !== operationTypes.presupuesto &&
          opId !== operationTypes.sena &&
          estado !== operationStates.pendiente &&
          estado !== operationStates.sena &&
          p.prodId !== service
        ) {
          if (opId === operationTypes.stock_traslado_entre_sucursales_egreso) {
            //egreso p/deposito del vendedor
            await insertDataIntoTable({
              tableName: "movimientoStock",
              object: {
              tipoOpId: operationTypes.stock_traslado_entre_sucursales_egreso,
              prodId: p.prodId,
              opDetalleId: dataDetalle[0].opDetalleId,
              depositoId: userData.depositoId,
              fecha: date,
              hora: time,
              cantidad: p.cantidad,
              usrId: userData.usrId,
            }});
            //ingreso p/deposito relatedUser
            await insertDataIntoTable({
              tableName: "movimientoStock",
              object: {tipoOpId: operationTypes.stock_traslado_entre_sucursales_ingreso,
              prodId: p.prodId,
              opDetalleId: dataDetalle[0].opDetalleId,
              depositoId: header?.cliente?.depositoId,
              fecha: date,
              hora: time,
              cantidad: p.cantidad,
              usrId: relatedUser,
            }});

            let newStockDepo1 = stock - p.cantidad;
            let depositoOrigen = userData.depositoId;
            let newStockDepo2 = stock - p.cantidad;
            let depositoDestino = header?.cliente?.depositoId;

            //consulto si el producto posee stock en el deposito del vendedor
            if(p.tipo !== productTypes.servicio){ // si no es un servicio controlo stock en deposito
              const getdataDepo1 = await getSimpleDataOfTable({
                tableName: "stockDeposito", 
                lookFor:"*, producto : productos(*), tipoDes : productos(tipoProducto(*)), marcaDes : productos(marca(*)), modeloDes : productos(modelo(*)), medidaDes : productos(medida(descripcion)), deposito : deposito(*)",
                extraQueryEq : [
                  {queryColumn: "prodId", queryValue: p.prodId},
                  {queryColumn: "depositoId", queryValue: depositoOrigen}
                ]
              });
              //si no hay stock disponible en el deposito del vendedor, no realizo el traslado
              if (getdataDepo1.length === 0) {
                console.log("No hay cantidad disponible de este producto");
                //si hay stock disponible en el deposito del vendedor, realizo el traslado
              } else {
                //consulto si existe un registro de stock en deposito relatedUser
                const getdataDepo2 = await getSimpleDataOfTable({
                  tableName: "stockDeposito", 
                  lookFor:"*, producto : productos(*), tipoDes : productos(tipoProducto(*)), marcaDes : productos(marca(*)), modeloDes : productos(modelo(*)), medidaDes : productos(medida(descripcion)), deposito : deposito(*)",
                  extraQueryEq : [
                    {queryColumn: "prodId", queryValue: p.prodId},
                    {queryColumn: "depositoId", queryValue: depositoDestino}
                  ]
                });
  
                //si no exite stock en el deposito del relatedUser, inserto el nuevo registro
                if (getdataDepo2.length === 0) {
                  await insertDataIntoTable({
                    tableName:"stockDeposito",
                    object:{
                    prodId: p.prodId,
                    depositoId: depositoDestino,
                    stockActual: p.cantidad,
                    fecha: date,
                  }});
  
                  //si exite stock en el deposito del reltedUser, actualizo el registro
                } else {
                  await updateSimpleData({
                    tableName: "stockDeposito",
                    object: {
                      prodId: p.prodId,
                      depositoId: depositoDestino,
                      stockActual: newStockDepo2,
                      fecha: date,
                    },
                    extraQueryEq: [
                      { queryColumn: "prodId", queryValue: p.prodId },
                      { queryColumn: "depositoId", queryValue: depositoOrigen }
                    ],
                  });
                }
  
                //si no hubo error al insertar/updatear en el deposito del relatedUser, actualizo el registro en el deposito del vendedor
                await updateSimpleData({
                  tableName: "stockDeposito",
                  object: {
                    prodId: p.prodId,
                    depositoId: depositoOrigen,
                    stockActual: newStockDepo1,
                    fecha: date,
                  },
                  extraQueryEq: [
                    { queryColumn: "prodId", queryValue: p.prodId },
                    { queryColumn: "depositoId", queryValue: depositoOrigen }
                  ],
                });
                
              }

            }

          } else {
            //si no es un traslado entre sucursales
            await insertDataIntoTable({
              tableName: "movimientoStock",
              object: {tipoOpId: opId,
              prodId: p.prodId,
              opDetalleId: dataDetalle[0].opDetalleId,
              depositoId: userData.depositoId,
              fecha: date,
              hora: time,
              cantidad: p.cantidad,
              usrId: userData.usrId,
            }});

            let newStockActual = stock;
            // asegurarse de que no sea un servicio como producto o un ingreso de productos
            if (
              opId !== operationTypes.stock_ingreso_manual &&
              opId !== operationTypes.stock_ingreso_por_remito_de_compra &&
              estado !== operationStates.pendiente &&
              estado !== operationStates.sena &&
              p.prodId !== service
            ) {
              newStockActual -= p.cantidad;
            } else {
              newStockActual += p.cantidad;
            }

            if(p.tipo !== productTypes.servicio && opId !== operationTypes.presupuesto){ // si no es un servicio o un presupuesto acutalizo stock en deposito
              await updateSimpleData({
                tableName: "stockDeposito",
                object: {
                  prodId: p.prodId,
                  depositoId: userData.depositoId,
                  stockActual: newStockActual,
                  fecha: date,
                },
                extraQueryEq: [
                  { queryColumn: "prodId", queryValue: p.prodId },
                  { queryColumn: "depositoId", queryValue: userData.depositoId }
                ],
              });
            }
          }
        }
    }
    return dataCabecera;
  }
};


export const updateCajas = async (
    cajaB,
    prevPayment,
    formasDePagoEscogidas,
    opId,
    estado
  ) => {
    let cajaBUpdated = cajaB;

    // restar de la caja lo que fue paga previamente si la operación fue señada previamente
    for (let i = 0; i < prevPayment.length; i++) {
      if (opId === operationTypes.venta_b || opId === operationTypes.sena || estado === operationStates.sena) {
        cajaBUpdated = await updateCajaReducePrevPayment({
            saldo : Number(String(cajaBUpdated.saldo).replace(/,/g, '.')),
            pagado: prevPayment[i]?.formaPagoId === discountId ? 0 : Number(String(prevPayment[i]?.pagado).replace(/,/g, '.')),
            cajaId : cajaBUpdated.cajaId
        });
      }
    }
  
    // sumar los pagos totales realizados (incluidos los que estaban como seña)
    for (let i = 0; i < formasDePagoEscogidas.length; i++) {
      if (opId === operationTypes.venta_b || opId === operationTypes.sena || estado === operationStates.sena) {
        cajaBUpdated = await updateCajaSumNewPayment({
            saldo : Number(String(cajaBUpdated.saldo).replace(/,/g, '.')),
            pagado: formasDePagoEscogidas[i]?.formaPagoId === discountId ? 0 : Number(String(formasDePagoEscogidas[i]?.clientPaid).replace(/,/g, '.')),
            cajaId : cajaB.cajaId
        });
      }
    }
  };


export const savePayment = async (
    dataCabecera,
    p,
    totalPrice,
    header,
    detallesPagos,
    cajaB
  ) => {
    let nowDate = new Date();
  let date =
    nowDate.getFullYear() +
    "/" +
    (nowDate.getMonth() + 1) +
    "/" +
    nowDate.getDate();

    // guardar datos del pago
    const pago = await insertDataIntoTable({
      tableName:"operacionPago",
      object: {opCabId: dataCabecera[0].opCabId,
        formaPagoId: p.formaPagoId,
        total: Number(String(totalPrice).replace(/,/g, '.')),
        pagado: Number(String(p.clientPaid).replace(/,/g, '.')),
        formaPagoDescripcion: p.descripcion,
        recargo: p.recargo,
      }});

    // hacer movimiento de dinero solo si se realizó una venta b o una seña
    if (dataCabecera[0].tipoOpId === operationTypes.venta_b || dataCabecera[0].tipoOpId === operationTypes.sena) {
      await insertDataIntoTable({
        tableName:"movimientos",
        object: {opPagoId: pago[0].id,
            cajaId: cajaB.cajaId,
            formaPagoId: p.formaPagoId,
            monto: p.formaPagoId === discountId ? 0 : Number(String(p.clientPaid).replace(/,/g, '.')),
            fecha: date,
          }});
        }
        // guardar los detalles de la forma de pago
        // hay que verificar cada caso para hacer el pase del valor a la columna de la tabla
      const detalles = {
        nroCheque: "",
        nroOrden: "",
        compania: "",
        fecha: date,
        monto: Number(String(p.clientPaid).replace(/,/g, '.')),
        banco: "",
        sucursal: "",
        fechaEmision: null,
        fechaCobro: null,
        fechaVencimiento: null,
        titular: "",
        plan: p.descripcion,
        descripcion: header?.observaciones,
        formaPagoId: p.formaPagoId,
        cuit: "",
        personaId: header?.cliente?.personaId,
        tarjeta: p.descripcion,
        procesado: false,
        recargo: Number(String(p.recargo).replace(/,/g, '.')),
        opPagoId: pago[0].id,
      };
  
      detallesPagos.map((forms) => {
        if (forms.formaPagoId === p.formaPagoId) {
          forms.detail.map(async (d) => {
            let noSpaceInput = d.input.replace(/ /g, "");
            switch (noSpaceInput) {
              case "numerodecheque":
                detalles.nroCheque = d.value;
                break;
              case "numerodeorden":
                detalles.nroOrden = d.value;
                break;
              case "nombredecompañia":
                detalles.compania = d.value;
                break;
              case "fecha":
                detalles.fecha = d.value;
                break;
              case "banco":
                detalles.banco = d.value;
                break;
              case "sucursal":
                detalles.sucursal = d.value;
                break;
              case "fechadeemision":
                detalles.fechaEmision = d.value ? formatDate(d.value, false, true) : null;
                break;
              case "fechadecobro":
                detalles.fechaCobro = d.value ? formatDate(d.value, false, true) : null;
                break;
              case "fechadevencimiento":
                detalles.fechaVencimiento = d.value ? formatDate(d.value, false, true) : null;
                break;
              case "titular":
                detalles.titular = d.value;
                break;
              case "cuit":
                detalles.cuit = d.value;
                break;
              default:
                break;
            }
          });
        }
      });

      let success = insertDataIntoTable({
        tableName:"detalleFormaPago",
        object:detalles})
      return success;
  };
  