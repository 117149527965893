/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import {
  BsPlusCircleFill,
  BsFillPlusCircleFill,
  BsFillTrashFill,
} from "react-icons/bs";
import { AiFillMinusCircle } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import { ProductTable } from "../ProductTable";
import { useStateAuthValue } from "../../context/AuthState";
import { Table } from "react-bootstrap";
import LoadingSpinner from "../Spinner";
import {
  NumberInputValidation,
  currencyOperations,
  formatNumber,
  sanitizeInput,
} from "../../utils/NumberInput";
import { getSimpleDataOfTable } from "../../utils/supabaseFunctions/getData";
import { supabaseClient } from "../../component/supabaseClient";
import { productTypes } from "../../constants";

const Products = ({
  disable = false,
  setProducts,
  products,
  total,
  setTotal,
  stock,
  setStock,
  depositos,
  senado,
  depositoId,
  isCompra,
  saldo,
  isPresupuesto
}) => {
  const [show, setShow] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [{ userData }] = useStateAuthValue();
  const [deposito, setDeposito] = useState();

  const iconsPlusStyle = {
    height: "21px",
    width: "21px",
    color: "#1f7d1f",
    cursor: "pointer",
  };
  const iconsMinusStyle = {
    height: "24px",
    width: "24px",
    color: "#b90202",
    cursor: "pointer",
  };

  useEffect(() => {
    const getData = async () => {
      setLoadingProducts(true);

      try {
        // const { data, error } = await supabaseClient
        //   .from("stockDeposito")
        //   .select(
        //     "prodId, productos(*),stockActual,depositoId,deposito(descripcion)"
        //   )
        //   .order("prodId", { ascending: true });



        
        // const sortedData = data.sort((a, b) => a.productos.descripcionConcatenada.localeCompare(b.productos.descripcionConcatenada));
        // const resultado = sortedData.reduce((agrupado, item) => {
        //   const { prodId, stockActual, depositoId, productos, deposito } = item;

        //   const existingEntry = agrupado.find((entry) => entry.prodId === prodId);

        //   if (existingEntry) {
        //     existingEntry.stock.push({
        //       depositoId,
        //       stockActual,
        //       productos,
        //       deposito,
        //     });
        //     // Verificar y guardar depositoId en caso de tener stock
        //     if (stockActual > 0) {
        //       if (!existingEntry.IdsDepositosConStock.includes(depositoId)) {
        //         existingEntry.IdsDepositosConStock.push(depositoId);
        //       }
        //     }
        //   } else {
        //     // Inicializar la propiedad IdsDepositosConStock si no existe
        //     const IdsDepositosConStock = stockActual > 0 ? [depositoId] : [];
        //     agrupado.push({
        //       ...item.productos,
        //       prodId,
        //       stock: [{ depositoId, stockActual, productos, deposito }],
        //       cantidad: 1,
        //       IdsDepositosConStock,
        //     });
        //   }
        //   return agrupado;
        // }, []);

        const productos = await getSimpleDataOfTable({
          tableName: "productos",
          lookFor:
            "*, tipoDes : tipoProducto(descripcion), marcaDes : marca(descripcion), modeloDes : modelo(descripcion), medidaDes : medida(descripcion), stock: stockDeposito(*, deposito : deposito(*))",
          extraQueryOrder: [
            {
              queryColumn: "descripcionConcatenada",
              queryValue: { ascending: true },
            },
          ],
      });
      const resultado = productos.map((prod) => {return {...prod, cantidad: 1}})

      // Filtrar los productos cuyo tipo sea distinto de 3 (servicio)
      // const productosFiltrados = resultado.filter(
      //   (item) => item.tipo !== productTypes.servicio
      // );

        if (isCompra){
          // Filtrar los productos cuyo tipo sea distinto de 3 (servicio)
          const productosFiltrados = resultado.filter(item => item.tipo !== productTypes.servicio);
          // Guardar los productos filtrados 
          setStock(productosFiltrados);

        } else {
          // Guardar los productos y servicios
          setStock(resultado);
          const productosSelected = []
          resultado.map((prod, index) => {
            if(products.some((item) => item.prodId === prod.prodId)){
              productosSelected.push(index)
            }
          })
          setSelectedRows(productosSelected)
        } 
        

      } catch (error) {
        console.error("Error al obtener el stock:", error);
      }


      const deposito = await getSimpleDataOfTable({
        tableName: "deposito",
        extraQueryEq: [
          { queryColumn: "depositoId", queryValue: userData.depositoId },
        ],
      });
      if(deposito)setDeposito(deposito[0]);
      
      setLoadingProducts(false);
    };

    getData();
  }, [userData]);

  useEffect(() => {
    let total = 0;
    if(products.length){
      for (let i = 0; i < products.length; i++) {
        let checkSupabaseOriginPrice =  typeof products[i].precioVenta === "string"
          ? String(products[i].precioVenta)
          : String(products[i].precioVenta).replace(/\./g, ",");
        let mult = currencyOperations(
          checkSupabaseOriginPrice,
          String(products[i].cantidad),
          "MULTIPLY"
        );
        total = currencyOperations(String(total), mult, "SUM");
      }
    }
    setTotal(total);
  }, [products.length, products]);

  const increment = (e) => {
    if (!disable) {
      let prod = products.slice();
      const stock = prod[e.currentTarget.id].stock.filter((item) => item.depositoId === deposito.depositoId);
      if (prod[e.currentTarget.id]?.cantidad >= stock[0]?.stockActual && prod[e.currentTarget.id].tipo !== productTypes.servicio && !isCompra && !isPresupuesto) {
        prod[e.currentTarget.id].error = "Llegó al máximo de stock disponible: "+ stock[0].stockActual;
      } else {
        ++prod[e.currentTarget.id].cantidad;
        prod[e.currentTarget.id].error = "";
      }
      setProducts(prod);
    }
  };

  const decrement = (e) => {
    if (!disable) {
      let prod = products.slice();
      const stock = prod[e.currentTarget.id].stock.filter((item) => item.depositoId === deposito.depositoId);
      if (prod[e.currentTarget.id].cantidad > 0) {
        --prod[e.currentTarget.id].cantidad;
        
        if (prod[e.currentTarget.id].cantidad > stock[0]?.stockActual && prod[e.currentTarget.id].tipo !== productTypes.servicio && !isCompra && !isPresupuesto) {
          prod[e.currentTarget.id].error = "Llegó al máximo de stock disponible: "+ stock[0].stockActual;
        } else {
          prod[e.currentTarget.id].error = "";
        }
        setProducts(prod);
      }
    }
  };

  const handlePriceChange = ({ value, id }) => {
    let prod = products.slice();
    if (!disable) {
      if (isCompra) {
        if (Number(value?.replace(/[.,]/g, "")) <= saldo || !value) {
          prod[id].stock[0].productos.precioVenta = value || '';
          prod[id].precioVenta = value || '';
          prod[id].error = "";
        } else {
          prod[id].precioVenta = value;
          prod[id].error =
            "El saldo en efectivo de la caja es insuficiente para esta compra";
        }
      } else {
        prod[id].precioVenta = value || '';
      }
      setProducts(prod);
    }
  };

  const deleteProduct = (e) => {
    if (!disable) {
      let prod = products.slice();

      const newProd = prod.filter((item) => {
        if (!isCompra) {
          return item.prodId !== Number(prod[e.currentTarget.id].prodId);
        } else {
          return item.descripcionConcatenada !== prod[e.currentTarget.id].descripcionConcatenada
        }
      });
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = [...prevSelectedRows];
        newSelectedRows.splice(e.currentTarget?.id, 1);
        return newSelectedRows;
      });
      setProducts(newProd);
    }
  };

  return (
    <>
      <div>
        <div>
          <h5 style={{ fontWeight: "bold" }}>Productos / Servicios</h5>
        </div>
        <div
          style={{
            height: "250px",
            minHeight: "200px",
            overflow: "scroll",
          }}
        >
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Cantidad</th>
                <th style={{ width: "60%" }}>Descripción</th>
                <th>Subtotal efectivo</th>
                <th>Total efectivo</th>
              </tr>
            </thead>
            <tbody>
              {products.map((p, index) => {
                const price = !(disable || p.noStock || p.wasSeñado)
                  ? String( p?.precioVenta).replace(/\./g, ",")
                  : formatNumber(
                      sanitizeInput(String( p?.precioVenta).replace(/\./g, ","))
                    );
                const cantidad = String(p.cantidad);
                  const result = currencyOperations(price, cantidad, "MULTIPLY");
                let description =  p?.prodId + " - " + p?.descripcionConcatenada

                return (
                  <tr key={index}>
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AiFillMinusCircle
                        style={iconsMinusStyle}
                        onClick={(e) => {
                          if (!disable && !senado) {
                            decrement(e);
                          }
                        }}
                        id={index}
                      />
                      <input
                        disabled={disable || senado}
                        style={{ width: "70px", margin: "0 10px" }}
                        className="form-control"
                        type="number"
                        value={p.cantidad}
                        onChange={(e) => {
                          const number = e.target.value === '' ? e.target.value : Number(e.target.value);
                          let prod = products.slice();
                          const stockActual = prod[index]?.stock?.filter((item) => item.depositoId === depositoId)
                          if (!p.noStock || isCompra) {
                            prod[index].cantidad = number;
                            if(stockActual[0]?.stockActual < number && prod[index].tipo !== productTypes.servicio && !isCompra && !isPresupuesto){
                              prod[index].error = "Llegó al máximo de stock disponible: "+stockActual[0].stockActual;
                            } else {
                              prod[index].error = "";
                            }
                            setProducts(prod);
                          }
                        }}
                      />
                      <BsFillPlusCircleFill
                        style={iconsPlusStyle}
                        onClick={(e) => {
                          if (!disable && !senado) {
                            increment(e);
                          }
                        }}
                        id={index}
                      />
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {description}
                        <BsFillTrashFill
                          style={{ color: "grey", cursor: "pointer" }}
                          onClick={(e) => {
                            if (!p.wasSeñado) {
                              deleteProduct(e);
                            }
                          }}
                          id={index}
                        />
                      </div>
                      <strong>
                        {p.error ||
                          (p.noStock ? "Ya no hay stock de este producto" : "")}
                      </strong>
                    </td>
                    <td>
                      {p.usado || isCompra ? (
                        <NumberInputValidation
                          id={index}
                          disabled={disable || p.noStock || p.wasSeñado}
                          value={p.precioVenta}
                          handlePriceChange={handlePriceChange}
                          className="form-control"
                        />
                      ) : (
                        `$${price}`
                      )}
                    </td>
                    <td>{`$${result}`}</td>
                    <td></td>
                  </tr>
                );
              })}
              {products.length !== 0 && (
                <tr>
                  <td></td>
                  <td></td>
                  <td>Sub total</td>
                  <td>{`$${total}`}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={disable || senado}
            variant="outline-success my-2"
            onClick={() => {
              setShow(true);
            }}
          >
            <BsPlusCircleFill style={{ marginRight: "10px" }} />
            Agregar producto
          </Button>
        </div>
      </div>
      <Modal
        size="xl"
        show={show}
        onHide={() => {
          setShow(!show);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Escoja productos</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {loadingProducts ? (
            <div className="center-div">
              <LoadingSpinner />
            </div>
          ) : (
            <div>
              <div
            style={{
              height: "480px",
            }}
          >
          <ProductTable
              isCompra={isCompra}
              isPresupuesto={isPresupuesto}
              depositoId={userData.depositoId}
              data={stock}
              hideOnSelect={false}
              setShow={setShow}
              shouldSelect={true}
              setDataSelected={setProducts}
              products={products}
              depositos={depositos}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />  
            </div>       
          <div
            style={{
              maxHeight: "149px",
              overflow: "scroll",
            }}
          >
            {products.map((prod, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#ede9e9",
                    padding: "10px",
                  }}
                >
                  {prod?.descripcionConcatenada}
                  <BsFillTrashFill
                    style={{ color: "grey", cursor: "pointer" }}
                    onClick={(e) => {
                      if (!prod.wasSeñado) {
                        deleteProduct(e);
                      }
                    }}
                    id={index}
                  />
                </div>
              );
            })}
          </div>
          </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              setShow(false);
            }}
            variant="primary"
          >
            Escoger
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Products;
