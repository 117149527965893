//Constants for the operation types
export const operationTypes = {
  venta_b: 1,
  venta_n: 2,
  presupuesto: 3,
  stock_ingreso_manual: 4,
  stock_egreso_manual: 5,
  caja_apertura: 6,
  caja_cierre: 7,
  caja_ingreso: 8,
  caja_egreso: 9,
  sena: 10,
  stock_traslado_entre_sucursales_ingreso: 11,
  stock_traslado_entre_sucursales_egreso: 12,
  stock_ingreso_por_remito_de_compra: 13,
  stock_ingreso_por_compra_a_particulares: 14,
};
