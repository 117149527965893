import { useState,useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../styles/MuiDataTableStyle";
import CustomReportsToolbar from "./CustomReportsStockToolbar";
import { Box } from "@mui/material";
import { formatDate } from "../utils/formatDate";
import { textLabels, downloadOptions } from "../utils/MuiTableOptions";
import {operationTypes} from '../constants/operationTypes'
import { supabaseClient } from "./supabaseClient";

export const ReportsStockTable = ({ data, isGlobal }) => {
  const [finalData, setFinalData] = useState(data);
  const [depositos, setDepositos] = useState([]); 
  const [filterData, setFilterData] = useState([
    {
      label: "Fecha desde",
      filterBy: "",
      column: "fecha",
      objective: "min",
    },
    {
      label: "Fecha hasta",
      filterBy: "",
      column: "fecha",
      objective: "max",
    },
    {
      label: "Tipo de movimiento",
      filterBy: "",
      column: "tipoOpId",
      objective: "eq",
      options: [
        { label: "Ingreso por compra a particulares", value: operationTypes.stock_ingreso_por_compra_a_particulares },
        { label: "Ingreso manual", value: operationTypes.stock_ingreso_manual },
        { label: "Egreso manual", value: operationTypes.stock_egreso_manual },
        { label: "Traslado entre sucursales (ingreso)", value: operationTypes.stock_traslado_entre_sucursales_ingreso },
        { label: "Traslado entre sucursales (egreso)", value: operationTypes.stock_traslado_entre_sucursales_egreso },
        { label: "Venta", value: operationTypes.venta_b },
      ],
    },  
  ]); 





  const lookForFilter = () => {
    const filters = filterData.filter(
      (filter) => filter.filterBy !== ""
    );

    if (filters.length === 0) {
      setFinalData(data);
      return;
    }
    const filteredArray = data.filter((item) => {
      let currentDate = new Date(item.fecha.replace(/-/g, '/'));
      currentDate.setHours(0, 0, 0, 0);
      
      let dateMin =
        filterData[0]?.filterBy ?  new Date(filterData[0].filterBy)
          : false;
      dateMin = dateMin ? dateMin.setHours(0, 0, 0, 0) : false;
      
      let dateMax =
        filterData[1]?.filterBy ? new Date(filterData[1].filterBy)
          : false;
      dateMax = dateMax ? dateMax.setHours(0, 0, 0, 0) : false;

      const operationTypeSelected =
        typeof filterData[2]?.filterBy === "number" && filterData[2]?.filterBy;

      let checkDates = true;
      let checkOperationType = true;

      // check one by one if the user inputted a value for those columns
      // if true then implement filter, otherwise don't check that column

      if (dateMax || dateMin) {
        checkDates =
          dateMax && dateMin
            ? currentDate.getTime() <= dateMax &&
              currentDate.getTime() >= dateMin
            : dateMax
            ? currentDate.getTime() <= dateMax
            : dateMin
            ? currentDate.getTime() >= dateMin
            : false;
      }

      if (operationTypeSelected && item?.tipoOperacion ) {
        checkOperationType = item?.tipoOpId === operationTypeSelected ? true : false;
      } 

      return (
        checkDates && checkOperationType
      );
    });
    setFinalData(filteredArray);
  };

  const columns = [
    {
      name: "operacionDetalle.opCabId",
      label: "ID Operación",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "tipoOperacion.descripcion",
      label: "Tipo Operación",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "producto.descripcionConcatenada",
      label: "Producto",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "operacionDetalle.cantidad",
      label: "Cantidad",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fecha",
      label: "Fecha",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => <>{formatDate(value)}</>,
      },
    },
    {
      name: "usuario.nombre",
      label: "Usuario",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];
  const columnsStockGlobal = [
    {
      name: "operacionDetalle.opCabId",
      label: "ID Operación",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "deposito.descripcion",
      label: "Deposito",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "producto.descripcionConcatenada",
      label: "Producto",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "operacionDetalle.cantidad",
      label: "Cantidad",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fecha",
      label: "Fecha",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => <>{formatDate(value)}</>,
      },
    },
    {
      name: "usuario.nombre",
      label: "Usuario",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  const options = {
    selectableRows: "none",
    textLabels,
    downloadOptions,
    filterType: "dropdown",
    responsive: "standard",
    draggableColumns: { enabled: true },
    enableNestedDataAccess: ".",
    elevation: 0,
    customToolbar: ({ displayData }) => {
      return (
        <CustomReportsToolbar
          filterData={filterData}
          setFilterData={setFilterData}
          lookForFilter={lookForFilter}
          setFinalData={setFinalData}
          data={data}
        />
      );
    },
    filter: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: "500px",
  };

  // Función para obtener los depósitos
  const fetchDepositos = async () => {
    try {

        const { data} = await supabaseClient
          .from("deposito")
          .select()
          .eq("mostrar",true);

      setDepositos(data);
    } catch (error) {
      console.error('Error al obtener los depósitos:', error);
    }
  };

  useEffect(() => {
    fetchDepositos();
  }, []); 

  // Actualiza las opciones del filtro "Deposito" con los depósitos obtenidos
  const updatedFilterData = [...filterData];
  const depositoFilterIndex = updatedFilterData.findIndex(
    (filter) => filter.label === 'Deposito'
  );
  if (depositoFilterIndex !== -1) {
    updatedFilterData[depositoFilterIndex].options = depositos.map((deposito) => ({
      label: deposito.descripcion,
      value: deposito.id,
    }));
  }

  return (
    <ThemeProvider theme={MuiTheme}>
      <Box
        sx={{
          "& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head": {
            span: {
              justifyContent: "center",
            },
          },
        }}
      >
        <MUIDataTable
          title=""
          data={finalData}
          columns={isGlobal? columnsStockGlobal : columns}
          options={options}
        />
      </Box>
    </ThemeProvider>
  );
};
