import {
    updateSimpleData,
} from "../supabaseFunctions/updateData";
import { getStockOfProduct } from "../supabaseFunctions/getData";
import { insertDataIntoTable } from "../supabaseFunctions/insertData";
import { operationStates } from "../../constants";
import { supabaseClient } from "../../component/supabaseClient";


export const deleteMovements = async (operation, productos) => {
    let nowDate = new Date();
    const date = `${nowDate.getFullYear()}/${
        nowDate.getMonth() + 1
        }/${nowDate.getDate()}`;
    const time = `${nowDate.getHours()}:${nowDate.getMinutes()}:${nowDate.getSeconds()}`;
    
    await updateSimpleData({
        tableName: "operacionCabecera",
        object: {
          fechaAnulada: date,
          horaAnulada: time,
          estadoOpId: operationStates.anulada,
        },
        extraQueryEq: [
          { queryColumn: "opCabId", queryValue: operation.opCabId },
        ],
      });
    
    operation.operacionDetalle.map(async (det) => {
        await updateSimpleData({
            tableName: "operacionDetalle",
            object: {
              detalle: "Anulado"
            },
            extraQueryEq: [
              { queryColumn: "opCabId", queryValue: operation.opCabId },
              { queryColumn: "opDetalleId", queryValue: det.opDetalleId },
            ],
          });

        await insertDataIntoTable({
            tableName: "movimientoStock",
            object: {
                tipoOpId: operation.tipoOpId === 4 ? 5 : operation.tipoOpId === 12 ? 11 : 4,
                prodId: det.prodId,
                opDetalleId: det.opDetalleId,
                depositoId: operation.depositoId,
                fecha: date,
                hora: time,
                cantidad: det.cantidad,
                usrId: operation.usrId,
            },
        });
    });

    productos.map(async (p) => {
        const stockActual = getStockOfProduct({ depositoId: operation.depositoId, p: p });
        await supabaseClient
        .from('stockDeposito')
        .update({ 
            stockActual: operation.tipoOpId === 4 ? stockActual - Number(p.cantidad) : stockActual + Number(p.cantidad), 
            fecha: date
        })
        .eq("prodId", p.id)
        .eq("depositoId", operation.depositoId);
    })

    //obtener la operacion de ingreso cuando es una traslado entre sucursales repetir proceso
    if (operation.tipoOpId === 12) {

      const { data: opCabeceraRelacionada} = await supabaseClient
          .from("operacionCabecera")
          .select(
              `
              *,
              tipoOperacion : tipoOperacion(descripcion),
              estadoOperacion (*),
              usuario: usrId(*),
              usuarioRelacionado: usrIdRelacionado(*),
              personas(*),
              operacionDetalle(*)
              operacionPago : operacionDetalle(operacionpago(*))
              `
          )
          .eq("tipoOpId", 11)
          .eq("fechaAlta", operation.fechaAlta)
          .eq("horaAlta", operation.horaAlta);   
  
      if(opCabeceraRelacionada){
          await updateSimpleData({
              tableName: "operacionCabecera",
              object: {
                fechaAnulada: date,
                horaAnulada: time,
                estadoOpId: operationStates.anulada,
              },
              extraQueryEq: [
                { queryColumn: "opCabId", queryValue: opCabeceraRelacionada[0].opCabId },
              ],
            });
  
          opCabeceraRelacionada[0].operacionDetalle.map(async (det) => {
              await updateSimpleData({
                  tableName: "operacionDetalle",
                  object: {
                    detalle: "Anulado"
                  },
                  extraQueryEq: [
                    { queryColumn: "opCabId", queryValue: opCabeceraRelacionada[0].opCabId },
                    { queryColumn: "opDetalleId", queryValue: det.opDetalleId },
                  ],
                });
              await insertDataIntoTable({
                  tableName: "movimientoStock",
                  object: {
                      tipoOpId: 12,
                      prodId: det.prodId,
                      opDetalleId: det.opDetalleId,
                      depositoId: opCabeceraRelacionada[0].depositoId,
                      fecha: date,
                      hora: time,
                      cantidad: det.cantidad,
                      usrId: opCabeceraRelacionada[0].usrId,
                  },
              });
          });
          productos.map(async (p) => {
              const stockActual = getStockOfProduct({ depositoId: opCabeceraRelacionada[0].depositoId, p: p });
              await supabaseClient
                  .from('stockDeposito')
                  .update({ 
                      stockActual: stockActual - Number(p.cantidad), 
                      fecha: date
                  })
                  .eq("prodId", p.id)
                  .eq("depositoId", opCabeceraRelacionada[0].depositoId);
          })
  
      } 
    }


}
