import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../styles/MuiDataTableStyle";
import { supabaseClient } from "../component/supabaseClient";
import {MdEdit} from "react-icons/md";
import {
  textLabels,
  downloadOptions,
  sortFunction,
} from "../utils/MuiTableOptions";
import { formatDate } from "../utils/formatDate";
import { GoBackButton } from "../component/GoBackButton";
import { toast } from "react-toastify";

const Clients = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState();

  // useEffect(() => {
  //   const getUsers = async () => {
  //     let query = supabaseClient
  //       .from("personas")
  //       .select("*")
  //       // TODO: Orden por ID
  //       .order("personaId", { ascending: true });

  //     const { data: usuarios, error } = await query;

  //     if (error) {
  //       alert(error);
  //     } else {
  //       const data = usuarios.map((u) => {
  //         return {
  //           ...u,
  //           telefono1: u.telefono1 ? u.telefono1 : "-",
  //           telefono2: u.telefono2 ? u.telefono2 : "-",
  //         };
  //       });
  //       setUsers(data);
  //     }
  //   };
  //   getUsers();
  // }, []);

  useEffect(() => {
    const getUsers = () => {
      let query = supabaseClient
        .from("personas")
        .select("*")
        .order("personaId", { ascending: true });

      query
        .then(({ data, error }) => {
          if (error) throw new Error(error.message);

          const users = data.map((user) => {
            return {
              ...user,

              telefono1: user.telefono1 ? user.telefono1 : "-",
              telefono2: user.telefono2 ? user.telefono2 : "-",
              fechaAlta: formatDate(user.fechaAlta),
            };
          });
          setUsers(users);
        })
        .catch((error) => {
          toast.error(
            "Hubo un error durante la carga de datos: " + error.message
          );
        });
    };
    getUsers();
  }, []);

  // const editUser = (event) => {
  //   users?.map((u) => {
  //     if (u.personaId === parseInt(event.currentTarget.id)) {
  //       navigate("editar-cliente", {
  //         state: {
  //           user: u,
  //         },
  //       });
  //     }
  //   });
  // };
  const editUser = (event) => {
    const user = users.find(
      (user) => user.personaId === parseInt(event.currentTarget.id)
    );
    if (user) {
      navigate("editar-cliente", {
        state: {
          user,
        },
      });
      return;
    }
  };

  const columns = [
    {
      name: "personaId",
      label: "Id",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "razonSocial",
      label: "Razón Social",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "domicilio",
      label: "Domicilio",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <a href={`mailto:${value}`}>{value}</a>;
        },
      },
    },
    {
      name: "telefono1",
      label: "Telefono principal",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "telefono2",
      label: "Telefono secundario",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "fechaAlta",
      label: "Fecha de creado",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "cuit",
      label: "Cuit",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "acciones",
      label: "Acciones",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div className="crud__table__icon-container">
              <MdEdit
                id={users[dataIndex].personaId}
                style={{ cursor: "pointer" }}
                className="crud__table__icon"
                alt="Item menú"
                onClick={editUser}
              />
            </div>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    searchOpen: true,
    textLabels,
    downloadOptions,
    filter: true,
    selectableRows: "none",
    fixedHeader: false,
    filterType: "dropdown",
    responsive: "standard",
    customSort: sortFunction,
    draggableColumns: { enabled: true },
  };

  return (
    <>
      <ThemeProvider theme={MuiTheme}>
        <div className="crud">
        <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>Clientes</h3>
              </div>
            </div>
            <hr />
          </div>
          <MUIDataTable
            title={"Clientes"}
            data={users}
            columns={columns}
            options={options}
          />
          <div className="crud__rigth-buttons-container">
            <button
              className="btn btn-dark"
              onClick={() => {
                navigate("agregar-cliente", { state: { user: false } });
              }}
            >
              Agregar cliente nuevo
            </button>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};

export default Clients;
