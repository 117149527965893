import { ingresoEgreso} from './saveMovements';
import { operationTypes } from '../../constants/operationTypes';

export const inputStock = async (
    userData,
    header,
    products,
) => {
  const operationType = [operationTypes.stock_ingreso_manual];

  await ingresoEgreso (   
    userData,
    header,
    products,
    operationType
  )
}
