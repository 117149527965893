/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../styles/MuiDataTableStyle";
import LoadingModal from "../component/modals/LoadingModal";
import {
  textLabels,
  downloadOptions,
  sortFunction,
} from "../utils/MuiTableOptions";
import { GoBackButton } from "../component/GoBackButton";
import { getSimpleDataOfTable } from "../utils/supabaseFunctions/getData";
import { useStateAuthValue } from "../context/AuthState";
import numeral from "numeral";
import CheckDetailsModal from "../component/modals/SeeCheckDetailsModal";
import { fireConfirmationModal } from "../component/modals/ConfirmationModal";
import { VscFolderActive } from "react-icons/vsc";
import { PiMagnifyingGlassBold } from "react-icons/pi";
import { MdOutlineCancel } from "react-icons/md";
import { updateSimpleData } from "../utils/supabaseFunctions/updateData";
import { formatNumber, sanitizeInput } from "../utils/NumberInput";

const Checks = () => {
  const [{ userData }] = useStateAuthValue();

  const [checkDetails, setCheckDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [showCheckDetailsModal, setShowCheckDetailsModal] = useState(false);
  const [data, setData] = useState();
  const [total, setTotal] = useState();

  const getData = async () => {
    const response = await getSimpleDataOfTable({
      tableName: "operacionCabecera",
      lookFor: "*, operacionPago!inner(*,detalleFormaPago(*))",
      extraQueryEq: [
        { queryColumn: "depositoId", queryValue: userData.depositoId },
        { queryColumn: "operacionPago.formaPagoId", queryValue: 6 },
      ],
    });

    let checks = [];
    response?.map((item) => {
      item.operacionPago.map((subItem) => {
        if (subItem.detalleFormaPago.length) {
          checks.push({
            opCabId: item.opCabId,
            ...subItem.detalleFormaPago[0],
            procesadoText: subItem.detalleFormaPago[0].procesado
              ? "Procesado"
              : "No procesado",
          });
        }
      });
    });
    setData(checks);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (data?.length) {
      let monto = data.reduce((acc, movement) => acc + movement.monto, 0);
      setTotal(formatNumber(sanitizeInput(String(monto))));
    }
  }, [data]);

  const editCheck = (event, activate) => {
    data?.map((u) => {
      if (u.detalleFormaPagoId === parseInt(event.currentTarget.id)) {
        fireConfirmationModal({
          title: `¿Estás seguro de que deseas ${
            activate ? "procesar" : "desactivar"
          } este cheque?`,
          submit: async () => {
            setShowCheckDetailsModal(false);
            setLoading(true);
            await updateSimpleData({
              tableName: "detalleFormaPago",
              object: {
                procesado: activate,
              },
              extraQueryEq: [
                {
                  queryColumn: "detalleFormaPagoId",
                  queryValue: u.detalleFormaPagoId,
                },
              ],
            });
            setLoading(false);
            setData([]);
            setTimeout(() => {
              getData();
            }, 1000);
          },
        });
      }
    });
  };

  const seeDetailsCheck = (event) => {
    data?.map((u) => {
      if (u.detalleFormaPagoId === parseInt(event.currentTarget.id)) {
        setCheckDetails(u);
        setShowCheckDetailsModal(true);
      }
    });
  };

  const columns = [
    {
      name: "opCabId",
      label: "Operación",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "nroCheque",
      label: "Número de cheque",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return data[dataIndex].nroCheque
            ? data[dataIndex].nroCheque
            : "No especificado";
        },
      },
    },
    {
      name: "compania",
      label: "Compañia",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return data[dataIndex].compania
            ? data[dataIndex].compania
            : "No especificado";
        },
      },
    },
    {
      name: "fecha",
      label: "Fecha de operación",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "monto",
      label: "Monto",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          let finalValue = data[dataIndex].monto;
          return `$${formatNumber(sanitizeInput(String(finalValue)))}`;
        },
        customSort: (value1, value2) => {
          const num1 = numeral(value1)._value;
          const num2 = numeral(value2)._value;
          if (num1 < num2) {
            return -1;
          }
          if (num1 > num2) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      name: "titular",
      label: "Titular",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return data[dataIndex].titular
            ? data[dataIndex].titular
            : "No especificado";
        },
      },
    },
    {
      name: "procesadoText",
      label: "Procesado",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "acciones",
      label: "Acciones",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div className="crud__table__icon-container">
              <PiMagnifyingGlassBold
                id={data[dataIndex].detalleFormaPagoId}
                style={{ cursor: "pointer" }}
                className="crud__table__icon"
                alt="Item menú"
                onClick={seeDetailsCheck}
              />
              {data[dataIndex].procesado ? (
                <VscFolderActive
                  id={data[dataIndex].detalleFormaPagoId}
                  style={{ cursor: "pointer" }}
                  className="crud__table__icon"
                  alt="Item menú"
                  onClick={(e) => {
                    editCheck(e, false);
                  }}
                />
              ) : (
                <MdOutlineCancel
                  id={data[dataIndex].detalleFormaPagoId}
                  style={{ cursor: "pointer" }}
                  className="crud__table__icon"
                  alt="Item menú"
                  onClick={(e) => {
                    editCheck(e, true);
                  }}
                />
              )}
            </div>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    searchOpen: true,
    textLabels,
    downloadOptions,
    onTableChange: (action, tableState) => {
      if (action === "filterChange" && tableState.data.length > 0) {
        let filteredData = [];
        data.map((item) => {
          tableState.displayData.map((row) => {
            if (item?.opCabId === row.data[0]) {
              filteredData.push(item);
            }
          });
        });
        let monto = filteredData.length
          ? filteredData.reduce((acc, movement) => acc + movement.monto, 0)
          : 0;
        setTotal(formatNumber(sanitizeInput(String(monto))));
      }
    },
    filter: true,
    selectableRows: "none",
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: "600px",
    filterType: "dropdown",
    responsive: "standard",
    customSort: sortFunction,
    draggableColumns: { enabled: true },
  };

  return (
    <>
      <ThemeProvider theme={MuiTheme}>
        <div>
          <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>Cheques</h3>
              </div>
            </div>
            <hr />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <h4 style={{ paddingRight: "10px", fontSize: "20px", marginLeft:"10px" }}>
              Monto total: ${total}
            </h4>
          </div>
          <MUIDataTable data={data} columns={columns} options={options} />
        </div>
        <CheckDetailsModal
          data={checkDetails}
          setShow={setShowCheckDetailsModal}
          show={showCheckDetailsModal}
        />
        <LoadingModal
          text="Actualizando datos"
          show={loading}
          setShow={setLoading}
        />
      </ThemeProvider>
    </>
  );
};

export default Checks;
