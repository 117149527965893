import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import MUIDataTable from 'mui-datatables';
import { BsPlusCircleFill } from 'react-icons/bs';
import Modal from 'react-bootstrap/Modal';
import { supabaseClient } from '../supabaseClient';
import {
  textLabels,
  downloadOptions,
  sortFunction,
} from '../../utils/MuiTableOptions';
import { formatDate } from '../../utils/formatDate';
import { errAndMsg } from '../../utils/MsgAlert';
import { ErrorModal } from '../modals/ErrorModal';

const GeneralDataStockMovements = ({
  disabled,
  userData,
  header,
  setHeader,
  deposito,
  isReading,
  isIngreso,
  isTransfer,
  operation
}) => {
  const date = new Date();
  const today = operation?.fechaAlta ? formatDate(operation?.fechaAlta) : date.toLocaleDateString("es-AR");
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [userIndex, setUserIndex] = useState([]);
  const [userRelated, setUserRelated] = useState({});

  useEffect(() => {
    const getUsers = async () => {
      
      var query = supabaseClient
      .from('usuarios')
      .select('*, deposito : deposito(descripcion)');
      if (isTransfer){
        query = supabaseClient
          .from('usuarios')
          .select('*, deposito : deposito(descripcion)')
          .neq('depositoId',userData.depositoId);
      }
      const { data: personas, error } = await query;

      if (error) {
        errAndMsg('Error', error.message, error.code);
      } else {
        const users = personas.map((p) => {
          return{
            ...p, deposito: p?.deposito?.descripcion,
            personaId: p.usrId,
            razonSocial: p.nombre,
            domicilio: p?.deposito?.descripcion + " - " + p?.deposito?.domicilio,
            telefono1: p.telefono,
            email: p.email,
            cuit: "-"
          }
        });
        setUsers(users);
      }
    };
    getUsers();
  }, [isTransfer, userData]);

  const handleChange = ({ target }) => {
    setHeader({ ...header, [target.name]: target.value});
  };

  const handleClose = () => {
    if (userRelated.personaId) {
      setShow(!show);
    }else{
      ErrorModal({
        title: 'Debe escoger un usuario relacionado para esta operación.',
      });
    }
  };

  const columns = [
    {
      name: 'usrId',
      label: 'Id',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'deposito',
      label: 'Deposito',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'nombre',
      label: 'Nombre',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'telefono',
      label: 'Telefono',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    searchOpen: true,
    textLabels,
    selectableRowsOnClick: true,
    downloadOptions,
    filter: true,
    selectableRows: 'single',
    rowsSelected: userIndex,
    customToolbarSelect: () => {},
    fixedHeader: false,
    filterType: 'dropdown',
    responsive: 'standard',
    onRowSelectionChange: (currentSelect, allSelected) => {
      setHeader({ ...header, userRelacionado: users[currentSelect[0].dataIndex], depositoRelacionado: users[currentSelect[0].dataIndex].deposito, existeUsuarioRelacionado: true});
      setUserRelated(users[currentSelect[0].dataIndex]);
      setUserIndex([currentSelect[0].dataIndex]);
      setShow(false);
    },
    onRowsDelete: (rowsDeleted) => {
      setHeader({ ...header, userRelacionado: {} });
      setUserRelated({});
      setUserIndex([]);
      setShow(false);
    },
    customSort: sortFunction,
    draggableColumns: { enabled: true },
  };

  return (
    <>
      <div>
        <div className="header-container">
          <div style={{ width: '85%' }}>
            <h5 style={{ fontWeight: 'bold', paddingLeft: '10px' }}>
              Datos generales
            </h5>
            {deposito && (
              <>
                <div className="vendedor-container">
                {isReading && <p>Operación N° {operation?.opCabId}</p>}
                  <p>Fecha: {today}</p>
                  <p>Sucursal: {deposito[0]?.domicilio}</p>
                  <p>Vendedor: {userData?.nombre}</p>
                  {isIngreso &&
                    <div className="factura">
                      <p>Remito:</p>
                      <input
                          className="form-control"
                          id="numFactura"
                          name="numFactura"
                          type="number"
                          placeholder=""
                          onChange={handleChange}
                          value={header.numFactura || ""}
                          disabled={isReading}
                        />
                    </div>
                  }
                </div>
              </>
            )}
          </div>
          <div></div>
        </div>
        <div className="cliente-container">
          <div className="cliente">
            <h5 style={{ fontWeight: 'bold' }}>Usuario relacionado</h5>
            <div>
              <p>Nombre: {header.existeUsuarioRelacionado? header.userRelacionado.nombre : userRelated?.nombre}</p>
              <p>Email: {header.existeUsuarioRelacionado? header.userRelacionado.email : userRelated?.email}</p>
            </div>
            <div>
              <p>Deposito: {header.existeUsuarioRelacionado ? header.depositoRelacionado : userRelated?.deposito}</p>
              <p>Telefono: {header.existeUsuarioRelacionado? header.userRelacionado.telefono : userRelated?.telefono}</p>
            </div>
          </div>
          <div className="observaciones">
            <h5 style={{ fontWeight: 'bold' }}>Observaciones:</h5>
            <input
              className="form-control"
              id="observaciones"
              name="observaciones"
              placeholder="Descripción general de la operación"
              onChange={handleChange}
              value={header.observaciones}
              disabled={isReading}
            />
          </div>
          <div className="button">
            <Button
              disabled={disabled}
              variant="outline-success my-2"
              onClick={() => {
                setShow(true);
              }}
            >
              <BsPlusCircleFill style={{ marginRight: '10px' }} />
              Buscar Usuario
            </Button>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          setShow(!show);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Usuarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MUIDataTable
            data={users}
            columns={columns}
            options={options}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={handleClose} variant="primary">
            Escoger
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GeneralDataStockMovements;
