import jsPDF from 'jspdf';

export const pdf = ({ 
    filename,
    id
}) => {
    const doc = new jsPDF({orientation:'p', unit :'pt', format: 'a2', precision: 1});

    doc.html(document.getElementById(id), {
        callback: function (doc) {
            let pageCount = doc.internal.getNumberOfPages();

            doc.deletePage(pageCount);
            doc.deletePage(pageCount - 1);
            doc.deletePage(pageCount - 2);
            doc.deletePage(pageCount - 3);
            doc.deletePage(pageCount - 4);
            doc.deletePage(pageCount - 5);

            doc.save(filename);
        },
        x: 10,
        y: 10,
        windowWidth:100
     }); 
}