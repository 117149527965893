/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useStateAuthValue } from "../../context/AuthState";
import { supabaseClient } from "../supabaseClient";
import { BsPlusCircleFill } from "react-icons/bs";
import { AiFillMinusCircle } from "react-icons/ai";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Table } from "react-bootstrap";
import { OperationTypeTable } from "../OperationTypeTable";
import CompraBienesUsados from "../../pages/CompraBienesUsados";
import {
  NumberInputValidation,
  currencyOperations,
} from "../../utils/NumberInput";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import moment from "moment";

const PaymentOperation = ({
  date,
  header,
  formaPagos,
  total,
  setFormasDePagoEscogidas,
  formasDePagoEscogidas,
  señado,
  recargo,
  setRecargo,
  setDetallesPagos,
  detallesPagos,
  setTotalPagado,
  totalPagado,
  pagoDetalle,
  setPagoDetalle,
  isEditionOfOp,
  prevPayment,
  formPagoDetalle,
  submitUsedGoodsForm,
  isPresupuesto,
  saldo,
  setSaldo,
  disable = false,
  recargoAdmin,
  setRecargoAdmin,
}) => {
  const [{ userData }] = useStateAuthValue();
  const [showFormaPago, setShowFormaPago] = useState(false);
  const [showPagoDetallesModal, setShowPagoDetallesModal] = useState(false);
  const [showUsedGoodsModal, setShowUsedGoodsModal] = useState(false);
  const [usedGoodsProducts, setUsedGoodsProducts] = useState([]);
  const [usedGoodsComprobante, setUsedGoodsComprobante] = useState("");

  useEffect(() => {
    const saveUsedGoods = async () => {
      usedGoodsProducts.map(async (p) => {
        const { data: prod, errorProductos } = await supabaseClient
          .from("productos")
          .insert([
            {
              tipo: p.tipo,
              marca: p.marca,
              modelo: p.modelo,
              medida: p.medida,
              tipoDibujo: p.tipoDibujo,
              descripcion: p.descripcion,
              stockMinimo: p.cantidad,
              precioCosto: 0,
              precioVenta: 0,
              usado: p.usado,
            },
          ])
          .select();
        if (errorProductos)
          toast.error(
            "Hubo un error durante el guardado de productos : " +
              errorProductos.message
          );
        const { errorStock } = await supabaseClient
          .from("stockDeposito")
          .insert([
            {
              fecha: date,
              prodId: prod[0].prodId,
              depositoId: userData.depositoId,
              stockActual: p.cantidad,
            },
          ]);
        if (errorStock) {
          toast.error(
            "Hubo un error durante el guardado de productos : " +
              errorStock.message
          );
        }
      });
    };

    if (submitUsedGoodsForm && usedGoodsProducts.length !== 0) {
      saveUsedGoods();
    }
  }, [submitUsedGoodsForm]);

  useEffect(() => {
    if (isEditionOfOp) {
      let payments = [];
      formaPagos?.map((formPay) => {
        prevPayment?.map((p) => {
          if (p.formaPagoId === formPay.formaPagoId) {
            if (señado) {
              payments.push({
                ...p,
                descripcion: formPay.descripcion,
                clientPaid: p.pagado,
                señado: true,
              });
            } else {
              payments.push({
                ...p,
                descripcion: formPay.descripcion,
                clientPaid: p.pagado,
                señado: false,
              });
            }
          }
        });
      });
      setFormasDePagoEscogidas(payments);
    }
  }, [isEditionOfOp, prevPayment, señado]);

  useEffect(() => {
    let totalCargado = 0;
    let totalRecargo = 0;
    let totalSaldoWithRecargoDiscount = 0;

    for (let i = 0; i < formasDePagoEscogidas.length; i++) {
      // check format from supabase
      let checkSupabaseOriginPaid = !(disable || señado)
        ? String(formasDePagoEscogidas[i].clientPaid)
        : String(formasDePagoEscogidas[i].clientPaid).replace(/\./g, ",");

      // discount amount with recargo to get remaining saldo
      let individualSaldoWithRecargoDiscount = currencyOperations(
        checkSupabaseOriginPaid,
        String(1 + formasDePagoEscogidas[i].recargo).replace(/\./g, ","),
        "DIVIDE"
      );

      // get full amount to render full saldo
      totalSaldoWithRecargoDiscount = currencyOperations(
        String(totalSaldoWithRecargoDiscount),
        individualSaldoWithRecargoDiscount,
        "SUM"
      );

      // sum all payment methods
      totalCargado = currencyOperations(
        String(totalCargado),
        checkSupabaseOriginPaid,
        "SUM"
      );

      // recargo has to be a positive number
      let recargoSubtract = currencyOperations(
        checkSupabaseOriginPaid,
        individualSaldoWithRecargoDiscount,
        "SUBTRAC"
      );

      totalRecargo =
        formasDePagoEscogidas[i].recargo > 0
          ? currencyOperations(
              String(totalRecargo),
              String(recargoSubtract).replace("-", ""),
              "SUM"
            )
          : totalRecargo;
    }
    setTotalPagado(totalCargado);

    // total - recargo + recargoAdmin
    totalSaldoWithRecargoDiscount =
      Number(String(total).replace(/\./g, "").replace(/,/g, ".")) -
      Number(
        String(totalSaldoWithRecargoDiscount)
          .replace(/\./g, "")
          .replace(/,/g, ".")
      ) +
      Number(String(recargoAdmin).replace(/\./g, "").replace(/,/g, "."));
    
      totalSaldoWithRecargoDiscount = disable
      ? 0
      : currencyOperations(
          "0",
          totalSaldoWithRecargoDiscount.toFixed(2).replace(/\./g, ","),
          "SUM"
        );
    setRecargo(`${totalRecargo}`);
    setSaldo(`${totalSaldoWithRecargoDiscount}`);
  }, [formasDePagoEscogidas, total, recargoAdmin]);

  const handlePriceChange = ({ value, id }) => {
    if (!disable) {
      let pagos = formasDePagoEscogidas.slice();
      pagos[id].clientPaid = value;
      setFormasDePagoEscogidas(pagos);
    }
  };

  const deletePayment = (e) => {
    if (!disable) {
      let pagos = formasDePagoEscogidas.slice();
      const newProd = pagos.filter((item) => {
        return item.formaPagoId !== Number(e.currentTarget.id);
      });
      const newDetails = detallesPagos.filter((item) => {
        return item.formaPagoId !== Number(e.currentTarget.id);
      });
      setFormasDePagoEscogidas(newProd);
      setDetallesPagos(newDetails);
    }
  };

  return (
    <>
      <div>
        <div>
          <h5>Formas de pago</h5>
        </div>
        <div className="price-table-container">
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th style={{ width: "45%" }}>Descripción</th>
                <th style={{ width: "30%" }}>Importe</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {formasDePagoEscogidas?.map((p, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div>
                        <AiFillMinusCircle
                          style={{
                            height: "20px",
                            width: "20px",
                            color: "#b90202",
                            cursor: "pointer",
                            marginRight: "10px",
                          }}
                          onClick={(e) => {
                            if (!p.señado) {
                              deletePayment(e);
                            }
                          }}
                          id={p.formaPagoId}
                        />
                        {p.descripcion}
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <NumberInputValidation
                          disabled={disable || p.señado}
                          value={p?.clientPaid}
                          handlePriceChange={handlePriceChange}
                          id={index}
                          max={Number(saldo.replace(/,/g, "."))}
                        />
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {formPagoDetalle.map((op) => {
                        if (op.formaPagoId === p.formaPagoId) {
                          return (
                            <Button
                              //disabled={disable}
                              key={op.formaPagoId}
                              onClick={() => {
                                setPagoDetalle(op);
                                if (op?.openModalUsedGoods) {
                                  setShowUsedGoodsModal(true);
                                } else {
                                  setShowPagoDetallesModal(true);
                                }
                              }}
                              variant="success my-2"
                              style={{
                                backgroundColor: "#f09142",
                                borderColor: "#f09142",
                              }}
                            >
                              Detalles
                            </Button>
                          );
                        }
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div>
            <h6 style={{ marginRight: "10px" }}>Recargos Administrativos</h6>
            <NumberInputValidation
              style={{ maxWidth: "30%" }}
              disabled={disable || isPresupuesto}
              value={recargoAdmin}
              handlePriceChange={({ value }) => {
                setRecargoAdmin(value ? value : 0);
              }}
            />
          </div>
        </div>
        <div className="saldos">
          <div className="text-container">
            <div>
              <h6 style={{ marginRight: "10px" }}>
                Saldo a cancelar : {`$${saldo}`}
              </h6>
            </div>
            <div>
              <h6 style={{ marginRight: "10px" }}>Recargo : {`$${recargo}`}</h6>
            </div>
            <div>
              <h6 style={{ marginRight: "10px" }}>
                Total pagado : {`$${totalPagado}`}
              </h6>
            </div>
          </div>
          <Button
            disabled={disable || isPresupuesto}
            variant="outline-success my-2"
            onClick={() => {
              setShowFormaPago(true);
            }}
          >
            <BsPlusCircleFill style={{ marginRight: "10px" }} />
            Agregar forma de pago
          </Button>
        </div>
      </div>

      <Modal
        style={{ zIndex: "10000" }}
        size="lg"
        show={showFormaPago}
        onHide={() => {
          setShowFormaPago(!showFormaPago);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Escoja una forma de pago</Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{
            height: "415px",
            overflow: "scroll",
          }}
        >
          <OperationTypeTable
            data={formaPagos}
            shouldSelect={true}
            setShowFormaPago={setShowFormaPago}
            setDataSelected={setFormasDePagoEscogidas}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              setShowFormaPago(false);
            }}
            variant="primary"
          >
            Escoger
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        style={{ zIndex: "1100" }}
        show={showPagoDetallesModal}
        onHide={() => {
          setShowPagoDetallesModal(!showPagoDetallesModal);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detalles</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-container form-container-two-colums">
            {pagoDetalle &&
              pagoDetalle?.detail?.map((det, index) => {
                return (
                  <div
                    key={index}
                    className="form-group form-container__item"
                    style={{ width: "20rem" }}
                  >
                    <label
                      className="form-container__label"
                      style={{ textTransform: "capitalize" }}
                      htmlFor="razonSocial"
                    >
                      {det.input}
                    </label>
                    {det.input.includes("fecha") ||
                    det.input.toLowerCase() === "fecha" ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Box
                            sx={{
                              ".MuiFormControl-root": {
                                padding: "0px !important",
                              },
                            }}
                          >
                            <DesktopDatePicker
                              format="DD/MM/YYYY"
                              slotProps={{
                                textField: {
                                  variant: "standard",
                                  disabled: true,
                                },
                              }}
                              disabled={disable}
                              value={disable ? moment(det.value) : det.value}
                              onChange={(value) => {
                                let details = pagoDetalle.detail.slice();
                                details[index].value = value;
                                setPagoDetalle({
                                  ...pagoDetalle,
                                  detail: details,
                                });
                              }}
                            />
                          </Box>
                        </div>
                      </LocalizationProvider>
                    ) : (
                      <input
                        className="form-control"
                        id={det.input}
                        name={det.input}
                        disabled={disable}
                        placeholder={det.input}
                        onChange={(e) => {
                          let details = pagoDetalle.detail.slice();
                          details[index].value = e.target.value;
                          setPagoDetalle({ ...pagoDetalle, detail: details });
                        }}
                        value={det.value}
                      />
                    )}
                  </div>
                );
              })}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={disable}
            onClick={() => {
              let detalles = detallesPagos;
              const i = detalles.findIndex(
                (_element) => _element.formaPagoId === pagoDetalle.formaPagoId
              );
              if (i > -1) detalles[i] = pagoDetalle;
              else detalles.push(pagoDetalle);

              setDetallesPagos(detalles);
              setShowPagoDetallesModal(false);
            }}
            variant="primary"
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        style={{ zIndex: "10000" }}
        size="xl"
        show={showUsedGoodsModal}
        onHide={() => {
          setShowUsedGoodsModal(!showUsedGoodsModal);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Comprobande de Compra de Bienes Usados</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CompraBienesUsados
            submitUsedGoodsForm={submitUsedGoodsForm}
            isPartOfOperation={true}
            prevClient={header?.cliente}
            usedGoodsProducts={usedGoodsProducts}
            setUsedGoodsProducts={setUsedGoodsProducts}
            usedGoodsComprobante={usedGoodsComprobante}
            setUsedGoodsComprobante={setUsedGoodsComprobante}
            setShowUsedGoodsModal={setShowUsedGoodsModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentOperation;
