/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import numeral from "numeral";
import { useStateAuthValue } from "../context/AuthState";
import LoadingSpinner from "../component/Spinner";
import { ReportsProductPricesTable } from "../component/ReportsProductPrices";
import { getSimpleDataOfTable } from "../utils/supabaseFunctions/getData";
import { GoBackButton } from "../component/GoBackButton";

const ReportsProductPrices = () => {
  const [reports, setReports] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [{ userData }] = useStateAuthValue();

  const getReports = async () => {
    const products = await getSimpleDataOfTable({
      tableName: "productos",
      lookFor: `*,stockDeposito(*)`,
      extraQueryEq: [
        { queryColumn: "activo", queryValue: true },
        { queryColumn: "mostrar", queryValue: true },
      ],
      extraQueryOrder: [
        {
          queryColumn: "descripcionConcatenada",
          queryValue: { ascending: true },
        },
      ],
    });

    const prices = await getSimpleDataOfTable({
      tableName: "formaDePago",
      extraQueryEq: [
        { queryColumn: "activa", queryValue: true },
        { queryColumn: "mostrar", queryValue: true },
      ],
      extraQueryOrder: [
        { queryColumn: "descripcion", queryValue: { ascending: true } },
      ],
    });
    setPriceList(prices);

    let newProducts = products.map((prod) => {
      let price = Math.abs(prod.precioVenta);
      price = numeral(price).format("$0,0.00");

      let stockTotal = prod.stockDeposito.reduce(
        (sum, dep) => sum + dep.stockActual,
        0
      );
      return {
        ...prod,
        price,
        stockTotal,
      };
    });

    setReports(newProducts);
    setLoading(false);
  };

  useEffect(() => {
    getReports();
  }, [userData]);

  return (
    <>
      {loading ? (
        <div className="center-div">
          <div>
            <LoadingSpinner />
          </div>
        </div>
      ) : (
        <div>
          <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>Lista de precios</h3>
              </div>
            </div>
            <hr />
          </div>

          {reports?.length > 0 ? (
            <ReportsProductPricesTable data={reports} priceList={priceList} />
          ) : (
            <div className="center-div">
              <h3>No se existen productos publicados</h3>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ReportsProductPrices;
