import React from "react";
import { supabaseClient } from "../../component/supabaseClient";
import { Formik, Field, Form } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { GoBackButton } from "../../component/GoBackButton";
import { errAndMsg } from "../../utils/MsgAlert";

const AddNewWarehouse = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { warehouse } = state || {};
  return (
    <div style={{ width: "100%" }}>
      <div className="crud__title">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GoBackButton />
              <div style={{ width: "100%" }}>
                <h3>{warehouse ? "Editar depósito" : "Agregar nuevo depósito"} </h3>
              </div>
            </div>
            <hr />
          </div>
      <Formik
        initialValues={
          warehouse
            ? {
                descripcion: warehouse.descripcion,
                domicilio: warehouse.domicilio,
                phone_number1: warehouse.telefono1,
                phone_number2: warehouse.telefono2,
                mostrar_ocultar: warehouse.mostrar,
              }
            : {
                descripcion: "",
                domicilio: "",
                phone_number1: "",
                phone_number2: "",
                mostrar_ocultar: true,
              }
        }
        validate={(values) => {
          const errors = {};
          if (!values.descripcion) {
            errors.descripcion = "Campo requerido";
          } else if (!values.domicilio) {
            errors.domicilio = "Campo requerido";
          } else if (!values.phone_number1) {
            errors.phone_number1 = "Campo requerido";
          } else if (/\D/.test(values.phone_number1)) {
            errors.phone_number1 = "Solo se aceptarn números en este campo.";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (!warehouse) {
            try {
              const { error } = await supabaseClient.from("deposito").insert([
                {
                  descripcion: values.descripcion,
                  domicilio: values.domicilio,
                  telefono1: values.phone_number1,
                  telefono2: values.phone_number2,
                  mostrar: values.mostrar_ocultar,
                },
              ]);
              if (error) errAndMsg("Error", error.message, error.code);
              setSubmitting(false);
              navigate("/configuracion/depositos");
            } catch (error) {
              errAndMsg("Error", error.message, error.code);
            }
          } else {
            const { error } = await supabaseClient
              .from("deposito")
              .update({
                descripcion: values.descripcion,
                domicilio: values.domicilio,
                telefono1: values.phone_number1,
                telefono2: values.phone_number2,
                mostrar: values.mostrar_ocultar,
              })
              .eq("depositoId", warehouse.depositoId);
            if (error) errAndMsg("Error", error.message, error.code);
            setSubmitting(false);
            navigate("/configuracion/depositos");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="form-container form-container-three-colums">
              <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="descripcion">
                  Descripcion / Alias
                </label>
                <Field
                  className="form-control"
                  id="descripcion"
                  name="descripcion"
                  placeholder="Descripcion / Alias"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.descripcion}
                />
                <div className="invalid-feedback">
                  {errors.descripcion &&
                    touched.descripcion &&
                    errors.descripcion}
                </div>
              </div>
              <div className="form-group form-container__item">
                <label className="form-container__label" htmlFor="domicilio">
                  Domicilio
                </label>
                <Field
                  className="form-control"
                  id="domicilio"
                  name="domicilio"
                  placeholder="Domicilio"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.domicilio}
                />
                <div className="invalid-feedback">
                  {errors.domicilio && touched.domicilio && errors.domicilio}
                </div>
              </div>

              <div className="form-group form-container__item">
                <label
                  className="form-container__label"
                  htmlFor="phone_number1"
                >
                  Número de teléfono principal
                </label>
                <Field
                  className="form-control"
                  id="phone_number1"
                  name="phone_number1"
                  placeholder="Número de teléfono"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone_number1}
                />
                <div className="invalid-feedback">
                  {errors.phone_number1 &&
                    touched.phone_number1 &&
                    errors.phone_number1}
                </div>
              </div>
              <div className="form-group form-container__item">
                <label
                  className="form-container__label"
                  htmlFor="phone_number2"
                >
                  Número de teléfono secundario
                </label>
                <Field
                  className="form-control"
                  id="phone_number2"
                  name="phone_number2"
                  placeholder="Opcional"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone_number2}
                />
              </div>
              <div className="form-group form-container__item">
                <FormControlLabel
                  control={
                    <Switch
                      name="mostrar_ocultar"
                      color="error"
                      checked={values.mostrar_ocultar === true}
                      onChange={(event, checked) => {
                        setFieldValue(
                          "mostrar_ocultar",
                          checked ? true : false
                        );
                      }}
                    />
                  }
                  label="Está vigente?"
                />
              </div>
            </div>
            <div className="form-container__button">
              <button
                className="btn btn-dark"
                type="submit"
                disabled={isSubmitting}
              >
                {warehouse ? "Guardar cambios" : "Crear depósito"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddNewWarehouse;
