import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useStateAuthValue } from "../context/AuthState";

const IdleTimer = () => {
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useStateAuthValue();
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.removeItem("user");
    dispatch({
      type: "LOG_OUT",
    });
    navigate("/login");
  };

  const restartAutoReset = () => {
    const authenticated = Cookies.get("authenticated");
    const expirationDate = new Date(); // Calcula la fecha de vencimiento
    expirationDate.setMinutes(expirationDate.getMinutes() + 60);

    if (authenticated) {
      Cookies.set("onSession", "true", { expires: expirationDate });
      Cookies.set("authenticated", "true", { expires: expirationDate });
    }
  };

  const onMouseMove = () => {
    restartAutoReset();
  };

  useEffect(() => {
    const authenticated = Cookies.get("authenticated");
    const onSession = Cookies.get("onSession");
    if (!authenticated && !onSession) {
      logOut();
    }
    restartAutoReset();

    window.addEventListener("mousemove", onMouseMove);

    let interval = setInterval(() => {
      const authenticated = Cookies.get("authenticated");
      const onSession = Cookies.get("onSession");

      if (!authenticated || !onSession) {
        logOut();
      }
    }, 30000);

    return () => {
      if (interval) {
        clearInterval(interval);
        window.removeEventListener("mousemove", onMouseMove);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

export default IdleTimer;
