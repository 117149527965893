import React from "react";
import { Table } from "react-bootstrap";
import { currencyOperations } from "../../utils/NumberInput";
import { discountId } from "../../constants";

const OperationTotal = ({ recargo, total, formasDePagoEscogidas, header }) => {
  let discount = 0;
  for (let i = 0; i < formasDePagoEscogidas.length; i++) {
    if(formasDePagoEscogidas[i].formaPagoId === discountId){
      discount = Number(String(formasDePagoEscogidas[i].clientPaid).replace(/,/g, '.'))
    }
  }
  const totalOperation = currencyOperations(String(total), discount, "SUBTRAC");
  const netoGrabado = currencyOperations(String(totalOperation), "1,21", "DIVIDE");
  const iva = currencyOperations(totalOperation, netoGrabado, "SUBTRAC");

  return (
    <div>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            {header?.tipoFactura === "A" && (
            <>
            <th>Importe Neto Gravado</th>
            <th>IVA 21%</th>
            </>
            )}
            <th>Total de la operación</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {header?.tipoFactura === "A" && (
              <>
            <td>{`$${netoGrabado}`}</td>
            <td>{`$${iva}`}</td>
            </>
            )}
            <td>{`$${totalOperation}`}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default OperationTotal;
